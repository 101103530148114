import React, {useState} from 'react';
import ToggleableComponent from "@Generic/blocks/ToggleableComponent";
import Row from "@Containers/components/Row";
import IconChevron from "@Icons/chevrons/IconChevron";
import Translation from "@Translation/components/Translation";

interface TicketTermsProps {
    terms: string,
}

function TicketTerms({terms}: TicketTermsProps) {
    const [ticketTerms, showTicketTerms] = useState(false)
    const toggleTicketTermsAndConditions = () => showTicketTerms(!ticketTerms)
    const orientation = !ticketTerms ? 'down' : 'up'

    if (terms) {
        return (
            <div className="gb--column gb--accordion gb--accordion-default" onClick={toggleTicketTermsAndConditions}>
                <Row center justify className="gb--accordion__title">
                    <Translation translationKey="check_order.refund_rebooking_terms"/>
                    <IconChevron
                        fill="#070c63"
                        chevronOrientation={orientation}
                    />
                </Row>
                <ToggleableComponent display={ticketTerms}>
                    <hr/>
                    <span className="gb--accordion__content">{terms}</span>
                </ToggleableComponent>
            </div>
        );
    }
    return null
}

export default TicketTerms;