import React from "react";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";
import IconType from "@/types/Icon.type";
import IconBus from "@Icons/transportType/IconBus";
import IconFerry from "@Icons/transportType/IconFerry";
import IconTrain from "@Icons/transportType/IconTrain";
import IconPlane from "@Icons/transportType/IconPlane";
import IconTransfer from "@Icons/transportType/IconTransfer";


interface TabVehicleIconProps extends IconType {
    tag: RouteTransferTypeValuesType,
}

function TravelingDotComTabVehicleIcon({tag, ...rest}: TabVehicleIconProps) {
    const components = {
        bus: IconBus,
        ferry: IconFerry,
        train: IconTrain,
        plane: IconPlane,
        minivan: IconTransfer,
        privateTransfer: IconTransfer,
    };

    const IconVehicleTab = components?.[tag] ?? components.bus;

    return (
        <IconVehicleTab {...rest}/>
    )
}

export default TravelingDotComTabVehicleIcon