import React from 'react';
import IconStar from "@Icons/searchRes/IconStar";
import IconDuration from "@Icons/searchRes/filters/IconDuration";
import IconDepartureTime from "@Icons/searchRes/filters/IconDepartureTime";
import {Colors} from "@Styles/Colors.style";
import IconCheapest from "@Icons/searchRes/filters/IconCheapest";

interface RouteIcTravelingDotComSortIconsProps {
    tag?: string
}

function TravelingDotComSortIcons({tag = "info"}: RouteIcTravelingDotComSortIconsProps) {
    const components = {
        departure: IconDepartureTime,
        rating: IconStar,
        travel_time: IconDuration,
        price: IconCheapest,
    };

    const SortIcon = components[tag];

    return (
        <SortIcon
            width="18"
            height="18"
            stroke={Colors.neutralDarkestColor}
            fill="none"
        />
    )
}

export default TravelingDotComSortIcons;
