import escapeRegexCharacters from "@String/escapeRegexCharacters";
import ajaxHandler from "@Ajax/v2/ajaxHandler";
import sortSuggestions from "@Form/functions/sortSuggestions";

/**
 * Fetches new suggestions array
 * @param input
 * @returns {Promise<*[]|*>}
 */
export const fetchSuggestions = async (input) => {
    if (input) {
        const value = escapeRegexCharacters((typeof input === "string") ? input : input?.label)
        const url = `/stations.php?Lang=${window.currentLocale.toUpperCase()}&term=${value}&fullCountry=1&v=${window.searchFormVersion ?? '1'}`

        if (value.length > 2) {
            try {
                const response = await ajaxHandler({
                    url,
                })

                return sortSuggestions(response?.data ?? [], input)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error)
                return []
            }
        }
    }
}