import TransportTypeCards from "@Pages/homepage/features/transportTypes/components/TransportTypeCards";
import React from "react";

type TransportTypesSectionProps = {}

function TransportTypesSection(props: TransportTypesSectionProps) {
    return (
        <section className="transport-types-section">
            <TransportTypeCards/>
        </section>
    );
}

export default TransportTypesSection;