import React from "react";
import VoucherType from "@MyVoucher/types/Voucher.type";
import SuccessfulRebook from "@UserModals/features/manageBookings/components/SuccessfulRebook";
import UnsuccessfulRebook from "@UserModals/features/manageBookings/components/UnsuccessfulRebook";
import ManageBookingForm from "@UserModals/features/manageBookings/components/ManageBookingForm";
import TripleDotLoader from "@Generic/loaders/TripleDotLoader";

interface ModalBodyProps {
    loadingData: boolean,
    isOneCompanyReturnTicket: boolean,
    successfulRebook: boolean,
    unsuccessfulRebook: boolean,
    voucher: VoucherType,
    radioButtonLabels: any,
    isFirstLegExpired: boolean,
    option: string | null,
    hasPremiumSupport: boolean,
}

function ManageBookingModalBody(props: ModalBodyProps) {
    const {
        loadingData,
        isOneCompanyReturnTicket,
        successfulRebook,
        unsuccessfulRebook,
        voucher,
        radioButtonLabels,
        isFirstLegExpired,
        option,
        hasPremiumSupport,
    } = props

    if (loadingData) {
        return (
            <div className="manage-booking-modal-body">
                <TripleDotLoader small/>
            </div>
        );
    }

    if (successfulRebook) {
        return (
            <SuccessfulRebook
                voucher={voucher}
            />
        )
    }
    if (unsuccessfulRebook) {
        return (
            <UnsuccessfulRebook/>
        )
    }
    return (
        <ManageBookingForm
            radioButtonLabels={radioButtonLabels}
            isOneCompanyReturnTicket={isOneCompanyReturnTicket}
            isFirstLegExpired={isFirstLegExpired}
            option={option}
            hasPremiumSupport={hasPremiumSupport}
        />
    );
}

export default ManageBookingModalBody