import Link from "@Generic/navigation/Link";
import Row from "@Containers/components/Row";
import {useClickBannerEvent} from "@Events/hooks/useClickBannerEvent";
import TransfersVehicleType
    from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersVehicle.type";
import IconUserOutline from "@Icons/user/IconUserOutline";
import IconArrowAirplane from "@Icons/arrows/IconArrowAirplane";
import {Colors} from "@Styles/Colors.style";
import IconHandLuggage from "@Icons/searchRes/amenities/IconHandLuggage";

interface GetByTransferVehicleSnippetProps {
    vehicleData: TransfersVehicleType
}

function GbtVehicleSnippet({vehicleData}: GetByTransferVehicleSnippetProps) {
    const {
        link: redirectUrl,
        imageUrl,
        vehicleGroupName,
        maxNumberOfPassengers,
        priceWithCurrency,
        price,
    } = vehicleData

    const bannerClicked = useClickBannerEvent({
        bannerType: 'snippet',
        bannerName: "transfer",
        bannerLink: redirectUrl,
        bannerVehicleType: null,
        bannerPrice: price,
    })

    return (
        <Link
            href={redirectUrl}
            className="gb--card gb--card-full gb--card-enlarge gb-card--transfer"
            onClick={() => {
                bannerClicked()
            }}
        >
            <img
                className="gb--card__image"
                src={imageUrl}
                alt={vehicleGroupName}
            />
            <div className="gb--card__content">
                <p className="gb--card__subtitle">
                    {vehicleGroupName}
                </p>
                <Row center gap className="gb--card__info-section">
                    <Row center className="gb--card__info">
                        <IconUserOutline/>
                        {maxNumberOfPassengers}
                    </Row>
                    <Row center className="gb--card__info">
                        <IconHandLuggage/>
                        {maxNumberOfPassengers}
                    </Row>
                </Row>
                <Row
                    className="transfer__price"
                    center
                    justify
                >
                    <div>
                        <span>
                            from
                            &nbsp;
                        </span>
                        <span className="transfer__price-main">
                            {priceWithCurrency}
                        </span>
                    </div>
                    <IconArrowAirplane
                        fill={Colors.neutralDarkestColor}
                        className="icon-arrow"
                        width="26"
                        height="26"
                    />
                </Row>
            </div>
        </Link>
    )
}

export default GbtVehicleSnippet