import {Palette} from "@Styles/Palette.style";

export const Colors = {
    // Primary colors
    primaryDarkestColor: Palette.darkPurple,
    primaryDarkerColor: Palette.russianViolet,
    primaryDarkColor: Palette.indigo,
    primaryColor: Palette.frenchViolet,
    primaryLightColor: Palette.veronica,
    primaryLighterColor: Palette.mauve,
    primaryLightestColor: Palette.mangolia,

    // Secondary color
    secondaryDeepColour: Palette.darkGreen,
    secondaryDarkestColor: Palette.brunswickGreen,
    secondaryDarkerColor: Palette.seaGreen,
    secondaryDarkColor: Palette.emerald,
    secondaryColor: Palette.aquamarine,
    secondaryLightColor: Palette.mintGreen,
    secondaryLightestColor: Palette.mintCream,

    // Information colors
    informationDarkestColor: Palette.richBlack,
    informationDarkerColor: Palette.delftBlue,
    informationDarkColor: Palette.trueBlue,
    informationColor: Palette.cornflowerBlue,
    informationLightColor: Palette.jordyBlue,
    informationLighterColor: Palette.lavander,
    informationLightestColor: Palette.aliceBlue,

    // Warnings colors
    warningDarkestColor: Palette.bistre,
    warningDarkerColor: Palette.cafNoir,
    warningDarkColor: Palette.goldenBrown,
    warningColor: Palette.orange,
    warningLightColor: Palette.saffron,
    warningLighterColor: Palette.peach,
    warningLightestColor: Palette.cosmicLatte,

    // Error colors
    errorDarkestColor: Palette.licorice,
    errorDarkerColor: Palette.blackBean,
    errorDarkColor: Palette.chestnut,
    errorColor: Palette.tomato,
    errorLightColor: Palette.coralPink,
    errorLighterColor: Palette.teaRoseRed,
    errorLightestColor: Palette.lavenderBlush,

    neutralBlackColor: Palette.black,
    neutralDarkestColor: Palette.jet,
    neutralDarkerColor: Palette.dimGray,
    neutralDarkColor: Palette.powderBlue,
    neutralMediumColor: Palette.iron,
    neutralLightColor: Palette.aliceBlue,
    neutralLighterColor: Palette.ghostWhite,
    neutralLightestColor: Palette.offWhite,
    neutralWhiteColor: Palette.white,

    // Label colors
    informationLabelColor: Palette.icyBlue,
    warningLabelColor: Palette.crayola,
    errorLabelColor: Palette.melon,
    successLabelColor: Palette.celadon,
};
