import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconLightning(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m11.3523913-.6-4.50103568 4.9726221h3.72581748l-12.04221768 8.6869839 3.13496782-5.64756743h-2.82802424l5.65681494-8.01203857z"
                    fill="none"
                    stroke={stroke}
                    stroke-linejoin="round"
                    stroke-width="1.2"
                    transform="translate(3 3)"
                />
            )}
        />
    )
}

export default IconLightning