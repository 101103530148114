import React from 'react';
import Translation from "@Translation/components/Translation";
import IconLocationMarker from "@Icons/IconLocationMarker";
import useMobileStationInput from "@Form/hooks/useMobileStationInput";
import FormInputError from "@Form/components/FormInputError";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";
import TravelingDotComStationSwitchButton from "@Form/components/TravelingDotComStationSwitchButton";
import {Colors} from "@Styles/Colors.style";

interface MobileStationInputButtonProps {
    direction: "departure" | "destination"
    formVersion?: FormVersionType
}

function MobileStationInputButton({direction, formVersion = "1"}: MobileStationInputButtonProps) {
    const {
        className,
        onClick,
        directionLabel,
        placeholder,
        station,
        isDeparture,
    } = useMobileStationInput(direction, formVersion)

    const {v} = getFormVersionClassNameAggregator(formVersion)

    return (
        <div
            className={className}
            onClick={onClick}
            role="button"
        >
            <span className={`gb--search${v()}__select--label`}>
                <Translation translationKey={directionLabel}/>
            </span>
            <IconLocationMarker
                className={`gb--search${v()}__select--icon`}
                height="14"
                fill={Colors.primaryColor}
            />
            <input
                placeholder={placeholder}
                readOnly
                type="text"
                value={station?.label ?? ''}
            />
            <FormInputError
                direction={direction}
            />
            {isDeparture && (
                <TravelingDotComStationSwitchButton/>
            )}
        </div>
    );
}

export default MobileStationInputButton;