import React from 'react';
import IconCheckmark from "@Icons/IconCheckmark";
import Translation from "@Translation/components/Translation";
import {Colors} from "@Styles/Colors.style";

interface SortOptionItemProps {
    onClick: (any?) => (any),
    isSelected: boolean,
    label: string,
    additionalLabel?: string,
}

function SortOptionItem(props: SortOptionItemProps) {
    const {
        onClick,
        isSelected,
        label,
        additionalLabel,
    } = props

    const setActiveClassName = () => {
        let className = 'gb--row gb--row-middle gb--row-justify sort-list__item '
        if (isSelected) {
            className += 'sort-list__item--selected'
        }
        return className
    }

    return (
        <li className={setActiveClassName()} onClick={onClick}>
            <span role="button" className="gb--row sort-list__item--action">
                <Translation translationKey={label}/>
            </span>
            {!isSelected && additionalLabel && (
                <span className="gb--emphasize" style={{marginLeft: '.5rem'}}>
                    {`${additionalLabel}`}
                </span>
            )}
            {isSelected && (<IconCheckmark width="16" height="16" fill={Colors.primaryColor}/>)}
        </li>
    );
}

export default SortOptionItem;