import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import React from "react";
import PriceFractionDisplay from "@CheckOrder/features/paymentSummary/components/PriceFractionDisplay";

const VoucherSummary = () => {
    const voucherIssuer = useAppSelector((state) => state?.checkOrder.ticket?.voucher?.voucherDetails?.voucherIssuer);
    if (voucherIssuer) {
        return (
            voucherIssuer.map((issuer) => {
                const isFixed = issuer?.currency
                const currencyOrPercentage = isFixed ? ` ${issuer?.currency?.shortCode}` : '%';
                return (
                    <PriceFractionDisplay
                        fractionTitle={<Translation translationKey="check_order.voucher.voucher"/>}
                        price={`-${issuer.amount}${currencyOrPercentage}`}
                    />
                )
            })
        )
    }
}
export default VoucherSummary;