import React, {useEffect, useState} from 'react';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import TicketType from "@MyTicket/types/Ticket.type";
import TicketDetails from "@MyTicket/features/ticketDetails/components/TicketDetails";
import Ticket from "@MyTicket/components/Ticket";
import {useScroll} from "@Scroll/hooks/useScroll";
import useHandleTicketSelected from "@MyTicket/hooks/useHandleTicketSelected";

interface TicketProps {
    ticket: TicketType,
    isActive: boolean,
    isArchived: boolean,
    className: string,
}

function TicketAccordion({ticket, isActive, isArchived, className}: TicketProps) {
    const {elRef, executeScroll} = useScroll({behavior: "instant", block: "start"})
    const {
        id,
        status,
    } = ticket

    const {
        isIntermodal,
    } = status

    const [accordionOpen, setAccordionOpen] = useState(false)
    const cardClassName = () => (`gb--card${accordionOpen ? "-expanded" : ""}${isIntermodal ? "-intermodal" : ""}`)

    const {navigateToSingleTicket} = useHandleTicketSelected(ticket)

    useEffect(() => {
        if (isActive) {
            elRef.current.click()
            executeScroll()
        }
    }, []);

    return (
        <Card
            className={`panel gb--card ${cardClassName()} ${className}`}
        >
            <Accordion.Toggle
                ref={elRef}
                onClick={navigateToSingleTicket}
                as={Card.Header}
                id={id}
                eventKey={`${id}`}
                className="gb--route gb--route-search panel-heading"
            >
                <Ticket
                    ticket={ticket}
                />
            </Accordion.Toggle>
            <Accordion.Collapse
                eventKey={`${id}`}
                onEnter={() => {
                    setAccordionOpen(true)
                }}
                onExit={() => {
                    setAccordionOpen(false)
                }}
            >
                <TicketDetails
                    ticket={ticket}
                    isArchived={isArchived}
                />
            </Accordion.Collapse>
        </Card>
    );
}

export default TicketAccordion;