import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconPaperTicket(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m10.68534 6.0711c.3313708 0 .6.26862915.6.6v4.00299c0 .3313708-.2686292.6-.6.6h-8.17485c-.33137085 0-.6-.2686292-.6-.6v-4.00299c0-.33137085.26862915-.6.6-.6zm-.60034 1.1999h-6.975v2.803h6.975zm-8.885-2.035v3.01079h-1.2v-3.61032c0-.33137085.26862915-.6.6-.6l1.31-.00047.00049-3.436c0-.29823376.21758961-.54564675.50267688-.59214701l.09732312-.00785299h8.17485c.3313708 0 .6.26862915.6.6l-.00034 3.436 1.31014.00047c.2982338 0 .5456468.21758961.592147.50267688l.007853.09732312v3.61032h-1.2l-.00014-3.01079-1.30966.00047h-8.17485zm8.885-4.036h-6.975v2.836h6.975z"
                    fill={fill}
                    transform="translate(1.4 2.4)"
                />
            )}
        />
    )
}

export default IconPaperTicket