import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";

interface TranslationProps {
    translationVariables?: object,
    translationKey: string,
    styleText?: 'upperCase' | 'lowerCase' | 'firstUpper' | 'none',
    exact?: boolean,
}

const Translation = (props: TranslationProps) => {
    const {
        styleText = 'none',
        translationKey,
        translationVariables = {},
        exact = false,
    } = props

    const translated = useTranslation(translationKey, translationVariables, exact)
    const styledTranslation = {
        [`${styleText === 'upperCase'}`]: translated?.toUpperCase(),
        [`${styleText === 'lowerCase'}`]: translated?.toLowerCase(),
        [`${styleText === 'firstUpper'}`]: translated.charAt(0).toUpperCase() + translated.slice(1),
        [`${styleText === 'none'}`]: translated,
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {styledTranslation?.true ?? translationKey}
        </>
    )
}

export default Translation