import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconSeatbelt(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(2.1788 .9265)"
                >
                    <circle cx="4.777514" cy="3.9565" r="3.3565"/>
                    <path
                        d="m9.77033924 14.0230454c0-2.6980046-2.18716494-4.88516959-4.88516962-4.88516959s-4.88516962 2.18716499-4.88516962 4.88516959"
                    />
                    <path d="m11.573277 6.111176-7.585945 7.303712" strokeLinecap="square"/>
                </g>
            )}
        />
    )
}

export default IconSeatbelt