import React from "react";

export default function scaleFontSizeByCharacterCount(
    ref: React.RefObject<HTMLElement>,
    length: number,
    minSize: number,
    maxSize: number,
    exclusionSize: number
) {
    const divWidth = (ref?.current?.clientWidth ?? 330) - exclusionSize;
    const scale = 1.618;
    const maxSizeAllowedPerChar = divWidth / length;
    const fontSize = Math.ceil(maxSizeAllowedPerChar * scale);

    if (fontSize > maxSize) {
        return maxSize;
    }
    if (fontSize < minSize) {
        return minSize;
    }

    return fontSize;
}