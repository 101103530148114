import React from 'react';
import useAddVoucher from "@CheckOrder/hooks/useAddVoucher";
import Button from "@Generic/buttons/Button";
import Translation from "@Translation/components/Translation";

interface ApplyVoucherButtonProps {
    voucherCode: string,
}

function ApplyVoucherButton({voucherCode}: ApplyVoucherButtonProps) {
    const addVoucher = useAddVoucher(voucherCode)

    return (
        <Button
            onClick={addVoucher}
            className="green"
            additionalClasses="btn--inline"
        >
            <Translation translationKey="check_order.apply"/>
        </Button>
    );
}

export default ApplyVoucherButton;