import Translation from "@Translation/components/Translation";

export const CompanyDirectSubmitButton = ({onSubmit}: {onSubmit: () => void}) => {
    return (
        <button
            id="submitNonGbbCompany"
            type="submit"
            className="btn btn-lg btn-submit btn--green"
            onClick={onSubmit}
        >
            <Translation
                translationKey="place_review.submit_your_review"
                styleText="firstUpper"
            />
        </button>
    )
}