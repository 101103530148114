import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconAccommodationBanner(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={fill}>
                    <path
                        d="m0 0 2.135217.00046933c1.2443296.05395264 2.0224382 1.19621987 1.9174997 2.29385487l-.0137167.0986757.0524564.0509939c.4198485.4282696.7032471.9400604.7843671 1.4701877l.0194578.1772988c.0194097.3004565.0328841.8118014.0417762 1.4806227.0050292.3782783.0082593.7832246.0100536 1.1881213.0004306.0971666.0007468.1857129.0009704.2639194l.0004654.2763376c0 .2761426-.2238579.5-.5.5h-4.4485469v-1l3.947-.0004811.0001211-.0353441c-.0011882-.2681098-.00301-.5361972-.0055694-.7962375l-.004406-.3830218c-.008667-.6519099-.0218341-1.1515851-.0397296-1.4286132-.0143177-.2159996-.0978842-.4426773-.2374904-.6605175l-.0719256-.1032662-.0478557.057589-.1086733.1105779c-.4808223.4523153-1.1065791.6221804-1.615888.330765-.6444414-.3678234-.794893-1.1382106-.4242233-1.7227023.3100467-.4888982.8747811-.6924236 1.4705927-.5259288l.1260474.0416992-.0113623-.0346204c-.1296296-.3215072-.3991013-.5811292-.7540765-.6391582l-.1090033-.0112213h-2.1135578zm2.7803113 2.6868486c-.2816705-.1609196-.4553442-.1225729-.5444536.0179398-.0767658.121048-.0524266.2456763.0758915.3189161.0666299.0381239.2415462-.0093579.4335427-.189969.0284238-.0268853.0547972-.0548282.0791743-.0836892l.0185337-.0250464z"
                        transform="matrix(.00000001 -1 -1 -.00000001 14.1056 9.7319)"
                    />
                    <path
                        d="m9.9280052 0c.1656848 0 .2999998.13431458.2999998.30000001v3.05236049h-10.228005v-3.05236049c0-.16568543.13431458-.30000001.30000001-.30000001zm-.7000055 1h-8.2279997v1.352h8.2279997z"
                        transform="translate(5.09138 9.47562)"
                    />
                    <path d="m0 0h1v1.8387668h-1z" transform="translate(5.08636 12.4819)"/>
                    <path d="m0 0h1v1.8387668h-1z" transform="translate(14.3225 12.4819)"/>
                    <path
                        d="m2.3738508 0c1.4183903 0 2.5756054 1.1031408 2.5756054 2.4737804 0 1.370614-1.1575584 2.4734194-2.5763624 2.4734194-1.0052678 0-1.9070936-.5599952-2.32999133-1.4225237-.18160148-.3703885.24834588-.7399089.58723006-.5046978.2453897.1703191.53348357.2632367.83625917.2632367.7850458 0 1.4137613-.5990636 1.4137613-1.32797 0-.5059987-.308744-.96215497-.7931023-1.18652622-.3781871-.17518908-.2697905-.73970116.1463866-.76235784.0133682-.00077538.0133682-.00077538.0294392-.00182551.0085636-.00056148.0085636-.00056148.017839-.00115388.0407305-.0024602.0407305-.0024602.0929353-.00338155zm1.1644051 1.212.0405279.1073927c.0662348.2017342.1015691.415518.1015691.6358523 0 1.0257478-.7525906 1.8766375-1.7500759 2.0811572l-.125021.020598.0984076.0306892c.1001563.0259423.2035547.0437441.3092597.0527291l.1601705.0067816c.9854141 0 1.7763622-.7535362 1.7763622-1.6734197 0-.4842222-.2190323-.9223878-.5701287-1.2288441z"
                        transform="matrix(-1 0 0 1 5.23007 2.93748)"
                    />
                    <path
                        d="m.74945092 1.0470321-.52994184.2323606.23236059-.52994178-.23236059-.52994184.52994184.23236059.52994178-.23236059-.2323606.52994184.2323606.52994178z"
                        fillRule="evenodd"
                        transform="matrix(-.707107 .707107 -.707107 -.707107 6.0337 3.38918)"
                    />
                    <path
                        d="m1.0598836 0-.23236055.5299418.23236055.5299418-.5299418-.23236055-.5299418.23236055.2323606-.5299418-.2323606-.5299418.5299418.2323606z"
                        transform="matrix(-.707107 .707107 -.707107 -.707107 5.72327 3.38918)"
                    />
                    <path
                        d="m.73312831-0-.1607253.36656415.1607253.36656416-.36656416-.1607253-.36656415.1607253.16072531-.36656416-.16072531-.36656415.36656415.16072531z"
                        transform="matrix(-.707107 .707107 -.707107 -.707107 4.37337 2.41251)"
                    />
                </g>
            )}
        />
    )
}

export default IconAccommodationBanner