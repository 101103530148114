import Translation from "@Translation/components/Translation";
import {Day, DaysIndex} from "@Route/types/travelInfo/timetable/RouteTimetable.type";

interface OperationalDaysProps {
    operationalDays: Record<DaysIndex, Day>
}

const OperationalDays = ({operationalDays}: OperationalDaysProps) => (
    <div>
        <hr/>
        <span className="gb--emphasize gb--emphasize-info">
            <Translation translationKey="sys.operational_days.operational_days"/>
        </span>
        <br/>
        {Object.keys(operationalDays).map((daysIndex, index) => (
            <span key={daysIndex}>
                <Translation translationKey={`sys.days.${operationalDays[daysIndex]}`}/>
                {Object.keys(operationalDays).length - 1 !== index && `, `}
            </span>
        ))}
    </div>
)

export default OperationalDays