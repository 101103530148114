import formatDate from "@DateTime/functions/formatDate";
import ModalRouteDataType from "@UserModals/types/ModalRouteData.type";

export default function createHeaderInfoTexts(singleRoute: ModalRouteDataType, returnRoute: ModalRouteDataType) {
    const {
        departure: outboundDeparture,
        destination: outboundDestination,
        departureTime: outboundDepartureTime,
        departureDate: outboundDepartureDate,
    } = singleRoute

    const {
        departureTime: returnDepartureTime,
        departureDate: returnDepartureDate,
    } = returnRoute || {}

    const outboundDateAndTime = `${outboundDepartureDate} ${outboundDepartureTime}`
    const returnDateAndTime = `${returnDepartureDate} ${returnDepartureTime}`

    const displayDateAndTime = (dateAndTime: string) => (`${formatDate(dateAndTime, "MMM dd, yyyy | HH:mm")}h`)

    const routeEndStationsInfo = `${outboundDeparture} - ${outboundDestination}${returnRoute ? ` - ${outboundDeparture}` : ""}`
    const routeDepartureTimesInfo = `${displayDateAndTime(outboundDateAndTime)}${returnRoute ? ` - ${displayDateAndTime(returnDateAndTime)}` : ""}`

    return {
        routeEndStationsInfo,
        routeDepartureTimesInfo,
    }
}