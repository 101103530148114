import React, {ReactNode, useState} from 'react';
import GenericCheckbox from "@Generic/form/GenericCheckbox";

interface CheckboxProps {
    children?: ReactNode,
    onChange: () => void,
    isChecked?: boolean,
    disableChange?: boolean,
    className?: string,
    onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void,
    disabled?: boolean,
}

function Checkbox({children, onChange, isChecked = false, disableChange = false, className = "", onBlur, disabled}: CheckboxProps) {
    const [checked, setChecked] = useState(isChecked)
    const onClick = () => {
        if (!disableChange && !disabled) {
            setChecked(!checked);
            onChange()
        }
    }

    return (
        <GenericCheckbox
            className={className}
            checked={checked}
            onClick={onClick}
            onBlur={onBlur}
            disabled={disabled}
        >
            {children}
        </GenericCheckbox>
    );
}

export default Checkbox;