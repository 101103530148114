import React from 'react';
import Row from "@Containers/components/Row";
import ConnectionDivider from "@Generic/station/ConnectionDivider";
import Column from "@Containers/components/Column";
import Link from "@Generic/navigation/Link";
import Translation from "@Translation/components/Translation";
import SidebarSectionContainerItem from "../Sections/SidebarSectionContainerItem";
import LatestTicketType from "@Sidebar/types/LatestTicket.type";

interface TicketProps {
    ticket: LatestTicketType,
}

function Ticket({ticket}: TicketProps) {
    const {
        departureStationName,
        destinationStationName,
        boughtTime,
        link,
    } = ticket

    return (
        <SidebarSectionContainerItem className="gb--sidebar-latest-ticket">
            <Row center>
                <ConnectionDivider customClass="gb--connect-divider__sidebar"/>
                <Column className="gb--sidebar-latest-ticket-stations">
                    <span>
                        {departureStationName}
                    </span>
                    <span>
                        {destinationStationName}
                    </span>
                </Column>
            </Row>
            <Column alignEnd className="gb--sidebar-latest-ticket-more-info">
                <span>
                    {boughtTime}
                </span>
                <Link href={link} className="gb--emphasize-info">
                    <Translation translationKey="sys.route_info"/>
                </Link>
            </Column>
        </SidebarSectionContainerItem>
    );
}

export default Ticket;