import React from 'react'
import Row from "@Containers/components/Row";
import ProgressBarDot from "@Pages/mobileApp/features/bookingSection/components/ProgressBarDot";
import BookingInfoType from "@Pages/mobileApp/features/bookingSection/types/bookingInfo.type";

interface DotsProgressBarProps {
    items: BookingInfoType[],
    activeIndex: number
}

const DotsProgressBar = ({items, activeIndex}: DotsProgressBarProps) => (
    <Row gap>
        {items.map((item, index) => (
            <ProgressBarDot
                key={item.index}
                color={item.color}
                isActive={items[activeIndex].index === index}
            />
        ))}
    </Row>
)

export default DotsProgressBar