import intervalToDuration from 'date-fns/intervalToDuration'
import parseISO from 'date-fns/parseISO'
import formatDuration from 'date-fns/formatDuration'

/**
 * Returns duration object as formatted string
 */
export const calculateTripDuration = (departureTime: string, arrivalTime: string): string => {
    const start = parseISO(departureTime)
    const end = parseISO(arrivalTime)
    const duration = intervalToDuration({start, end})

    return formatDuration(duration)
        .replace(' hour', 'h')
        .replace(' hours', 'h')
        .replace(' minutes', 'min')
};