import React from 'react';
import {BrandFaqCard} from "@Features/faq/components/BrandFaqCard";

interface FaqCardProps {
    index?: number,
    activeIndex: number,
    setActiveIndex: (value: number) => void,
}

function FaqCard({index, activeIndex, setActiveIndex}: FaqCardProps) {
    const {question, answer}: {
        question: string,
        answer: string
        // @ts-ignore this is how the FaqPortal works, data is actually in twig elements not displayed to user
    } = document.getElementsByClassName('gb__faq-question')[index].dataset;

    const displayAnswer = index === activeIndex;

    const handleItemClick = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <BrandFaqCard
            question={question}
            answer={answer}
            index={index}
            displayAnswer={displayAnswer}
            handleItemClick={handleItemClick}
        />
    )
}

export default FaqCard;