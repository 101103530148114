import {PassengersTypeType} from "@Form/types/PassengersType.type";
import {addPassenger, removePassenger} from "@Form/reducers/formSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import usePassengers from "@Hooks/selector/usePassengers";
import {
    minTotalPassengersCount,
    passengerCountPerCategoryLowerLimit,
    passengerCountPerCategoryUpperLimit
} from "@Form/features/passengers/constants/passengerCountLimit";
import {ControlType} from "@Generic/controls/CountControl";
import {Colors} from "@Styles/Colors.style";

interface UseHandlePassengersReturnType {
    incrementControl: ControlType,
    decrementControl: ControlType
}

export default function useHandlePassenger(passengerType: PassengersTypeType): UseHandlePassengersReturnType {
    const dispatch = useAppDispatch()
    const {passengers: totalPassengers, ...passengerCounts} = usePassengers()

    const handlePassenger = (type: PassengersTypeType, action: "add" | "remove") => {
        if (action === 'add') {
            dispatch(addPassenger(type))
        } else {
            dispatch(removePassenger(type))
        }
    };

    const disableIncrement = !(passengerCounts[passengerType] < passengerCountPerCategoryUpperLimit)
    const disableDecrement = !(totalPassengers > minTotalPassengersCount && passengerCounts[passengerType] > passengerCountPerCategoryLowerLimit)

    const incrementCount = () => {
        if (!disableIncrement) {
            handlePassenger(passengerType, "add")
        }
    }

    const decrementCount = () => {
        if (!disableDecrement) {
            handlePassenger(passengerType, "remove")
        }
    }

    return {
        incrementControl: {
            handle: incrementCount,
            className: "pax--counter gb--row gb--row-middle",
            iconStyle: disableIncrement ? {backgroundFill: Colors.secondaryLightestColor, fill: Colors.secondaryLightColor} : {},
        },
        decrementControl: {
            handle: decrementCount,
            className: "pax--counter gb--row gb--row-middle",
            iconStyle: disableDecrement ? {backgroundFill: Colors.secondaryLightestColor, fill: Colors.secondaryLightColor} : {},
        },
    }
}