import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconWarning(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.32"
                    transform="translate(1.0413 1.7591)"
                >
                    <path
                        d="m6.95868127-0-6.95868127 11.8985795h13.9173625z"
                        strokeLinejoin="round"
                        transform="matrix(-1 0 0 1 13.9174 0)"
                    />
                    <path d="m6.958681 4.250946v4.170324"/>
                    <path d="m6.958681 9.055344v.922101"/>
                </g>

            )}
        />
    )
}

export default IconWarning