import React from 'react';
import Translation from "@Translation/components/Translation";
import Date from "@DateTime/components/Date";
import getFormattedTravelTime from "@Route/functions/getFormattedTravelTime";
import GenericPopover from "@Popover/components/GenericPopover";
import Row from "@Containers/components/Row";

interface RouteOperatingDateProps {
    date: string,
    routeId: string,
    format?: string,
    className?: string,
    travelTime: string,
    showOperatingDate: boolean,
}

function RouteOperatingDate(props: RouteOperatingDateProps) {
    const {
        date,
        routeId,
        format = "dd MMM | ",
        className = "route--date gb--emphasize gb--emphasize-info",
        travelTime,
        showOperatingDate,
    } = props

    const setClassName = () => {
        let className = '';

        if (showOperatingDate) {
            className = 'route--date-time'
        }
        return className
    }

    const displayTrigger = () => (
        <Row className={setClassName()}>
            {showOperatingDate && (
                <Date
                    date={date}
                    className={className}
                    format={format}
                />
            )}
            <span className="gb--emphasize gb--emphasize-default gb--route-time">
                {getFormattedTravelTime({travelTime})}
            </span>
        </Row>
    )

    const displayPopover = () => (
        <span className="gb--emphasize-warning">
            <Translation translationKey="sys.route"/>
            {' '}
            ID:
            {routeId}
        </span>
    )

    return (
        <GenericPopover
            trigger={displayTrigger()}
            overlay={displayPopover()}
            id={routeId}
        />
    )
}

export default React.memo(RouteOperatingDate);