import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconNoInfoAvailable(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                    <path
                        d="m13.5595984 6.99046131c0 3.74437969-3.0354195 6.77979919-6.77979921 6.77979919-1.05351553 0-2.05090766-.2402928-2.94035863-.6690608.22819596-.2448222.51195371-.5479446.86835549-.9286685.6405228.2566936 1.33979264.3977293 2.07200314.3977293 3.081638 0 5.57979921-2.4981612 5.57979921-5.57979919 0-.86281156-.1958345-1.67988353-.5454791-2.40919163.2676627-.28595103.5557993-.59375107.8669324-.926117.5599014.98337749.8785467 2.12213125.8785467 3.33530863zm-6.77979921-6.7797992c1.51368541 0 2.91151087.49605543 4.03978031 1.33447024l-.2176767.23391599c-.2136432.22822295-.4163812.44479642-.6092969.6508774-.90720388-.64247035-2.0160029-1.01926363-3.21280671-1.01926363-3.081638 0-5.57979919 2.4981612-5.57979919 5.5797992 0 1.32864677.46438298 2.54883109 1.23969396 3.50709799-.3454244.3697129-.61371878.6563166-.82726303.8844339-1.00506319-1.1835324-1.61243093-2.71660297-1.61243093-4.39153189 0-3.7443797 3.03541949-6.7797992 6.77979919-6.7797992z"
                        fill={stroke}
                        fillRule="nonzero"
                    />
                    <g stroke={stroke} strokeWidth="1.2">
                        <path d="m6.77979919 6.5945929v4.1395411"/>
                        <path d="m6.779799 4.633561v1.174046"/>
                        <path d="m1.538447 13.589814 11.739041-12.624205"/>
                    </g>
                </g>
            )}
        />
    )
}

export default IconNoInfoAvailable