import {isLightBackground} from "@Header/functions/isLightBackground";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Should some css-classes/icon properties change depanding on the color of header background
 */
export default function useLogoColorConstant() {
    const {headerBackgroundImageRgbPixelData} = useAppSelector((state) => state?.page)
    const displayInverseLogo = () => (
        window.currentRoute === 'base_permalink' && isLightBackground(headerBackgroundImageRgbPixelData)
    )
    // 0.2126R+0.7152G+0.0722B
    return {
        displayInverseLogo: displayInverseLogo(),
    }
}