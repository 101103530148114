import React, {ReactNode} from "react";

interface CircleProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children" | "className"> {
    children: ReactNode,
    additionalClassName?: string,
}

/**
 * "Prototype" component
 * Circle component wrapper, to avoid "padding šteming" on different labels
 */
function Circle({additionalClassName = "", children, ...props}: CircleProps) {
    return (
        <div className={`trvl--circle ${additionalClassName}`} {...props}>
            {children}
        </div>
    )
}

export default Circle