import React from "react";
import formatDate from "@DateTime/functions/formatDate";
import fromUnixTime from "date-fns/fromUnixTime";
import Column from "@Containers/components/Column";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconCalendarAvailableDates from "@Icons/searchRes/IconCalendarAvailableDates";

interface SuggestedDateProps {
    unixFormatDate: number,
    searchSuggestedDate: (unixFormatDate: number) => void,
    hasNoRoutes: boolean,
}

function SuggestedDate({unixFormatDate, searchSuggestedDate, hasNoRoutes}: SuggestedDateProps) {
    const formattedDate = formatDate(fromUnixTime(unixFormatDate), 'dd MMM')
    const day = formatDate(fromUnixTime(unixFormatDate), 'eeee')

    const {
        results_available: resultsAvailableLabel,
        results_unavailable: resultsUnavailableLabel,
    } = useTranslation("search_results.suggested_dates")

    const search = () => {
        if (!hasNoRoutes) {
            searchSuggestedDate(unixFormatDate)
        }
    }

    return (
        <button
            className={`suggested--dates-card suggested--dates-card_${hasNoRoutes ? "unavailable" : "available"}-date`}
            type="button"
            onClick={search}
        >
            <IconCalendarAvailableDates
                isChecked={!hasNoRoutes}
                width="56"
                height="56"
            />
            <Column className="date-info">
                <span className="date-info__date gb--emphasize-default">
                    {formattedDate}
                </span>
                <span className="date-info__day-of-week">
                    {day}
                </span>
            </Column>
            <p className={`suggested--dates-card_${hasNoRoutes ? "unavailable" : "available"}-date-cta`}>
                {hasNoRoutes ? resultsUnavailableLabel : resultsAvailableLabel}
            </p>
        </button>
    )
}

export default SuggestedDate