import entries from "@Object/entries";
import React from "react";
import Translation from "@Translation/components/Translation";
import {isNotEmpty} from "@Array/isNotEmpty";
import {PopularLinesTypeType} from "@/types/pageSlice/homepageData/PopularLinesType.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function PopularLinesSection() {
    const {
        popularLinesTrvl,
    } = useAppSelector((state) => state?.page.homepageData);

    const popularLinesBySection = entries(popularLinesTrvl).filter(([_key, lines]) => (
        isNotEmpty(lines) && _key !== "train" && _key !== "travel"
    ))

    const matchTranslationKey: Record<PopularLinesTypeType, string> = {
        bus: "bus_pages",
        ferry: "ferry_pages",
        train: "train_pages",
        travel: "travel_pages",
    }

    return (
        <section className="popular-lines">
            <h2>
                <Translation translationKey="home_page.popular_routes_section.title"/>
            </h2>
            <div className="popular-lines__container">
                {popularLinesBySection.map(([key, lines]) => (
                    <>
                        <div>
                            {lines.slice(0, 10).map((route) => (
                                <a
                                    href={route.trvlPermalinkSlug}
                                    key={route.trvlPermalinkSlug}
                                    className="gb--link gb--truncate"
                                >
                                    <Translation
                                        translationKey={`home_page.popular_routes_section.${matchTranslationKey[key]}`}
                                        translationVariables={{
                                            departureStation: route.departureStationName,
                                            destinationStation: route.destinationStationName,
                                        }}
                                    />
                                </a>
                            ))}
                        </div>
                        <div>
                            {lines.slice(10, 20).map((route) => (
                                <a
                                    href={route.trvlPermalinkSlug}
                                    key={route.trvlPermalinkSlug}
                                    className="gb--link gb--truncate"
                                >
                                    <Translation
                                        translationKey={`home_page.popular_routes_section.${matchTranslationKey[key]}`}
                                        translationVariables={{
                                            departureStation: route.departureStationName,
                                            destinationStation: route.destinationStationName,
                                        }}
                                    />
                                </a>
                            ))}
                        </div>
                    </>
                ))}
            </div>
        </section>
    )
}

export default PopularLinesSection