import React from 'react';
import useSharedImagesPath from "@Images/hooks/useSharedImagesPath";
import ImageSourceType from "@Images/types/ImageSource.type";

interface SourcedImageProps extends ImageSourceType {
    className?: string
}

function SourcedImage({filePath, fileType = "jpg", alt, className = ""}: SourcedImageProps) {
    const imagePath = useSharedImagesPath(filePath)
    const fullImagePath = `${imagePath}.${fileType}`

    return (
        <img
            src={fullImagePath}
            alt={alt ?? filePath}
            className={className}
        />
    );
}

export default SourcedImage;