import RoutesListTitle from "@RoutesList/components/RoutesListTitle";
import RouteItemContainer from "@Route/components/Container/RouteItemContainer";
import Card from "react-bootstrap/Card";
import RouteExpandedContainer from "@Route/components/Container/RouteExpandedContainer";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";
import ParamsType from "@SearchResults/types/params/Params.type";
import {PreparedRouteType} from "@Route/types/PreparedRoute.type";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import Accordion from "react-bootstrap/Accordion";
import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";
import CompanyLogo from "@Route/features/company/components/CompanyLogo";
import RouteItinerary from "@Route/components/Sections/RouteItinerary";
import RouteTotalPrice from "@Route/components/Price/RouteTotalPrice";
import {useTranslation} from "@Translation/hooks/useTranslation";
import usePrepareSingleSelectedRoute from "@RoutesList/hooks/usePrepareSingleSelectedRoute";
import IconChevron from "@Icons/chevrons/IconChevron";
import {Colors} from "@Styles/Colors.style";

type SingleSelectedRouteProps = {
    singleSelectedRoute: PreparedRouteType
    params: ParamsType,
    routeIcons: Record<number, RouteIconType>
}

function SingleSelectedRoute({singleSelectedRoute, params, routeIcons}: SingleSelectedRouteProps) {
    const {outbound, ticket_info: ticketInfo} = useTranslation("sys");
    const returnRouteTitle = `${outbound} ${ticketInfo}`
    const isMobile = useIsMobile()
    const singleSelectedRouteWithAddedPrice = usePrepareSingleSelectedRoute(singleSelectedRoute)
    const singleSelectedRouteWithPrices = singleSelectedRoute.prices.price ? singleSelectedRoute : singleSelectedRouteWithAddedPrice

    if (isMobile) {
        return (
            <Accordion id="accordion">
                <Card className="panel gb--card gb--card-selected">
                    <Accordion.Toggle
                        id="0"
                        className="gb--row gb--row-middle gb--row-justify"
                        as={Card.Header}
                        eventKey="0"
                    >
                        <Row center justify>
                            <span className="gb--emphasize gb--emphasize-default" style={{textTransform: 'capitalize'}}>
                                <Translation translationKey="sys.outbound"/>
                                &nbsp;
                                <Translation translationKey="sys.ticket_info"/>
                            </span>
                        </Row>
                        <Row center justify>
                            <CompanyLogo
                                // @ts-ignore
                                companyId={singleSelectedRouteWithPrices.company.id}
                                // @ts-ignore
                                companyName={singleSelectedRouteWithPrices.company.name}
                            />
                            <IconChevron fill={Colors.primaryColor}/>
                        </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey="0"
                        className="gb--route-details"
                    >
                        <Row className="route-expanded-details-section route-single-selected">
                            <RouteItinerary
                                singleSelectedRouteWithPrices
                                // @ts-ignore
                                route={singleSelectedRouteWithPrices}
                                params={params}
                                // @ts-ignore
                                companyLetterCode={singleSelectedRouteWithPrices.company.letterCode}
                            />
                                <div className="gb--route-status">
                                    <RouteTotalPrice
                                        price={singleSelectedRouteWithPrices.prices.price}
                                        displayPassengers
                                    />
                                </div>
                        </Row>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }

    return (
        <>
            <RoutesListTitle
                title={returnRouteTitle}
            />
            <div className="panel-group routes-list accordion">
                <div className="panel gb--card gb--card-expanded">
                    <div className="gb--route gb--route-search panel-heading">
                        <RouteItemContainer
                            params={params}
                            route={singleSelectedRouteWithPrices}
                            isReturn={false}
                        />
                    </div>
                    <Card.Body className="gb--route-details">
                        <RouteExpandedContainer
                            routeIcons={routeIcons}
                            route={singleSelectedRouteWithPrices}
                            params={params}
                            isReturn={false}
                        />
                    </Card.Body>
                </div>
            </div>
        </>
    )
}

export default SingleSelectedRoute;