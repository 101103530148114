import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconHeadsetWithLogomark(props: IconType) {
    const {
        height = "16",
        viewBox = "0 0 16 16",
        width = "16",
        fill = Colors.primaryColor,
        ...rest
    } = props
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    clipRule="evenodd"
                    d="m5.83381 6.86429c0-.74326-.53405-1.36178-1.23936-1.49277.23154-1.70483 1.6933-3.0191 3.46155-3.0191 1.76259 0 3.2207 1.30587 3.4593 3.00273-.7595.08365-1.3503.7274-1.3503 1.50914v2.0855c0 .72881.5135 1.33771 1.1985 1.48461-.0263.1279-.0641.2673-.1184.4109l-.0007.0019c-.1079.2889-.2634.5798-.525.8216-.1095.1014-.2432.1992-.4088.2856-.0008-.0324-.0025-.0651-.005-.0982v-.0005c-.0389-.5067-.3053-.9736-.68392-1.2605-.38949-.295-.94201-.423-1.47167-.1307l-.00059.0003c-.72154.3994-.7715 1.2402-.48603 1.8202.17653.3587.48647.6606.89645.8234-.03475.0245-.07046.0488-.10709.0728-.25626.1677-.51997.2956-.72224.382-.10032.0429-.18354.0747-.24054.0954-.02845.0104-.05023.0179-.06419.0226l-.01487.005-.00244.0008c.00013 0 .00026-.0001.1511.4766s.15099.4767.15115.4766l.00036-.0001.00089-.0003.00242-.0007.00739-.0024.02459-.0082c.0206-.007.04947-.017.0854-.0301.07178-.0261.17225-.0646.29164-.1156.23715-.1013.55694-.2555.87696-.4649.31303-.2049.6729-.4945.93629-.8721.62874-.1477 1.10384-.4119 1.46164-.7427.4147-.3834.6419-.8287.7826-1.2052.1012-.2682.1603-.5229.1944-.7408.7519-.0907 1.3345-.73099 1.3345-1.50731v-2.0855c0-.72363-.5062-1.32903-1.1839-1.48138-.2318-2.26396-2.1448-4.03049-4.4699-4.03049-2.31783 0-4.22613 1.75547-4.4677 4.00922-.73474.10619-1.29924.7385-1.29924 1.50265v2.0855c0 .83855.67979 1.51831 1.51835 1.51831h.50806c.83856 0 1.51834-.67976 1.51834-1.51831zm-2.54475 0c0-.28627.23207-.51834.51835-.51834h.50806c.28627 0 .51834.23207.51834.51834v2.0855c0 .28627-.23207.51834-.51834.51834h-.50806c-.28628 0-.51835-.23207-.51835-.51834zm7.87594 0c0-.28627.2321-.51834.5184-.51834h.508c.2863 0 .5184.23207.5184.51834v2.0855c0 .28627-.2321.51834-.5184.51834h-.508c-.2863 0-.5184-.23207-.5184-.51834zm-1.85653 5.06771c.00788.1043-.00302.2063-.03077.3058-.39105.0069-.61825-.1935-.71708-.3943-.13153-.2673-.03531-.4436.07281-.5036.11365-.0626.24103-.0562.38441.0524.15431.1169.27396.3233.29063.5397z"
                    fill={fill}
                    fillRule="evenodd"
                />
            )}
        />
    )
}

export default IconHeadsetWithLogomark