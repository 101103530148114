import IconMinus from "@Icons/controls/IconMinus";
import IconPlus from "@Icons/controls/IconPlus";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconType from "@/types/Icon.type";

export interface ControlType {
    handle: () => void,
    className: string,
    iconStyle?: IconType & {backgroundFill?: string},
}

interface CountControlProps {
    incrementControl: ControlType,
    decrementControl: ControlType,
    count: number,
    countClassName?: string
}

/*
 * component used to increment count gradually, eg. "- {count} +". Doesn't have its own wrapper
 */
function CountControl(props: CountControlProps) {
    const {
        incrementControl,
        decrementControl,
        count,
        countClassName = "",
    } = props
    const isMobile = useIsMobile()

    return (
        <>
            <button
                className={decrementControl.className}
                type="button"
                onClick={decrementControl.handle}
            >
                <IconMinus
                    width={isMobile ? "40" : "20"}
                    {...(decrementControl?.iconStyle ?? {})}
                />
            </button>
            <span className={countClassName}>
                {count}
            </span>
            <button
                className={incrementControl.className}
                type="button"
                onClick={incrementControl.handle}
            >
                <IconPlus
                    width={isMobile ? "40" : "20"}
                    {...(incrementControl?.iconStyle ?? {})}
                />
            </button>
        </>
    )
}

export default CountControl