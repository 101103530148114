import {activateReturnCalendar, activateSingleCalendar} from "@Reducers/mobileSlice";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

/**
 * Activates single or return calendar for mobile / smallTablet
 * @param type {('single'|'return')}
 */
export default function useActivateCalendar(type: "single" | "return") {
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const dispatchType = type === 'single' ? activateSingleCalendar(true) : activateReturnCalendar(true)

    if (isMobile || isSmallTablet) {
        return () => dispatch(dispatchType)
    }

    // Silences errors when clicked on desktop return calendar
    return () => null
}