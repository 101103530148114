import React, {ReactNode} from "react";
import ToggleTag from "@Generic/buttons/ToggleTag";

type SortButtonProps = {
    label: string | ReactNode,
    onClick: (any?) => any,
    value: any,
    active?: boolean,
    disabled?: boolean,
    activeClass?: string,
    inactiveClass?: string,
    icon?: ReactNode,
    onBlur: React.FocusEventHandler<HTMLInputElement>
}

function SortOption(props: SortButtonProps) {
    const {
        label,
        onClick,
        value,
        active = false,
        disabled = false,
        activeClass = 'gb--row gb--tag gb--tag-large gb--tag-light',
        inactiveClass = 'gb--row gb--tag gb--tag-large gb--tag-no-background',
        icon,
        onBlur,
    } = props

    return (
        <ToggleTag
            icon={icon}
            label={label}
            onClick={() => onClick(value)}
            value={value}
            active={active}
            disabled={disabled}
            activeClass={activeClass}
            inactiveClass={inactiveClass}
            onBlur={onBlur}
        />
    );
}

export default SortOption;