import Carousel from "@Pages/mobileApp/features/bookingSection/components/Carousel";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";

const TravelingDotComBookingsCarousel = () => {
    const {
        one,
        two,
        three,
    } = useTranslation("mobile_app.carousel_section")

    const bookingInfoData = [
        {
            label: one.intro,
            title: one.title,
            content: one.description,
            imagePath: "images/mobile-app-landing-page/traveling_dot_com_carousel_fast_and_simple_booking",
            color: "purple",
            index: 0,
        },
        {
            label: two.intro,
            title: two.title,
            content: two.description,
            imagePath: "images/mobile-app-landing-page/traveling_dot_com_carousel_detailed_journey_information",
            color: "purple",
            index: 1,
        },
        {
            label: three.intro,
            title: three.title,
            content: three.description,
            imagePath: "images/mobile-app-landing-page/traveling_dot_com_carousel_my_bookings",
            color: "purple",
            index: 2,
        },
    ]

    return (
        <Carousel
            data={bookingInfoData}
        />
    )
}

export default TravelingDotComBookingsCarousel