import React, {ReactElement, ReactNode} from "react";
import useLazyLoading from "@Features/lazyLoading/hooks/useLazyLoading";
import LoaderIcon from "@Features/lazyLoading/components/LoaderIcon";
import LazyLoadingLoadMoreButton from "@Features/lazyLoading/components/LazyLoadingLoadMoreButton";
import AllResultsLoaded from "@Features/lazyLoading/components/AllResultsLoaded";
import NoResultsNotification from "@Features/lazyLoading/components/NoResultsNotification";
import TripleDotLoader from "@Generic/loaders/TripleDotLoader";

interface LazyLoadingProps {
    children: ReactNode,
    loaderIcon?: ReactElement<any>,
    noResultsNotification?: ReactElement<any>,
    allResultsLoaded?: ReactElement<any>,
    loading: boolean,
    batchLoading: boolean,
    fetchedDataLength: number,
    totalDataLength?: number,
    page: number,
    setPage: (any) => void,
    setScroll?: (any) => void,
    dataPerBatch?: number,
}

function LazyLoading(props: LazyLoadingProps) {
    const {
        children,
        loaderIcon = <TripleDotLoader/>,
        noResultsNotification = null,
        allResultsLoaded = null,
        loading,
        batchLoading,
        fetchedDataLength,
        totalDataLength = fetchedDataLength,
        page,
        setPage,
        setScroll,
        dataPerBatch = 15,
    } = props

    useLazyLoading({
        isLoading: (loading || batchLoading),
        page,
        dataPerBatch,
        totalData: totalDataLength,
        setPage,
        setScroll,
    })

    if (loading) {
        return (
            <LoaderIcon
                loaderIcon={loaderIcon}
            />
        )
    }
    if (fetchedDataLength === 0) {
        return (
            <NoResultsNotification
                noResultsNotification={noResultsNotification}
            />
        )
    }

    return (
        <>
            {children}
            <LoaderIcon
                condition={batchLoading}
                loaderIcon={loaderIcon}
            />
            <LazyLoadingLoadMoreButton
                totalDataCount={totalDataLength}
                dataPerBatch={dataPerBatch}
                batchLoading={batchLoading}
                page={page}
                setPage={setPage}
            />
            {((totalDataLength === fetchedDataLength) && !loading) && (
                <AllResultsLoaded
                    allResultsLoaded={allResultsLoaded}
                />
            )}
        </>
    );
}

export default LazyLoading