import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MobileMenuItem from "@User/components/Mobile/MobileMenuItem";
import React from "react";
import {PagesType} from "@/types/pageSlice/Pages.type";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import {localeSpecificMenuItems} from "@Header/constants/localeSpecificMenuItems";
import useIsModifiedMenuDisplayed from "@Header/hooks/useIsModifiedMenuDisplayed";
import TravelingDotComMobileMenuIcons from "@User/components/Mobile/TravelingDotComMobileMenuIcons";
import entries from "@Object/entries";

function MobileMenuItemsList() {
    const urlsObject = useAppSelector((state) => (state.page.urlsObject))
    const {company_id: companyId} = useCompanyStyle()
    const displayFAQ = !(companyId === "815")
    const isModifiedMenuDisplayed = useIsModifiedMenuDisplayed()
    const visibleLinks = []

    if (isModifiedMenuDisplayed) {
        localeSpecificMenuItems.map((item) => {
            const menuItem = entries(urlsObject).find(([key, _urlObject]) => {
                return key === item
            })

            if (menuItem && menuItem[1].text !== "") {
                visibleLinks.push(menuItem)
            }
        })
    } else {
        entries(urlsObject).map((entry: [PagesType, UrlObjectType]) => {
            const [_key, urlObject] = entry
            if (!displayFAQ && urlObject.routeName === "gbb_front_faq") {
                return
            }
            // display correct help center link on gbb or trvl
            if ((urlObject.routeName === "help_center") || (urlObject.routeName === "trvl_help_center")) {
                return
            }
            if (urlObject.routeName !== window.currentRoute && urlObject.text !== '') {
                visibleLinks.push(entry)
            }
        })
    }

    return (
        <>
            {visibleLinks.map(([key, urlObject]: [PagesType, UrlObjectType]) => (
                <MobileMenuItem
                    key={urlObject.text}
                    text={urlObject.text}
                    icon={(
                        <TravelingDotComMobileMenuIcons
                            tag={key}
                        />
                    )}
                    openItem={() => {
                        window.location.href = urlObject.url
                    }}
                />
            ))}
        </>
    )
}

export default MobileMenuItemsList