import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconSignIn(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={fill} fillRule="evenodd" transform="translate(.9156 .8627)">
                    <path
                        d="m0 4.635088h5.44166104c-.73304.8196-1.17864 1.90176-1.17864 3.0884h1.5452c0-1.70656 1.38176-3.0884 3.0884-3.0884v-1.5452h-.00472c-1.70656-.00144-3.0884-1.383264-3.0884-3.089888h-1.5452c0 1.187408.44616 2.270128 1.18 3.089888-1.72669364 0-4.57495422 0-5.43830104 0z"
                        transform="translate(0 3.2755)"
                    />
                    <path
                        d="m14.0348011-0v14.2745113h-12.2207606v-.6-4.63886065h1.2v4.03834935h9.82v-11.874h-9.82v3.96278893h-1.2v-5.16278893h.6z"
                        fillRule="nonzero"
                    />
                </g>
            )}
        />
    )
}

export default IconSignIn