import {useTranslation} from "@Translation/hooks/useTranslation";
import IconTimes from "@Icons/controls/IconTimes";
import TrvlMyProfileNotification from "@MyProfile/components/TrvlMyProfileNotification";
import IconMTicket from "@Icons/ticket/IconMTicket";
import TrvlIconPaperTicket from "@Icons/ticket/IconPaperTicket";

interface TicketNotificationsProps {
    isCanceled: boolean,
    isExchangedForVoucher: boolean,
    isExpired: boolean,
    isMTicket: boolean,
}

// TODO this is shit, refactor, add wrapper with gap and margins defined
function TicketNotifications(props: TicketNotificationsProps) {
    const {
        isExpired,
        isExchangedForVoucher,
        isCanceled,
        isMTicket,
    } = props

    const {
        has_mticket_desc: gbbTransportTypeLabel,
        ticket_has_to_be_printed: paperTicketDescriptionLabel,
    } = useTranslation("search_results")
    const {
        not_valid: notValidLabel,
        cancelled: cancelledLabel,
        ticket_automatically_archived: ticketAutomaticallyArchivedLabel,
        ticket_exchanged_for_voucher: ticketExchangedForVoucherLabel,
        ticket_refund: ticketRefundLabel,
    } = useTranslation('my_profile.my_tickets')

    return (
        <>
            {isExpired && (
                <TrvlMyProfileNotification
                    Icon={IconTimes}
                    title={notValidLabel}
                    description={ticketAutomaticallyArchivedLabel}
                />
            )}
            {isExchangedForVoucher && (
                <TrvlMyProfileNotification
                    Icon={IconTimes}
                    title={cancelledLabel}
                    description={ticketExchangedForVoucherLabel}
                />
            )}
            {isCanceled && (
                <TrvlMyProfileNotification
                    Icon={IconTimes}
                    title={notValidLabel}
                    description={ticketRefundLabel}
                />
            )}
            {isMTicket ? (
                <TrvlMyProfileNotification
                    Icon={IconMTicket}
                    title="M-ticket eco friendly"
                    description={gbbTransportTypeLabel}
                />
            ) : (
                <TrvlMyProfileNotification
                    Icon={TrvlIconPaperTicket}
                    title="Paper ticket"
                    description={paperTicketDescriptionLabel}
                />
            )}
        </>
    );
}

export default TicketNotifications;