import IconInfo from "@Icons/IconInfo";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {Colors} from "@Styles/Colors.style";

type TotalLabelProps = {
    showInfoIcon?: boolean,
}

function TotalLabel({showInfoIcon = true}: TotalLabelProps) {
    const {
        ticket: {
            paymentCurrency,
            bookingFeeRange,
            totalPrice,
        },
    } = useAppSelector((state) => state?.checkOrder)
    const {total: totalLabel} = useTranslation("sys")
    const checkOrderFeesIncluded = useTranslation("check_order.fees_included")
    return (
        <div>
            <strong>
                {totalLabel}
            </strong>
            <small>
                {" "}
                {`(${checkOrderFeesIncluded} ${paymentCurrency})`}
                {showInfoIcon && (
                    <IconInfo
                        bold
                        width="20"
                        height="16"
                        fill={Colors.primaryColor}
                        style={{verticalAlign: 'middle'}}
                    />
                )}
            </small>
        </div>
    );
}

export default TotalLabel;