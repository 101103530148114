import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconCamera = (props: IconType) => {
    const {
        width = '20',
        height = '20',
        fill = '#2A2A2A',
        viewBox = '0 0 20 20',
        title = 'IconCamera',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...props}
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <>
                    <path d="M10.0005 2.57812V4.07813H7.97633L7.9632 4.12307C7.9232 4.25018 7.87008 4.41903 7.79427 4.65996L7.73301 4.85465C7.4259 5.83073 7.4259 5.83073 7.32353 6.15608C7.22524 6.46849 6.93561 6.68098 6.60811 6.68098L3.01758 6.68063V15.9194L12.4586 15.9197V17.4197H2.26758C1.85336 17.4197 1.51758 17.0839 1.51758 16.6697V5.93098C1.51758 5.51677 1.85336 5.18098 2.26758 5.18098L6.05758 5.18063L6.07198 5.13604C6.11198 5.00893 6.1651 4.84008 6.24091 4.59915L6.30217 4.40446C6.60928 3.42838 6.60928 3.42838 6.71165 3.10303C6.80994 2.79062 7.09957 2.57812 7.42707 2.57812H10.0005Z" fill={fill}/>
                    <path d="M10.0005 7.69099C11.7951 7.69099 13.25 9.14586 13.25 10.9405C13.25 12.7352 11.7951 14.1901 10.0005 14.1901C8.20579 14.1901 6.75092 12.7352 6.75092 10.9405C6.75092 9.14586 8.20579 7.69099 10.0005 7.69099ZM10.0005 8.94099C8.89615 8.94099 8.00092 9.83621 8.00092 10.9405C8.00092 12.0448 8.89615 12.9401 10.0005 12.9401C11.1048 12.9401 12 12.0448 12 10.9405C12 9.83621 11.1048 8.94099 10.0005 8.94099Z" fill={fill}/>
                    <path d="M10.0005 2.57812V4.07813H12.0242L12.0373 4.12307C12.0773 4.25018 12.1304 4.41903 12.2062 4.65996L12.2675 4.85465C12.5746 5.83073 12.5746 5.83073 12.677 6.15608C12.7752 6.46849 13.0649 6.68098 13.3924 6.68098L16.9829 6.68063V15.9194L13.8665 15.9197V17.4197H17.7329C18.1471 17.4197 18.4829 17.0839 18.4829 16.6697V5.93098C18.4829 5.51677 18.1471 5.18098 17.7329 5.18098L13.9429 5.18063L13.9285 5.13604C13.8885 5.00893 13.8354 4.84008 13.7596 4.59915L13.6983 4.40446C13.3912 3.42838 13.3912 3.42838 13.2888 3.10303C13.1905 2.79062 12.9009 2.57812 12.5734 2.57812H10.0005Z" fill={fill}/>
                </>
            )}
        />
    );
};

export default IconCamera;
