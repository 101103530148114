import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconFood(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth="1.2">
                    <g transform="translate(2.8443 2.691)">
                        <path
                            d="m.02194489 3.90597275c-.15513584-2.60398183.51225901-3.90597275 2.00218454-3.90597275h3.13153276"
                        />
                        <path
                            d="m5.17760708 3.90597275c-.15513584-2.60398183.51225901-3.90597275 2.00218453-3.90597275h3.13153279"
                            transform="matrix(-1 0 0 1 15.467 0)"
                        />
                        <path d="m0 3.905973h10.311324" strokeLinecap="round"/>
                    </g>
                    <g transform="matrix(1 0 0 -1 2.8443 12.8661)">
                        <path
                            d="m.02194489 2.67919702c-.15513584-1.78613135.51225901-2.67919702 2.00218454-2.67919702h3.13153276"
                        />
                        <path
                            d="m5.17760708 2.67919702c-.15513584-1.78613135.51225901-2.67919702 2.00218453-2.67919702h3.13153279"
                            transform="matrix(-1 0 0 1 15.467 0)"
                        />
                        <path d="m0 2.679197h10.311324" strokeLinecap="round"/>
                    </g>
                    <path d="m1.801522 8.372805h12.32187"/>
                </g>
            )}
        />
    )
}

export default IconFood