import React from 'react'
import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";

const AppIntro = () => {
    return (
        <div className="app-overview-intro-container">
            <Row>
                <div>
                    <h1>
                        <Translation translationKey="mobile_app.intro.title"/>
                    </h1>
                    <p className="mobile-app-landing-page-description">
                        <Translation translationKey="mobile_app.intro.description"/>
                    </p>
                </div>
            </Row>
        </div>
    )
}

export default AppIntro