import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconAfternoon({width = "16", height = "16", viewBox = "0 0 16 16", fill = Colors.primaryColor, ...rest}: IconType) {
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={fill} strokeWidth="1.2"
                   transform="translate(.8912 .7495)">
                    <ellipse
                        cx="7.042053"
                        cy="7.024767"
                        rx="2.674599"
                        ry="2.678321"
                    />
                    <g strokeLinecap="round">
                        <path d="m11.962787 2.12132-1.318525 1.319943"/>
                        <path
                            d="m13.939884 6.204444-1.318525 1.319943"
                            transform="matrix(-.70672657 -.70748679 .70748679 -.70672657 17.424972 21.271947)"
                        />
                        <path
                            d="m2.232738 6.204444-1.318525 1.319943"
                            transform="matrix(-.70672657 -.70748679 .70748679 -.70672657 -2.556018 12.989258)"
                        />
                        <path
                            d="m7.860958.594271-1.318525 1.319942"
                            transform="matrix(.70748679 -.70672657 .70672657 .70748679 1.060542 5.390723)"
                        />
                        <path
                            d="m7.860958 12.266843-1.318525 1.319943"
                            transform="matrix(.70748679 -.70672657 .70672657 .70748679 -7.188794 8.805113)"
                        />
                        <path d="m3.439845 10.608271-1.318525 1.319943"/>
                        <path
                            d="m3.439845 1.876243-1.318525 1.319942"
                            transform="matrix(1 0 0 -1 0 5.3924)"
                        />
                        <path
                            d="m11.962787 10.288329-1.318525 1.319942"
                            transform="matrix(1 0 0 -1 0 22.2166)"
                        />
                    </g>
                </g>

            )}
        />
    )
}

export default IconAfternoon