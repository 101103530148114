import React from 'react';
import ProgressBar from "@Generic/loaders/ProgressBar";

function RoutesProgressBar({totalCount, resolvedCount, queriedRoutesTitle}: {
    totalCount: number,
    resolvedCount: number,
    queriedRoutesTitle: string
}) {
    const percentage = (resolvedCount / totalCount) * 100

    if (percentage < 100) {
        return (
            <div className="gb--progress-card">
                <h5>{`Checked ${resolvedCount} of ${totalCount} ${queriedRoutesTitle}`}</h5>
                <ProgressBar
                    percentage={percentage}
                />
            </div>
        )
    }

    return null
}

export default RoutesProgressBar;