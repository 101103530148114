import React, {useEffect} from 'react';
import {Outlet} from "react-router";
import SplashLoader from "@Generic/loaders/SplashLoader";
import Column from "@Containers/components/Column";
import {useScroll} from "@Scroll/hooks/useScroll";
import {isTicketsPage} from "@MyProfile/functions/isActivePage";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MyProfileAvatarSection from "@MyProfile/features/avatar/components/MyProfileAvatarSection";
import MyProfileLinks from "@MyProfile/features/links/components/MyProfileLinks";
import MyProfileFilter from "@MyProfile/features/filter/components/MyProfileFilter";
import Form from "@Form/components/Form";
import MobileFormOverlayContainer from "@Form/components/Mobile/MobileFormOverlayContainer";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useHandleNotLoggedInUsers from "@MyProfile/hooks/useHandleNotLoggedInUsers";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import useAutomaticallyShowArchivedDataOnMobile from "@MyProfile/hooks/useAutomaticallyShowArchivedDataOnMobile";

/**
 * Main view that is injected in body of my tickets twig. Render my tickets by default. Renders all other components
 * and passing them props. Keep current view in state so that we know what view to render.
 */
function MyProfile() {
    const isMobile = useIsMobile()
    const {formInputSelected} = useAppSelector((state) => state?.mobile)
    const {activeSplashLoader} = useAppSelector((state) => state?.userProfile)
    const {elRef: avatarRef, executeScroll} = useScroll({block: "start"})

    useEffect(() => {
        if (avatarRef.current) {
            executeScroll()
        }
    }, [isTicketsPage()])

    useHandleNotLoggedInUsers()
    useAutomaticallyShowArchivedDataOnMobile()

    if (formInputSelected) {
        return null
    }

    return (
        <div className="gb--main-content gb--base-container">
            {!isMobile ? (
                <Form/>
            ) : (
                <MobileFormOverlayContainer/>
            )}
            <div className="my-profile">
                <MyProfileAvatarSection avatarRef={avatarRef}/>
                <div className="trvl--my-profile__main-section">
                    <Column className="my-profile">
                        <MyProfileLinks/>
                        <ErrorBoundary>
                            <Outlet/>
                        </ErrorBoundary>
                    </Column>
                    <MyProfileFilter/>
                </div>
            </div>
            {activeSplashLoader && (
                <SplashLoader/>
            )}
        </div>
    );
}

export default MyProfile
