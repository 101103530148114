import usePassengers from "@Hooks/selector/usePassengers";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import {PassengersTypeType} from "@Form/types/PassengersType.type";

export default function usePreparePassengerGroupData(): { type: PassengersTypeType, count: number, name: string }[] {
    const {
        passengersAdult,
        passengersChildren,
        passengersPensioner,
        passengersStudent,
    } = usePassengers()

    const adultLabel = useQuantityAwareTranslation("form.passengers.adult", passengersAdult)
    const childLabel = useQuantityAwareTranslation("form.passengers.child", passengersChildren)
    const studentLabel = useQuantityAwareTranslation("form.passengers.student", passengersStudent)
    const pensionerLabel = useQuantityAwareTranslation("form.passengers.pensioner", passengersPensioner)

    return [
        {
            type: "passengersAdult",
            count: passengersAdult,
            name: adultLabel,
        },
        {
            type: "passengersChildren",
            count: passengersChildren,
            name: childLabel,
        },
        {
            type: "passengersStudent",
            count: passengersStudent,
            name: studentLabel,
        },
        {
            type: "passengersPensioner",
            count: passengersPensioner,
            name: pensionerLabel,
        },
    ]
}