import ModalContent from "@Generic/modal/ModalContent";
import ModalHeader from "@Generic/modal/ModalHeader";
import React, {ReactNode} from "react";
import IconUserFull from "@Icons/IconUserFull";
import PriceWithCurrency from "@Route/components/Price/PriceWithCurrency";
import useGoBack from "@Routing/hooks/useGoBack";
import Row from "@Containers/components/Row";
import {useTranslation} from "@Translation/hooks/useTranslation";
import ModalFooter from "@Generic/modal/ModalFooter";

interface PriceChangeModalProps {
    /** Handler */
    handleClick: (any?) => any,
    /** Content of modal header */
    title?: ReactNode | string,
    /** Content of modal */
    message: ReactNode | string,
    buttonText: string,
    oldPrice: number,
    newPrice: number,
    passengersNum: number,
    /** Optional */
    closeAction?: (any?) => any,
    titleIcon?: ReactNode | string,
    closeIcon?: ReactNode | string,
    noticeType?: string,
    // DangerouslySetInnerHtml
    isInnerHtml?: boolean,
}

const PriceChangeModal = (props: PriceChangeModalProps) => {
    const {
        title,
        titleIcon,
        message,
        handleClick,
        buttonText,
        closeAction,
        closeIcon,
        noticeType,
        isInnerHtml = false,
        oldPrice,
        newPrice,
        passengersNum,
    } = props;

    const {goBack} = useGoBack()

    const goBackLabel = useTranslation("check_order.back_to_search_results")
    const newPriceLabel = useTranslation("check_order.new_price")

    const onClickGoBack = (e) => {
        e.preventDefault()
        goBack()
    }

    return (
        <ModalContent
            className="modal-dialog gb-modal-dialog price-change-modal"
        >
            <ModalHeader
                closeAction={closeAction}
                title={title}
                titleIcon={titleIcon}
                closeIcon={closeIcon}
                noticeType={noticeType}
            />
            <div className="modal-body gb-modal-body">
                {isInnerHtml ? (
                    <div dangerouslySetInnerHTML={{__html: (`${message}`)}}/>
                ) : (
                    message
                )}
                <br/>
                <Row justify center>
                    <span className="gb--emphasize">
                        {newPriceLabel}
                    </span>
                    <Row gap center>
                        <PriceWithCurrency price={oldPrice} className="gb--emphasize old-price"/>
                        <PriceWithCurrency price={newPrice} className="gb--emphasize gb--emphasize-default"/>
                        <span
                            className="gb--row gb--row-middle gb--row-end price-pax gb--label gb--label-default gb--emphasize gb--emphasize-default"
                        >
                            <IconUserFull fill="#070c63"/>
                            {passengersNum}
                        </span>
                    </Row>
                </Row>
            </div>
            <ModalFooter
                handleClick={handleClick}
                buttonText={buttonText}
                alternativeActionText={goBackLabel}
                alternativeActionUrl=""
                alternativeActionClick={(e) => onClickGoBack(e)}
                buttonClassName="reload-btn"
            />
        </ModalContent>
    )
}

export default PriceChangeModal