import {Colors} from "@Styles/Colors.style";
import {useEffect} from "react";
import Row from "@Containers/components/Row";
import {useCookies} from "react-cookie";
import displayStickyHeader from "@Header/features/functions/displayStickyHeader";
import addDays from "date-fns/addDays";
import IconTimes from "@Icons/controls/IconTimes";

type StickyHeaderNoticeProps = {}

function StickyHeaderNotice(props: StickyHeaderNoticeProps) {
    const displayHeader = displayStickyHeader()
    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        if (typeof cookies?.displayTravelingDotComNotice === 'undefined') {
            setCookie("displayTravelingDotComNotice", displayHeader, {path: "/", expires: addDays(new Date(), 30)});
        }
    }, [cookies])

    const dismissNotice = () => {
        setCookie("displayTravelingDotComNotice", false, {path: "/", expires: addDays(new Date(), 30)});
    }

    if (cookies?.displayTravelingDotComNotice) {
        return (
            <div style={{background: Colors.secondaryColor, position: 'relative', zIndex: 3}}>
                <Row justify center style={{margin: "0 1rem"}}>
                    <span>
                    GetByBus is now Traveling.com, New name, same service!
                    </span>
                    <button onClick={dismissNotice}>
                        <IconTimes/>
                    </button>
                </Row>
            </div>
        );
    }

    return null;
}

export default StickyHeaderNotice;