import React from 'react';
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import ActiveResultsCountHeading from "@SearchResults/features/travelInfo/components/ActiveResultsCountHeading";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import useIsMobile from "@Hooks/selector/useIsMobile";
import ApiQueryLink from "@Features/debug/components/ApiQueryLink";
import useIsDebugUser from "@Features/debug/hooks/useIsDebugUser";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import IconArrowAirplane from "@Icons/arrows/IconArrowAirplane";
import {Palette} from "@Styles/Palette.style";

interface TravelInfoProps {
    /** Name of departure station based on current locale */
    departureStationName: string,
    /** Name of destination station based on current locale */
    destinationStationName: string,
    /** Date for which we're searching, pass return date if displaying return direction info */
    date: string,
}

function TravelInfo(props: TravelInfoProps) {
    const {
        departureStationName,
        destinationStationName,
        date,
    } = props;

    const {isLoading} = useSearchResults()
    const isMobile = useIsMobile()
    const isDebugUser = useIsDebugUser()
    const isCheckOrder = useRouteMatch('checkOrder')

    return (
        <Row className="gb--row-middle gb--travel-info__title">
            <Column>
                <Row center gap={((isMobile) || (!!isCheckOrder))}>
                    <span className="gb--travel-info__station gb--emphasize gb--emphasize-default">
                        {departureStationName}
                    </span>
                    <IconArrowAirplane
                        width="26"
                        height="26"
                        fill={isMobile ? Palette.white : Palette.jet}
                    />
                    <span className="gb--travel-info__station gb--emphasize gb--emphasize-default">
                        {destinationStationName}
                    </span>
                    {!isCheckOrder && isDebugUser && (
                        <ApiQueryLink/>
                    )}
                </Row>
                {!isLoading ? (
                    <ActiveResultsCountHeading
                        date={date}
                    />
                ) : (
                    <small className="filtered-routes-count">
                        loading...
                    </small>
                )}
            </Column>
        </Row>
    );
}

export default TravelInfo;
