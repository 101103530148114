import React from "react";
import Row from "@Containers/components/Row";
import useDownloadDocumentsModal from "@UserModals/features/downloadDocuments/hooks/useDownloadDocumentsModal";
import ModalHeader from "@UserModals/components/ModalHeader";
import DownloadDocumentOption from "@UserModals/features/downloadDocuments/components/DownloadDocumentOption";
import ModalCloseButton from "@UserModals/components/ModalCloseButton";
import ModalLinkButton from "@UserModals/components/ModalLinkButton";
import ModalDataType from "@UserModals/types/ModalData.type";

interface DownloadDocumentsModalProps {
    closeModal: () => void,
    modalData: ModalDataType
}

function DownloadDocumentsModal({closeModal, modalData}: DownloadDocumentsModalProps) {
    const {
        option,
        link,
        onClick,
        downloadOptions,
        downloadLabel,
        downloadDocumentsLabel,
    } = useDownloadDocumentsModal(modalData.ticketCode, modalData.isCancelled)

    return (
        <>
            <ModalHeader
                modalData={modalData}
                title={downloadDocumentsLabel}
            />
            <div className="download-documents-modal-body">
                <form className="modal-form">
                    {downloadOptions.map((radioButton, index) => (
                        <DownloadDocumentOption
                            key={radioButton.value}
                            value={radioButton.value}
                            label={radioButton.label}
                            onClick={radioButton.onClick}
                            option={option}
                            showHr={(downloadOptions.length - 1) !== index}
                        />
                    ))}
                </form>
            </div>
            <Row className="button-wrapper">
                <ModalCloseButton closeModal={closeModal}/>
                <ModalLinkButton
                    link={link}
                    text={downloadLabel}
                    onClick={onClick}
                />
            </Row>
        </>
    );
}

export default DownloadDocumentsModal