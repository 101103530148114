import React from 'react'
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import MobileAppFeatures from "@Pages/mobileApp/features/appOverview/components/MobileAppFeatures";
import AppIntro from "@Pages/mobileApp/features/appOverview/components/AppIntro";

const AppOverview = () => {
    return (
        <Column center className="mobile-app-landing-page-section gb-container app-overview-section">
            <AppIntro/>
            <Row>
                <MobileAppFeatures/>
            </Row>
        </Column>

    )
}

export default AppOverview