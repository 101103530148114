import React from 'react';
import IconChevronDown from "@Icons/chevrons/IconChevronDown";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import LanguageDropdown from "./LanguageDropdown";
import {useToggle} from "@Hooks/dispatch/useToggle";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";
import useLocale from "@Hooks/selector/useLocale";
import {LocaleType} from "@/types/Locale.type";
import {Colors} from "@Styles/Colors.style";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import {Palette} from "@Styles/Palette.style";

function LanguageSwitcher() {
    const showLanguageDropdown = useToggle()
    const searchResults = useSearchResults()
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const locale = useLocale() as LocaleType;
    const linkTranslations = searchResults?.data?.routeTranslations ?? JSON.parse(window.routeTranslations)
    const isNotDesktop = isMobile || isTablet
    const isCompanyStore = useIsCompanyStore()
    const {headerBackgroundColor} = useCompanyStyle()
    const fill = isCompanyStore ? headerBackgroundColor : Palette.white


    return (
        <li className={isNotDesktop ? 'gb--menu gb--menu-mobile gb--column' : 'gb--menu__item'}>
            <button
                onClick={showLanguageDropdown.onChange}
                className="gb--emphasize-default gb--menu__item-language"
                type="submit"
            >
                {!isNotDesktop && (
                    <>
                        {locale.toUpperCase()}
                        <IconChevronDown
                            style={{verticalAlign: 'middle', marginLeft: '.5rem'}}
                            stroke={isCompanyStore ? fill : Colors.neutralLightestColor}
                        />
                    </>
                )}
            </button>
            {(isNotDesktop || showLanguageDropdown.value) && (
                <ClickOutsideAwareComponent onClickOutside={() => showLanguageDropdown.set(false)}>
                    {linkTranslations && (
                        <LanguageDropdown
                            linkTranslations={linkTranslations}
                        />
                    )}
                </ClickOutsideAwareComponent>
            )}
        </li>
    );
}

export default LanguageSwitcher
