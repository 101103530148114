import {useRef} from "react";
import useCloseDialogOnOutsideClick from "@Features/dialog/hooks/useCloseDialogOnOutsideClick";

export default function useDialog(isFullScreen = true, isClosedClickOutside: boolean = false) {
    const dialogRef = useRef<HTMLDialogElement>(null)

    const closeModal = () => {
        dialogRef?.current?.close()
    }

    const openModal = () => {
        if (isFullScreen) {
            dialogRef?.current?.showModal()
        } else {
            dialogRef?.current.show()
        }
    }

    useCloseDialogOnOutsideClick(dialogRef, closeModal, isClosedClickOutside)

    return {
        dialogRef,
        closeModal,
        openModal,
    }
}