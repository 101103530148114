import React from 'react'
import HorizontalCardWithLink from "@Generic/cards/HorizontalCardWithLink";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconQuestionsAndAnswers from "@Icons/home/IconQuestionsAndAnswers";

interface HelpCenterSectionProps {
    className?: string
}

function HelpCenterSection({className}: HelpCenterSectionProps) {
    const {
        urlsObject,
    } = useAppSelector((state) => state?.page)

    const title = useTranslation("help_center_section.we_are_here")
    const content = useTranslation("help_center_section.all_info_in_one_place")
    const ctaLabel = useTranslation("help_center_section.help_center")

    return (
        <HorizontalCardWithLink
            title={title}
            content={content}
            icon={(
                <IconQuestionsAndAnswers
                    className="help-center-icon"
                    width={94}
                    height={94}
                    strokeWidth="0.5"
                />
            )}
            link={urlsObject.trvlHelpCenter?.url}
            actionText={ctaLabel}
            className={className ? `${className} help-center` : "help-center"}
            btnClass="help-center-btn"
        />
    )
}

export default HelpCenterSection