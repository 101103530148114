import DropdownButton from "@Generic/buttons/DropdownButton";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import React, {useEffect} from "react";
import {DimensionOption, VehicleAddonResponseType} from "@CheckOrder/api/types/GetAddonData.type";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface VehicleAdditionalLengthProps {
    activeOption: { value: number, label: string, activeGroup: VehicleAddonResponseType }
    setLength: (value: DimensionOption) => void,
    additionalLength: DimensionOption | null,
    isMotorcycle: boolean,
}

function VehicleAdditionalLength(props: VehicleAdditionalLengthProps) {
    const {
        activeOption,
        setLength,
        additionalLength,
        isMotorcycle,
    } = props

    const {
        additional_length: additionalLengthLabel,
        additional_length_placeholder: additionalLengthPlaceholderLabel,
    } = useTranslation("check_order.addons.JADL.vehicle")

    const getLengthActiveLabel = () => {
        return activeOption.activeGroup.lengthOptions.find((option) => (
            option.value === additionalLength?.value
        ))?.label
    }

    useEffect(() => {
        setLength(activeOption.activeGroup.lengthOptions[0])
    }, [isMotorcycle]);

    return (
        <CheckOrderInputContainer label={isMotorcycle ? "Length" : additionalLengthLabel}>
            {!isMotorcycle && (
                <>
                    {additionalLengthPlaceholderLabel}
                </>
            )}
            <DropdownButton
                label={getLengthActiveLabel()}
                baseClass="gb--select"
                cssButtonClass="gb--select-dropdown"
            >
                {activeOption.activeGroup.lengthOptions.map((option) => (
                    <button
                        type="button"
                        key={option.value}
                        value={option.value}
                        onClick={() => setLength(option)}
                        className="gb--select__option full-width"
                    >
                        {option.label}
                    </button>
                ))}
            </DropdownButton>
        </CheckOrderInputContainer>
    );
}

export default VehicleAdditionalLength