import {useEffect, useRef, useState} from "react";

export const useImageUpload = () => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const imgPlaceholderRef = useRef<HTMLDivElement>(null);
    const wrapperDivRef = useRef<HTMLDivElement>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result as string);
                if (imgPlaceholderRef.current && wrapperDivRef.current) {
                    imgPlaceholderRef.current.style.display = "inline";
                    wrapperDivRef.current.style.display = "none";
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const checkImageSizeAndType = (file: File) => {
        const validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];
        if (file.size > 2048000) {
            alert('Image is too big');
            return false;
        }
        if (!validImageTypes.includes(file.type)) {
            alert('Wrong file type');
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (imgPlaceholderRef.current) {
            imgPlaceholderRef.current.style.display = "none";
        }
    }, []);

    return {
        imageSrc,
        selectedFile,
        inputRef,
        imgPlaceholderRef,
        wrapperDivRef,
        handleImageChange,
        checkImageSizeAndType,
    }
}