import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Standard outlined (not filled) user icon (does not include traveling.com's "v")
 */
function IconUserOutline(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    clipRule="evenodd"
                    d="m5.34199 5.01869c0-1.46718 1.18938-2.65656 2.65656-2.65656 1.46717 0 2.65655 1.18938 2.65655 2.65656 0 1.46717-1.18938 2.65655-2.65655 2.65655-1.46718 0-2.65656-1.18938-2.65656-2.65655zm2.65656-3.95656c-2.18515 0-3.95656 1.77141-3.95656 3.95656 0 2.18514 1.77141 3.95655 3.95656 3.95655s3.95655-1.77141 3.95655-3.95655c0-2.18515-1.7714-3.95656-3.95655-3.95656zm.00161 8.44599c-.71306 0-1.41914.14044-2.07792.41332-.65877.27286-1.25736.67286-1.76156 1.17706-.50421.5042-.90417 1.1028-1.17705 1.7616-.27287.6587-.41332 1.3648-.41332 2.0779h1.3c0-.5424.10682-1.0794.31437-1.5805.20754-.501.51174-.9563.89524-1.3398.38349-.3835.83876-.6877 1.33981-.8952.50106-.2076 1.03809-.3144 1.58043-.3144s1.07936.1068 1.58042.3144c.50102.2075.95632.5117 1.33982.8952s.6877.8388.8952 1.3398c.2076.5011.3144 1.0381.3144 1.5805h1.3c0-.7131-.1404-1.4192-.4133-2.0779-.2729-.6588-.6729-1.2574-1.1771-1.7616s-1.1028-.9042-1.7615-1.17706c-.65881-.27288-1.36489-.41332-2.07794-.41332z"
                    fill={fill}
                    fillRule="evenodd"
                />
            )}
        />
    )
}

export default IconUserOutline