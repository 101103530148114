import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";
import {RotationOrientationType} from "@Features/icons/types/RotationOrientationType";

type IconChevronProps = IconType & {
    chevronOrientation?: RotationOrientationType,
}

function IconChevronTriple(props: IconChevronProps) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        chevronOrientation = "down",
        ...rest
    } = props

    const orientation = {
        [`${chevronOrientation === "up"}`]: "0deg",
        [`${chevronOrientation === "down"}`]: "-180deg",
        [`${chevronOrientation === "right"}`]: "90deg",
        [`${chevronOrientation === "left"}`]: "-90deg",
    }

    const style = {verticalAlign: "middle", transform: `rotate(${orientation.true})`};

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, style, ...rest}}
            svgIconPath={(
                <g fill={fill} transform="translate(.857)">
                    <path
                        d="m14.285961 10.116v1.884l-6.12021-5.35219c-.30845-.27723-.66235-.42433-1.02275-.42433s-.7143.1471-1.0195.41867l-6.123501 5.35785v-1.884l6.857281-5.99719c.08766-.07355.18507-.11881.28572-.11881s.19805.04526.28897.12447z"
                    />
                    <path
                        d="m14.285961 6.11598v1.88402l-6.12021-5.35219c-.30845-.27723-.66235-.42433-1.02275-.42433s-.7143.1471-1.0195.41867l-6.123501 5.35785v-1.88402l6.857281-5.997168c.08766-.0735503.18507-.118812.28572-.118812s.19805.0452617.28897.12447z"
                    />
                    <path
                        d="m14.285961 14.116v1.884l-6.12021-5.3522c-.30845-.2772-.66235-.4243-1.02275-.4243s-.7143.1471-1.0195.4186l-6.123501 5.3579v-1.884l6.857281-5.99719c.08766-.07355.18507-.11881.28572-.11881s.19805.04526.28897.12447z"
                    />
                </g>
            )}
        />
    )
}

export default IconChevronTriple