import useAcceptCookies from "@Cookies/hooks/useAcceptCookies";
import Translation from "@Translation/components/Translation";
import React from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function CookieAcceptEssentialButton() {
    const {cookiePreferencesActive} = useAppSelector((state) => state?.cookies)
    const {acceptEssentialCookies} = useAcceptCookies()

    return (
        <button
            type="button"
            className={`btn btn--link`}
            onClick={acceptEssentialCookies}
        >
            <Translation translationKey={!cookiePreferencesActive ? "cookie.notice.reject_all" : "cookie.notice.confirm_selection"}/>
        </button>
    );
}

export default CookieAcceptEssentialButton;