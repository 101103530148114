import {Link} from "react-router-dom";
import {useParams} from "react-router";
import Row from "@Containers/components/Row";
import IconMap from "@Icons/routeIcons/IconMap";

function ApiQueryLink() {
    const {date, fromSlug, toSlug} = useParams()
    const departureId = fromSlug?.split("-")
    const destinationId = toSlug?.split("-")
    const {host} = window.location
    const link = `https://admin.${host}/api_query/search?departure_station_id=${departureId[departureId.length - 1]}&destination_station_id=${destinationId[destinationId.length - 1]}&date=${date}`

    return (
        <Link
            to={link}
            target="_blank"
        >
            <Row>
                <IconMap/>
                <span>
                    Check matched routes
                </span>
            </Row>
        </Link>
    );
}

export default ApiQueryLink;