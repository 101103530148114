import React, {useEffect, useRef} from 'react';
import LocationToLocationCountryImageType
    from "@Pages/stationToStation/features/header/types/LocationToLocationCountryImage.type";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setHeaderBackgroundImageRgbPixelData} from "@Reducers/pageSlice";

type BackgroundImageCanvasProps = {
    imagePaths: LocationToLocationCountryImageType
}

const BackgroundImageCanvas = ({imagePaths}: BackgroundImageCanvasProps) => {
    const canvasRef = useRef(null);
    const isMobile = useIsMobile()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = isMobile ? imagePaths?.pathMobile : imagePaths?.pathWindow;

        image.onload = () => {
            // Draw the image onto the canvas
            ctx.drawImage(image, 0, 0);

            // Get the pixel data of the top-left corner (position: 0,0)
            const pixelData = ctx.getImageData(0, 0, 1, 1).data;
            dispatch(setHeaderBackgroundImageRgbPixelData(pixelData))
        };
    }, [isMobile]);

    return (
        <canvas ref={canvasRef} style={{display: "none", position: "absolute"}} />
    );
};

export default BackgroundImageCanvas;