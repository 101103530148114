import TicketTypeInfo from "@MyTicket/components/TicketTypeInfo";
import MyProfileCodeDisplay from "@MyProfile/components/MyProfileCodeDisplay";
import TicketTripDataInfo from "@MyTicket/components/TicketTripDataInfo";
import TransportDataInfo from "@MyTicket/components/TransportDataInfo";
import PriceInfo from "@MyTicket/components/PriceInfo";
import {createRouteTransferTypeArray} from "@MyTicket/functions/createRouteTransferTypeArray";
import getNumberOfPassengers from "@MyTicket/functions/getNumberOfPassengers";
import MoreInfoButton from "@MyProfile/components/MoreInfoButton";
import React from "react";
import TicketType from "@MyTicket/types/Ticket.type";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Row from "@Containers/components/Row";
import IconPremiumSupportAncillary from "@Icons/ancillaries/IconPremiumSupportAncillary";

interface TicketProps {
    ticket: TicketType,
}

function Ticket({ticket}: TicketProps) {
    const {
        ticketCode,
        singleRoute,
        returnRoute,
        status,
        baseRoutes,
    } = ticket

    const {
        isReturn,
        isIntermodal,
    } = status

    const {
        ticket_code: ticketCodeLabel,
        booking_info: bookingInfoLabel,
    } = useTranslation('my_profile.my_tickets')

    return (
        <Row justify className="gb--route ticket">
            <TicketTypeInfo
                isReturn={isReturn}
                isIntermodal={isIntermodal}
            />
            <Row className="ticket-code-container">
                {!isIntermodal && ticket?.ancillariesData?.premiumSupport?.isEnabled && (
                    <div className="premium-support-diamond-container">
                        <IconPremiumSupportAncillary/>
                    </div>
                )}

                <MyProfileCodeDisplay
                    code={ticketCode}
                    subTitle={ticketCodeLabel}
                    className="ticket-code-info"
                    codeClassName="ticket-code"
                    codeDescriptionClassName="code-description"
                    isInfoField
                />
            </Row>

            <TicketTripDataInfo
                isReturn={isReturn}
                isIntermodal={isIntermodal}
                outboundRoute={singleRoute}
                returnDate={returnRoute && `${returnRoute.departureStation.departureDateTime}`}
                baseRoutes={baseRoutes}
            />
            <TransportDataInfo
                status={status}
                singleRoute={singleRoute}
                returnRoute={returnRoute}
                baseRoutes={baseRoutes}
            />
            <PriceInfo
                isIntermodal={isIntermodal}
                isCancelled={status.isCancelled}
                routeTransferTypeArray={createRouteTransferTypeArray(baseRoutes)}
                priceInCurrency={ticket.payment.priceInCurrency}
                numberOfPassengers={getNumberOfPassengers(ticket)}
            />
            <MoreInfoButton
                buttonTitle={bookingInfoLabel}
            />
        </Row>
    );
}

export default Ticket;