import {useState} from "react";
import useElementEventListener from "@Hooks/eventListeners/useElementEventListener";

export default function useHover(ref: React.MutableRefObject<any>) {
    const [hovered, setHovered] = useState(false)


    useElementEventListener(ref, "mouseover", () => setHovered(true))
    useElementEventListener(ref, "mouseout", () => setHovered(false))

    return hovered
}