import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconGuide(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconGuide',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#7e00d9"
                    strokeLinejoin="round"
                    transform="translate(3.25 1.5)"
                >
                    <path
                        d="m0 8.428785v-8.428785h5.62372c2.18085.087905 3.2418 2.73523 1.61915 4.162035-.5374.4699-1.20655.666-1.7024.4023-1.3764-.7303-.26345-2.85017 1.60185-1.85954 1.2586.66944 2.19125 1.76824 2.271 2.88734.0867 1.2476.0867 4.90245.0867 4.90245h-7.38505"
                    />
                    <path d="m8.18262 12.996935v-2.6473"/>
                    <path
                        d="m9.29542 10.491235h-8.00911c-.710765 0-1.28631.5612-1.28631 1.25435 0 .69305.575545 1.25435 1.28631 1.25435h8.00911"
                        strokeLinecap="round"
                    />
                </g>
            )}
        />

    );
}

export default IconGuide;
