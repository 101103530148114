import React from 'react';
import IconLocationMarker from "@Icons/IconLocationMarker";
import {Colors} from "@Styles/Colors.style";

interface FormSuggestionProps {
    suggestion?: any,
    onClick?: (any?) => any
}

const FormSuggestion = ({suggestion, onClick}: FormSuggestionProps) => {
    const onClickHandler = () => {
        onClick(suggestion)(suggestion)
    }

    return (
        <div
            role="button"
            onClick={onClickHandler}
            className="suggestion-content"
        >
            <IconLocationMarker
                style={{marginRight: '1rem'}}
                fill={Colors.primaryColor}
            />
            <span style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                <p>{suggestion.label}</p>
                <small>{suggestion?.country_name}</small>
            </span>
        </div>
    )
};

export default FormSuggestion;