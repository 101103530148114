import React, {Fragment} from 'react';
import RouteDetails from "@Route/components/Container/RouteDetails";
import IntermodalRouteNotification from "@Intermodal/components/IntermodalRouteNotification";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import RouteTotalPriceSection from "@Route/components/Sections/RouteTotalPriceSection";
import RouteNotesAndTicketType from "@Route/features/notes/RouteNotesAndTicketType";

interface IntermodalRouteDetailsProps {
    route: IntermodalRouteWithDetailsType,
    routeIcons: Record<number, RouteIconType>,
    params: ParamsType,
}

function IntermodalRouteDetails({route, routeIcons, params}: IntermodalRouteDetailsProps) {
    return (
        <>
            {route.baseRoutes.map((baseRoute, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`${baseRoute.id}-${index}`}>
                    {route.status.isSuggestion && (
                        <>
                            {index > 0 && (
                                <br/>
                            )}
                            <RouteNotesAndTicketType
                                isCurrentRouteSingle
                                notes={baseRoute.travelInfo.notes}
                                hasMTicket={baseRoute.status.hasMTicket}
                            />
                        </>
                    )}
                    <RouteDetails
                        routeIcons={routeIcons}
                        route={baseRoute}
                        index={index}
                    />
                    {route.status.isSuggestion && (
                        <>
                            <hr/>
                            <RouteTotalPriceSection
                                route={baseRoute}
                                isReturn={false}
                                showButton
                                params={params}
                            />
                        </>
                    )}
                    <IntermodalRouteNotification
                        route={route}
                        baseRoute={baseRoute}
                        index={index}
                    />
                </Fragment>
            ))}
        </>
    )
}

export default IntermodalRouteDetails;