import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconHighway(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(2.1539 1.7288)"
                >
                    <g strokeLinejoin="round">
                        <path d="m0 7.57633324c0-1.74019511 0-2.61029267 0-2.61029267h11.6921555v2.61029267"/>
                        <path
                            d="m5.84607777 0h-2.14099114l-.90546687 3.67831012m-.65092312 2.64426804-1.54204509 6.26430434h5.23942622"
                        />
                        <path
                            d="m11.085504 0h-2.14099115l-.90546687 3.67831012m-.62867586 2.64426804-1.56429235 6.26430434h5.23942623"
                            transform="matrix(-1 0 0 1 16.9316 0)"
                        />
                    </g>
                    <path d="m5.846078-0v12.770716" strokeDasharray="1 1.5"/>
                </g>
            )}
        />
    )
}

export default IconHighway