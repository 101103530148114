import React from "react";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import formatDate from "@DateTime/functions/formatDate";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import useDisplayEditButton from "@SearchResults/hooks/useDisplayEditButton";
import useGetActiveResultsCountLabel from "@SearchResults/features/travelInfo/hooks/useGetActiveResultsCountLabel";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

function ActiveResultsCountHeading({date}: { date: string }) {
    const isCheckOrder = useRouteMatch("checkOrder")
    const dateFormat = isCheckOrder ? "EEEE, dd MMMM yyyy" : "E MMM dd"
    const dateLabel = formatDate(date, dateFormat, true)
    const {passengers} = useAppSelector((state) => state.form.passengers)
    const passengersLabel = useQuantityAwareTranslation('form.passengers.count', passengers)

    const displayEditButton = useDisplayEditButton()
    const label = useGetActiveResultsCountLabel()

    return (
        <small data-notification={label} className="filtered-routes-count">
            {`${dateLabel}, ${passengers} ${passengersLabel}`}
            {displayEditButton ? ` | ${label}` : ""}
        </small>
    );
}

export default ActiveResultsCountHeading;