import useSharedImagesPath from "@Images/hooks/useSharedImagesPath";

function LocationToLocationBackgroundImage() {
    const desktopPath = useSharedImagesPath('images/layout/footer-bg.jpg')
    return (
        <picture className="gb--navigation-background">
            <img
                className="background-image"
                typeof="image/jpg"
                src={desktopPath}
                alt=""
            />
        </picture>
    )
}

export default LocationToLocationBackgroundImage