import {useEffect, useRef} from "react";

/**
 * Returns previous value of some prop / state. e.g. prevProps / prevState in class based components
 * @param value
 * @return {undefined}
 */
export default function usePrevious<T>(value: T): T {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}