import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useTranslation} from "@Translation/hooks/useTranslation";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import TicketTripData from "@MyTicket/components/TicketTripData";

interface TicketTripDataInfoProps {
    isReturn: boolean,
    isIntermodal: boolean,
    outboundRoute?: TicketRouteType,
    returnDate?: string,
    baseRoutes?: TicketRouteType[],
}

function TicketTripDataInfo(props: TicketTripDataInfoProps) {
    const {
        isReturn,
        isIntermodal,
        outboundRoute,
        returnDate,
        baseRoutes,
    } = props

    const {
        departureStation,
        destinationStation,
    } = outboundRoute || {}

    const {
        departureDateTime,
    } = departureStation || {}

    const isMobile = useIsMobile()

    const {
        outbound: outboundLabel,
        return: returnLabel,
    } = useTranslation('sys')

    if (isMobile) {
        return (
            <>
                <TicketTripData
                    departureStationName={isIntermodal ? baseRoutes[0]?.departureStation.name : departureStation?.name}
                    destinationStationName={isIntermodal ? baseRoutes[baseRoutes.length - 1]?.destinationStation.name : destinationStation?.name}
                    departureDate={isIntermodal ? baseRoutes[0]?.departureStation.departureDateTime : departureDateTime}
                    className="outbound-trip-data-infos"
                    directionLabel={isReturn && outboundLabel}
                />
                {isReturn && (
                    <TicketTripData
                        departureStationName={destinationStation?.name}
                        destinationStationName={departureStation?.name}
                        departureDate={returnDate}
                        className="return-trip-data-info"
                        directionLabel={returnLabel}
                    />
                )}
            </>
        )
    }

    return (
        <TicketTripData
            departureStationName={isIntermodal ? baseRoutes[0]?.departureStation.name : departureStation?.name}
            destinationStationName={isIntermodal ? baseRoutes[baseRoutes.length - 1]?.destinationStation.name : destinationStation?.name}
            showReturnIcon={isReturn}
            departureDate={isIntermodal ? baseRoutes[0]?.departureStation.departureDateTime : departureDateTime}
            returnDate={returnDate}
        />
    );
}

export default TicketTripDataInfo;