import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Icon SMS/envelope/mail, representing email
 */
function IconEmail(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m13.463741 0c1.0217574 0 1.85.82826368 1.85 1.849998v8.00639c0 1.0217708-.8282292 1.85-1.85 1.85h-11.61374c-1.02174174 0-1.850001-.82824-1.850001-1.85v-8.00639c0-1.02172343.82827274-1.849998 1.850001-1.849998zm0 1.2h-11.61374c-.35898597 0-.650001.29101567-.650001.649998v8.00639c0 .359012.29099473.65.650001.65h11.61374c.3590292 0 .65-.2909708.65-.65v-8.00639c0-.35899945-.2909912-.649998-.65-.649998zm-9.77427194 1.67416957 3.66633567 2.89689659c.23703493.18728583.57184642.18658357.80809298-.0016971l3.63089999-2.89364.7478866.93843788-3.63089472 2.89363579c-.6723781.53586304-1.62530661.53786178-2.29993664.0048237l-3.66634-2.8969z"
                    fill={fill}
                    transform="translate(.4 2.4)"
                />
            )}
        />
    )
}

export default IconEmail