import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconCoffeeBreak(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(2.2455 1)"
                >
                    <g strokeLinejoin="round" transform="translate(.331 3.7602)">
                        <path
                            d="m0 0h9.16870313c-1.08136257 5.01627393-1.62204385 7.5244109-1.62204385 7.5244109s-1.96012471 0-5.88037411 0c-1.11085678-5.01627393-1.66628517-7.5244109-1.66628517-7.5244109z"
                        />
                        <path
                            d="m8.74769938 1.68228117h2.85382672c-.6296697 1.98523104-.9445046 2.97784656-.9445046 2.97784656s-.82505594 0-2.47516782 0"
                        />
                    </g>
                    <path d="m3.71373948 0v2.52347758"/>
                    <path d="m6.458274.881247v1.642231"/>
                    <path d="m0 13.388534h10.64435"/>
                </g>
            )}
        />
    )
}

export default IconCoffeeBreak