import {CSSProperties, ReactNode} from "react";

type GridProps = {
    children: ReactNode,
    className?: string,
    autofill?: boolean
    style?: CSSProperties
    min?: string
    max?: string
}

function Grid(props: GridProps) {
    const style = {
        ...props.style,
        gridTemplateColumns: props.autofill ? `repeat( auto-fill, minmax(${props.min ?? '300px'}, ${props.max ?? '1fr'}))` : `repeat( auto-fit, minmax(${props.min ?? '300px'}, ${props.max ?? '1fr'}))`,
    }

    return (
        <div
            className={`gb--grid ${props.className ?? ''}`}
            style={style}
        >
            {props.children}
        </div>
    );
}

export default Grid;