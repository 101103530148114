import {useCompanySelect} from "@Pages/companyDirectReview/hooks/useCompanySelect";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import {CompanyInputFieldSuggestion} from "@Pages/companyDirectReview/components/CompanyInputFieldSuggestion";
import React from "react";

export const CompanySelect = () => {
    const {
        locationPlaceholder: fieldPlaceholder,
        fieldValue,
        selectedSuggestion,
        suggestionResponse,
        handleSelect,
        handleChange,
        showDropdown,
        setShowDropdown,
        placeholder,
        label,
    } = useCompanySelect();

    return (
        <div className="review-location_station react-autosuggest__container">
            <h3>{label}</h3>
            <div className="line">
                <div className="triangle"></div>
            </div>
            <input
                required
                type="text"
                name="name"
                id="company-name"
                className="gb--input-standalone company-filter__autocomplete"
                placeholder={placeholder}
                value={fieldValue}
                onChange={handleChange}
                onClick={() => setShowDropdown(fieldValue.length > 1)}
            />
            {showDropdown && suggestionResponse?.length > 0 && (
                <ClickOutsideAwareComponent
                    className="suggestion-container"
                    onClickOutside={() => setShowDropdown(false)}
                >
                    <ul className="react-autosuggest__suggestions-list">
                        {suggestionResponse?.map((suggestion) => (
                            <CompanyInputFieldSuggestion
                                onClick={() => handleSelect(suggestion)}
                                suggestion={suggestion}
                            />
                        ))}
                    </ul>
                </ClickOutsideAwareComponent>
            )}
            <input type="hidden" id="company_id" name="company_id" value={selectedSuggestion?.value}/>
        </div>
    )
}