import {useOutletContext} from "react-router";
import TicketType from "@MyTicket/types/Ticket.type";
import TicketDetails from "@MyTicket/features/ticketDetails/components/TicketDetails";
import Ticket from "@MyTicket/components/Ticket";
import {useScrollToTopOnMount} from "@Scroll/hooks/useScrollToTopOnMount";
import SlideInContainer from "@Containers/components/SlideInContainer";
import Notification from "@Features/notification/components/Notification";
import Translation from "@Translation/components/Translation";

type TicketAdditionalInfoProps = {}

function TicketAdditionalInfo(props: TicketAdditionalInfoProps) {
    const ticket = useOutletContext<TicketType>()
    useScrollToTopOnMount()
    const cardClassName = () => (`gb--card-expanded${ticket.status.isIntermodal ? "-intermodal" : ""}`)

    return (
        <SlideInContainer
            slideInDirection="right"
            className="ticket__additional-info"
        >
            <Notification
                isBorder
                notificationType="primary"
                additionalClass="additional-info__title"
            >
                <Translation translationKey="sys.ticket_info"/>
            </Notification>
            <div className={`panel gb--card ${cardClassName()}`}>
                <Ticket
                    ticket={ticket}
                />
                <TicketDetails
                    ticket={ticket}
                />
            </div>
        </SlideInContainer>
    );
}

export default TicketAdditionalInfo;