import React from 'react';
import RouteItinerary from "@Route/components/Sections/RouteItinerary";
import TicketInfoLabel from "@CheckOrder/components/Sidebar/TicketInfoLabel";
import {isEmpty} from "@Array/isEmpty";
import CompanySpecificTerms from "@CheckOrder/features/sidebar/components/CompanySpecificTerms";
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteType from "@Route/types/Route.type";
import TicketTerms from "@CheckOrder/components/Sidebar/TicketTerms";
import {displayTicketType} from '@/functions/overrides/displayTicketType';
import TravelingDotComTicketTypeSection from "@Route/features/notes/TravelingDotComTicketTypeSection";
import filterCheckOrderCompanySpecificNotes
    from "@CheckOrder/features/sidebar/functions/filterCheckOrderCompanySpecificNotes";

interface TicketInfoProps {
    company: CompanyType,
    route: RouteType,
    isReturn?: boolean,
    isIntermodal?: boolean,
}

function TicketInfo({company, route, isReturn = false, isIntermodal = false}: TicketInfoProps) {
    const {
        status: {
            hasMTicket,
            providerId,
        },
    } = route

    return (
        <>
            <TicketInfoLabel
                companyId={company.id}
                companyLogoUrl={company.logoUrl}
                isIntermodal={isIntermodal}
                isReturn={isReturn}
            />
            {displayTicketType(route) && (
                <TravelingDotComTicketTypeSection
                    hasMTicket={hasMTicket}
                    showDescription={false}
                />
            )}
            <hr/>
            <div className="gb--route-details">
                <div className="route-expanded-details-section">
                    <RouteItinerary
                        route={route}
                        companyLetterCode={company.letterCode}
                    />
                </div>
            </div>
            <TicketTerms
                terms=""
            />
            {!isEmpty(filterCheckOrderCompanySpecificNotes(route.travelInfo?.notes)) && (
                <CompanySpecificTerms
                    notes={route.travelInfo.notes}
                />
            )}
        </>
    )
}

export default TicketInfo