import {
    CheckboxAdditionalFieldType
} from "@CheckOrder/features/TicketAdditionalInfo/types/CheckboxAdditionalField.type";
import TermWithCheckbox from "@Generic/form/TermWithCheckbox";
import Column from "@Containers/components/Column";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";

type CheckboxAdditionalFieldProps = {
    field: CheckboxAdditionalFieldType,
    value: boolean,
    onClick: (value: boolean) => void,
    userConfirmed: boolean,
}

function CheckboxAdditionalField({field, value, onClick, userConfirmed}: CheckboxAdditionalFieldProps) {
    const {valueRequired} = useTranslation('sys.required')

    const {
        title,
    } = field.translations || {}

    return (
        <Column className="gb--check-order-input-container">
            <TermWithCheckbox
                onClick={() => onClick(!value)}
                value={value}
                label={title ?? field.label}
            />
            {userConfirmed && !value && (
                <CheckOrderErrorLabel
                    error={valueRequired}
                    isAbsolute
                />
            )}
        </Column>
    );
}

export default CheckboxAdditionalField;