import Notification from "@Features/notification/components/Notification";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface NoResultsNotificationProps {
    noResultsKey: string,
}

function NoResultsNotification({noResultsKey}: NoResultsNotificationProps) {
    const noResultsLabel = useTranslation(noResultsKey)

    return (
        <Notification isFullWidth additionalClass="my-profile__no-results-notification">
            <h4>{noResultsLabel}</h4>
        </Notification>
    );
}

export default NoResultsNotification;