import {SelectAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/SelectAdditionalFieldType";
import DropdownButton from "@Generic/buttons/DropdownButton";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";
import React, {useEffect} from "react";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";

type SelectAdditionalFieldProps = {
    field: SelectAdditionalFieldType,
    value: string,
    onClick: (value: string) => void,
    updateValue: (value: string) => void,
    userConfirmed: boolean,
}

function SelectAdditionalField({field, value, onClick, updateValue, userConfirmed}: SelectAdditionalFieldProps) {
    const {
        title,
        description,
        error,
    } = field.translations || {}

    const getActiveLabel = () => {
        return field.options.find((option) => option.value === value)?.label ?? title
    }

    useEffect(() => {
        if (field.options.length === 1) {
            updateValue(field.options[0]?.value ?? "")
        }
    }, [field.options.length])

    return (
        <CheckOrderInputContainer
            label={title ?? field.label}
            key={field.key}
        >
            {!!description && (
                description
            )}

            {field.options.length === 1 && (
                <p className="gb--select-dropdown-preselected ">
                    {field.options[0]?.value}
                </p>
            )}

            {field.options.length > 1 && (
                <DropdownButton
                    label={getActiveLabel()}
                    baseClass="gb--select"
                    cssButtonClass="gb--select-dropdown gb--select-dropdown-btn"
                    cssLabelClass="gb--select-dropdown-label"
                >
                    <div className="react-autosuggest__suggestions-list">
                        {field.options.map((option) => (
                            <button
                                type="button"
                                key={option.value}
                                value={option.value}
                                onClick={() => onClick(option.value)}
                                className="gb--select__option full-width"
                            >
                                {option.label}
                            </button>
                        ))}
                    </div>
                </DropdownButton>
                )}

            {!value && userConfirmed && (
                <CheckOrderErrorLabel
                    isAbsolute
                    error={error}
                />
            )}
        </CheckOrderInputContainer>
    );
}

export default SelectAdditionalField;