import React from "react";
import useMyProfileLink from "@User/hooks/useMyProfileLink";
import NavigationContextAwareNavLink from "@Routing/components/NavigationContextAwareNavLink";
import IconUserOutlineWithTrademark from "@Icons/user/IconUserOutlineWithTrademark";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {Palette} from "@Styles/Palette.style";

function MyProfileLink({className, showIcon}: { className?: string, showIcon?: boolean }) {
    const {label, href} = useMyProfileLink()
    const isMobile = useIsMobile()
    return (
        <NavigationContextAwareNavLink
            routeName="myProfile"
            className={className}
            to={href}
        >
            {showIcon && (
                <IconUserOutlineWithTrademark
                    width="25"
                    height="20"
                    fill={isMobile ? undefined : Palette.white}
                />
            )}
            {label}
        </NavigationContextAwareNavLink>
    );
}

export default MyProfileLink;