import React, {useState} from 'react';
import PortalMap from "@Portals/components/PortalMap";
import FaqCard from "./FaqCard";
import IconChevron from "@Icons/chevrons/IconChevron";

function FaqPortal() {
    const [activeIndex, setActiveIndex] = useState(-1);
    const data = document.getElementsByClassName('gb__faq-question');

    return (
        <PortalMap
            className="gb__faq-question"
        >
            <FaqCard activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>

        </PortalMap>
    );
}

export default FaqPortal;