import React, {PureComponent} from 'react';
import LazyLoad, {forceCheck} from "react-lazyload";

interface ImageLabelFieldProps {
    imageUrl: string,
    // Required for lazy-load logic
    imageHeight: number,
    label: string,
    // Index is required for forceCheck() lazy load method if array changes
    index: number,
    // Optional click handler
    onClickHandler: () => void,
}

/**
 * Generic field that includes lazy loaded image along the label
 */
class ImageLabelField extends PureComponent<ImageLabelFieldProps> {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.index !== this.props.index) {
            forceCheck()
        }
    }

    render() {
        const {
            imageUrl,
            label,
            imageHeight,
            onClickHandler,
        } = this.props

        return (
            <LazyLoad overflow height={imageHeight} offset={imageHeight + 100} once>
                <div className="gb--image-label" onClick={onClickHandler}>
                    <span className="gb--image-container">
                        <img src={imageUrl} alt={label}/>
                    </span>
                    {label || null}
                </div>
            </LazyLoad>
        );
    }
}

export default ImageLabelField;