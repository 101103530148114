import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import MobileStationInputButton from "@Form/components/Mobile/MobileStationInputButton";
import StationInputFields from "@Form/features/stations/components/StationInputFields";
import DatePickerInputFields from "../features/dates/components/DatePickerInputFields";
import PassengerPickerButton from "../features/passengers/components/PassengerPickerButton";
import SearchButton from "./SearchButton";
import FormContainer from "./FormContainer";
import useFormMethods from "../hooks/useFormMethods";
import FormWrapper from "@Form/components/FormWrapper";
import {FormVersionType} from "@Form/types/FormVersion.type";
import HeroBackground from "@Form/features/hero/components/HeroBackground";

interface FormProps {
    formVersion?: FormVersionType,
    displayDescription?: boolean
}

function Form({formVersion = "1", displayDescription}: FormProps) {
    const {mobileOverlayActive} = useFormMethods()
    const isMobile = useIsMobile()

    return (
        <FormWrapper formVersion={formVersion} displayDescription={displayDescription}>
            <HeroBackground/>
            <FormContainer formVersion={formVersion}>
                {!isMobile ? (
                    <StationInputFields formVersion={formVersion}/>
                ) : (
                    <>
                        <MobileStationInputButton direction="departure" formVersion={formVersion}/>
                        <MobileStationInputButton direction="destination" formVersion={formVersion}/>
                    </>
                )}
                {!mobileOverlayActive && (
                    <DatePickerInputFields formVersion={formVersion}/>
                )}
                <PassengerPickerButton formVersion={formVersion}/>
                <SearchButton formVersion={formVersion}/>
            </FormContainer>
        </FormWrapper>
    );
}

export default Form
