import React from 'react';
import IconType from "@/types/Icon.type";
import GenericSvgIcon from "@Icons/GenericSvgIcon";

const TravelingDotComLogoMark = (props: IconType) => {
    const {
        width = '32',
        height = '32',
        fill = '#fff',
        viewBox = '0 0 32 32',
        title = 'LogomarkTraveling',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, fill, viewBox, title, ...rest}}
            svgIconPath={(
                <path
                    d="m35.6459206.41048091c-.1238773-.04704391-.1238773-.04800399-.1238773-.04800399l-.0048014.01344111c-.006722.01632136-.0182455.04704392-.0355307.08928743-.0345704.08544711-.0893069.21889821-.1642094.39363275-.149805.34946908-.3783538.86119165-.6827653 1.47564278-.6145849 1.24234336-1.509574 2.85143724-2.646556 4.39524568-.0729819.09984831-.1469242.19777645-.2199061.29378444-.9862166-2.43476257-2.7118556-4.37316384-4.774556-5.59438545-1.9148159-1.13289426-4.2310325-1.70318171-6.5290036-1.29994816-2.3891986.41859483-4.508556 1.85775457-5.8174296 4.23203211-1.9772347 3.58685843-1.2714224 7.17851729.7595884 9.76401239 1.8860073 2.4001997 4.847538 3.9171259 7.7639351 4.3193993.1229169.0172815.2458339.0326428.3677906.0460839-.654917 1.1079322-1.3818556 2.007527-2.1087942 2.716066-1.7630903 1.719503-3.2861083 2.1236967-3.7355235 2.1236967-.8296895 0-1.4077834-.0144012-2.02813-.1910559-.5617689-.1603334-1.2992707-.5002017-2.1808159-1.3834751-1.8802454-1.8846369-4.4499783-6.2818027-6.6893718-16.46248973l-6.7959639 1.49388429c2.32966065 10.58680084 5.25950181 16.57481904 8.56001444 19.88229424 1.70931406 1.7127825 3.48296746 2.6699821 5.19708306 3.1596229 1.609444.4589181 3.0364332.4579581 3.867083.4570857h.0701011c2.8482166 0 6.0200505-1.5890199 8.5945848-4.0996288 2.3565488-2.2984312 4.4317329-5.5598226 5.6253719-9.7976152 2.4122455-1.5524491 4.3136173-3.6847865 5.6762671-5.5339004 1.4519567-1.97104398 2.5495668-3.95840933 3.2803466-5.43597226.3120938-.6298124.5617689-1.17705794.748065-1.6033334zm-12.1956679 7.00570287c1.1148953.65957488 2.1932996 2.03824959 2.2489964 4.11010192-.5233574.0892875-1.0495957.0998484-1.5777545.0278424-1.3568881-.1872156-2.6043033-.913036-3.2428953-1.72622366-.2938484-.37443115-.4090831-.69893815-.4369314-.95719964-.0259278-.23521957-.0019206-.6058104.2996101-1.15113578.2919278-.53092417.6136245-.68453695.9257184-.7392615.4042816-.07008583 1.0515162.00288024 1.7832563.43587626z"
                    fill="#97edc4"
                    fillRule="evenodd"
                />
            )}
        />
    );
};

export default TravelingDotComLogoMark;
