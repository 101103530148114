export const bannerUrlDefaultQueryParams = {
    aid: 'getbybus-search',
    openmenu: "null",
    resolution: "1",
    scroll: "disabled",
    hideenlargemap: true,
    hideshare: true,
    hidesettings: true,
    hidemappanels: true,
    hidenavbuttons: true,
    hidenavbar: true,
    hidefilters: true,
    hidebrandlogo: true,
    hidemapattribution: true,
    hideguestpicker: true,
    hidefooter: true,
}