import React from 'react'
import IconArrowAirplane from "@Icons/arrows/IconArrowAirplane";
import IconArrowAirplaneSwitch from "@Icons/arrows/IconArrowAirplaneSwitch";
import {Colors} from "@Styles/Colors.style";

interface TicketRouteTitleProps {
    departureStationName: string,
    destinationStationName: string,
    showReturnIcon: boolean,
}

const TicketRouteTitle = ({departureStationName, destinationStationName, showReturnIcon}: TicketRouteTitleProps) => {
    return (
        <div>
            <span className="station-info gb--emphasize-default">
                {departureStationName}
            </span>
            &nbsp;
            {showReturnIcon ? (
                <IconArrowAirplaneSwitch
                    fill={Colors.primaryColor}
                />
            ) : (
                <IconArrowAirplane/>
            )}
            &nbsp;
            <span className="station-info gb--emphasize-default">
                {destinationStationName}
            </span>
        </div>
    )
}

export default TicketRouteTitle