import React from 'react';
import Translation from "@Translation/components/Translation";
import Row from "@Containers/components/Row";
import Link from "@Generic/navigation/Link";
import SidebarSectionContainerItem from "../Sections/SidebarSectionContainerItem";
import ColoredRatingStar from "@Features/ratings/components/ColoredRatingStar";
import Column from "@Containers/components/Column";
import LatestReviewType from "@Sidebar/types/LatestReview.type";
import {Colors} from "@Styles/Colors.style";
import IconStarFull from "@Icons/IconStarFull";
import IconOpenLink from "@Icons/IconOpenLink";

interface ReviewProps {
    review: LatestReviewType,
}

function Review({review}: ReviewProps) {
    const {
        rating,
        Name: companyName,
        widgetText,
        reviewLink,
        real_name: username,
    } = review || {}

    const reviewRating = Math.round(rating * 10) / 10;

    return (
        <SidebarSectionContainerItem className="gb--sidebar-latest-review">
            <span className="icon-container">
                <IconStarFull fill={'#81eeb8'}/>
            </span>
            <Column>
                <Row center justify>
                    <span className="gb--emphasize gb--emphasize-default">
                        {companyName}
                    </span>
                    <ColoredRatingStar score={reviewRating}/>
                </Row>
                <span>
                    {widgetText}
                </span>
                <Row center justify>
                    <span>
                        <Translation translationKey="c_profile.by"/>
                        {` ${username}`}
                    </span>
                    <Link
                        href={reviewLink}
                        className="reviews-item__more"
                    >
                        <Translation translationKey="sys.see_more"/>
                        <IconOpenLink
                            fill={Colors.primaryColor}
                            direction={'right'}
                            width="14"
                            height="14"
                        />
                    </Link>
                </Row>
            </Column>
        </SidebarSectionContainerItem>
    );
}

export default Review;