import React from 'react'
import FeatureCard from "@Pages/mobileApp/features/appOverview/components/FeatureCard";
import useGenerateTransportSections from "@Pages/mobileApp/features/appOverview/hooks/useGenerateTransportSections";

const MobileAppFeatures = () => {
    const features = useGenerateTransportSections()

    return (
        <div className="feature-cards-container">
            <div className="feature-cards">
                {features.map((feature) => (
                    <FeatureCard
                        color={feature.color}
                        title={feature.title}
                        icons={feature.icons}
                        key={feature.title}
                    >
                        <p className="feature-card-content">
                            {feature.content}
                        </p>
                    </FeatureCard>
                ))}
            </div>
        </div>
    )
}

export default MobileAppFeatures