import useRouteMatch from "@Routing/hooks/useRouteMatch";
import {useLayoutEffect, useState} from "react";
import usePrevious from "@Hooks/refs/usePrevious";
import {MatchedRoutes} from "@Routing/types/MatchedRoutes.type";

type PagesWithBodyClassChanges = Extract<MatchedRoutes, "homepage" | "searchResults" | "myProfile" | "checkOrder">

/**
 * Changes body className depending on the route
 */
export default function useSetBodyClassName() {
    const isHomepage = useRouteMatch('homepage')
    const isSearchResults = useRouteMatch('searchResults')
    const isMyProfile = useRouteMatch('myProfile')
    const isCheckOrder = useRouteMatch('checkOrder')

    const routeBodyClasses: Record<PagesWithBodyClassChanges, string[]> = {
        homepage: ["homepage", "homepage--gbb"],
        searchResults: ["gb--search-results", "search-results"],
        myProfile: ["trvl--my-profile"],
        checkOrder: ["gb--main-content"],
    }

    const routeMatcher: Record<string, PagesWithBodyClassChanges> = {
        [`${!!isHomepage}`]: "homepage",
        [`${!!isSearchResults}`]: "searchResults",
        [`${!!isMyProfile}`]: "myProfile",
        [`${!!isCheckOrder}`]: "checkOrder",
    }

    const [currentPage, setCurrentPage] = useState(routeMatcher?.true)
    const previousPage = usePrevious(currentPage)

    const setBodyClassName = () => {
        const body = document.getElementsByTagName('body')[0];

        if (!body) {
            return
        }

        routeBodyClasses[previousPage]?.map((bodyClass) => (
            body.classList.remove(bodyClass)
        ))

        routeBodyClasses[currentPage]?.map((bodyClass) => (
            body.classList.add(bodyClass)
        ))
    }

    useLayoutEffect(() => {
        setCurrentPage(routeMatcher?.true)
    }, [isSearchResults, isHomepage, isCheckOrder, isMyProfile]);

    useLayoutEffect(() => {
        setBodyClassName()
    }, [currentPage, previousPage]);
}