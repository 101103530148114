import React, {ReactNode} from 'react';
import TicketsContainer from "./Tickets/TicketsContainer";
import SidebarContainer from "./SidebarContainer";
import GuidesContainer from "./Guides/GuidesContainer";
import BlogPostsContainer from "./BlogPosts/BlogPostsContainer";
import ReviewsContainer from "./Reviews/ReviewsContainer";
import useSidebarData from "@Hooks/promises/useSidebarData";

interface SidebarProps {
    className?: string,
    children: ReactNode,
}

function Sidebar({className = "", children}: SidebarProps) {
    const {
        latestTickets,
        latestBlogPages,
        latestBlogPosts,
        latestReviews,
    } = useSidebarData();

    return (
        <SidebarContainer className={`gb--sidebar-container ${className}`}>
            {children}
            <TicketsContainer
                latestTickets={latestTickets}
            />
            <GuidesContainer
                latestGuides={latestBlogPages}
            />
            <BlogPostsContainer
                latestBlogPosts={latestBlogPosts}
            />
            <ReviewsContainer
                latestReviews={latestReviews}
            />
        </SidebarContainer>
    );
}

export default Sidebar