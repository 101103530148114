import {Dispatch, ReactNode, SetStateAction, useRef} from "react";
import useUpdateSlideWrapperWidth from "@Features/slider/features/slider/hooks/useUpdateSlideWrapperWidth";

interface SliderItemProps {
    itemWidth: number,
    child: ReactNode,
    setItemWidth: Dispatch<SetStateAction<number>>
}

function SliderItem({itemWidth, child, setItemWidth}: SliderItemProps) {
    const wrapperRef = useRef<HTMLDivElement>()
    useUpdateSlideWrapperWidth({wrapperRef, itemWidth, setItemWidth})

    return (
        <div
            className="slider-card"
            ref={wrapperRef}
            style={{
                width: `${itemWidth}%`,
                maxWidth: `${itemWidth}%`,
            }}
        >
            {child}
        </div>
    )
}

export default SliderItem