import React from 'react';
import ControlCheckbox from "@Generic/form/ControlCheckbox";

type AncillaryCardProps = {
    callback: any,
    ancillaryValue: any
}

function AncillaryCheckbox({callback, ancillaryValue}: AncillaryCardProps) {
    return (
        <ControlCheckbox
            onChange={callback}
            checked={ancillaryValue}
            className="ancillary-checkbox"
        />
    );
}

export default AncillaryCheckbox;