import {useTranslation} from "@Translation/hooks/useTranslation";
import RouteNoteType from "@Route/types/notes/RouteNote.type";

type CompanySpecificTermsProps = {
    notes: RouteNoteType[]
}

function CompanySpecificTerms({notes}: CompanySpecificTermsProps) {
    const fareTermsLabel = useTranslation('sys.fare_terms')

    return (
        <>
            <hr/>
            <span className="gb--card-title">
                {fareTermsLabel}
            </span>
            {notes.map((note, index) => (
                <p className="company-specific-note" key={note.note}>{note.note}</p>
            ))}
        </>
    );
}

export default CompanySpecificTerms;