import React from 'react';
import TrvlIconDownload from "@Icons/files/IconDownload";
import TrvlIconPen from "@Icons/edit/IconPen";
import {useTranslation} from "@Translation/hooks/useTranslation";
import TicketType from "@MyTicket/types/Ticket.type";
import TicketStatusType from "@MyTicket/types/TicketStatus.type";
import prepareTicketModalData from "@MyTicket/features/ticketDetails/functions/prepareTicketModalData";
import TicketActionButton from "@MyTicket/features/ticketDetails/components/TicketActionButton";
import Dialog from "@Features/dialog/components/Dialog";
import DownloadDocumentsModal from "@UserModals/features/downloadDocuments/components/DownloadDocumentsModal";
import ManageBookingModal from "@UserModals/features/manageBookings/components/ManageBookingModal";
import useDialog from "@Features/dialog/hooks/useDialog";

interface TicketActionsSectionProps {
    ticket: TicketType,
    status: TicketStatusType,
}

function TicketActionsSection({ticket, status}: TicketActionsSectionProps) {
    const {
        isPastDeparture,
        isIntermodal,
        isRebookable,
        isCancelable,
        isCancelled,
    } = status

    const {
        download_documents: downloadDocumentsLabel,
        standard_download_options: standardDownloadOptionsLabel,
        storno_download_options: stornoDownloadOptionsLabel,
        manage_booking: manageBookingLabel,
        booking_options: bookingOptionsLabel,
    } = useTranslation('my_profile.my_tickets')

    const {
        dialogRef: downloadDocumentsDialogRef,
        openModal: openDownloadDocumentsDialog,
        closeModal: closeDownloadDocumentsDialog,
    } = useDialog()

    const {
        dialogRef: manageBookingsDialogRef,
        openModal: openManageBookingsDialog,
        closeModal: closeManageBookingsDialog,
    } = useDialog()

    return (
        <div className="ticket-actions-section">
            <TicketActionButton
                mainLabel={downloadDocumentsLabel}
                descriptionLabel={isCancelled ? stornoDownloadOptionsLabel : standardDownloadOptionsLabel}
                notificationColor="green"
                notificationIcon={(
                    <TrvlIconDownload
                        width="32"
                        height="32"
                    />
                )}
                onClick={openDownloadDocumentsDialog}
            />
            {!isPastDeparture && !isIntermodal && (isRebookable || isCancelable) && (
                <TicketActionButton
                    mainLabel={manageBookingLabel}
                    descriptionLabel={bookingOptionsLabel}
                    notificationColor="blue"
                    notificationIcon={(
                        <TrvlIconPen
                            width="32"
                            height="32"
                        />
                    )}
                    onClick={openManageBookingsDialog}
                />
            )}
            <Dialog dialogRef={downloadDocumentsDialogRef}>
                <DownloadDocumentsModal
                    modalData={prepareTicketModalData(ticket)}
                    closeModal={closeDownloadDocumentsDialog}
                />
            </Dialog>
            <Dialog dialogRef={manageBookingsDialogRef}>
                <ManageBookingModal
                    modalData={prepareTicketModalData(ticket)}
                    closeModal={closeManageBookingsDialog}
                />
            </Dialog>
        </div>
    );
}

export default TicketActionsSection;