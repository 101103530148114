import React, {MutableRefObject} from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import AccordionItem from "@Generic/blocks/AccordionItem";
import Accordion from "react-bootstrap/Accordion";
import TravelInfoWithDurationType from "@Route/types/travelInfo/TravelInfoWithDuration.type";
import StationsPopover from "@SearchResults/components/Popover/StationsPopover/StationsPopover";
import MobileContentContainer from "@Route/features/mobile/components/MobileContentContainer";

interface MobileRouteStationsProps {
    travelInfo: TravelInfoWithDurationType,
    scrollRef: MutableRefObject<any>,
    routeId: string,
}

function MobileRouteStations({travelInfo, routeId, scrollRef}: MobileRouteStationsProps) {
    const stationsLabel = useTranslation("sys.stations")
    const routeLabel = useTranslation("sys.route")
    const seeRouteStations = useTranslation("sys.click_to_see_stations")
    const clickToSeeStations = `${seeRouteStations} | RouteID: ${routeId}`
    const title = `${routeLabel} ${stationsLabel}`

    return (
        <MobileContentContainer title={title} scrollRef={scrollRef}>
            <Accordion>
                <AccordionItem label={clickToSeeStations} cardClassName="gb--stations-list gb--truncate">
                    <StationsPopover
                        travelInfo={travelInfo}
                    />
                </AccordionItem>
            </Accordion>
        </MobileContentContainer>
    );
}

export default MobileRouteStations;