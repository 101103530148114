import React, {ReactNode} from 'react'

interface BookingInfoProps {
    title: string,
    label: string,
    color: string,
    children: ReactNode
}

const BookingInfo = ({title, label, color, children}: BookingInfoProps) => {
    return (
        <div>
            <span className="feature-card-title feature-card-title-large">
                {label}
            </span>
            <h2 className="bookings-section-title">
                {title}
            </h2>
            {children}
        </div>
    )
}

export default BookingInfo