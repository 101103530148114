import RadioField from "@Generic/form/RadioField";
import React from "react";
import {RadioAdditionalFieldType} from "@CheckOrder/features/TicketAdditionalInfo/types/RadioAdditionalField.type";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import {useTranslation} from "@Translation/hooks/useTranslation";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";

type RadioAdditionalFieldProps = {
    field: RadioAdditionalFieldType,
    value: string,
    onClick: (value: string) => void,
    index: number,
    userConfirmed: boolean,
}

function RadioAdditionalField({field, onClick, value, index, userConfirmed}: RadioAdditionalFieldProps) {
    const {valueRequired} = useTranslation('sys.required')

    const {
        title,
        description,
    } = field.translations || {}

    return (
        <CheckOrderInputContainer
            label={title ?? field.label}
            key={field.key}
        >
            {!!description && (
                description
            )}
            <fieldset className="gb-card--options">
                {field.options.map((field) => (
                    <RadioField
                        passengerId={index}
                        key={field.value}
                        label={field.label}
                        onClick={() => onClick(field.value)}
                        value={field.value}
                        comparisonValue={value}
                        required
                        disabled={false}
                    />
                ))}
            </fieldset>
            {userConfirmed && !value && (
                <CheckOrderErrorLabel
                    error={valueRequired}
                    isAbsolute
                />
            )}
        </CheckOrderInputContainer>
    );
}

export default RadioAdditionalField;