import React, {useEffect, useState} from 'react';
import Column from "@Containers/components/Column";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import Row from "@Containers/components/Row";
import AncillaryCheckbox from "@CheckOrder/features/ancillaries/components/AncillaryCheckbox";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconStar from "@Icons/searchRes/IconStar";
import {Colors} from "@Styles/Colors.style";
import Translation from "@Translation/components/Translation";

type AncillaryCardProps = {
    ancillaryName: string,
    icon: any,
    cardTitleKey: string,
    cardTextKey: string,
    cardTextVariables: object | null,
    ancillaryCallback: any,
    ancillaryValue: any,
    frequentlyBoughtLabel: boolean,
}
const AncillaryCard = (props: AncillaryCardProps) => {
    const {
        ancillaryName,
        icon,
        cardTitleKey,
        cardTextKey,
        cardTextVariables,
        ancillaryCallback,
        ancillaryValue,
        frequentlyBoughtLabel,
    } = props

    const dispatch = useAppDispatch();
    const onClick = () => {
        dispatch(ancillaryCallback(!ancillaryValue));
    };

    const ancillaryData = useAppSelector((state) => state.checkOrder.ticket.ancillariesData[ancillaryName]);
    const [isChecked, setIsChecked] = useState(ancillaryValue);
    useEffect(() => {
        setIsChecked(ancillaryValue);
    }, [ancillaryValue])

    const {frequentlyBoughtOnRoute} = useAppSelector((state) => state?.page.experimentsJson)

    return (
        <Row className="gb--card-ancillary" onClick={onClick}>
            <Row gap>
                <Column className="ancillary-checkbox-container">
                    <AncillaryCheckbox callback={onClick} ancillaryValue={isChecked}/>
                </Column>
                <Column className="ancillary-card-title-container">
                <span className="gb--card-title ancillary-card-title">
                    {useTranslation(cardTitleKey)}
                    {icon}
                </span>
                    {frequentlyBoughtLabel && frequentlyBoughtOnRoute && (
                        <div className="ancillary-text-frequently-bought">
                            <IconStar
                                fill="none"
                                stroke={Colors.neutralDarkestColor}
                            />
                            <Translation
                                translationKey="sys.frequently_bought_on_route"
                            />
                        </div>
                    )}
                    <div className="ancillary-text-container">
                        {useTranslation(cardTextKey, cardTextVariables)}
                    </div>
                </Column>
            </Row>
            <Column className="ancillary-price-container">
                {`+ ${ancillaryData.displayPrice} ${ancillaryData.displayCurrencySymbol}`}
            </Column>
        </Row>
    )
};

export default AncillaryCard
