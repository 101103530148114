import React, {useEffect} from 'react';
import {useInput} from "@Hooks/dispatch/useInput";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Translation from "@Translation/components/Translation";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import AppliedVoucherNotification from "@CheckOrder/components/MainSection/Voucher/AppliedVoucherNotification";
import {clearInvalidVoucherState} from "@CheckOrder/reducers/checkOrderSlice";
import VoucherButtons from "@CheckOrder/components/MainSection/Voucher/VoucherButtons";

interface VoucherInputFieldProps {
    onCancelClick: () => void,
}

function VoucherInputField({onCancelClick}: VoucherInputFieldProps) {
    const {
        voucherDetails,
        ticketVoucher,
    } = useAppSelector((state) => state?.checkOrder.ticket.voucher)

    const voucherCodeLabel = useTranslation("sys.voucher_code")
    const voucher = useInput(voucherDetails?.code ?? '')
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(clearInvalidVoucherState())
    }, [voucher.value])

    return (
        <Column className="check-order__voucher">
            <small>
                <Translation translationKey="check_order.enter_code_voucer"/>
            </small>
            <div className="check-order__voucher-code">
                <Row className="gb--check-order-input-container">
                    <input
                        type="text"
                        value={voucher.value}
                        onChange={voucher.onChange}
                        disabled={voucherDetails?.code}
                        placeholder={voucherCodeLabel}
                    />
                    <VoucherButtons
                        hasValue={!!voucher.value}
                        voucherCode={voucher.value}
                        voucherDetails={voucherDetails}
                        onCancelClick={onCancelClick}
                    />
                </Row>
                <div>
                    <AppliedVoucherNotification
                        voucherDetails={voucherDetails}
                        ticketVoucher={ticketVoucher}
                    />
                </div>
            </div>
        </Column>
    );
}

export default VoucherInputField;