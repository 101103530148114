import React, {ReactNode} from 'react';
import {useScroll} from "@Scroll/hooks/useScroll";
import {isNotEmpty} from "@Array/isNotEmpty";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useTransportTypeAwareTranslation from "@Translation/hooks/useTransportTypeAwareTranslation";
import useDirectionAwareTicketTypeTranslation from "@Translation/hooks/useDirectionAwareTicketTypeTranslation";
import useTripTitle from "@Intermodal/hooks/useTripTitle";
import useSectionFocusedObserver from "@Route/features/mobile/context/hooks/useSectionFocusedObserver";
import RouteActiveTicketContextType from "@Route/features/mobile/types/RouteActiveTicketContext.type";
import {
    IntermodalRouteActiveTicketContextType
} from "@Route/features/mobile/types/IntermodalRouteActiveTicketContext.type";
import {isIntermodalRoute} from "@SearchResults/functions/isIntermodalRoute";
import RouteType from "@Route/types/Route.type";
import {isSuggestedRoute} from "@SearchResults/functions/isSuggestedRoute";

const ActiveTicketContext = React.createContext<RouteActiveTicketContextType | IntermodalRouteActiveTicketContextType>(null);
const {Provider} = ActiveTicketContext

interface ActiveTicketProviderProps {
    children: ReactNode,
    singleSelectedRoute: RouteType,
}

function ActiveTicketProvider({children, singleSelectedRoute}: ActiveTicketProviderProps) {
    const {generateTripTitle} = useTripTitle()
    const hasDiscounts = isNotEmpty(singleSelectedRoute?.prices?.discounts)
    const hasAmenities = isNotEmpty(singleSelectedRoute?.amenitiesHashMap)
    const amenitiesLabel: string = useTranslation("search_results.amenities").toLowerCase()
    const routeLabel: string = useTranslation("sys.route")
    const stationsLabel: string = useTranslation("sys.stations")
    const ticketInfoLabel = useDirectionAwareTicketTypeTranslation()
    const companyTypeTitle = useTransportTypeAwareTranslation('search_results.bus_company')
    const discountTitle: string = useTranslation("search_results.web_discount")
    const amenitiesTitle = `${routeLabel} ${amenitiesLabel}`
    const travelInfoTitle = `${routeLabel} ${stationsLabel}`
    const hasSeatClass = singleSelectedRoute?.travelInfo.seatClass
    const seatClassTitle = 'Seat class'

    // TODO: fix this - it is dumb, but requires large rewrite to fix right now (hook callbacks)
    const observer = {
        0: useSectionFocusedObserver(),
        1: useSectionFocusedObserver(),
        2: useSectionFocusedObserver(),
        3: useSectionFocusedObserver(),
        4: useSectionFocusedObserver(),
    }

    const useNamedSection = (title: string) => ({
        refs: useScroll({block: "start", behavior: "smooth", offset: 110}),
        isIntermodal: false,
        title,
    })

    const discounts = useNamedSection(discountTitle)
    const amenities = useNamedSection(amenitiesTitle)
    const travelInfo = useNamedSection(travelInfoTitle)
    const seatClass = useNamedSection(seatClassTitle)

    const activeSectionsRoute = {
        ticketInfoSection: useNamedSection(ticketInfoLabel),
        seatClassSection: hasSeatClass ? seatClass : null,
        companySection: useNamedSection(companyTypeTitle),
        amenitiesSection: hasAmenities ? amenities : null,
        discountsSection: hasDiscounts ? discounts : null,
        travelInfoSection: (singleSelectedRoute?.travelInfo && singleSelectedRoute?.status?.showStations)
            ? travelInfo
            : null,
    } as RouteActiveTicketContextType

    let activeSectionsIntermodalRoute: IntermodalRouteActiveTicketContextType

    if (isIntermodalRoute(singleSelectedRoute) || isSuggestedRoute(singleSelectedRoute)) {
        activeSectionsIntermodalRoute = {
            ...singleSelectedRoute.baseRoutes.map((route, index) => ({
                refs: observer[index],
                isIntermodal: true,
                title: generateTripTitle(route),
            })),
        }
    }

    return (
        <Provider value={(isIntermodalRoute(singleSelectedRoute) || isSuggestedRoute(singleSelectedRoute)) ? activeSectionsIntermodalRoute : activeSectionsRoute}>
            {children}
        </Provider>
    );
}

export {ActiveTicketProvider, ActiveTicketContext};