import {useAppSelector} from "@Hooks/selector/useAppSelector";
import Translation from "@Translation/components/Translation";
import Column from "@Containers/components/Column";
import React from "react";
import SourcedImage from "@Images/components/SourcedImage";
import displayNumberInHumanReadableFormat from "@Number/displayNumberInHumanReadableFormat";

function TravelConnectionsTravelingDotCom() {
    const {
        homepageData: {
            connectionsCount,
            routeCount,
        },
    } = useAppSelector((state) => state.page)

    const {
        transportTypeKeyword,
    } = useAppSelector((state) => state.searchResults)

    return (
        <section className="travel-connections__wrapper">
            <div className="travel-connections">
                <h2>
                    <Translation translationKey="home_page.travel_connections_section.title"/>
                </h2>
                <h3>
                    <Translation
                        translationKey="home_page.travel_connections_section.subtitle"
                        translationVariables={{transportType: transportTypeKeyword}}
                    />
                </h3>
                <div className="travel-connections__content">
                    <div className="travel-connections__counter-wrapper">
                        <SourcedImage
                            filePath="images/homepage/travel-connections-seated-man-hero"
                            fileType="png"
                            className="travel-connections__user"
                        />
                        <Column className="travel-connections__counter">
                            {displayNumberInHumanReadableFormat(connectionsCount)}
                            <small className="gb--emphasize-default">
                                <Translation translationKey="home_page.connections"/>
                            </small>
                        </Column>
                        <Column className="travel-connections__counter">
                            {displayNumberInHumanReadableFormat(routeCount)}
                            <small className="gb--emphasize-default">
                                <Translation translationKey="home_page.routes"/>
                            </small>
                        </Column>
                        <SourcedImage
                            filePath="images/homepage/travel-connections-seats-hero"
                            fileType="png"
                            className="travel-connections__seats"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TravelConnectionsTravelingDotCom