import parseUtmMediumFromUrlSearchParams from "@Header/features/functions/parseUtmMediumFromUrlSearchParams";

export default function displayStickyHeader() {
    const utmMedium = parseUtmMediumFromUrlSearchParams()
    const gbbUtmMedium = 'gbb-brand'

    if (utmMedium && utmMedium !== gbbUtmMedium) {
        return false
    }

    if (document.referrer.includes('getbybus') || document.referrer.includes('getbyferry')) {
        return true
    }

    return true
}