import React from 'react';
import Column from "@Containers/components/Column";
import CompanyType from "@SearchResults/types/company/Company.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteRatingInformation from "@Route/features/company/components/RouteRatingInformation";
import CompanyRating from "@Route/features/company/components/CompanyRating";

interface CompanyDetailsProps {
    company: CompanyType,
    route: RouteWithAddedPropsAndPricesType,
    hideLetterCode?: boolean,
}

const CompanyDetails = ({company, route, hideLetterCode}: CompanyDetailsProps) => (
    <Column className="gb--company-details">
        <CompanyRating
            displayedValue="name"
            displayCompanyLogo
            company={company}
            rating={route.ratings.rating}
            hideLetterCode={hideLetterCode}
        />
        <RouteRatingInformation
            route={route}
        />
    </Column>
)

export default CompanyDetails;