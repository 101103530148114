import React from 'react';
import PassengerPicker from "./PassengerPicker";
import useQuantityAwareTranslation from "@Translation/hooks/useQuantityAwareTranslation";
import usePassengers from "@Hooks/selector/usePassengers";
import useTogglePassengerPicker from "../hooks/useTogglePassengerPicker";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";
import IconAddPassengerTravelingCom from "@Icons/IconAddPassengerTravelingCom";

interface PassengerPickerButtonProps {
    formVersion?: FormVersionType,
}

function PassengerPickerButton({formVersion = "1"}: PassengerPickerButtonProps) {
    const passengerPickerActive = useAppSelector((state) => state?.form.passengerPickerActive)
    const {passengers} = usePassengers()
    const label = useQuantityAwareTranslation("form.passengers.count", passengers)
    const onClick = useTogglePassengerPicker()

    const {v} = getFormVersionClassNameAggregator(formVersion)

    return (
        <div className={`gb--search${v()}__select gb--search${v()}__select--pax`}>
            <span className={`gb--search${v()}__select--label`}>
                <Translation translationKey="form.passengers.label"/>
            </span>
            <IconAddPassengerTravelingCom
                className="gb--search__select--icon"
            />
            <input
                type="text"
                name="passengers"
                className="gb--input-standalone"
                placeholder={label}
                value={`${passengers} ${label}`}
                onClick={onClick}
                readOnly
            />
            {passengerPickerActive && (
                <PassengerPicker
                    formVersion={formVersion}
                />
            )}
        </div>
    );
}

export default PassengerPickerButton;