import React, {useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import DateSlickSlide from "@SearchResults/features/dateSlick/components/DateSlickSlide";
import useLimitSlidesToShow from "@SearchResults/features/dateSlick/hooks/useLimitSlidesToShow";
import createNewSliderDates from "@SearchResults/features/dateSlick/functions/createNewSliderDates";
import useMoveSlider from "@SearchResults/features/dateSlick/hooks/useMoveSlider";
import useSelectNewSearchDate from "@SearchResults/features/dateSlick/hooks/useSelectNewSearchDate";
import DaysLimitType from "@SearchResults/features/dateSlick/types/DaysLimit.type";
import {sliderSetting} from "@SearchResults/features/dateSlick/constants/sliderSetting";
import DateSlickArrow from "@SearchResults/features/dateSlick/components/DateSlickArrow";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface DateSlickProps {
    /** Date for which search is executed */
    date: string,
    isReturn: boolean,
}

function DateSlick({date, isReturn}: DateSlickProps) {
    const datesInSliderLimit = useLimitSlidesToShow()

    const [
        daysLimit,
        setDaysLimit,
    ] = useState<DaysLimitType>({min: -10, max: 10})

    const [
        clientXonMouseUp,
        setClientXonMouseUp,
    ] = useState<number>(null)

    const [
        clientXonMouseDown,
        setClientXonMouseDown,
    ] = useState<number>(null)

    const [
        centralDate,
        setCentralDate,
    ] = useState<Date | string>(date)

    const [
        datesInSlider,
        setDatesInSlider,
    ] = useState(createNewSliderDates(date, daysLimit))

    const sliderRef = useRef<Slider>()

    useEffect(() => {
        setCentralDate(date)
    }, [date])

    useEffect(() => {
        setDatesInSlider(createNewSliderDates(centralDate, daysLimit))
    }, [centralDate])

    const {moveSliderRight, moveSliderLeft} = useMoveSlider({
        daysLimit,
        setDaysLimit,
        centralDate,
        setCentralDate,
    })

    const {onClick} = useSelectNewSearchDate(isReturn)
    const isMobile = useIsMobile()

    return (
        <div className="datestrip">
            <Slider
                ref={(slider) => (sliderRef.current = slider)}
                {...sliderSetting}
                initialSlide={daysLimit.max}
                prevArrow={isMobile ? (
                    <></>
                ) : (
                    <DateSlickArrow
                        customClassName="slick-prev"
                        orientation="left"
                        click={moveSliderLeft}
                    />
                )}
                nextArrow={isMobile ? (
                    <></>
                ) : (
                    <DateSlickArrow
                        customClassName="slick-next"
                        orientation="right"
                        click={moveSliderRight}
                    />
                )}
                slidesToShow={datesInSliderLimit}
            >
                {datesInSlider.map((dateToDisplay) => (
                    <DateSlickSlide
                        key={dateToDisplay.toDateString()}
                        date={dateToDisplay}
                        isReturn={isReturn}
                        searchedDate={date}
                        setClientXonMouseDown={setClientXonMouseDown}
                        setClientXonMouseUp={setClientXonMouseUp}
                        selectNewSearchDate={onClick}
                    />
                ))}
            </Slider>
        </div>
    )
}

export default DateSlick