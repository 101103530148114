import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Palette} from "@Styles/Palette.style";

function IconArrowAirplaneSwitch({fill = Palette.white, width = 16, height = 16, viewBox = "0 0 16 16", ...rest}: IconType) {
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={fill}
                    strokeLinejoin="round"
                    strokeWidth="1.498533"
                    transform="translate(0 2)"
                >
                    <path d="m8.03279 3.714179h-8.029017"/>
                    <path d="m3.74822031 7.4284948c0-2.05208386-1.67554166-3.71402385-3.74443187-3.71402385"/>
                    <path d="m0 3.71404001c2.06889253 0 3.7444342-1.66193998 3.7444342-3.71404001"/>
                    <path d="m7.967231 9.285868h8.029045"/>
                    <path d="m12.2518247 5.57152905c0 2.05212234 1.6755106 3.71403155 3.7444513 3.71403155"/>
                    <path d="m16 9.28594537c-2.0688631 0-3.7443737 1.66198613-3.7443737 3.71405463"/>
                </g>
            )}
        />
    )
}

export default IconArrowAirplaneSwitch