import React from 'react';
import IconMenu from "@Icons/menuIcons/IconMenu";
import CurrencySelect from "@SearchResults/features/sorting/features/currency/components/CurrencySelect";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import {activateMobileMenu} from "@Reducers/mobileSlice";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import MainMenu from "./MainMenu";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import LocaleSpecificMainMenu from "@Header/components/TopBar/LocaleSpecificMainMenu";
import useIsModifiedMenuDisplayed from "@Header/hooks/useIsModifiedMenuDisplayed";
import Translation from "@Translation/components/Translation";
import Row from "@Containers/components/Row";

function Navbar() {
    const isSearchResults = useRouteMatch('searchResults')
    const isDesktop = useIsDesktop()
    const dispatch = useAppDispatch()
    const openMobileMenu = () => dispatch(activateMobileMenu(true))
    const isModifiedMenuDisplayed = useIsModifiedMenuDisplayed()

    if (isDesktop) {
        return (
            <ul className="gb--menu gb--menu__nav gb--row gb--row-middle gb--row-end">
                {!isModifiedMenuDisplayed && (
                    <MainMenu/>
                )}
                {isModifiedMenuDisplayed && (
                    <LocaleSpecificMainMenu/>
                )}
                {isSearchResults && (
                    <li className="gb--menu__item">
                        <CurrencySelect/>
                    </li>
                )}
                <LanguageSwitcher/>
            </ul>
        )
    }

    return (
        <Row center reverse className="menu-button">
            <IconMenu
                height="35"
                className="gb--menu-toggle"
                onClick={openMobileMenu}
            />
            <Translation translationKey="sys.menu"/>
        </Row>
    )
}

export default Navbar;