import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconToilet(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke}>
                    <g strokeWidth="1.2" transform="translate(1.7464 1.8525)">
                        <path
                            d="m9.45724353 8.19583875c.17435254.06339.3555053.09680284.54137962.09680284 1.38546345 0 2.50860345-1.85637105 2.50860345-4.1463208 0-2.28994974-1.12314-4.14632079-2.50860345-4.14632079-1.38546344 0-2.50860348 1.85637105-2.50860348 4.14632079"
                        />
                        <path
                            d="m10.1132426 0h-8.05422076c-.52685771.09000898-1.00016058.50120665-1.4199086 1.233593-.41974802.73238634-.63278576 1.56566925-.63911324 2.49984874v8.56153306h7.49001967v-8.14865401"
                        />
                    </g>
                    <path d="m3.422015 8.635125h5.612362" strokeDasharray="0 2" strokeLinecap="square"/>
                </g>
            )}
        />
    )
}

export default IconToilet