import Row from "@Containers/components/Row";
import MTicketEcoFriendlyLabel from "@Route/components/MTicket/MTicketEcoFriendlyLabel";
import Column from "@Containers/components/Column";
import React from "react";
import IconMTicket from "@Icons/ticket/IconMTicket";
import useIsMobile from "@Hooks/selector/useIsMobile";
import Translation from "@Translation/components/Translation";
import IconPaperTicket from "@Icons/ticket/IconPaperTicket";

interface TravelingDotComTicketTypeSectionProps {
    hasMTicket: boolean,
    showDescription?: boolean,
    cardClassName?: string,
    contentClassName?: string,
}

function TravelingDotComTicketTypeSection(props: TravelingDotComTicketTypeSectionProps) {
    const {
        hasMTicket,
        showDescription = true,
        cardClassName = "",
        contentClassName = ""
    } = props

    const isMobile = useIsMobile()

    if (hasMTicket) {
        return (
            <Row gap center className={cardClassName}>
                <IconMTicket
                    width={isMobile ? "20" : "32"}
                    height={isMobile ? "20" : "32"}
                />
                <Column className={contentClassName} justify>
                    <MTicketEcoFriendlyLabel/>
                    {!isMobile && showDescription && (
                        <Translation
                            translationKey="search_results.has_mticket_desc"
                        />
                    )}
                </Column>
            </Row>
        )
    }

    return (
        <Row gap center className={cardClassName}>
            <IconPaperTicket
                width={isMobile ? "20" : "32"}
                height={isMobile ? "20" : "32"}
            />
            <Column className={contentClassName} justify>
                <span className="gb--emphasize-warning">Paper ticket</span>
                {!isMobile && showDescription && (
                    <Translation
                        translationKey="search_results.ticket_has_to_be_printed"
                    />
                )}
            </Column>
        </Row>
    )
}

export default TravelingDotComTicketTypeSection