import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import setOrientation from "@Features/icons/functions/setOrientation";
import {RotationOrientationType} from "@Features/icons/types/RotationOrientationType";

type IconFilterProps = IconType & {
    orientation?: RotationOrientationType
}

function IconFilter(props: IconFilterProps) {
    const {
        width = '17',
        height = '14',
        fill = '#4a4a4a',
        viewBox = '0 0 17 14',
        title = 'IconFilter',
        orientation,
        ...rest
    } = props

    const style = setOrientation(orientation);

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            style={style}
            svgIconPath={(
                <path
                    d="m11.2099697 7c-.4997762.00039537-.9614402.26718089-1.21132826.7h-9.2886717c-.25244514-.0035702-.48725695.12906271-.6145214.34711099-.12726445.21804829-.12726445.48772973 0 .70577802.12726445.21804828.36207626.35068119.6145214.34711099h9.29003026c.2496519.43239687.7106821.69911711 1.2099697.7.7731987 0 1.4-.62680135 1.4-1.4s-.6268013-1.4-1.4-1.4zm2.7015625.7c.0581.2247.0984375.4571.0984375.7s-.0403375.4753-.0984375.7h2.1984375c.3865994 0 .7-.31340068.7-.7s-.3134006-.7-.7-.7zm-9.70156246 4.9c-.49928749.000883-.96031756.2676032-1.20996974.7h-2.29003026c-.25244514-.0035702-.48725695.1290627-.6145214.347111s-.12726445.4877297 0 .705778.36207626.3506812.6145214.347111h2.28867184c.249888.4328191.71155202.6996046 1.21132816.7.77319865 0 1.4-.6268014 1.4-1.4s-.62680135-1.4-1.4-1.4zm2.7015625.7c.0581.2247.0984375.4571.0984375.7s-.0403375.4753-.0984375.7h9.19843746c.3865994 0 .7-.3134007.7-.7s-.3134006-.7-.7-.7zm1.4984375 4.9c-.49977619.0003954-.96144028.2671809-1.2113283.7h-6.4886717c-.25244514-.0035702-.48725695.1290627-.6145214.347111s-.12726445.4877297 0 .705778.36207626.3506812.6145214.347111h6.4900388c.24964341.4323969.71067361.6991171 1.2099612.7.77319865 0 1.4-.6268014 1.4-1.4s-.62680135-1.4-1.4-1.4zm2.70156246.7c.0581.2247.0984375.4571.0984375.7s-.0403375.4753-.0984375.7h4.9984375c.3865994 0 .7-.3134007.7-.7s-.3134006-.7-.7-.7z"
                    fill={fill}
                    transform="translate(-.014242 -7)"
                />
            )}
        />
    );
}

export default IconFilter;
