import React, {ReactNode} from 'react';
import IconTimes from "@Icons/controls/IconTimes";

interface ModalHeaderProps {
    /** What buttons does on close click */
    closeAction?: (any?) => any,
    /** Content of modal header */
    title?: ReactNode | string,
    /** Title icon - optional */
    titleIcon?: ReactNode | string,
    closeIcon?: ReactNode | string,
    noticeType?: string,
}

/**
 * @deprecated use Dialog instead
 * @see main/symfony/src/FrontBundle/React/src/features/dialog/components/Dialog.tsx
 * @param props
 * @constructor
 */
function ModalHeader(props: ModalHeaderProps) {
    const {
        closeAction,
        title,
        titleIcon,
        closeIcon,
        noticeType = 'default',
    } = props;

    return (
        <div className="modal-header gb-modal-header">
            <h5 className="modal-title gb-modal-title">
                {!!titleIcon && (
                    <span className={`user--icon user--icon-${noticeType}`}>
                        {titleIcon}
                    </span>
                )}
                {typeof title === "string" ? ` ${title}` : title}
            </h5>
            {!!closeAction && (
                <button
                    type="submit"
                    className="gb-modal-title--action"
                    onClick={closeAction}
                    style={{margin: 'unset'}}
                >
                    {closeIcon ? (
                        <svg width="20" height="20" fill="#9b9b9b">
                            <use href={`${closeIcon}`} xlinkHref={`${closeIcon}`}/>
                        </svg>
                    ) : <IconTimes/>}
                </button>
            )}
        </div>
    )
}

export default ModalHeader;
