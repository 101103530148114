import React from 'react';
import getRouteTransferType from "@Route/features/transportType/functions/getRouteTransferType";
import {RouteTransferTypeKeys} from "@Route/features/transportType/types/routeTransferType.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import IconBus from "@Icons/transportType/IconBus";
import IconFerry from "@Icons/transportType/IconFerry";
import IconTrain from "@Icons/transportType/IconTrain";
import IconPlane from "@Icons/transportType/IconPlane";
import IconTransfer from "@Icons/transportType/IconTransfer";
import useCompanyStoreStyleForComponent from "@Features/companyStore/hooks/useCompanyStoreStyleForComponent";

type VehicleTypeIconProps = {
    className?: string,
    fill?: string,
    width?: string,
    height?: string,
    transportType?: RouteTransferTypeKeys,
}

const VehicleTypeIcon = ({className, fill, width, height, transportType}: VehicleTypeIconProps) => {
    const transportTypeKeyword = useAppSelector((state) => state?.searchResults.transportTypeKeyword)
    const trvlComponents = {
        bus: IconBus,
        ferry: IconFerry,
        train: IconTrain,
        plane: IconPlane,
        // TODO remove IconTransfers from privateMinivan and privateBus, create new icons (not me personally)
        minivan: IconTransfer,
        privateMinivan: IconTransfer,
        privateBus: IconTransfer,
    }
    const iconToShow = transportType ? getRouteTransferType(transportType) : transportTypeKeyword
    const IconVehicleFull = trvlComponents[iconToShow || 'bus'];
    const strokeColor = useCompanyStoreStyleForComponent('headerBackgroundColor', undefined)

    return (
        <IconVehicleFull
            width={width ?? 14}
            height={height ?? 14}
            fill={fill ?? "#fff"}
            className={className ?? "connect--vehicle_icon"}
            stroke={strokeColor}
        />
    )
}

export default VehicleTypeIcon;
