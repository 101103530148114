import FilterButton from "@Generic/buttons/FilterButton";
import IconStarOutline from "@Icons/IconStarOutline";
import {CompanyScoreType} from "@SearchResults/features/sidebar/features/companyFilters/types/CompanyScore.type";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useFilterByCompanyScore
    from "@SearchResults/features/sidebar/features/companyFilters/hooks/useFilterByCompanyScore";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";

type CompanyScoreProps = {
    companyScore: CompanyScoreType
}

function CompanyScoreFilterButton({companyScore}: CompanyScoreProps) {
    const {isActive, onPress} = useFilterByCompanyScore()
    const active = isActive(companyScore)
    const suffix = `${companyScore.from} - ${companyScore.to}`
    const label = useTranslation(`search_results.filter_by.company_score.scores.${companyScore.label}`)

    const handleOnBlur = useDispatchFilterEvents('CompanyScoreFilter', suffix)

    return (
        <FilterButton
            label={label}
            active={active}
            onClick={() => onPress(companyScore)}
            icon={<IconStarOutline width="15" height="15"/>}
            suffix={suffix}
            onBlur={handleOnBlur}
        />
    );
}

export default CompanyScoreFilterButton;