import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import rootExtractor from "../utils/dataset/rootExtractor";
import intToBool from "@Number/intToBool";
import getMainCompanyTransportType from "@Tabs/functions/getCompanyMainTransportType";
import PageSliceStateType from "@/types/pageSlice/PageSliceState.type";
import parseExperiments from "@Features/experiments/functions/parseExperiments";
import {mobileScreenWidth, smallTabletScreenWidth, tabletScreenWidth} from "@Reducers/constants/screenWidths";

const searchParams = new URLSearchParams(window.location.search);
const noHeader = !!searchParams.get('no_header') || false
const translations = JSON.parse(rootExtractor('translations'));
const companyStore = JSON.parse(rootExtractor('companyStore'));

const initialState: PageSliceStateType = {
    locale: window.currentLocale || rootExtractor('locale'),
    activeLanguages: JSON.parse(window.activeLanguages),
    siteUrl: rootExtractor('siteUrl') || window.location.host,
    gbbUrl: rootExtractor('gbbUrl') ?? 'https://getbybus.com',
    gbtUrl: rootExtractor('gbtUrl') ?? 'https://getbytransfer.com',
    noHeader,
    isMobile: window.innerWidth <= mobileScreenWidth,
    isTablet: window.innerWidth >= mobileScreenWidth && window.innerWidth <= tabletScreenWidth,
    isSmallTablet: window.innerWidth >= mobileScreenWidth && window.innerWidth <= smallTabletScreenWidth,
    isCompanyStore: intToBool(rootExtractor('isCompanyStore')),
    companyStore,
    companyMainTransportType: getMainCompanyTransportType(companyStore),
    translations,
    adminAssetsBaseUrl: rootExtractor('adminAssetsBase'),
    assetsBaseUrl: rootExtractor('assetsBase'),
    activeBlogLanguages: rootExtractor('activeBlogLanguages', true),
    langSpritePositions: rootExtractor('langSpritePositions', true),
    urlsObject: rootExtractor('urlsObject', true),
    urlsForHeaderMenu: rootExtractor('urlsForHeaderMenu', true),
    footerUrls: rootExtractor('footerUrls', true),
    myProfileRoutes: rootExtractor('myProfileRoutes', true),
    sidebar: rootExtractor('sidebar'),
    activePopup: false,
    activeStickyContainerHeight: 0,
    homepageData: rootExtractor('homepageData', true),
    eventToken: rootExtractor('tokenBawBi'),
    bawApiUrl: rootExtractor('bawApiUrl'),
    alwaysDispatchTrackingEvents: JSON.parse(rootExtractor('alwaysDispatchTrackingEvents')),
    experimentsArray: parseExperiments(rootExtractor('abExperiments')),
    experiments: rootExtractor('abExperiments'),
    dualPriceHrkEurConversionRate: rootExtractor('dualPriceHrkEurConversionRate'),
    isDualPriceRequirementActive: JSON.parse(rootExtractor('isDualPriceRequirementActive')),
    supportedCurrencies: Object.values(rootExtractor('supportedCurrencies', true)),
    sameTabRedirectProviders: Object.values(rootExtractor('sameTabRedirectProviders', true)),
    rentABusRoutePaths: JSON.parse(rootExtractor('rentABusRoutes')),
    experimentsJson: JSON.parse(rootExtractor('experimentsJson')),
    enabledClientsideFeatures: JSON.parse(rootExtractor('enabledClientsideFeatures')),
    is404Page: false,
    headerBackgroundImageRgbPixelData: [],
}

const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setLocale: (state, action) => (
            {...state, locale: action.payload}
        ),

        setPageLayout: (state, action) => ({
            ...state,
            ...action.payload,
        }),

        activatePopup: (state, action) => (
            {...state, activePopup: action.payload}
        ),

        setActiveStickyContainerHeight: (state, action) => ({
            ...state,
            activeStickyContainerHeight: action.payload,
        }),

        setIs404Page: (state, action: PayloadAction<boolean>) => ({
            ...state,
            is404Page: action.payload,
        }),

        setHeaderBackgroundImageRgbPixelData: (state, action: PayloadAction<Uint8ClampedArray[]>) => ({
            ...state,
            headerBackgroundImageRgbPixelData: action.payload,
        }),

        clear: () => initialState,
    },
})

export const {
    setLocale,
    setPageLayout,
    activatePopup,
    setActiveStickyContainerHeight,
    setIs404Page,
    setHeaderBackgroundImageRgbPixelData,
} = pageSlice.actions

export default pageSlice.reducer