/**
 * Used by accommodation banner to get accommodation map image dimensions
 */
export default function getImageWidthAndHeight() {
    let width = "913";
    let height = "245";

    if (window.innerWidth < 360) {
        width = "320";
        height = "140";
    } else if (window.innerWidth < 400) {
        width = "360";
        height = "140";
    } else if (window.innerWidth < 500) {
        width = "414";
        height = "140";
    } else if (window.innerWidth < 600) {
        width = "600";
        height = "200";
    }

    return {
        width,
        height,
    }
}