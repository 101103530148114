import Grid from "@Containers/components/Grid";
import TransportTypeCard from "@Pages/homepage/features/transportTypes/components/TransportTypeCard";
import IconBus from "@Icons/transportType/IconBus";
import IconFerry from "@Icons/transportType/IconFerry";
import IconTrain from "@Icons/transportType/IconTrain";
import IconPlane from "@Icons/transportType/IconPlane";

type TransportTypeCardsProps = {}

function TransportTypeCards(props: TransportTypeCardsProps) {
    return (
        <Grid min={'20em'} className={'gb--transport-type-cards'}>
            <TransportTypeCard
                transportType={"bus"}
                icon={<IconBus height={72} width={72}/>}
            />
            <TransportTypeCard
                transportType={"ferry"}
                icon={<IconFerry height={72} width={72}/>}
            />
            <TransportTypeCard
                transportType={"train"}
                icon={<IconTrain height={72} width={72}/>}
            />
            <TransportTypeCard
                transportType={"plane"}
                icon={<IconPlane height={72} width={72}/>}
            />
        </Grid>
    );
}

export default TransportTypeCards;