import React from 'react';

interface ReviewScoreLabelWithStarProps {
    score: string | number,
}

/**
 * Colored rating star used for reviews/ratings.
 * Red for bad score, green for the good one.
 */
const ColoredRatingStar = ({score}: ReviewScoreLabelWithStarProps) => {
    return (
        <div className="gb--sidebar-latest-review-score">
            {score}
        </div>
    )
};

export default ColoredRatingStar;