import React from 'react';
import Card from "react-bootstrap/Card";
import TicketType from "@MyTicket/types/Ticket.type";
import TicketActionsSection from "@MyTicket/features/ticketDetails/components/TicketActionsSection";
import TicketDataSection from "@MyTicket/features/ticketDetails/components/TicketDataSection";

interface TicketDetailsProps {
    ticket: TicketType,
    isArchived?: boolean,
}

const TicketDetails = ({ticket, isArchived}: TicketDetailsProps) => {
    const {
        baseRoutes,
        payment,
        status,
        singleRoute,
    } = ticket

    const {
        isReturn,
        isIntermodal,
        isCancelled,
        isApiPendingReservation,
        isApiReservationFailedToApprove,
    } = status

    return (
        <Card.Body className="card-expanded ticket-details gb--route-details">
            <hr/>
            {(!isApiPendingReservation && !isApiReservationFailedToApprove) && (
                <TicketActionsSection
                    ticket={ticket}
                    status={status}
                />
            )}
            <TicketDataSection
                ticket={ticket}
                isArchived={isArchived}
            />
        </Card.Body>
    )
}

export default TicketDetails;