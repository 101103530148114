import React from 'react';
import CountriesSection from "@Pages/homepage/features/countries/components/CountriesSection";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import CatchphraseIntroSection from "@Pages/homepage/features/catchphraseIntro/components/CatchphraseIntroSection";
import FormTravelingDotCom from "@Form/components/FormTravelingDotCom";
import TravelConnectionsTravelingDotCom
    from "@Pages/homepage/features/travelConnections/components/TravelConnectionsTravelingDotCom";
import MobileAppPromoSection from "@Pages/homepage/features/MobileAppPromoSection/components/MobileAppPromoSection";
import TransportTypesSection from "@Pages/homepage/features/transportTypes/components/TransportTypesSection";
import FeatureCardsSection from "@Pages/homepage/features/featureCards/components/FeatureCardsSection";
import PopularLinesSection from "@Pages/homepage/components/PopularLinesSection";
import CompanyStoreHomepage from "@Pages/homepage/components/CompanyStoreHomepage";
import Form from "@Form/components/Form";

function Homepage() {
    const isCompanyStore = useIsCompanyStore()

    if (!isCompanyStore) {
        return (
            <div className="homepage">
                <FormTravelingDotCom inverseColors/>
                <div className="gb--main-content">
                    <CatchphraseIntroSection/>
                    <TransportTypesSection/>
                    <MobileAppPromoSection/>
                    <FeatureCardsSection/>
                    <CountriesSection/>
                    <TravelConnectionsTravelingDotCom/>
                    <PopularLinesSection/>
                </div>
            </div>
        );
    }

    return (
        <div className="homepage">
            <Form/>
            <div className="gb--base-container">
                <CompanyStoreHomepage/>
            </div>
        </div>
    )
}

export default Homepage;