import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconLatestTickets(props: IconType) {
    const {
        width = '16',
        height = '16',
        stroke = Colors.primaryColor,
        viewBox = '0 0 16 16',
        title = 'IconLatestTickets',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinejoin="round"
                   transform="translate(3.5 1.5)">
                    <path d="m0 4.59961h8.99675"/>
                    <path
                        d="m.093735 10.71351c2.324365 2.4096 5.004315-.19305 4.306015-1.51435-.69825-1.3213-2.7252-.2529-1.778 1.53765.6401 1.20815 1.69075 2.10345 2.7608 2.18 1.1929.08319 3.61745.08319 3.61745.08319v-12.98004h-2.01725v-.01996c0 1.421135-1.11855 2.576025-2.50215 2.576025s-2.5054-1.15489-2.5054-2.576025v.01996h-1.9752v9.44545"
                    />
                </g>

            )}
        />

    );
}

export default IconLatestTickets;
