export default function handleImageSwipe(slideRight: () => void, slideLeft: () => void) {
    const handleSwipe = (deltaX: number, _deltaY: number) => {
        if (deltaX > 200) {
            slideRight()
        }
        if (deltaX < 200) {
            slideLeft()
        }
    }

    return {
        handleSwipe,
    }
}