import useExperiments from "@Hooks/selector/useExperiments";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useGetCheapestGbtVehicle
    from "@SearchResults/features/banners/features/getByTransferBanner/hooks/useGetCheapestGbtVehicle";
import {useClickBannerEvent} from "@Events/hooks/useClickBannerEvent";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useCreateGbtDefaultVehicle
    from "@SearchResults/features/banners/features/getByTransferBanner/hooks/useCreateGbtDefaultVehicle";
import Link from "@Generic/navigation/Link";
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import LogoGetByTransfer from "@Generic/logotypes/LogoGetByTransfer";
import {
    servicesTranslationKeys
} from "@SearchResults/features/banners/features/getByTransferBanner/constants/servicesTranslationKeys";
import Translation from "@Translation/components/Translation";
import React from "react";
import {TransfersDataType} from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersData.type";
import IconUserOutline from "@Icons/user/IconUserOutline";
import IconArrowAirplane from "@Icons/arrows/IconArrowAirplane";
import {Colors} from "@Styles/Colors.style";
import IconCheckmarkEncircled from "@Icons/validation/IconCheckmarkEncircled";

interface GetByTransferBannerProps {
    transfersData: TransfersDataType,
}

function TrvlGetByTransferBanner({transfersData}: GetByTransferBannerProps) {
    const {
        fromName,
        toName,
        vehicles,
        distanceInTime,
    } = transfersData

    const {gbtBannerTopOfSearchResult} = useExperiments();

    const {
        travel_faster_and_with_style: ctaLabel,
        up_to: upToLabel,
    } = useTranslation("transfers_banner")

    const vehicle = useGetCheapestGbtVehicle(vehicles)

    const bannerClicked = useClickBannerEvent({
        bannerType: 'banner',
        bannerName: "transfer",
        bannerLink: vehicle?.link,
        bannerVehicleType: null,
        bannerPrice: vehicle?.price,
    })

    const {
        departureStationObject,
        destinationStationObject,
    } = useAppSelector((state) => state.form)

    const departureValue = departureStationObject.label
    const destinationValue = destinationStationObject.label
    const gbtDefaultVehicle = useCreateGbtDefaultVehicle()

    // this is just for typescript not to cry, the show/don't show logic is in useShowTransfersBanner
    if (!vehicle && !gbtBannerTopOfSearchResult) {
        return null
    }

    const {
        maxNumberOfPassengers,
        priceWithCurrency,
        link,
        imageUrl,
    } = vehicle ?? gbtDefaultVehicle

    return (
        <Link
            href={link}
            className="banner__component gbt-banner"
            onClick={() => {
                bannerClicked()
            }}
        >
            <Column className="gbt-banner__vehicle-section" justify>
                <img
                    alt="Transfer vehicle"
                    src={imageUrl}
                />
                <LogoGetByTransfer/>
            </Column>
            <div className="gbt-banner__travel-details-section">
                <p className="travel-details__title">
                    {ctaLabel}
                </p>
                <Row className="travel-details__trip">
                    <span>
                        {fromName !== '' ? fromName : departureValue}
                    </span>
                    <span>
                        {distanceInTime ? `~ ${distanceInTime} ~` : ` ~ `}
                    </span>
                    <span>
                        {toName !== '' ? toName : destinationValue}
                    </span>
                </Row>
                <Row className="travel-details__amenities" gap>
                    {servicesTranslationKeys.map((translationKey) => (
                        <Row className="gbt-amenities--service" center key={translationKey}>
                            <IconCheckmarkEncircled fill={Colors.neutralDarkestColor}/>
                            <Translation translationKey={translationKey}/>
                        </Row>
                    ))}
                </Row>
            </div>
            <div className="gbt-banner__price-section">
                <Row className="price-section__info" gap center>
                    {`${upToLabel} ${maxNumberOfPassengers}`}
                    <IconUserOutline/>
                </Row>
                <Row className="price-section__price" gap center>
                    {`${priceWithCurrency}`}
                    <div className="price-section__arrow trvl--circle">
                        <IconArrowAirplane
                            fill={Colors.neutralDarkestColor}
                        />
                    </div>
                </Row>
            </div>
        </Link>
    );
}

export default TrvlGetByTransferBanner