import React, {MutableRefObject} from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import MobileContentContainer from "./MobileContentContainer";
import RouteAmenitiesListDetails from "../../amenities/components/RouteAmenitiesListDetails";
import useSearchResults from "../../../../../hooks/useSearchResults";

interface MobileRouteAmenitiesProps {
    amenitiesHashMap: number[],
    scrollRef: MutableRefObject<any>
}

function MobileRouteAmenities({amenitiesHashMap, scrollRef}: MobileRouteAmenitiesProps) {
    const {data: {result: {routeIcons}}} = useSearchResults()
    const amenitiesLabel = useTranslation("search_results.amenities").toLowerCase()
    const routeLabel = useTranslation("sys.route")
    const title = `${routeLabel} ${amenitiesLabel}`

    return (
        <MobileContentContainer title={title} scrollRef={scrollRef}>
            <RouteAmenitiesListDetails
                routeIcons={routeIcons}
                amenities={amenitiesHashMap}
            />
        </MobileContentContainer>
    );
}

export default MobileRouteAmenities;