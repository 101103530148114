import React from "react";
import useMyVouchersLink from "@MyProfile/features/links/hooks/useMyVouchersLink";
import useMyTicketsLink from "@MyProfile/features/links/hooks/useMyTicketsLink";
import MyProfileLink from "@MyProfile/features/links/components/MyProfileLink";
import IconTicketQr from "@Icons/ticket/IconTicketQr";
import IconVoucherQr from "@Icons/voucher/IconVoucherQr";

function MyProfileLinks() {
    const vouchers = useMyVouchersLink()
    const tickets = useMyTicketsLink()
    const routes = [
        {
            ...tickets,
            icon: <IconTicketQr width="24" height="24"/>,
        },
        {
            ...vouchers,
            icon: <IconVoucherQr width="24" height="24"/>,
        },
    ]

    return (
        <div className="gb--tabs gb--row my-profile-nav-links">
            <ul className="nav nav-tabs gb--tabs-nav">
                {routes.map((route) => (
                    <MyProfileLink
                        key={route.url}
                        url={route.url}
                        isActive={route.isActive()}
                        icon={route.icon}
                        label={route.label}
                        description={route.descriptionText}
                    />
                ))}
            </ul>
        </div>
    );
}

export default MyProfileLinks;
