import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconCheapest(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={stroke} fillRule="evenodd" transform="translate(.4853 3.0636)">
                    <circle cx="12.089969" cy="4.936404" r="1"/>
                    <circle cx="3.101098" cy="4.936404" r="1"/>
                    <g fillRule="nonzero">
                        <path
                            d="m7.5146743 2.30043285c1.26806841 0 2.29604098 1.18016448 2.29604098 2.63597114s-1.02797257 2.63597114-2.29604098 2.63597114c-1.26806842 0-2.29604099-1.18016448-2.29604099-2.63597114s1.02797257-2.63597114 2.29604099-2.63597114zm0 1.2c-.56566281 0-1.09604099.60890097-1.09604099 1.43597114 0 .82707018.53037818 1.43597114 1.09604099 1.43597114.5656628 0 1.09604098-.60890096 1.09604098-1.43597114 0-.82707017-.53037818-1.43597114-1.09604098-1.43597114z"
                        />
                        <path
                            d="m.6 9.87280798c-.33137085 0-.6-.26862915-.6-.6v-8.67280798c0-.33137085.26862915-.6.6-.6h2.36962484c.33137085 0 .6.26862915.6.6s-.26862915.6-.6.6h-1.76962484v7.472h12.629v-7.472h-8.34268289c-.29823377 0-.54564676-.21758961-.59214702-.50267688l-.00785298-.09732312c0-.33137085.26862915-.6.6-.6h8.94303149c.3313708 0 .6.26862915.6.6v8.67280798c0 .33137085-.2686292.6-.6.6z"
                        />
                    </g>
                </g>
            )}
        />
    )
}

export default IconCheapest