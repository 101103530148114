import React, {MutableRefObject} from 'react';
import useTimeout from "@Hooks/effects/useTimeout";
import PopupType from "@Popups/types/Popup.type";
import Dialog from "@Features/dialog/components/Dialog";
import Button from "@Generic/buttons/Button";

/**
 * Generic popup based on modal, hides after 1.5 seconds
 * Do not pass any props directly to this component, accepts props from redux activePopup
 */
function Popup({activePopup, dialogRef}: {
    activePopup: PopupType,
    dialogRef: MutableRefObject<HTMLDialogElement>,
}) {
    const {
        message,
        title,
        buttonText = "OK",
        timeout = 4000,
        handle,
    } = activePopup as PopupType || {}

    const handleCloseAction = () => {
        if (handle) {
            handle()
        }
        dialogRef.current.close()
    }

    useTimeout(handleCloseAction, timeout, activePopup)

    return (
        <Dialog dialogRef={dialogRef} className="gb--popup">
            <h3>{title}</h3>
            <p>{message}</p>
            <Button onClick={handleCloseAction} className="blue">
                {buttonText}
            </Button>
        </Dialog>
    );
}

export default Popup;