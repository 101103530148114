import React from "react";
import useActivateMobileFormOverlay from "@Form/hooks/useActivateMobileFormOverlay";
import Translation from "@Translation/components/Translation";
import IconPenWithABoard from "@Icons/edit/IconPenWithABoard";
import {Palette} from "@Styles/Palette.style";

function EditSearchButton() {
    const {toggleFormAction} = useActivateMobileFormOverlay()

    return (
        <button
            onClick={toggleFormAction}
            type="button"
            className="gb--emphasize gb--emphasize-default gb--app-title__edit-search"
        >
            <Translation translationKey="sys.edit"/>
            <IconPenWithABoard
                width="20"
                height="20"
                stroke={Palette.white}
            />
        </button>
    );
}

export default EditSearchButton;