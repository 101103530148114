import React from 'react';
import Row from "@Containers/components/Row";
import IconFilterWithCount from "@Icons/IconFilterWithCount";
import Translation from "@Translation/components/Translation";
import Filters from "@SearchResults/features/sidebar/components/Filters";
import {SearchResultsRtkQueryResponseType} from "@SearchResults/api/types/SearchResultsRtkQueryResponse.type";

type FiltersContainerProps = {
    searchData: SearchResultsRtkQueryResponseType
}

function FiltersContainer({searchData}: FiltersContainerProps) {
    return (
        <div className="gb--sidebar-section gb--sidebar-section-filters">
            <Row center className="gb--sidebar-section-title">
                <Row center className="gb--sidebar-section-title_wrapper">
                    <div className="icon-container">
                        <IconFilterWithCount
                            width="25"
                            height="25"
                        />
                    </div>
                    <h3>
                        <Translation translationKey="search_results.filter_by.label"/>
                    </h3>
                </Row>
            </Row>
            <div className="gb--sidebar-section-content">
                <Filters
                    searchData={searchData}
                />
            </div>
        </div>
    );
}

export default FiltersContainer;