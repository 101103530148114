import SeatClassType from "@Route/types/travelInfo/SeatClass.type";
import IconMTicket from "@Icons/ticket/IconMTicket";
import {Colors} from "@Styles/Colors.style";
import IconMinivanOutline from "@Icons/IconMinivanOuline";
import Translation from "@Translation/components/Translation";
import React from "react";
import IconMoonOutline from "@Icons/IconMoonOutline";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TotalAllotmentLeft from "@Route/components/Price/TotalAllotmentLeft";
import IconConnected from "@Icons/ticket/IconConnected";
import {isFerryRoute} from "@Route/features/transportType/functions/isFerryRoute";
import FerryTypeBadge from "@Route/components/Ferry/FerryTypeBadge";
import RouteTypeType from "@Route/types/type/RouteType.type";

interface RouteStatusProps {
    hasMTicket?: boolean,
    isMinivan?: boolean,
    isPrivateBus?: boolean,
    overnightTravel?: boolean,
    isPrivate?: boolean,
    showOvernightTravel?: boolean
    seatClass?: SeatClassType,
    totalAllotmentLeft: number,
    type?: RouteTypeType
}

/**
 * This is a route status label container.
 * It includes all the labels/badges in top left corner of the card (or at least it is what i hope this will do one day)
 *
 * TODO remove all these spans and reuse-redefine PrivateBusLabel.tsx & its co.
 */
function RouteStatus(props: RouteStatusProps) {
    const {
        hasMTicket,
        isMinivan,
        isPrivateBus,
        overnightTravel,
        isPrivate,
        showOvernightTravel,
        seatClass,
        totalAllotmentLeft,
        type,
    } = props

    const translationKey = isPrivate ? 'private_minivan' : 'shared_minivan'
    const isMobile = useIsMobile()

    return (
        <div className="route-status-container">
            {hasMTicket && isMobile && (
                <span className="gb--badge-green">
                    <IconMTicket fill={Colors.neutralDarkestColor}/>
                    M Ticket
                </span>
            )}
            {isMinivan && (
                <span className="gb--badge-blue">
                    <IconMinivanOutline/>
                    <Translation translationKey={`search_results.transport_labels.minivan.${translationKey}`}/>
                </span>
            )}
            {isPrivateBus && (
                <span className="gb--badge-blue">
                    <IconMinivanOutline/>
                    <span>Private bus</span>
                </span>
            )}
            {isFerryRoute(type.vehicleType, type.routeTransferType) && (
                <FerryTypeBadge
                    ferryVehicle={type.vehicleType}
                />
            )}
            {seatClass?.isCombinedTransportTypeRoute && (
                <span className="gb--badge-blue">
                    <IconConnected
                        fill={Colors.neutralDarkestColor}
                    />
                    <span>
                        {seatClass.className}
                    </span>
                </span>
            )}
            {showOvernightTravel && overnightTravel && (
                <span className="gb--badge-blue">
                    <IconMoonOutline/>
                    <Translation translationKey="search_results.after_midnight_arrival"/>
                </span>
            )}
            <TotalAllotmentLeft
                totalAllotmentLeft={totalAllotmentLeft}
            />
        </div>
    )
}

export default RouteStatus