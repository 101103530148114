import React from "react";
import Button from "@Generic/buttons/Button";

interface MoreInfoButtonProps {
    buttonTitle: string
}

function MoreInfoButton({buttonTitle}: MoreInfoButtonProps) {
    return (
        <Button
            onClick={() => {
            }}
            className="blue"
            additionalClasses="more-info-btn btn--compact"
        >
            {buttonTitle}
        </Button>
    )
}

export default MoreInfoButton