import React, {ReactNode} from "react"
import Column from "@Containers/components/Column"
import TotalPassengers from "@Route/components/Price/TotalPassengers"
import PriceWithCurrency from "@Route/components/Price/PriceWithCurrency"
import {useTranslation} from "@Translation/hooks/useTranslation"
import TripleDotLoader from "@Generic/loaders/TripleDotLoader"

interface RouteTotalPriceProps {
    children?: ReactNode,
    className?: string,
    price: number,
    displayLabel?: boolean,
    passengersClassName?: string,
    displayPassengers?: boolean,
    displayPerPerson?: boolean,
    align?: boolean,
    loading?: boolean,
    displayFromNotice?: boolean,
}

const RouteTotalPrice = (props: RouteTotalPriceProps) => {
    const {
        price,
        className = "gb--emphasize-default route--price-total",
        displayLabel = false,
        displayPassengers = true,
        passengersClassName = "gb--label gb--label-default route--price-pax",
        align = false,
        displayPerPerson = false,
        children,
        loading,
        displayFromNotice,
    } = props

    const perPersonLabel = useTranslation("tour.per_person")

    return (
        <Column alignEnd={align} className={className}>
            {!loading ? (
                <PriceWithCurrency
                    price={price}
                    className="total-price"
                    displayFromNotice={displayFromNotice}
                >
                    {children}
                </PriceWithCurrency>
            ) : (
                <span className={'total-price gb--column'} style={{height: '18px'}}>
                    <TripleDotLoader/>
                </span>
            )}
            {displayPassengers && (
                <TotalPassengers
                    className={passengersClassName}
                    displayLabel={displayLabel}
                />
            )}
            {displayPerPerson && (
                <small>{perPersonLabel}</small>
            )}
        </Column>
    );
}

export default RouteTotalPrice;