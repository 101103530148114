import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";
import IconEmail from "@Icons/chat/IconEmail";

/**
 * Icon SMS/envelope, representing sms reminder ancillary
 */
function IconSmsReminderAncillary(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <IconEmail
            {...{width, height, viewBox, fill, ...rest}}
        />
    )
}

export default IconSmsReminderAncillary