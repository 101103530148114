import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";
import PriceFractionDisplay from "@CheckOrder/features/paymentSummary/components/PriceFractionDisplay";

type AncillarySummaryProps = {
    price: number
    currencySymbol: string,
    ancillaryNameKey: string,
}
const AncillarySummary = ({price, currencySymbol, ancillaryNameKey}: AncillarySummaryProps) => (
    <PriceFractionDisplay
        fractionTitle={<Translation translationKey={ancillaryNameKey}/>}
        price={`${price} ${currencySymbol}`}
    />
)
export default AncillarySummary;