import {useTranslation} from "@Translation/hooks/useTranslation";
import {useEffect, useState} from "react";
import datasetParser from "@Dataset/datasetParser";

interface CountryType {
    value: number | null;
    label: string;
}

export const useCompanySelect = () => {
    const parsedData = datasetParser({id: 'review-company'});
    const locationPlaceholder = useTranslation("place_review.selects.location_placeholder") as string;
    const [showDropdown, setShowDropdown] = useState(false);
    const [suggestionResponse, setSuggestionResponse] = useState<CountryType[]>([])
    const [selectedSuggestion, setSelectedSuggestion] = useState<CountryType>()
    const [fieldValue, setFieldValue] = useState<string>('')
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(event.target.value);
        if (event.target.value.length > 2) {
            const filteredSuggestions = parsedData?.companies.filter((suggestion) => {
                if (suggestion.label.toLowerCase().includes(event.target.value.toLowerCase())) {
                    return suggestion;
                }
            });
            setSuggestionResponse(filteredSuggestions);
        }
    };

    const handleSelect = (country: CountryType) => {
        setShowDropdown(false)
        setSelectedSuggestion(country)
        setFieldValue(country.label)
    }

    useEffect(() => {
        if (parsedData?.companyName) {
            setFieldValue(parsedData?.companyName)
        }
    }, []);

    return {
        locationPlaceholder,
        showDropdown,
        setShowDropdown,
        suggestionResponse,
        setSuggestionResponse,
        selectedSuggestion,
        setSelectedSuggestion,
        fieldValue,
        setFieldValue,
        handleChange,
        handleSelect,
        label: parsedData?.companyFieldLabel,
        placeholder: parsedData?.companyFieldPlaceholder,
    }
}