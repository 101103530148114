import CardWrapper from "@Generic/cards/CardWrapper";
import {ReactNode} from "react";
import Column from "@Containers/components/Column";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import displayNumberInHumanReadableFormat from "@Number/displayNumberInHumanReadableFormat";

type TransportTypeCardProps = {
    icon: ReactNode,
    transportType: "bus" | "train" | "plane" | "ferry",
}

function TransportTypeCard(props: TransportTypeCardProps) {

    const {routeCountPerTransportType} = useAppSelector((state) => state?.page?.homepageData)
    console.log("=>(TransportTypeCard.tsx:16) routeCountPerTransportType", routeCountPerTransportType);

    const routeCount = displayNumberInHumanReadableFormat(routeCountPerTransportType[props.transportType])

    const titleLabel = useTranslation(`home_page.travel_type_cards.${props.transportType}.title`);
    const catchphraseLabel = useTranslation(`home_page.travel_type_cards.${props.transportType}.catchphrase`);
    const descriptionLabel = useTranslation(`home_page.travel_type_cards.${props.transportType}.description`, {routeCount});

    return (
        <CardWrapper type={"static"} cardProps={{className: "gb--row"}}>
            <Column>
                {props.icon}
            </Column>
            <Column>
                <h4 className="gb--uppercase">{titleLabel}</h4>
                <h3 className="gb--emphasize">{catchphraseLabel}</h3>
                <p>{descriptionLabel}</p>
            </Column>
        </CardWrapper>
    );
}

export default TransportTypeCard;