import {useEffect, useState} from "react";
import {setDepartureStationObject, setDestinationStationObject, toggleStations} from "@Form/reducers/formSlice";
import {activateDepartureStationInput, activateDestinationStationInput} from "@Reducers/mobileSlice";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

export default function useFormMethods() {
    const {
        departureStationObject,
        destinationStationObject,
    } = useAppSelector((state) => state?.form)

    const {is404Page} = useAppSelector((state) => (state.page))

    const isHomepage = useRouteMatch('homepage')
    const {mobileOverlayActive} = useAppSelector((state) => state?.mobile)
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const isCompanyStore = useIsCompanyStore()
    const [mobileStationInputSelected, setMobileStationInputSelected] = useState(null)
    const [departureValue, setDepartureValue] = useState(departureStationObject?.label ?? '')
    const [destinationValue, setDestinationValue] = useState(destinationStationObject?.label ?? '')
    const [departureTopStations, setDepartureTopStations] = useState([])
    const [destinationTopStations, setDestinationTopStations] = useState([])
    const formDepartureStation = useTranslation("form.departure.placeholder")
    const formDestinationStation = useTranslation("form.destination.placeholder")

    useEffect(() => {
        setDepartureValue(departureStationObject?.label ?? '')
        setDestinationValue(destinationStationObject?.label ?? '')
    }, [departureStationObject, destinationStationObject])

    const handleDepartureClear = () => {
        dispatch(setDepartureStationObject(null))
    };

    const handleDestinationClear = () => {
        dispatch(setDestinationStationObject(null))
    };

    const setClassName = () => {
        let className = 'main-hero';

        if (!isHomepage || is404Page) {
            className += '--small';
        }

        if (!isCompanyStore) {
            className += ' main-hero--vector';
        }

        return className;
    };

    /**
     *
     * @param response {object}
     * @param station {string}
     * @param direction {number}
     */
    const handleTopStations = (response, station, direction) => {
        if (direction === 1) {
            setDestinationTopStations(response.data.stations[0])
        } else {
            setDepartureTopStations(response.data.stations[0])
        }
    };

    const handleDepartureInputChange = (event, {newValue}) => {
        setDepartureValue(newValue)
    };

    const handleDestinationInputChange = (event, {newValue}) => {
        setDestinationValue(newValue)
    };

    const openStationInput = (fieldName) => {
        if (isMobile || isSmallTablet) {
            setMobileStationInputSelected(fieldName)
            if (fieldName === 'stationDeparture') {
                dispatch(activateDepartureStationInput(true))
            }
            if (fieldName === 'stationDestination') {
                dispatch(activateDestinationStationInput(true))
            }
        }
    };

    /**
     * @param {object} suggestion
     * @param {int} direction
     */
    const handleStationChange = (suggestion, direction) => {
        if (direction === 1) {
            dispatch(setDepartureStationObject(suggestion))
        } else {
            dispatch(setDestinationStationObject(suggestion))
        }
        dispatch(activateDepartureStationInput(false))
        dispatch(activateDestinationStationInput(false))
    };

    // Toggle function for <StationSwitchButton/>
    const toggleStationHandler = (e) => {
        e.stopPropagation()
        dispatch(toggleStations())
        setDepartureValue(destinationValue)
        setDestinationValue(departureValue)
    };

    const clearActiveInputFieldOnMobile = () => {
        setMobileStationInputSelected(null)
    }

    return {
        isBig: isHomepage,
        setClassName,
        departureValue,
        destinationValue,
        handleStationChange,
        toggleStationHandler,
        clearActiveInputFieldOnMobile,
        departureStationObject,
        destinationStationObject,
        mobileStationInputSelected,
        departureTopStations,
        destinationTopStations,
        formDepartureStation,
        formDestinationStation,
        mobileOverlayActive,
        handleDepartureClear,
        handleDestinationClear,
        handleTopStations,
        handleDepartureInputChange,
        handleDestinationInputChange,
        openStationInput,
    }
}