import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconNight(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m4.56387697 3.83820523c-.09287213 0-.18223966.00834711-.27423564.01335537 1.40009126.64856968 2.37262024 2.00413877 2.37262024 3.58674897 0 2.20864273-1.87934654 3.99993043-4.1985215 3.99993043-.92346445 0-1.7698275-.2929832-2.46374007-.7746109.80255544 1.6368664 2.54084147 2.7745761 4.56212467 2.7745761 2.78265949 0 5.03787533-2.1485436 5.03787533-4.79958261 0-2.65103904-2.25433969-4.80041736-5.03612303-4.80041736z"
                    fill="none"
                    stroke={stroke}
                    strokeLinejoin="round"
                    strokeWidth="1.2"
                    transform="matrix(-1 0 0 1 11.8794 .6919)"
                />
            )}
        />
    )
}

export default IconNight