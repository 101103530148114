import Translation from "@Translation/components/Translation";
import FeatureCards from "@Pages/homepage/features/featureCards/components/FeatureCards";
import React from "react";

type FeatureCardsSectionProps = {}

function FeatureCardsSection(props: FeatureCardsSectionProps) {
    return (
        <section className="gb--column plan-trip-section feature-cards">
            <h2>
                <Translation translationKey="home_page.feature_cards.title"/>
            </h2>
            <FeatureCards/>
        </section>
    );
}

export default FeatureCardsSection;