import React from 'react';
import PassengerPicker from "@Form/features/passengers/components/PassengerPicker";
import {useTranslation} from "@Translation/hooks/useTranslation";
import MobileOverlay from "./MobileOverlay";

function MobilePassengerPicker() {
    const addLabel = useTranslation("sys.add")
    const passengerLabel = useTranslation("form.passengers.label")
    return (
        <MobileOverlay title={`${addLabel} ${passengerLabel}`}>
            <PassengerPicker/>
        </MobileOverlay>
    );
}

export default MobilePassengerPicker;