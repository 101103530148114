import Portal from "@Portals/components/Portal";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import React from "react";
import FooterSection from "@/layout/footer/FooterSection";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useLocale from "@Hooks/selector/useLocale";
import HelpCenterSection from "@/layout/footer/HelpCenterSection";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";

function AdditionalFooter() {
    const footerUrls = useAppSelector((state) => state?.page.footerUrls)
    const destinationsLabel = useTranslation("menu.footer.destinations")
    const locale = useLocale()
    const busLabelKey = locale === "hr" ? "menu.main.buses" : "sys.bus";
    const busLabel = useTranslation(busLabelKey)
    const trainLabelKey = locale === "hr" ? "menu.main.trains" : "sys.train"
    const trainLabel = useTranslation(trainLabelKey)
    const shuttleLabel = useTranslation("menu.footer.shuttle")
    const isCompanyStore = useIsCompanyStore()
    const localeEnOrHr = locale === "hr" || locale === "en"

    if (!(locale === "hr" || locale === "en") || isCompanyStore) {
        return null
    }

    return (
        <Portal id="footer-portal">
            {(localeEnOrHr && !isCompanyStore)
                ? (
                    <div className="gb-footer-additional">
                        <div className="gb-footer-grid">
                            <FooterSection
                                title={destinationsLabel}
                                items={footerUrls.destinations}
                            />
                            <FooterSection
                                title={busLabel}
                                items={footerUrls.bus}
                            />
                            <FooterSection
                                title={trainLabel}
                                items={footerUrls.train}
                            />
                            <FooterSection
                                title={shuttleLabel}
                                items={footerUrls.airports}
                            />
                        </div>
                        <HelpCenterSection/>
                    </div>
                ) : (
                    <HelpCenterSection
                        className="gb-container help-center-additional"
                    />
                )}
        </Portal>
    )
}

export default AdditionalFooter