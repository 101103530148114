import Row from "@Containers/components/Row";
import {useTranslation} from "@Translation/hooks/useTranslation";
import React, {useRef} from "react";
import {Colors} from "@Styles/Colors.style";
import IconArrowAirplaneSwitch from "@Icons/arrows/IconArrowAirplaneSwitch";
import Column from "@Containers/components/Column";
import scaleFontSizeByCharacterCount
    from "@Pages/stationToStation/features/header/functions/scaleFontSizeByCharacterCount";
import parseHTMLStrings from "@String/parseHTMLStrings";

function StaticPageHeaderTitle() {
    const imageHeadingLabel = useTranslation("sts_page.image_heading")
    const ref = useRef<HTMLDivElement>(null);
    const size = scaleFontSizeByCharacterCount(
        ref,
        window.departingStationName.length + (window?.destinationStationName?.length ?? 0),
        18,
        36,
        35
    )

    return (
        <Column justify className="static-page-header-title">
            <span className="static-page-header-title-prefix">
                {imageHeadingLabel.toUpperCase()}
            </span>
            <Row gap center scrollRef={ref}>
                <span className="static-page-header-title-label" style={{fontSize: `${size}px`}}>
                    {parseHTMLStrings(window.departingStationName)}
                </span>
                {window?.destinationStationName && (
                    <>
                        <div className="trvl--circle trvl--circle-white">
                            <IconArrowAirplaneSwitch
                                width={20}
                                height={20}
                                fill={Colors.primaryColor}
                            />
                        </div>
                        <span className="static-page-header-title-label" style={{fontSize: size}}>
                            {parseHTMLStrings(window.destinationStationName)}
                        </span>
                    </>
                )}
            </Row>
        </Column>
    )
}

export default StaticPageHeaderTitle