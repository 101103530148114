import Dialog from "@Features/dialog/components/Dialog";
import React, {MutableRefObject} from "react";
import DropdownButton from "@Generic/buttons/DropdownButton";
import Column from "@Containers/components/Column";
import SeatDeck from "@CheckOrder/components/MainSection/SeatPicker/components/SeatDeck";
import SeatPickerTitle from "@CheckOrder/components/MainSection/SeatPicker/components/SeatPickerTitle";
import Button from "@Generic/buttons/Button";
import Translation from "@Translation/components/Translation";
import LoaderButton from "@Generic/loaders/LoaderButton";
import Notification from "@Features/notification/components/Notification";
import {
    SeatPickerDirectionDataType
} from "@CheckOrder/components/MainSection/SeatPicker/types/SeatPickerDirectionData.type";
import useSeatSelection from "@CheckOrder/components/MainSection/SeatPicker/hooks/useSeatSelection";
import Cabins from "@CheckOrder/components/MainSection/SeatPicker/components/Cabins";

export type SeatSelectionDialogProps = {
    dialogRef: MutableRefObject<HTMLDialogElement>,
    isReturn: boolean,
    seatPickerDirectionData: SeatPickerDirectionDataType
}

function SeatSelectionDialog({dialogRef, seatPickerDirectionData, isReturn}: SeatSelectionDialogProps) {
    const {seatPicker, selectedSeatData} = seatPickerDirectionData
    const {
        passengers,
        selectedDeckIndex,
        onClick,
        onContinueClick,
        updatedSeatSelectionResponse,
    } = useSeatSelection({
        dialogRef,
        isReturn,
        seatPickerDirectionData,
    })

    const activeSeatDeck = seatPicker.seatDecks[selectedDeckIndex]

    return (
        <Dialog
            className="gb--seat-picker-dialog"
            dialogRef={dialogRef}
            showCloseButton
        >
            <SeatPickerTitle
                isReturn={isReturn}
            />

            {seatPicker.seatDecks.length > 1 ? (
                <DropdownButton
                    label={seatPicker.seatDecks[selectedDeckIndex].label}
                    baseClass="gb--select"
                    cssButtonClass="gb--select-dropdown gb--select-dropdown-btn"
                    cssLabelClass="gb--select-dropdown-label"
                >
                    <div className="react-autosuggest__suggestions-list">
                        {seatPicker.seatDecks.map((option, index) => (
                            <button
                                type="button"
                                key={option.key}
                                value={option.label}
                                onClick={() => onClick(index)}
                                className="gb--select__option full-width"
                            >
                                {option.label}
                            </button>
                        ))}
                    </div>
                </DropdownButton>
            ) : (
                <hr/>
            )}
            {activeSeatDeck.displayAsCabins ? (
                <Cabins
                    isReturn={isReturn}
                    seatDeck={activeSeatDeck}
                />
            ) : (
                <SeatDeck
                    isReturn={isReturn}
                    seatDeck={activeSeatDeck}
                />
            )}
            <Column className="gb--seat-picker-dialog-confirm-section">
                <span>
                    <Translation
                        translationKey="check_order.seat_picker.dialog.selected_seats"
                        translationVariables={{
                            selected: selectedSeatData.length,
                            available: passengers.length,
                        }}
                    />
                </span>
                <Button
                    disabled={updatedSeatSelectionResponse.isLoading}
                    onClick={onContinueClick}
                    className="green"
                    additionalClasses="gb--seat-picker-dialog-confirm-section-button"
                >
                    {updatedSeatSelectionResponse.isFetching ? (
                        <LoaderButton height={15}/>
                    ) : (
                        <Translation translationKey="check_order.seat_picker.dialog.continue"/>
                    )}
                </Button>
            </Column>
            {updatedSeatSelectionResponse.error && (
                <Notification notificationType="red">
                    {updatedSeatSelectionResponse?.error?.data?.userMessage}
                </Notification>
            )}
        </Dialog>
    );
}

export default SeatSelectionDialog;