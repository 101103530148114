import ToggleButton from "@Generic/buttons/ToggleButton";
import React from "react";
import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import {useTranslation} from "@Translation/hooks/useTranslation";
import CheckOrderErrorLabel from "@CheckOrder/components/MainSection/CheckOrderErrorLabel";

interface VehicleRegistrationProps {
    isRental: boolean,
    registrationNumber: string,
    setRegistrationNumber: (value: string) => void,
    toggleRental: () => void,
    showErrors: boolean
}

function VehicleRegistration(props: VehicleRegistrationProps) {
    const {
        isRental,
        registrationNumber,
        setRegistrationNumber,
        toggleRental,
        showErrors,
    } = props

    const {
        vehicle_registration: vehicleRegistrationLabel,
        rental: rentalLabel,
    } = useTranslation("check_order.addons.JADL.vehicle")

    return (
        <>
            <CheckOrderInputContainer label={vehicleRegistrationLabel}>
                <input
                    type="text"
                    // name={field.label}
                    value={registrationNumber}
                    onChange={(e) => {
                        setRegistrationNumber(e.target.value)
                    }}
                    disabled={isRental}
                    required
                    maxLength={15}
                />
                {showErrors && !isRental && (
                    <CheckOrderErrorLabel
                        error="Vehicle registration plate number is mandatory"
                    />
                )}
            </CheckOrderInputContainer>
            <ToggleButton
                label={rentalLabel}
                checked={isRental}
                onClick={toggleRental}
                additionalWrapperClassName="gb--check-order-input-container"
            />
        </>
    )
}

export default VehicleRegistration