import React from 'react';
import Row from "@Containers/components/Row";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import routeIconsHashMapResolver from "@Route/features/amenities/functions/routeIconsHashMapResolver";
import TravelingDotComRouteAmenityIcon from "@Route/features/amenities/components/TravelingDotComRouteAmenityIcon";

interface RouteAmenitiesIconProps {
    icon: RouteIconType,
    displayLabel?: boolean,
}

const RouteAmenitiesIcon = ({icon, displayLabel = false}: RouteAmenitiesIconProps) => {
    return (
        <Row className="gb--route-amenities_icons">
            <span>
                <TravelingDotComRouteAmenityIcon tag={routeIconsHashMapResolver(icon?.id)}/>
            </span>
            {displayLabel && (
                <span>
                    {icon?.label}
                </span>
            )}
        </Row>
    );
}

export default RouteAmenitiesIcon
