import React from 'react';
import Translation from "@Translation/components/Translation";
import SuggestedDate from "@RouteSuggestions/features/suggestedDates/components/SuggestedDate";
import useSearchSuggestedDate from "@RouteSuggestions/features/suggestedDates/hooks/useSearchSuggestedDate";
import useCreateSuggestedDatesArray from "@RouteSuggestions/features/suggestedDates/hooks/useCreateSuggestedDatesArray";
import Slider from "@Features/slider/features/slider/components/Slider";
import useIsMobile from "@Hooks/selector/useIsMobile";
import Notification from "@Features/notification/components/Notification";

interface SuggestedDatesProps {
    dates: number[],
    isReturn: boolean,
    searchedDate: string,
    hasRoutesOnSelectedDate: boolean,
}

function SuggestedDates({dates, isReturn, searchedDate, hasRoutesOnSelectedDate}: SuggestedDatesProps) {
    const {searchSuggestedDate} = useSearchSuggestedDate(isReturn, hasRoutesOnSelectedDate)

    const {
        searchedDateIndex,
        appendedDates,
    } = useCreateSuggestedDatesArray(dates, searchedDate, hasRoutesOnSelectedDate)

    const isMobile = useIsMobile()

    return (
        <div className="gb--suggestions">
            <Notification additionalClass="suggested--dates-notification">
                <h2>
                    {hasRoutesOnSelectedDate ? (
                        <Translation
                            translationKey="search_results.suggested_dates.have_results_other_dates_notification"
                        />
                    ) : (
                        <Translation
                            translationKey="search_results.suggested_dates.no_results_other_dates_notification"
                        />
                    )}
                </h2>
            </Notification>
            {isMobile ? (
                <Slider
                    itemsPerSlide={2.5}
                    dataLength={appendedDates.length}
                    className="suggested--dates"
                >
                    {appendedDates.map((unixFormatDate, index) => (
                        <SuggestedDate
                            key={unixFormatDate}
                            unixFormatDate={unixFormatDate}
                            searchSuggestedDate={searchSuggestedDate}
                            hasNoRoutes={searchedDateIndex === index}
                        />
                    ))}
                </Slider>
            ) : (
                <ul className="suggested--dates">
                    {appendedDates.map((unixFormatDate, index) => (
                        <SuggestedDate
                            key={unixFormatDate}
                            unixFormatDate={unixFormatDate}
                            searchSuggestedDate={searchSuggestedDate}
                            hasNoRoutes={searchedDateIndex === index}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
}

export default SuggestedDates;