import React, {ReactNode, useContext} from 'react';
import Row from "@Containers/components/Row";
import convertObjectToArrayOfObjects from "@Object/convertObjectToArrayOfObjects";
import {ActiveTicketContext} from "@Route/features/mobile/context/ActiveTicketContext";
import MobileActiveTicketSectionButton from "./MobileActiveTicketSectionButton";

type ActiveTicketSectionsAsArray = {
    id: any,
    properties: any,
}

function MobileActiveTicketSectionButtons() {
    const activeSections = useContext(ActiveTicketContext)

    const activeTicketSections: ActiveTicketSectionsAsArray[] = convertObjectToArrayOfObjects(
        activeSections,
        "id",
        "properties"
    )

    return (
        <Row center className="gb--mobile-active-ticket-section-buttons">
            {activeTicketSections.reduce((acc: ReactNode[] | [], section: { id: any, properties: any }) => (
                section?.properties ? ([
                    ...acc,
                    <MobileActiveTicketSectionButton
                        key={section?.id}
                        section={section.properties}
                        id={section?.id}
                        initiallyActive={section?.id === 'ticketInfoSection'}
                    />,
                ]) : acc
            ), [])}
        </Row>
    );
}

export default MobileActiveTicketSectionButtons;