import React from 'react';
import ErrorBoundary from "@Errors/components/ErrorBoundary"
import useIsMobile from "@Hooks/selector/useIsMobile";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import RouteAmenitiesIcon from "@Route/features/amenities/components/RouteAmenitiesIcon";
import RouteAmenitiesPopover from "@Route/features/amenities/components/RouteAmenitiesPopover";

interface RouteAmenitiesProps {
    amenities: number[],
    routeIcons: Record<number, RouteIconType>,
}

function RouteAmenities({amenities, routeIcons}: RouteAmenitiesProps) {
    const isMobile = useIsMobile()

    return (
        <ErrorBoundary>
            {isMobile ? (
                <div className={`gb--${isMobile ? 'column' : 'row'}`}>
                    {amenities.map((icon) => (
                        <RouteAmenitiesIcon
                            key={icon}
                            displayLabel={isMobile}
                            icon={routeIcons?.[icon]}
                        />
                    ))}
                </div>
            ) : (
                <RouteAmenitiesPopover
                    amenities={amenities}
                    routeIcons={routeIcons}
                />
            )}
        </ErrorBoundary>
    );
}

export default RouteAmenities;