import Column from "@Containers/components/Column";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TicketRouteTitle from "@MyTicket/components/TicketRouteTitle";
import formatDate from "@DateTime/functions/formatDate";

interface TicketTripDataProps {
    departureStationName: string,
    destinationStationName: string,
    showReturnIcon?: boolean,
    departureDate: string,
    returnDate?: string,
    className?: string,
    directionLabel?: string,
}

function TicketTripData(props: TicketTripDataProps) {
    const {
        departureStationName,
        destinationStationName,
        showReturnIcon,
        departureDate,
        returnDate,
        className = "",
        directionLabel,
    } = props

    const isMobile = useIsMobile()

    const date = formatDate(departureDate, "MMM dd, yyyy | HH:mm")

    return (
        <Column className={`trip-data-info ${className} info-field-large`}>
            <TicketRouteTitle
                departureStationName={departureStationName}
                destinationStationName={destinationStationName}
                showReturnIcon={showReturnIcon}
            />
            <div>
                {isMobile && directionLabel && (
                    <span className="gb--emphasize-info">
                        {directionLabel}
                        :
                        &nbsp;
                    </span>
                )}
                {date}
            </div>
        </Column>
    )
}

export default TicketTripData;