import React from "react";
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import Translation from "@Translation/components/Translation";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {isNotEmpty} from "@Array/isNotEmpty";
import TabType from "@Tabs/types/Tab.type";
import ParamsType from "@SearchResults/types/params/Params.type";
import MobileAppPromoBanner from "@SearchResults/features/banners/components/MobileAppPromoBanner";
import StationToStationNotifications from "./StationToStationNotifications"
import ActiveFiltersNotification from "../HeadingContent/ActiveFiltersNotification";
import NoResultsForVehicleType from "../NoResults/NoResultsForVehicleType";
import CompanyType from "@SearchResults/types/company/Company.type";
import useShowTripDelayNotification from "@SearchResults/features/notifications/hooks/useShowTripDelayNotification";
import IconWarning from "@Icons/searchRes/IconWarning";
import {Colors} from "@Styles/Colors.style";
import IconDepartureTime from "@Icons/searchRes/filters/IconDepartureTime";

type RouteNotificationProps = {
    mobileAppPromoNotification: boolean,
    noRoutesNotification: boolean,
    allRoutesFilteredNotification: boolean,
    onlyFootPassengersNotification: boolean,
    hasActiveRoutes: boolean,
    activeTab: TabType,
    tabsToShow: TabType[],
    params: ParamsType,
    companies: Record<string, CompanyType>
}

function RouteNotifications(props: RouteNotificationProps) {
    const borderCrossing = useTranslation("search_results.border_crossing")
    const {
        params,
        mobileAppPromoNotification,
        noRoutesNotification,
        allRoutesFilteredNotification,
        onlyFootPassengersNotification,
        activeTab,
        tabsToShow,
        hasActiveRoutes,
        companies,
    } = props;

    const showTripDelayNotification = useShowTripDelayNotification(params, activeTab, hasActiveRoutes)

    return (
        <>
            {allRoutesFilteredNotification && hasActiveRoutes && (
                <Notification
                    additionalClass="gb--row"
                    notificationType="blue"
                    notificationIcon={<IconExclamationCircle width="18" height="18" fill="#f5a623"/>}
                >
                    <ActiveFiltersNotification/>
                </Notification>
            )}

            {!hasActiveRoutes && noRoutesNotification && (
                <NoResultsForVehicleType
                    departureStation={params.departureStation.cityName}
                    destinationStation={params.destinationStation.cityName}
                    vehicleType={activeTab.tabType}
                    tabsToShow={tabsToShow}
                />
            )}

            {isNotEmpty(params?.stationToStationNotifications) && (
                <StationToStationNotifications
                    companies={companies}
                    stationToStationNotifications={params.stationToStationNotifications}
                />
            )}

            {params.showBorderCrossingMessage && (
                <Notification
                    notificationType="orange"
                    notificationIcon={<IconWarning width="18" height="18" stroke={Colors.warningColor}/>}
                    isDismissible
                    isRow
                >
                    <div dangerouslySetInnerHTML={{__html: borderCrossing}}/>
                </Notification>
            )}

            {showTripDelayNotification && (
                <Notification
                    notificationType="orange"
                    notificationIcon={(
                        <IconDepartureTime width="18" height="18" stroke={Colors.warningColor}/>
                    )}
                >
                    <Translation translationKey="search_results.heavy_traffic"/>
                </Notification>
            )}
            {/* TODO why the fuck is this here? move to TopBanners.tsx */}
            {mobileAppPromoNotification && (
                <MobileAppPromoBanner
                    departureStation={params.departureStation.cityName}
                    destinationStation={params.destinationStation.cityName}
                />
            )}
        </>
    )
}

export default RouteNotifications