import React from 'react';
import ErrorBoundary from "@Errors/components/ErrorBoundary"
import PortalPages from "./PortalPages";
import SidebarPortalContainer from "@Sidebar/components/SidebarPortalContainer";
import AdditionalFooter from "@/layout/footer/AdditionalFooter";

const PortalPagesContainer = () => (
    <>
        <SidebarPortalContainer/>
        <ErrorBoundary shouldReload={false}>
            <PortalPages/>
            <AdditionalFooter/>
        </ErrorBoundary>
    </>
);

export default PortalPagesContainer