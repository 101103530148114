import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Icon diamond, representing premium support ancillary
 */
function IconPremiumSupportAncillary(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m11.1498027 0c.1905254 0 .3468476.08186665.4522924.20428561l.0090626.01371439 3.0502113 3.60941023c.184934.21948068.1780797.50421636.0458742.71135011l-.0240855.03123966-6.79938214 9.1722468c-.23987872.3234794-.72401453.3234788-.96389252-.0000011l-6.800672-9.17083197-.02373031-.03415679c-.13194633-.20811756-.13764577-.49388168.04945225-.71292385l2.96222472-3.46833309.0074264-.0173379c.08129157-.16899482.23938964-.30153688.44781614-.33203185l.09232978-.00663025zm1.860355 4.814h-11.218l5.609 7.563zm-9.295-3.322-1.813 2.122h4.013l-.08784991-.08498297c-1.26329575-1.21843067-1.75069991-1.688525-2.07757478-2.00379114zm7.393-.019-2.221 2.141h4.025zm-1.445-.273h-4.522l.00959976.00930017c.43075866.41546057 1.09538231 1.05648056 2.24235249 2.16271688l.00804775.00898295z"
                    fill={fill}
                    transform="translate(.5982 1.0076)"
                />
            )}
        />
    )
}

export default IconPremiumSupportAncillary