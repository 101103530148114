import React from "react";
import RouteDetailsContainer from "@Route/components/Container/RouteDetailsContainer";
import ParamsType from "@SearchResults/types/params/Params.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteNotesAndTicketType from "@Route/features/notes/RouteNotesAndTicketType";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import {displayTicketType} from "@/functions/overrides/displayTicketType"

interface RouteExpandedContainerProps {
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType,
    params: ParamsType
    isReturn: boolean,
    routeIcons: Record<number, RouteIconType>,
}

function RouteExpandedContainer(props: RouteExpandedContainerProps) {
    const {
        route,
        params,
        isReturn,
        routeIcons,
    } = props;

    const {
        status: {
            hasMTicket,
        },
        travelInfo: {
            notes,
        },
    } = route || {}

    return (
        <>
            {!route.status.isSuggestion && (
                <RouteNotesAndTicketType
                    displayTicketPrintType={displayTicketType(route)}
                    hasMTicket={hasMTicket}
                    notes={notes}
                    isCurrentRouteSingle={!isReturn}
                />
            )}
            <RouteDetailsContainer
                params={params}
                routeIcons={routeIcons}
                route={route}
            />
        </>
    );
}

export default RouteExpandedContainer;