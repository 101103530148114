import Translation from "@Translation/components/Translation";
import React from "react";
import {useToggle} from "@Hooks/dispatch/useToggle";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import TransportSpecificLink from "@Header/components/TransportSpecificLinks/TransportSpecificLink";
import MobileMenuItem from "@User/components/Mobile/MobileMenuItem";
import TravelingDotComMobileMenuIcons from "@User/components/Mobile/TravelingDotComMobileMenuIcons";

interface TransportSpecificLinkMobileProps {
    title: string,
    tag: string,
    items: UrlObjectType[]
}

function TransportSpecificLinkMobile({title, tag, items}: TransportSpecificLinkMobileProps) {
    const showLinksList = useToggle()

    return (
        <>
            <MobileMenuItem
                text={<Translation translationKey={title}/>}
                openItem={() => showLinksList.onChange()}
                rotateFor={showLinksList.value ? '-180' : '0'}
                icon={<TravelingDotComMobileMenuIcons tag={tag}/>}
            />
            {showLinksList.value && (
                <ul className="select__list--mobile">
                    {items.map((item) => (
                        <TransportSpecificLink
                            url={item.url}
                            text={item.text}
                            isActive={item.url === window.location.pathname || item.url === window.location.href}
                            key={item.url ?? item.text}
                        />
                    ))}
                </ul>
            )}
        </>
    )
}

export default TransportSpecificLinkMobile