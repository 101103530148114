import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";

interface IconChevronDoubleProps extends IconType {
    chevronOrientation?: 'up' | 'right' | 'down' | 'left'
}

function IconChevronDouble(props: IconChevronDoubleProps) {
    const {
        width = '20',
        height = '20',
        fill = '#4a79e2',
        viewBox = '0 0 20 20',
        title = 'IconChevronDouble',
        chevronOrientation = 'right',
        ...rest
    } = props

    const orientation = {
        [`${chevronOrientation === "up"}`]: "-90deg",
        [`${chevronOrientation === "down"}`]: "90deg",
        [`${chevronOrientation === "right"}`]: "0deg",
        [`${chevronOrientation === "left"}`]: "-180deg",
    }

    const style = {verticalAlign: "middle", transform: `rotate(${orientation.true})`};

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            style={style}
            svgIconPath={(
                <g
                    fill={fill}
                    fillRule="nonzero"
                    stroke={fill}
                >
                    <path
                        d="m5.28062959 11.8888889c.12612336 0 .2342291-.0458359.32431721-.1375077l6.3376988-6.44910987c.0900881-.09167178.1351322-.20167792.1351322-.33001841 0-.1283405-.0450441-.23834664-.1351322-.33001842s-.1981938-.13750767-.3243172-.13750767-.2342291.04583589-.3243172.13750767l-6.01338161 6.1190915-6.10797411-6.21534687c-.09008812-.08250461-.19819385-.12375691-.32431721-.12375691s-.2342291.0412523-.32431721.12375691c-.04504406.04583589-.0788271.09854716-.10134913.15813382s-.03378304.11688153-.03378304.17188459c0 .06417025.01126101.12604871.03378304.18563536.02252203.05958666.05630507.11229794.10134913.15813383l6.43229132 6.53161447c.09008812.0916718.19819385.1375077.32431721.1375077z"
                        transform="matrix(0 1 1 0 -2.922223 2.922223)"
                    />
                    <path
                        d="m10.2584074 11.8888889c.1261233 0 .2342291-.0458359.3243172-.1375077l6.3376988-6.44910987c.0900881-.09167178.1351322-.20167792.1351322-.33001841 0-.1283405-.0450441-.23834664-.1351322-.33001842s-.1981939-.13750767-.3243172-.13750767c-.1261234 0-.2342291.04583589-.3243172.13750767l-6.0133816 6.1190915-6.10797414-6.21534687c-.09008812-.08250461-.19819385-.12375691-.32431721-.12375691s-.2342291.0412523-.32431721.12375691c-.04504406.04583589-.0788271.09854716-.10134913.15813382s-.03378304.11688153-.03378304.17188459c0 .06417025.01126101.12604871.03378304.18563536.02252203.05958666.05630507.11229794.10134913.15813383l6.43229132 6.53161447c.09008814.0916718.19819384.1375077.32431724.1375077z"
                        transform="matrix(0 1 1 0 2.055555 -2.055555)"
                    />
                </g>
            )}
        />
    );
}

export default IconChevronDouble;
