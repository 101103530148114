import Row from "@Containers/components/Row";
import SourcedImage from "@Images/components/SourcedImage";
import Column from "@Containers/components/Column";
import React from "react";
import Translation from "@Translation/components/Translation";

const UserSection = () => {
    return (
        <div className="app-reviews-section-intro">
            <Row
                center
                className="gb-container mobile-app-landing-page-section app-reviews-section-intro-container"
            >
                <div className="app-reviews-section-intro-content">
                    <SourcedImage
                        filePath="images/mobile-app-landing-page/mobile_app_landing_page_woman_with_logo"
                        fileType="png"
                    />
                </div>
                <Column className="app-reviews-section-intro-content">
                    <h1 className="app-reviews-section-intro-numbers">
                        <Translation translationKey="mobile_app.users_section.intro"/>
                    </h1>
                    <h1>
                        <Translation translationKey="mobile_app.users_section.title"/>
                    </h1>
                    <p>
                        <Translation translationKey="mobile_app.users_section.description"/>
                    </p>
                </Column>
            </Row>
        </div>
    )
}

export default UserSection