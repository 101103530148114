export const Palette = {
    // primary colors palette
    // https://coolors.co/130021-2c004c-550093-7e00d9-9a33d9-dfbff5-f9f2fd
    darkPurple: '#130021',
    russianViolet: '#2c004c',
    indigo: '#550093',
    frenchViolet: '#7e00d9',
    veronica: '#9a33d9',
    mauve: '#dfbff5',
    mangolia: '#f9f2fd',

    // secondary colors palette
    // https://coolors.co/0f2018-234a38-448f6c-64d49f-81eeb8-c9f0dd-effbf5
    darkGreen: '#0f2018',
    brunswickGreen: '#234a38',
    seaGreen: '#448f6c',
    emerald: '#64d49f',
    aquamarine: '#81eeb8',
    mintGreen: '#c9f0dd',
    mintCream: '#eefbf5',

    // information state colors palette
    // https://coolors.co/101826-20304d-4568a6-6a9fff-9ec1ff-d2e2ff-f0f5ff
    richBlack: '#101826',
    delftBlue: '#20304d',
    trueBlue: '#4568a6',
    cornflowerBlue: '#6a9fff',
    jordyBlue: '#9ec1ff',
    lavander: '#d2e2ff',
    aliceBlue: '#f0f5ff',

    // warning state colors palette
    // https://coolors.co/251900-4a3200-a16d00-f7a700-fac659-fde5b2-fef6e5
    bistre: '#251900',
    cafNoir: '#4a3200',
    goldenBrown: '#a16d00',
    orange: '#f7a700',
    saffron: '#fac659',
    peach: '#fde5b2',
    cosmicLatte: '#fef6e5',

    // error state colors palette
    // https://coolors.co/210909-411212-a03a2b-ff6144-fa9384-f4c4c4-fbebeb
    licorice: '#210909',
    blackBean: '#411212',
    chestnut: '#a03a2b',
    tomato: '#ff6144',
    coralPink: '#fa9384',
    teaRoseRed: '#f4c4c4',
    lavenderBlush: '#fbebeb',

    // grayscale colors palette
    black: '#000000',
    jet: '#2a2a2a',
    dimGray: '#676d77',
    powderBlue: '#aab5c4',
    iron: '#cdd5df',
    // aliceBlue: '#e4e8f1',
    ghostWhite: '#eef1f8',
    offWhite: '#f6f8fd',
    white: '#ffffff',

    // label state colors palette
    icyBlue: "#adcaff",
    crayola: "#faca66",
    melon: "#ffa898",
    celadon: "#b4f3d5",
};