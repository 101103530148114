import React from 'react';
import IconChevron from "@Icons/chevrons/IconChevron";
import Translation from "@Translation/components/Translation";
import IconPointingHandCta from "@Icons/cta/IconPointingHandCta";
import {Colors} from "@Styles/Colors.style";

interface VoucherNoticeProps {
    onClick: () => void,
}

function VoucherNotice({onClick}: VoucherNoticeProps) {

    return (
        <div
            onClick={onClick}
            className="gb--row gb--row-justify gb--check-order-voucher-notice"
            role="button"
        >
            <span className="gb--emphasize gb--emphasize-default">
                <IconPointingHandCta
                    width="20"
                    height="20"
                />
                <Translation
                    translationKey="sys.click_here_to_apply"
                />
            </span>
            <IconChevron
                width="20"
                height="20"
                fill={Colors.primaryColor}
                chevronOrientation="right"
                style={{stroke: '#070c63'}}
            />
        </div>
    );
}

export default VoucherNotice;