import React from 'react';
import Column from "@Containers/components/Column";
import Time from "@DateTime/components/Time";
import Row from "@Containers/components/Row";
import VehicleTypeIcon from "@Generic/vehicle/VehicleTypeIcon";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RouteType from "@Route/types/Route.type";
import RouteConnection from "@Route/components/Connection/RouteConnection";
import RouteBusChangeDetails from "@Route/components/Connection/ExpandedRoute/RouteBusChangeDetails";
import RouteBusLayover from "@Route/components/Connection/ExpandedRoute/RouteBusLayover";

interface RouteTravelDetailsWithBusChangesProps {
    /**
     *  RouteWithAddedPropsAndPricesType is added here because of Search Results.
     *
     *  RouteType is added here because of Check Order.
     */
    route: RouteWithAddedPropsAndPricesType | RouteType,
    companyLetterCode: string,
}

function RouteTravelDetailsWithBusChanges({route, companyLetterCode}: RouteTravelDetailsWithBusChangesProps) {
    const {
        travelInfo: {
            departureStation: {
                name: departureStationName,
                departureTime,
                departureDate,
            },
            destinationStation: {
                name: destinationStationName,
                arrivalTime,
                arrivalDate,
            },
            busChange,
        },
    } = route || {}

    return (
        <Row className="route-travel-details">
            <Column className="travel-time">
                <span>
                    <Time
                        time={departureTime}
                        className="gb--emphasize-default"
                    />
                </span>
                <RouteBusLayover
                    arrivalDate={arrivalDate}
                    arrivalTime={arrivalTime}
                    busChange={busChange}
                    departureDate={departureDate}
                    departureTime={departureTime}
                />
                <span>
                    <Time
                        time={arrivalTime}
                        className="gb--emphasize-default"
                    />
                </span>
            </Column>
            <RouteConnection
                vertical
                hasStopover
                route={route}
                showVehicleIcon={false}
            />
            <Column className="travel-stations">
                <span className="gb--emphasize-default gb--truncate">
                    {departureStationName}
                </span>
                <span className="gb--label gb--label-default">
                    <VehicleTypeIcon
                        width="10"
                        height="10"
                        fill="#070c63"
                        className="vehicle-icon"
                    />
                    {companyLetterCode}
                </span>
                <RouteBusChangeDetails
                    busChange={busChange}
                    companyLetterCode={companyLetterCode}
                />
                <span className="gb--emphasize-default gb--truncate">
                    {destinationStationName}
                </span>
            </Column>
        </Row>
    );
}

export default RouteTravelDetailsWithBusChanges;