import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";
import {useEffect, useState} from "react";

/**
 * Hook to manage number of slides in date slider shown to user depending on screen size
 */
export default function useLimitSlidesToShow() {
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()

    function limitSlidesToShow() {
        if (isMobile) {
            return 3;
        }
        if (isTablet) {
            return 5;
        }
        return 7;
    }

    const [
        datesInSliderLimit,
        setDatesInSliderLimit,
    ] = useState(limitSlidesToShow())

    useEffect(() => {
        setDatesInSliderLimit(limitSlidesToShow())
    }, [isMobile, isTablet])

    return datesInSliderLimit
}