import React from 'react';
import Modal from "@Generic/modal/Modal";
import FullScreenLoader from "./FullScreenLoader";

const SplashLoader = () => (
    <Modal
        buttonText={null}
        handleClick={null}
        message={<FullScreenLoader/>}
    />
);

export default SplashLoader;