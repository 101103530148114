import MobileOverlaysType from "@Reducers/types/MobileOverlays.type";
import MobileSliceStateType from "@Reducers/types/MobileSliceState.type";

export const mobileOverlaysInitialState: MobileOverlaysType = {
    singleCalendarActive: false,
    returnCalendarActive: false,
    stsCalendarActive: false,
    mobileLogInActive: false,
    mobileLanguagesActive: false,
    mobileMenuActive: false,
    mobileMyTicketsActive: false,
    mobilePassengerPickerActive: false,
    sortingOptionsActive: false,
    currencyPickerActive: false,
    mobileOverlayActive: false,
    currencySelectActive: false,
    sorterActive: false,
    departureStationInputActive: false,
    destinationStationInputActive: false,
    filtersOverlayActive: false,
}

export const mobileSliceInitialState: MobileSliceStateType = {
    formInputSelected: false,
    formAnimationActive: false,
    ...mobileOverlaysInitialState,
}