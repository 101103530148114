import React from 'react';
import Card from "react-bootstrap/Card";
import VoucherType from "@MyVoucher/types/Voucher.type";
import VoucherNotificationSection from "@MyVoucher/features/voucherDetails/components/VoucherNotificationSection";
import VoucherAmountSection from "@MyVoucher/features/voucherDetails/components/VoucherAmountSection";

interface VoucherDetailsProps {
    voucher: VoucherType,
}

function VoucherDetails({voucher}: VoucherDetailsProps) {
    const {
        type,
        restrictions,
    } = voucher

    return (
        <Card.Body className="card-expanded voucher-details">
            <VoucherNotificationSection
                voucher={voucher}
            />
            <VoucherAmountSection
                storesList={restrictions.companyStores}
                voucherType={type}
                voucherAmount={`${voucher.amount}`}
            />
        </Card.Body>
    );
}

export default VoucherDetails;