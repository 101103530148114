import React, {ReactNode} from 'react';
import StationsDivider from "@Generic/station/StationsDivider";

interface ConnectionDividerProps {
    customClass?: string,
    children?: ReactNode,
    hasStopover?: boolean,
    numberOfStops?: number,
    lineClass?: string
}

const ConnectionDivider = ({customClass, hasStopover, numberOfStops, lineClass, children}: ConnectionDividerProps) => {
    return (
        <div className={`gb--connect-divider ${(customClass)}`}>
            <span className="gb--connect-circle gb--connect-circle--green"/>
            {children || (
                <span className={`gb--connect-line ${lineClass}`}/>
            )}
            {hasStopover && !numberOfStops && (
                <>
                    <span className="gb--connect-circle gb--connect-circle--blue"/>
                    <span className={`gb--connect-line ${lineClass}`}/>
                </>
            )}
            {hasStopover && numberOfStops && (
                <StationsDivider
                    numberOfStations={numberOfStops}
                    lineClass={lineClass}
                />
            )}
            <span className="gb--connect-circle gb--connect-circle--orange">
                <span className="gb--connect-circle gb--connect-circle--traveling"/>
            </span>
        </div>
    );
}

export default ConnectionDivider;