import createTabsFromGroups from "@Tabs/functions/createTabsFromGroups";
import {RoutesOrganizedByTransportTypeType} from "@SearchResults/types/RoutesOrganizedByTransportType.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";

export default function useTabsToShow(singleRoutes: RoutesOrganizedByTransportTypeType, intermodalRoutes?: IntermodalRouteType[]) {
    const getTabsToShow = () => {
            return createTabsFromGroups(singleRoutes, intermodalRoutes)
    }

    return {
        getTabsToShow,
    }
}