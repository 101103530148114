import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import SidebarSectionContainer from "../Sections/SidebarSectionContainer";
import Reviews from "./Reviews";
import LatestReviewType from "@Sidebar/types/LatestReview.type";
import IconLatestRating from "@Icons/IconLatestRating";

interface ReviewsContainerProps {
    latestReviews: LatestReviewType[],
}

function ReviewsContainer({latestReviews}: ReviewsContainerProps) {
    const latestReviewsTitle = useTranslation("search_results.latest_reviews")

    return (
        <SidebarSectionContainer
            contentArray={latestReviews}
            title={latestReviewsTitle}
            icon={<IconLatestRating width="32" height="32"/>}
        >
            <Reviews latestReviews={latestReviews}/>
        </SidebarSectionContainer>
    );
}

export default ReviewsContainer;