import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import IconStar from "@Icons/searchRes/IconStar";
import Translation from "@Translation/components/Translation";

const ReviewsNumbersSection = () => {
    return (
        <div className="reviews-numbers-section-container">
            <Row
                gap
                center
                justify
                className="gb-container mobile-app-landing-page-section reviews-numbers-section"
            >
                <Column>
                    <Row center>
                        <h1 className="app-reviews-section-intro-numbers reviews-numbers-section-title">4.7</h1>
                        <IconStar width={28} height={28}/>
                    </Row>
                    <p className="reviews-numbers-section-description">
                        {`1k `}
                        <Translation translationKey="mobile_app.user_reviews_section.banner.reviews" styleText="lowerCase"/>
                    </p>
                </Column>
                <Column>
                    <h1 className="app-reviews-section-intro-numbers reviews-numbers-section-title">100 000+</h1>
                    <p className="reviews-numbers-section-description">
                        <Translation translationKey="mobile_app.user_reviews_section.banner.downloads" styleText="lowerCase"/>
                    </p>
                </Column>
                <Column>
                    <h1 className="app-reviews-section-intro-numbers reviews-numbers-section-title">200 000+</h1>
                    <p className="reviews-numbers-section-description">
                        <Translation translationKey="mobile_app.user_reviews_section.banner.routes" styleText="lowerCase"/>
                    </p>
                </Column>
                <Column>
                    <h1 className="app-reviews-section-intro-numbers reviews-numbers-section-title">70+</h1>
                    <p className="reviews-numbers-section-description">
                        <Translation translationKey="mobile_app.user_reviews_section.banner.countries" styleText="lowerCase"/>
                    </p>
                </Column>
            </Row>
        </div>
    )
}

export default ReviewsNumbersSection