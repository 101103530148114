import React from "react";
import IconBus from "@Icons/transportType/IconBus";
import IconTrain from "@Icons/transportType/IconTrain";
import IconPlane from "@Icons/transportType/IconPlane";
import IconFerry from "@Icons/transportType/IconFerry";
import IconPalmIsland from "@Icons/location/IconPalmIsland";
import IconPenWithABoard from "@Icons/edit/IconPenWithABoard";
import IconLocationArrow from "@Icons/IconLocationArrow";
import IconInfo from "@Icons/IconInfo";
import IconPencilContact from "@Icons/menuIcons/IconPencilContact";
import IconBusTab from "@Icons/IconBusTab";
import IconHome from "@Icons/IconHome";
import {DisplayedPagesType} from "@User/types/DisplayedPages.type";
import IconLocationMarker from "@Icons/IconLocationMarker";
import {Colors} from "@Styles/Colors.style";
import IconVoucherQr from "@Icons/voucher/IconVoucherQr";

interface TravelingDotComMobileMenuIconsProps {
    tag: DisplayedPagesType,
}

function TravelingDotComMobileMenuIcons({tag}: TravelingDotComMobileMenuIconsProps) {
    const components = {
        // TODO
        homePage: IconHome,
        // TODO
        rentBus: IconBusTab,
        // TODO
        contact: IconPencilContact,
        // TODO
        helpCenter: IconInfo,
        trvlHelpCenter: IconInfo,
        // TODO
        faq: IconInfo,
        // TODO
        specialOffer: IconVoucherQr,
        stationLocator: IconLocationMarker,
        blog: IconPenWithABoard,
        // TODO
        availableRoutes: IconLocationArrow,
        buses: IconBus,
        trains: IconTrain,
        flights: IconPlane,
        ferries: IconFerry,
        croatian_islands: IconPalmIsland,
        asian_islands: IconPalmIsland,
    };

    const IconMenu = components[tag || ''];

    return (
        <IconMenu
            width="18"
            height="18"
            fill={Colors.primaryColor}
            style={{
                verticalAlign: 'text-top',
                marginRight: '1rem',
            }}
        />
    );
}

export default TravelingDotComMobileMenuIcons