import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconChevronTriple from "@Icons/chevrons/IconChevronTriple";
import {Colors} from "@Styles/Colors.style";

interface LoadMoreRoutesProps {
    showLoadMore: boolean,
    loadMoreRoutes: () => void,
    length: number,
}

export default function LoadMoreRoutes({showLoadMore, loadMoreRoutes, length}: LoadMoreRoutesProps) {
    const showAllLabel = useTranslation("search_results.show_all_affiliate_results", {results: length})

    if (showLoadMore) {
        return (
            <div
                onClick={loadMoreRoutes}
                className="gb--row gb--row-middle gb--affiliates-results"
            >
                <div dangerouslySetInnerHTML={{
                    __html: `${showAllLabel} *`,
                }}
                />
                <IconChevronTriple
                    fill={Colors.secondaryColor}
                />
            </div>
        )
    }

    return null
}