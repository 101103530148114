import {convertArrayToObject} from "@Array/convertArrayToObject";

/**
 * Returns all dataset attributes from an element, does not require manual dataset querying
 * @param id
 * @param className
 * @param tagName
 * @param index
 * @return {object}
 */

interface DatasetParserAttributes {
    id?: string,
    className?: string,
    tagName?: string,
    index?: number,
}

export default function datasetParser({id, className, tagName, index}: DatasetParserAttributes) {
    if ((!!className || !!tagName) && typeof index === "undefined") {
        throw new TypeError(
            `Index must be defined if element is selected via tag or class, error in ${className ?? tagName}`
        );
    }
    const getters = {
        [`${!!id}`]: document.getElementById(id),
        [`${!!className}`]: document.getElementsByClassName(className)[index],
        [`${!!tagName}`]: document.getElementsByClassName(className)[index],
    }

    // @ts-ignore
    const element: HTMLElement = getters.true
    if (element) {
        const keys = Object.keys(element.dataset)
        const parsed = keys.map((key) => {
            const parsedKey = element.dataset[key].includes("{") || element.dataset[key].includes("[")
                ? JSON.parse(element.dataset[key])
                : element.dataset[key]
            return {[key]: parsedKey}
        })

        return convertArrayToObject(parsed)
    }

    return null
}
