import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconAirConditioning(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeLinecap="square"
                    strokeWidth="1.2"
                    transform="translate(1.5 1.5)"
                >
                    <path d="m.5 6.5h12"/>
                    <path d="m.5 6.5h12" transform="matrix(0 1 -1 0 13 0)"/>
                    <path
                        d="m.5 6.5h12"
                        transform="matrix(-.70710678 .70710678 -.70710678 -.70710678 15.692388 6.5)"
                    />
                    <path
                        d="m.5 6.5h12"
                        transform="matrix(.70710678 .70710678 -.70710678 .70710678 6.5 -2.692388)"
                    />
                </g>
            )}
        />
    )
}

export default IconAirConditioning