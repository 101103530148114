import React from 'react';
import {toggleLoginModal} from "@MyProfile/reducers/userProfileSlice";
import useIsMobile from "@Hooks/selector/useIsMobile";
import Translation from "@Translation/components/Translation";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import IconUserOutlineWithTrademark from "@Icons/user/IconUserOutlineWithTrademark";
import {Colors} from "@Styles/Colors.style";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import {Palette} from "@Styles/Palette.style";
import useCompanyStoreStyleForComponent from "@Features/companyStore/hooks/useCompanyStoreStyleForComponent";

function LoginButton() {
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const toggleLogin = () => {
        dispatch(toggleLoginModal())
    }

    const setClassName = () => {
        return isMobile ? "gb--column gb--column-middle" : ""
    }
    const fill = useCompanyStoreStyleForComponent('headerBackgroundColor', Palette.white)

    return (
        <button
            type="button"
            className={`${setClassName()} btn--login`}
            onClick={toggleLogin}
        >
            <IconUserOutlineWithTrademark
                width="25"
                height="25"
                fill={isMobile ? Colors.primaryColor : fill}
            />
            {' '}
            &nbsp;
            <Translation translationKey="sys.my_tickets"/>
        </button>
    );
}

export default LoginButton;