import React from 'react';

interface MTicketEcoFriendlyLabelProps {
    isSmall?: boolean,
}

const MTicketEcoFriendlyLabel = ({isSmall = false}: MTicketEcoFriendlyLabelProps) => {
    return (
        <span className="gb--ticket_label gb--emphasize-default">
            M-ticket
            {!isSmall && (
                <>
                    &nbsp;
                    eco friendly
                </>
            )}
        </span>
    );
}

export default MTicketEcoFriendlyLabel;