import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {mobileOverlaysInitialState, mobileSliceInitialState} from "@Reducers/constants/mobileSliceInitialState";

const mobileSlice = createSlice({
    name: 'mobile',
    initialState: mobileSliceInitialState,
    reducers: {
        activateDepartureStationInput: (state, action: PayloadAction<boolean>) => ({
            ...state,
            formInputSelected: action.payload,
            mobileOverlayActive: action.payload,
            departureStationInputActive: action.payload,
        }),

        activateDestinationStationInput: (state, action: PayloadAction<boolean>) => ({
            ...state,
            formInputSelected: action.payload,
            mobileOverlayActive: action.payload,
            destinationStationInputActive: action.payload,
        }),

        activateSingleCalendar: (state, action: PayloadAction<boolean>) => ({
            ...state,
            singleCalendarActive: action.payload,
            formInputSelected: action.payload,
            mobileOverlayActive: action.payload,
        }),

        activateReturnCalendar: (state, action: PayloadAction<boolean>) => ({
            ...state,
            returnCalendarActive: action.payload,
            formInputSelected: action.payload,
            mobileOverlayActive: action.payload,
        }),

        toggleMobileLogin: (state) => ({
            ...state,
            mobileLogInActive: !state.mobileLogInActive,
            mobileOverlayActive: !state.mobileOverlayActive,
        }),

        activateStsCalendar: (state, action: PayloadAction<boolean>) => ({
            ...state,
            stsCalendarActive: action.payload,
            formInputSelected: action.payload,
        }),

        toggleMobileLanguages: (state) => ({
            ...state,
            mobileLanguagesActive: !state.mobileLanguagesActive,
        }),

        activateMobileMenu: (state, action: PayloadAction<boolean>) => ({
            ...state,
            mobileMenuActive: action.payload,
            mobileOverlayActive: action.payload,
        }),

        toggleMobileMyTickets: (state) => ({
            ...state,
            mobileMyTicketsActive: !state.mobileMyTicketsActive,
            mobileOverlayActive: !state.mobileOverlayActive,
        }),

        toggleMobilePassengerPicker: (state) => ({
            ...state,
            mobilePassengerPickerActive: !state.mobilePassengerPickerActive,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.formInputSelected,
        }),

        toggleSortingOptions: (state) => ({
            ...state,
            sortingOptionsActive: !state.sortingOptionsActive,
        }),

        toggleCurrencyPicker: (state) => ({
            ...state,
            currencyPickerActive: !state.currencyPickerActive,
        }),

        toggleSorter: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.formInputSelected,
            sorterActive: !state.sorterActive,
        }),

        toggleCurrencySelect: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.formInputSelected,
            currencySelectActive: !state.currencySelectActive,
        }),

        toggleFiltersOverlay: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            formInputSelected: !state.formInputSelected,
            filtersOverlayActive: !state.filtersOverlayActive,
        }),

        activateFormAnimation: (state, action: PayloadAction<boolean>) => ({
            ...state,
            formAnimationActive: action.payload,
        }),

        dismissAllOverlays: (state) => ({
            ...state,
            additionalInfoActive: null,
            formInputSelected: false,
            ...mobileOverlaysInitialState,
        }),

        clearMobileState: () => (mobileSliceInitialState),
    },
})

export const {
    activateSingleCalendar,
    activateReturnCalendar,
    activateStsCalendar,
    toggleMobileLogin,
    toggleMobileLanguages,
    activateMobileMenu,
    toggleMobilePassengerPicker,
    toggleMobileMyTickets,
    toggleSortingOptions,
    toggleFiltersOverlay,
    toggleCurrencyPicker,
    activateDepartureStationInput,
    activateDestinationStationInput,
    toggleSorter,
    toggleCurrencySelect,
    dismissAllOverlays,
    clearMobileState,
    activateFormAnimation,
} = mobileSlice.actions

export default mobileSlice.reducer