import React, {useEffect, useRef} from "react";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import {CompanyInputFieldSuggestion} from "@Pages/companyDirectReview/components/CompanyInputFieldSuggestion";
import {useCompanyStationInput} from "@Pages/companyDirectReview/hooks/useCompanyStationInput";

interface CompanyReviewInputFieldProps {
    fieldKey: string;
    error?: boolean;
    validateForm?: () => void;
}

export const CompanyReviewInputField = (props: CompanyReviewInputFieldProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const {
        locationPlaceholder: fieldPlaceholder,
        fieldValue,
        selectedSuggestion,
        suggestionResponse,
        handleSelectStation: handleSelect,
        handleChangeStation: handleChange,
        showDropdown,
        setShowDropdown,
        data,
        setShowError,
        showError,
    } = useCompanyStationInput();

    const {fieldKey, error, validateForm} = props;

    useEffect(() => {
        if (error) {
            setShowError(true);
            inputRef?.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }, [error]);

    return (
        <div className="review-location_station react-autosuggest__container">
            {data[fieldKey] && (
                <label htmlFor={fieldKey}>
                    {data[fieldKey]}
                </label>
            )}

            <input
                ref={inputRef}
                value={fieldValue}
                onChange={handleChange}
                type="text"
                className={`gb--input-standalone select-input ui-autocomplete-input ${showError ? 'station-error' : ''}`}
                id={fieldKey}
                required
                placeholder={fieldPlaceholder}
                autoComplete="off"
                onFocus={() => setShowDropdown(true)}
                onClick={() => setShowDropdown(true)}
            />
            {showDropdown && suggestionResponse?.length > 0 && (
                <ClickOutsideAwareComponent
                    className="suggestion-container"
                    onClickOutside={() => setShowDropdown(false)}
                >
                    <ul className="react-autosuggest__suggestions-list">
                        {suggestionResponse?.map((suggestion) => (
                            <CompanyInputFieldSuggestion
                                onClick={() => handleSelect(suggestion)}
                                suggestion={suggestion}
                                showIcon
                            />
                        ))}
                    </ul>
                </ClickOutsideAwareComponent>
            )}
            <input type="hidden" id={`${fieldKey}Id`} name={`${fieldKey}Id`} value={selectedSuggestion?.value}/>
        </div>
    )
}