import convertToServerTime from "@DateTime/functions/convertToServerTime";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import {sliderMoveDirections} from "@SearchResults/features/dateSlick/constants/sliderMoveDirections";
import DaysLimitType from "@SearchResults/features/dateSlick/types/DaysLimit.type";

interface UseMoveSliderAttributes {
    daysLimit: DaysLimitType,
    centralDate: Date | string,
    setCentralDate: (value: Date) => void,
    setDaysLimit: (value: DaysLimitType) => void,
}

/**
 * Move entire slider left or right.
 */
export default function useMoveSlider({daysLimit, centralDate, setCentralDate, setDaysLimit}: UseMoveSliderAttributes) {
    function moveSlider(direction) {
        if (![sliderMoveDirections.left, sliderMoveDirections.right].includes(direction)) {
            return null;
        }

        let minDayLimit = daysLimit.min;
        let maxDayLimit = daysLimit.max;

        if (sliderMoveDirections.left === direction) {
            minDayLimit -= 1;
        }

        if (sliderMoveDirections.right === direction) {
            maxDayLimit += 1;
        }

        const centralDateObject = convertToServerTime(centralDate)
        const newCentralDate = direction === 1
            ? addDays(centralDateObject, 1)
            : subDays(centralDateObject, 1)

        setCentralDate(newCentralDate)
        setDaysLimit({
            min: minDayLimit,
            max: maxDayLimit,
        })
    }

    const moveSliderLeft = () => {
        moveSlider(sliderMoveDirections.left)
    }

    const moveSliderRight =() => {
        moveSlider(sliderMoveDirections.right)
    }

    return {
        moveSliderLeft,
        moveSliderRight,
    }
}