import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconLatestRating(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconLatestRating',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m2.58016 13 .442575-1.450855c.310105-.7986.915255-1.5606 1.80342-2.05135 1.61975-.8931 2.5862 1.018 1.39095 1.67645-1.0056.5547-2.827055-1.14-2.09245-2.9963l.0452-.14325-4.169855-3.0694h5.157305l1.5927-4.965295 1.59265 4.965295h5.157345l-4.172845 3.0694 1.5927 4.965305-2.1888-1.609355"
                    fill="none"
                    stroke="#7e00d9"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    transform="translate(1.25 1.5)"
                />

            )}
        />

    );
}

export default IconLatestRating;
