import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {VoucherTypeType} from "@MyVoucher/types/VoucherType.type";
import getRedeemCompanies from "@MyVoucher/functions/getRedeemCompanies";

interface VoucherAmountSectionProps {
    storesList: string[],
    voucherAmount: string,
    voucherType: VoucherTypeType,
}

function VoucherAmountSection({storesList, voucherAmount, voucherType}: VoucherAmountSectionProps) {
    const {
        promo: promoLabel,
        rebook: rebookLabel,
        valid_on_company_stores: validOnCompanyStoresLabel,
        total_voucher_amount: totalVoucherAmountLabel,
    } = useTranslation('my_profile.my_vouchers')

    return (
        <Row justify className="voucher-amount-section">
            <Column>
                <div className="gb--emphasize-info payment-title">{totalVoucherAmountLabel}</div>
                {storesList && (
                    <div>
                        {`${validOnCompanyStoresLabel} ${getRedeemCompanies(storesList)}`}
                    </div>
                )}
            </Column>
            <Column alignEnd>
                <div className="gb--emphasize-default payment-amount">{voucherAmount}</div>
                <div>
                    {voucherType === "promo" ? promoLabel : rebookLabel}
                </div>
            </Column>
        </Row>
    );
}

export default VoucherAmountSection