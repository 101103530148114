import React from 'react';
import Sort from "../../../components/Sort";
import useCurrency from "@Currency/hooks/useCurrency";
import useValidCurrenciesList from "@Currency/hooks/useValidCurrenciesList";
import {useChangeCurrency} from "@Currency/hooks/useChangeCurrency";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconChevron from "@Icons/chevrons/IconChevron";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {toggleCurrencySelect} from "@Reducers/mobileSlice";

function CurrencySelect() {
    const changeCurrency = useChangeCurrency()
    const selectedCurrency = useCurrency()
    const currencyOptions = useValidCurrenciesList()
    const isMobile = useIsMobile()

    const dispatch = useAppDispatch()

    const fingerMe = () => {
        dispatch(toggleCurrencySelect())
    }

    if (isMobile) {
        return (
            <button
                type="button"
                className="currency-picker__selected-currency"
                onClick={fingerMe}
            >
                {selectedCurrency.sign}
                <IconChevron
                    chevronOrientation="down"
                    width="12"
                    height="12"
                />
            </button>
        )
    }

    return (
        <Sort
            onSelect={changeCurrency}
            selectedLabel={`${selectedCurrency?.sign ?? selectedCurrency?.val}`}
            selected={selectedCurrency}
            sortingOptions={currencyOptions}
            value="val"
            label="val"
            className="currency-picker"
        />
    );
}

export default CurrencySelect;