import LoaderButton from "@Generic/loaders/LoaderButton";
import LoginButton from "@CheckOrder/components/MainSection/BuyerInfo/LoginButton";
import LogoutButton from "@CheckOrder/components/MainSection/BuyerInfo/LogoutButton";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function UserCTAButton() {
    const {user, loading} = useAppSelector((state) => state?.userProfile)

    if (user?.email) {
        return (
            <LogoutButton/>
        )
    }

    if (loading) {
        <LoaderButton fill="#4a79e2"/>
    }

    return (
        <LoginButton/>
    )

}

export default UserCTAButton;