import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconDiscount(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth="1.2">
                    <path d="m3.294505 12.486696 9-9" strokeLinecap="square"/>
                    <ellipse cx="4.389589" cy="4.430055" rx="1.340289" ry="1.278255"/>
                    <ellipse cx="11.61042" cy="11.56985" rx="1.340289" ry="1.278255"/>
                </g>
            )}
        />
    )
}

export default IconDiscount