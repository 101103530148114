import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Returns full filepath for provided shared image path
 */
export default function useSharedImagesPathAsMethod() {
    const assetsBaseUrl = useAppSelector((state) => state?.page.assetsBaseUrl)
    const getPath = (filePath: string) => {
        return `${assetsBaseUrl}/apps/shared-images/${window.sharedImagesVersion}/${filePath}`
    }

    return {
        getPath,
    }
}