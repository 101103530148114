import React from 'react';
import FaqPortal from "@Features/faq/components/FaqPortal";
import TableOfContentsPortal from "@Features/tableOfContents/components/TableOfContentsPortal";

const IslandPage = () => {
    return (
        <>
            <FaqPortal />
            {window.tableOfContents && (<TableOfContentsPortal/>)}
        </>
    )
}

export default IslandPage;