import React from 'react'
import Row from "@Containers/components/Row";
import useIsMobile from "@Hooks/selector/useIsMobile";
import SourcedImage from "@Images/components/SourcedImage";
import Column from "@Containers/components/Column";
import IconGreenTravel from "@Icons/home/IconGreenTravel";
import TravelingDotComLogoMark from "@Generic/logotypes/TravelingDotComLogoMark";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import Translation from "@Translation/components/Translation";

const TravelingDotComMTicketSection = () => {
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()

    return (
        <div className="m-ticket-background">
            <Row
                justify
                gap
                wrap
                center
                className="gb-container mobile-app-landing-page-section m-ticket-section"
            >
                <Column className="m-ticket-content">
                    <IconGreenTravel
                        width={isMobile ? "30" : "70"}
                        height={isMobile ? "40" : "52"}
                    />
                    <span className="feature-card-title feature-card-title-large">
                        <Translation translationKey="mobile_app.m_ticket_section.intro"/>
                    </span>
                    <h1 className="m-ticket-title">
                        <Translation translationKey="mobile_app.m_ticket_section.title"/>
                    </h1>
                    <p>
                        <Translation translationKey="mobile_app.m_ticket_section.description"/>
                    </p>
                </Column>

                <Row
                    center
                    className="m-ticket-img-container"
                >
                    <TravelingDotComLogoMark
                        className="m-ticket-img-icon"
                        viewBox="0 0 50 50"
                        width={(isMobile || isSmallTablet) ? 500 : 600}
                        height={(isMobile || isSmallTablet) ? 500 : 600}
                    />
                    <SourcedImage
                        filePath="images/mobile-app-landing-page/m_ticket_mobile_in_hand"
                        fileType="png"
                        className="m-ticket-img"
                    />
                </Row>
            </Row>
        </div>
    )
}

export default TravelingDotComMTicketSection