import React from 'react';
import IconChevron from "@Icons/chevrons/IconChevron";
import NationalityType from "@CheckOrder/types/Nationality.type";
import CountryFlag from "@CheckOrder/components/MainSection/BuyerInfo/CountryFlag";
import IconGlobe from "@Icons/checkOrder/IconGlobe";
import {Colors} from "@Styles/Colors.style";

interface SelectedCountryIconProps {
    selectedCountry: NationalityType | string,
}

function SelectedCountryIcon({selectedCountry}: SelectedCountryIconProps) {
    if (typeof (selectedCountry) !== "string" && selectedCountry?.isoCode) {
        return (
            <>
                <CountryFlag isoCode={selectedCountry.isoCode}/>
                <IconChevron fill="#070c63" width="30" height="15"/>
            </>
        )
    }

    return (
        <>
            <IconGlobe
                width="32"
                height="32"
            />
            <IconChevron
                fill={Colors.primaryColor}
                width="30"
                height="15"
            />
        </>
    )
}

export default SelectedCountryIcon;