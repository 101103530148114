import {FetchBaseQueryError} from "@reduxjs/toolkit/query"
import {SerializedError} from "@reduxjs/toolkit"
import SourcedImage from "@Images/components/SourcedImage"
import React from "react"
import Column from "@Containers/components/Column"
import Translation from "@Translation/components/Translation"
import Notification from "@Features/notification/components/Notification"
import Button from "@Generic/buttons/Button";
import NotificationExpandable from "@Features/notification/components/NotificationExpandable"

type SearchResultsErrorProps = {
    error: FetchBaseQueryError | SerializedError
}

function SearchResultsError({error}: SearchResultsErrorProps) {
    if ("status" in error) {
        const errorMessage = "error" in error ? error.error : JSON.stringify(error.data)

        return (
            <Column center justify>
                <h1>
                    <Translation translationKey="sys.there_has_been_an_error" />
                </h1>
                <SourcedImage
                    filePath="images/content/img-11"
                    fileType="png"
                    alt="no results"
                />
                <div style={{margin: 10}}>
                    <NotificationExpandable
                        title="Something went wrong while trying to load search results, please retry"
                    >
                        <>
                            <br/>
                            <Notification isLarge>
                                {errorMessage}
                            </Notification>
                        </>
                    </NotificationExpandable>
                </div>
                <Button
                    style={{margin: 10}}
                    onClick={() => window.location.reload()}
                    className="green"
                >
                    Retry
                </Button>
                <small>
                    Or go back to the
                    {' '}
                    <a href="/">
                        home page
                    </a>
                </small>
            </Column>
        )
    }

    return (
        <Column center justify>
            <h1>
                <Translation translationKey="sys.there_has_been_an_error" />
            </h1>
            <SourcedImage
                filePath="images/content/img-11"
                fileType="png"
                alt="no results"
            />
            <NotificationExpandable title="Error">
                {error.message}
            </NotificationExpandable>
        </Column>
    )
}

export default SearchResultsError