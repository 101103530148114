import React from 'react';
import LoginButton from "./LoginButton";
import MyTicketsDropdown from "./MyTicketsDropdown";
import useIsMobile from "@Hooks/selector/useIsMobile";
import LoaderButton from "@Generic/loaders/LoaderButton";
import useUser from "@User/hooks/useUser";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MyProfileLink from "@User/components/MyProfileLink";

/**
 * Show my tickets button. Display my tickets text with log in modal trigger if user is not logged in, otherwise show
 * link to my profile.
 */
function MyTicketsButton() {
    const isMobile = useIsMobile()
    const userLoading = useAppSelector((state) => state?.userProfile.loading)
    const user = useUser()

    if (!user?.email) {
        if (userLoading) {
            return (
                <LoaderButton fill="#7ed321"/>
            )
        }

        return (
            <LoginButton/>
        );
    }

    if (isMobile) {
        return (
            <MyProfileLink
                className="gb--column gb--column-middle"
                showIcon
            />
        );
    }

    return (
        <MyTicketsDropdown/>
    )
}

export default MyTicketsButton;
