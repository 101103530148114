import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useSearchResultsCount from "@SearchResults/hooks/useSearchResultsCount";

export default function useGetActiveResultsCountLabel() {
    const {
        results: resultsLabel,
    } = useTranslation("sys")

    const {
        filteredRoutesCount,
    } = useAppSelector((state) => state.filter)
    const totalRoutesCount = useSearchResultsCount()

    const filteredCountLabel = useTranslation("sts_page.showing", {
        count: filteredRoutesCount,
        total: totalRoutesCount,
    })

    const routesFiltered = totalRoutesCount !== filteredRoutesCount

    return routesFiltered
        ? filteredCountLabel
        : `${filteredRoutesCount} ${resultsLabel}`
}