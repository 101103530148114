import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconTimes from "@Icons/controls/IconTimes";

interface CloseModalButtonProps {
    closeModal: () => void,
    additionalEffect?: () => void,
}

function ModalCloseButton(props: CloseModalButtonProps) {
    const {
        closeModal,
        additionalEffect = () => {
        },
    } = props

    const closeTextLabel = useTranslation("my_profile.my_tickets.close")
    const isMobile = useIsMobile()

    return (
        <button
            type="button"
            onClick={() => {
                closeModal()
                additionalEffect()
            }}
            className={`${isMobile ? "gb-modal-title--action" : "btn--dismiss btn close-button"}`}
        >
            {isMobile ? <IconTimes/> : closeTextLabel}
        </button>
    );
}

export default ModalCloseButton