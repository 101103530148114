import React from 'react';
import Button from "@Generic/buttons/Button";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface LoadMoreButtonProps {
    onClick: () => any
}

function LoadMoreButton({onClick}: LoadMoreButtonProps) {
    const loadMore = useTranslation("c_profile.load_more")

    return (
        <Button
            onClick={onClick}
            className="green-outline"
            style={{width: '100%'}}
        >
            {loadMore}
        </Button>
    );
}

export default LoadMoreButton;