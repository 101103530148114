import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconPen({width = "16", height = "16", viewBox = "0 0 16 16", fill = Colors.primaryColor, ...rest}: IconType) {
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m9.98016394 2.81048218c.67691046-.68917065 1.79034706-.69619293 2.47646536-.0160955l.7874841.78057611c.689066.6830024.6819439 1.79583331-.014067 2.46765472-2.3497563 2.29003024-2.3497563 2.29003024-3.84505572 3.74732221-.42033038.40964648-.69516178.67749218-.95400429.92975538l-.17161417.1672521c-.43021982.4192845-.89597051.8731968-1.9968358 1.9460803-.07794166.0759606-.1750765.1292908-.28100309.1542801l-3.00003.70774c-.43505136.1026333-.82554422-.2894145-.72118269-.7240545l.713999-2.97363001c.02563947-.10678208.08009341-.20447945.15742883-.28244756zm1.63168426.83616316c-.2140536-.21217523-.5647149-.20996366-.7776408.00680847l-6.73246898 6.78646849-.447 1.863 1.889-.446.09314452-.0897714c1.63015705-1.5887217 1.73674971-1.692605 2.77567282-2.7051208l.13489893-.13147007c1.49529941-1.45729197 1.49529941-1.45729197 3.84712461-3.74932886.2136851-.20626151.2158515-.54476876.0047582-.75400451z"
                    fill={fill}
                />
            )}
        />
    )
}

export default IconPen