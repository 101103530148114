import React from "react"
import Translation from "@Translation/components/Translation"
import useIsMobile from "@Hooks/selector/useIsMobile"
import useSingleSelectedRoute from "@Route/hooks/useSingleSelectedRoute"
import useConfirmSingleRoute from "@Route/hooks/useConfirmSingleRoute"
import {useNavigate} from "react-router-dom"
import {useLocation} from "react-router"
import useBuyTicketButtonLabelConfigurator
    from "@Route/features/ticketPurchase/hooks/useBuyTicketButtonLabelConfigurator"
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type"

interface ConfirmButtonProps {
    isApi: boolean,
    route: RouteWithAddedPropsAndPricesType,
}

function ConfirmButton({isApi, route}: ConfirmButtonProps) {
    const isMobile = useIsMobile();
    const singleSelectedRoute = useSingleSelectedRoute()
    const {confirmSingleRoute} = useConfirmSingleRoute()
    const navigate = useNavigate()
    const {search} = useLocation()
    const {buyFromLabel, showBuyFromLabel} = useBuyTicketButtonLabelConfigurator(route)

    const confirmRoute = () => {
        if (!isMobile && singleSelectedRoute) {
            navigate(`..${search}`)
        } else {
            confirmSingleRoute(route)
        }
    }

    const buttonColor = (!isApi || showBuyFromLabel) ? 'btn--blue' : 'btn--orange'
    return (
        <button
            type="submit"
            className={`btn ${buttonColor} ${isMobile ? 'btn--mobile' : ''} `}
            onClick={confirmRoute}
        >
            {isMobile ? (
                <>
                    <Translation translationKey="sys.confirm"/>
                    &nbsp;
                    <Translation translationKey="sys.outbound"/>
                </>
            ) : (
                <span>
                    <Translation
                        translationKey={!singleSelectedRoute ? "search_results.select_single_route" : "search_results.change"}
                        styleText="firstUpper"
                    />
                </span>
            )}
        </button>
    )
}

export default ConfirmButton