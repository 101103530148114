import React from 'react';
import IconChevron from "@Icons/chevrons/IconChevron";
import useGoBack from "@Routing/hooks/useGoBack";
import IconTimes from "@Icons/controls/IconTimes";
import {Palette} from "@Styles/Palette.style";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface BackButtonProps {
    backClick?: (any?) => any,
    icon?: 'times' | 'back'
}

const BackButton = ({backClick, icon = 'back'}: BackButtonProps) => {
    const {goBack: defaultBackClick} = useGoBack()
    const goBack = backClick ? () => backClick() : () => defaultBackClick()
    const isCheckOrder = useRouteMatch('checkOrder')
    const isMobile = useIsMobile()

    const getButtonColor = () => {
        if (!isCheckOrder || (isCheckOrder && isMobile)) {
            return Palette.white
        }
        return Palette.jet
    }

    return (
        <button onClick={() => goBack()} type="submit">
            {icon === 'back' ? (
                <IconChevron
                    // TODO end me, end this. Why is this even the same component? It looks the same but should be more adjustable
                    chevronOrientation="left"
                    height={isCheckOrder ? "32" : "16"}
                    width={isCheckOrder ? "32" : "16"}
                    fill={getButtonColor()}
                />
            ) : (
                <IconTimes
                    height="16"
                    width="16"
                    fill={getButtonColor()}
                />
            )}
        </button>
    )
}

export default BackButton;