import React from 'react';
import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import CompanyLogo from "@Route/features/company/components/CompanyLogo";
import useIsMobile from "@Hooks/selector/useIsMobile";
import CompanyType from "@SearchResults/types/company/Company.type";
import IconStar from "@Icons/searchRes/IconStar";

interface CompanyRatingProps {
    displayedValue?: 'name' | 'letterCode',
    rating?: string,
    company?: CompanyType,
    highlight?: boolean,
    hideLetterCode?: boolean,
    displayCompanyLogo?: boolean,
    customMainCompanyName?: string,
    displayRating?: boolean,
}

/**
 * Displays company rating with background color based on reviews
 */
function CompanyRating(props: CompanyRatingProps) {
    const {
        company,
        rating,
        hideLetterCode,
        highlight,
        displayedValue = "name",
        displayCompanyLogo = false,
        displayRating = true,
        customMainCompanyName,
    } = props

    const isMobile = useIsMobile()

    if (company) {
        const {letterCode} = company;
        let mainCompanyLetterCodeColor = 'default'

        if (highlight) {
            mainCompanyLetterCodeColor = 'info';
        }

        return (
            <Column className="company-rating">
                {!hideLetterCode && (
                    <span className={`gb--truncate gb--company-code gb--emphasize-${mainCompanyLetterCodeColor}`}>
                        {displayedValue === 'name' ? (customMainCompanyName ?? company?.name) : (letterCode ?? letterCode)}
                    </span>
                )}
                <Row justify={isMobile} center>
                    {rating && rating !== '0' && displayRating && (
                        <Row center className="company-rating__score">
                            <IconStar/>
                            {rating}
                        </Row>
                    )}
                    {displayCompanyLogo && company?.id && (
                        <CompanyLogo
                            companyId={company.id}
                            companyName={company?.name}
                            logoUrl={company.logoUrl}
                        />
                    )}
                </Row>
            </Column>
        );
    }

    return null
}

export default CompanyRating
