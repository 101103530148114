import GenericSvgIcon from "@Icons/GenericSvgIcon";
import {Colors} from "@Styles/Colors.style";
import IconType from "@/types/Icon.type";

/**
 * Validation type: Positive
 * description: Checkmark with a circle around it
 */
function IconCheckmarkEncircled(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <svg xmlns="http://www.w3.org/2000/svg">
                    <g fill={fill}>
                        <path
                            d="m7.6991735 0v1.3983471c-3.4798503 0-6.3008264 2.8209761-6.3008264 6.3008264 0 3.4798505 2.8209761 6.3008265 6.3008264 6.3008265 3.4798505 0 6.3008265-2.820976 6.3008265-6.3008265h1.398347c0 4.2521365-3.447037 7.6991735-7.6991735 7.6991735-4.252136 0-7.6991735-3.447037-7.6991735-7.6991735 0-4.252136 3.4470375-7.6991735 7.6991735-7.6991735z"
                            transform="translate(.300827 .300827)"
                        />
                        <path
                            d="m3.5769231 9.1153841 7.9999999-7.9999995-1.115385-1.1153846-6.8846149 6.8461537-2.4615385-2.4615383-1.1153846 1.1538463z"
                            transform="translate(3.61538 2.07692)"
                        />
                    </g>
                </svg>
            )}
        />
    )
}

export default IconCheckmarkEncircled