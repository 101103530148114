import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconVoucherQr(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        title = "IconVoucher",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <>
                    <path
                        d="m8.8041973 5.1160226-1.3509884 1.9624429c-.5049548.7334537-1.4746332.9448328-2.1749034.4627266l-4.63556591-3.1912733c-.70031784-.4821062-.84893968-1.4633803-.34399509-2.1968343l1.3509827-1.96244286c.1334242-.19384297.3819155-.25087419.5723228-.11980244.1904075.13107177.2258055.38357845.0923812.57742139l-1.35098274 1.96244291c-.24484711.3556683-.16953307.8157096.15923804 1.0420441l4.6355609 3.1912737c.3287549.2263341.7854213.1324935 1.0302367-.223175l1.3509879-1.9624429c.13344-.1938429.3819418-.250874.57236-.1198025.1903649.1311245.2257529.3835783.0923653.5774217z"
                        fill={fill}
                        transform="translate(1.08438 7.79973)"
                    />
                    <path
                        d="m8.8041973 5.1160226-1.3509884 1.9624429c-.5049548.7334537-1.4746332.9448328-2.1749034.4627266l-4.63556591-3.1912733c-.70031784-.4821062-.84893968-1.4633803-.34399509-2.1968343l1.3509827-1.96244286c.1334242-.19384297.3819155-.25087419.5723228-.11980244.1904075.13107177.2258055.38357845.0923812.57742139l-1.35098274 1.96244291c-.24484711.3556683-.16953307.8157096.15923804 1.0420441l4.6355609 3.1912737c.3287549.2263341.7854213.1324935 1.0302367-.223175l1.3509879-1.9624429c.13344-.1938429.3819418-.250874.57236-.1198025.1903649.1311245.2257529.3835783.0923653.5774217z"
                        fill="none"
                        stroke={fill}
                        strokeMiterlimit="10"
                        strokeWidth=".19"
                        transform="translate(1.08438 7.79973)"
                    />
                    <path
                        d="m6.5697279 10.095694 3.3774433-4.9061074c.2448678-.3556685.1695118-.8157363-.1592436-1.0420756l-.6320228-.4351067c-.6508761.6237254-1.6414595.7501001-2.3784867.242717-.7370271-.5073936-.9725217-1.4778459-.6221757-2.3084638l-1.0028538-.69040433c-.3287554-.2263397-.7854214-.13248831-1.0302897.22318533l-3.3774331 4.9061123c-.13342422.1937904-.38191542.250874-.5723281.11975-.19040741-.131072-.22580009-.3835258-.09237587-.5773687l3.37746467-4.90610203c.5049551-.73348051 1.4745803-.94486992 2.1749034-.46274808l1.2979598.89355241c.1904178.1310823.2258053.3835732.0923653.5773845-.3529262.512665-.2423935 1.1994724.2347574 1.5279312.477098.3284535 1.1580949.1865863 1.5110212-.3260784.13344-.1938115.3819418-.2508691.57236-.1197815l.9270764.6382496c.700323.4821217.848928 1.4633589.344027 2.1968656l-3.3774967 4.9061066c-.1333876.193791-.3818894.250875-.5723071.11975-.1904183-.131072-.2258053-.383526-.0923653-.577368z"
                        fill={fill}
                        transform="translate(4.00501 .4)"
                    />
                    <path
                        d="m6.5697279 10.095694 3.3774433-4.9061074c.2448678-.3556685.1695118-.8157363-.1592436-1.0420756l-.6320228-.4351067c-.6508761.6237254-1.6414595.7501001-2.3784867.242717-.7370271-.5073936-.9725217-1.4778459-.6221757-2.3084638l-1.0028538-.69040433c-.3287554-.2263397-.7854214-.13248831-1.0302897.22318533l-3.3774331 4.9061123c-.13342422.1937904-.38191542.250874-.5723281.11975-.19040741-.131072-.22580009-.3835258-.09237587-.5773687l3.37746467-4.90610203c.5049551-.73348051 1.4745803-.94486992 2.1749034-.46274808l1.2979598.89355241c.1904178.1310823.2258053.3835732.0923653.5773845-.3529262.512665-.2423935 1.1994724.2347574 1.5279312.477098.3284535 1.1580949.1865863 1.5110212-.3260784.13344-.1938115.3819418-.2508691.57236-.1197815l.9270764.6382496c.700323.4821217.848928 1.4633589.344027 2.1968656l-3.3774967 4.9061066c-.1333876.193791-.3818894.250875-.5723071.11975-.1904183-.131072-.2258053-.383526-.0923653-.577368z"
                        fill="none"
                        stroke={fill}
                        strokeMiterlimit="10"
                        strokeWidth=".19"
                        transform="translate(4.00501 .4)"
                    />
                    <g fill={fill}>
                        <path
                            d="m.00511031 1.8740139c-.01216442.06809-.00173777.140182.02201181.2029533.07335515.1770442.28215136.2495575.45877244.175833l3.55259004-1.58818522c.1765685-.07372457.2491336-.28320769.1757784-.46019933-.0733548-.1770443-.2821513-.24955769-.4587722-.17583311l-3.55259017 1.58818536c-.102634.0518704-.17746363.1438155-.19779032.257246z"
                            transform="translate(6.82019 5.51545)"
                        />
                        <path
                            d="m.01446776.75980115c-.08936377.49911535.24465759.98037885.74224033 1.06948025.49763541.0891014.97789301-.2463454 1.06725671-.7454607.0893638-.49911541-.2446575-.98032621-.7422403-1.06948029-.4976354-.08910142-.97789298.24639805-1.06725674.74546074zm1.13092254.20253193c-.0223278.12475252-.1423922.20864062-.26677474.18636522-.12443519-.0222754-.20795363-.1426043-.18557322-.2673569.02232772-.12480515.14239216-.20864058.26677465-.18636519.12443521.02227533.20795371.14255172.18557331.26735687z"
                            transform="translate(9.07289 7.03468)"
                        />
                        <path
                            d="m.01446768.75980943c-.08936373.49906277.24465758.98032627.74229298 1.06942767.49758274.0891013.97784034-.2463454 1.06720414-.7454082.0893637-.49911532-.2446576-.98038406-.7422403-1.06948547-.4976354-.08910668-.97789308.24634538-1.06725682.745466zm1.13092252.20247931c-.0223277.12480516-.1423922.20864056-.2667747.18636516-.12443519-.0222754-.207901-.1426044-.18557322-.26735691.02232778-.12475252.14239216-.20864051.26682734-.18636518.12438248.02227538.20790098.14260441.18552058.26735693z"
                            transform="translate(6.9706 4.43398)"
                        />
                    </g>
                </>
            )}
        />
    )
}

export default IconVoucherQr