import React from 'react';
import useFormMethods from "@Form/hooks/useFormMethods";
import IconArrowAirplaneSwitch from "@Icons/arrows/IconArrowAirplaneSwitch";
import useCompanyStoreStyleForComponent from "@Features/companyStore/hooks/useCompanyStoreStyleForComponent";

function TravelingDotComStationSwitchButton() {
    const {toggleStationHandler} = useFormMethods()
    const style = useCompanyStoreStyleForComponent('buttonBackgroundColor1', null)

    return (
        <div className="gb--search__select--switch">
            <button
                type="button"
                onClick={toggleStationHandler}
                style={style ? {backgroundColor: style} : {}}
            >
                <IconArrowAirplaneSwitch
                    width={20}
                    height={20}
                />
            </button>
        </div>
    );
}

export default TravelingDotComStationSwitchButton;