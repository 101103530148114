import React, {Component, ReactNode} from "react";
import getClientProperties from "@/features/errors/functions/getClientProperties";
import intToBool from "@Number/intToBool";
import Modal from "@Generic/modal/Modal";
import TripleDotLoader from "@Generic/loaders/TripleDotLoader";
import postError from "@Errors/functions/postError";
import {connect} from "react-redux";

interface ErrorBoundaryProps {
    shouldReload?: boolean,
    experiments: string,
    userId?: number,
    children?: ReactNode,
    errorModal?: ReactNode,
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    async componentDidCatch(error, info) {
        const {
            experiments,
            userId,
        } = this.props

        const clientProperties = getClientProperties()
        this.setState({hasError: true});

        // Temporary silencing of irrelevant errors pending proper fix with new redesign
        const loggingEnabled = intToBool(window.clientErrorLoggingEnabled)
        const chunkError = error?.message?.includes("Loading chunk")
        const isGoogleBot = clientProperties?.userAgent?.includes('AdsBot-Google')

        if (loggingEnabled && !isGoogleBot && !chunkError) {
            await postError({
                error,
                info,
                experiments,
                userId,
            })()
        }

        if (this.props.shouldReload) {
            // Prevent infinite crash loop
            if (!!sessionStorage.getItem('crashed') === false) {
                window.location.reload();
            }
        }
    }

    render() {
        if (this.state.hasError && !this.props.shouldReload) {
            return null
        }

        if (!!sessionStorage.getItem('crashed') && this.state.hasError) {
            return (
                <Modal
                    message="Please reload the page"
                    buttonText="OK"
                    title="Something went wrong"
                    handleClick={() => {
                        sessionStorage.removeItem('crashed')
                        window.location.href = `//${window.location.hostname}`
                    }}
                />
            )
        }

        if (this.state.hasError && this.props.errorModal) {
            return (
                <>
                    {this.props.errorModal}
                </>
            )
        }

        if (this.state.hasError) {
            sessionStorage.setItem('crashed', 'true')
            return (
                <div>
                    <TripleDotLoader/>
                </div>
            )
        }

        return this.props.children
    }
}

function mapStateToProps(state) {
    return {
        experiments: state.page.experiments,
        userId: state.userProfile.user?.id,
    };
}

export default connect(mapStateToProps)(ErrorBoundary);