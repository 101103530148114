import React, {useEffect, useState} from 'react';
import useDismissAllOverlays from "@MobileOverlays/hooks/useDismissAllOverlays";
import {useTranslation} from "@Translation/hooks/useTranslation";
import SignIn from "./SignIn";
import ModalHeader from "@Generic/modal/ModalHeader";
import ModalBody from "@Generic/modal/ModalBody";
import SignUp from "./SignUp";
import UserModalTitle from "@User/components/UserModalTitle";
import Dialog from "@Features/dialog/components/Dialog";
import useDialog from "@Features/dialog/hooks/useDialog";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function UserAccount() {
    const dismissAllOverlays = useDismissAllOverlays()
    const toggleModal = () => () => dismissAllOverlays()
    const [activeContent, setActiveContent] = useState('signIn')
    const {loginModalActive} = useAppSelector((state) => state.userProfile)
    const {
        sign_in: signIn,
        have_account: haveAccount,
    } = useTranslation('user')

    const {dialogRef, openModal, closeModal} = useDialog()

    useEffect(() => {
        if (loginModalActive) {
            openModal()
        } else {
            closeModal()
        }
    }, [loginModalActive])

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                dismissAllOverlays()
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const userLoginModalTitle = useTranslation("user.login_modal.title")
    const userLoginModalDescription = useTranslation("user.login_modal.description")
    const userLoginModalSubtitle = useTranslation("user.login_modal.subtitle")
    const userLoginModalSubtitleCta = useTranslation("user.login_modal.subtitle_cta")
    const userRegisterModalTitle = useTranslation("user.register_modal.title")
    const userRegisterModalSubtitle = useTranslation("user.register_modal.subtitle")


    const setTitle = () => {
        if (activeContent === 'signIn') {
            return (
                <UserModalTitle
                    title={userLoginModalTitle}
                    subtitle={userLoginModalSubtitle}
                    onClick={() => setActiveContent('signUp')}
                    switchToContent={userLoginModalSubtitleCta}
                />
            )
        }

        if (activeContent === 'signUp') {
            return (
                <UserModalTitle
                    title={userRegisterModalTitle}
                    titleSuffix={userRegisterModalSubtitle}
                    subtitle={haveAccount}
                    onClick={() => setActiveContent('signIn')}
                    switchToContent={signIn}
                />
            )
        }
    }

    const setContent = () => {
        if (activeContent === 'signIn') {
            return <SignIn/>
        }

        if (activeContent === 'signUp') {
            return <SignUp/>
        }
    }

    return (
        <Dialog dialogRef={dialogRef} className="user-account__modal">
            <ModalHeader
                closeAction={toggleModal()}
                title={setTitle()}
            />
            <ModalBody
                message={setContent()}
            />
        </Dialog>
    )
}

export default UserAccount;