import SourcedImage from "@Images/components/SourcedImage";
import React from "react";
import IconMobileApp from "@Icons/IconMobileApp";
import Translation from "@Translation/components/Translation";
import Grid from "@Containers/components/Grid";
import AppStoreLinksContainer from "@Sidebar/components/AppStoreNotice/AppStoreLinksContainer";

type MobileAppBannerProps = {}

function MobileAppPromoSection(props: MobileAppBannerProps) {
    return (
        <section className="mobile-app-promo-section">
            <Grid min="350px">
                <div className="mobile-app-promo-content">
                    <IconMobileApp/>
                    <h4>
                        <Translation translationKey="home_page.mobile_app_section.prefix"/>
                    </h4>
                    <h2>
                        <Translation translationKey="home_page.mobile_app_section.title"/>
                    </h2>
                    <p>
                        <Translation translationKey="home_page.mobile_app_section.description"/>
                    </p>
                    <AppStoreLinksContainer/>
                </div>
                <SourcedImage
                    filePath="images/homepage/mobile-section-hero"
                    fileType="png"
                    className="mobile-app-promo-hero"
                />
            </Grid>
        </section>
    );
}

export default MobileAppPromoSection;