import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Standard outlined (not filled) user icon with "v" trademark
 */
function IconUserOutlineWithTrademark({width = "16", height = "16", viewBox = "0 0 16 16", fill = Colors.primaryColor, ...rest}: IconType) {
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    clipRule="evenodd"
                    d="m7.92839 1.6322c-1.70741 0-3.09053 1.38312-3.09053 3.09053s1.38312 3.09053 3.09053 3.09053 3.09051-1.38312 3.09051-3.09053-1.3831-3.09053-3.09051-3.09053zm-2.09053 3.09053c0-1.15512.93541-2.09053 2.09053-2.09053 1.15513 0 2.09051.93541 2.09051 2.09053 0 1.15513-.93538 2.09053-2.09051 2.09053-1.15512 0-2.09053-.9354-2.09053-2.09053zm3.20356 4.91531c-.26816-.1658-.53832-.30604-.7948-.41847-1.67546-.74188-3.26182-.43962-4.38859.50605-1.11162.93298-1.73709 2.45718-1.60914 4.10278l.99699-.0776c-.10521-1.353.41239-2.552 1.25501-3.2592.82725-.69429 2.00591-.94905 3.3416-.3573l.00187.0008c.10667.0467.2156.099.32522.1563-.373.4351-.60201 1.0186-.58558 1.7415l.00002.0008c.01426.5848.16306 1.3215.71194 1.8261l.00089.0008c.41821.3826 1.03053.5609 1.5784.4831.28125-.0399.56695-.1506.80105-.3601.2387-.2136.3975-.5068.4531-.8606l-.4939-.0782.494.0775c.1548-.9791-.2748-1.829-.8543-2.4724-.0834-.0927-.1709-.1822-.2616-.2684.6832-.1037 1.4527.104 1.9725.6214l.0121.0122.0066.0065.0067.0063c.3825.3552.645.8088.8285 1.2646l.9276-.3735c-.2171-.5392-.5484-1.1318-1.0686-1.6173l-.0066-.0067-.0008-.0008c-.8522-.84827-2.1163-1.10962-3.16458-.8316-.16717.04434-.32996.1029-.4856.17544zm-.45769 2.37176c-.0114-.5062.15695-.8939.43758-1.1667.18423.1463.35753.3058.51089.4761.46304.5141.7005 1.0725.6095 1.6474l-.0001.0007c-.0222.1414-.0763.2211-.1321.2711-.06056.0542-.1513.0977-.27484.1153-.26148.0371-.57179-.0564-.7624-.2306-.25378-.2336-.37683-.6364-.38853-1.1133z"
                    fill={fill}
                    fillRule="evenodd"
                />
            )}
        />
    )
}

export default IconUserOutlineWithTrademark