import React from 'react';
import Column from "@Containers/components/Column";
import useCurrency from "@Currency/hooks/useCurrency";
import DiscountType from "@Route/types/prices/discounts/Discount.type";
import Row from "@Containers/components/Row";
import IconDiscount from "@Icons/searchRes/filters/IconDiscount";

interface PriceSectionProps {
    className: string,
    price: number,
}

interface ActiveDiscountProps {
    discount: DiscountType,
    style: object,
}

const ActiveDiscount = ({discount, style}: ActiveDiscountProps) => {
    const {
        discountPercentage,
        priceWithoutDiscount,
        priceWithDiscount,
        description,
    } = discount

    const {ratio, sign} = useCurrency()

    const PriceSection = ({className, price}: PriceSectionProps) => (
        <span className={className}>
            {`${parseFloat((`${price * ratio}`)).toFixed(2)} `}
            <span className="currency">
                {`${sign} `}
            </span>
        </span>
    )

    return (
        <>
            <Row center className="gb--discount__percentage">
                {discountPercentage}
                <IconDiscount
                    width="18"
                    height="18"
                />
            </Row>
            <Column className="gb--discount">
                <div className="discount__price">
                    <PriceSection
                        price={priceWithoutDiscount}
                        className="gb--emphasize gb--emphasize-inactive base__price"
                    />
                    <PriceSection
                        price={priceWithDiscount}
                        className="gb--emphasize gb--emphasize-default discounted__price"
                    />
                </div>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{__html: description}}/>
            </Column>
        </>
    )
}

export default ActiveDiscount;