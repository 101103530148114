import IconType from "@/types/Icon.type";
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import {Colors} from "@Styles/Colors.style";

/**
 * Main traveling.com ferry icon, not filled
 */
function IconFerry(props: IconType) {
    const {
        width = 16,
        height = 16,
        viewBox = "0 0 16 16",
        title = "IconFerry",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} transform="translate(1.2202 1.45)">
                    <path
                        d="m0 13.10005c.93608 0 1.69495-.7498 1.69495-1.6746 0 .9248.75887 1.6746 1.69494 1.6746.93608 0 1.69495-.7498 1.69495-1.6746 0 .9248.75887 1.6746 1.69494 1.6746.93608 0 1.69495-.7498 1.69495-1.6746 0 .9248.75886 1.6746 1.69496 1.6746s1.6949-.7498 1.6949-1.6746c0 .9248.7589 1.6746 1.695 1.6746"
                    />
                    <path
                        d="m3.10069 5.70512v-3.45961c0-.16366.13482-.29686.30047-.29686h6.73743c.1656 0 .3004.1332.3004.29686v3.45961m-3.67106-5.70512v1.95245m-3.66724 8.6395c0-.0875-.05393-.3616-.12712-.7003-.2581-1.157-.76273-2.24931-1.46767-3.21221-.05008-.06851-.02311-.16366.05393-.19791l4.42997-1.97909c.47381-.19411.77813-.65463.77813-1.16082m3.67106 7.25033c0-.0875.054-.3654.131-.7003.2581-1.157.7589-2.24931 1.4638-3.21221.0463-.06851.0231-.16366-.0539-.19791l-4.42997-1.97909c-.46996-.19411-.77814-.65463-.77814-1.16082v7.24653"
                    />
                </g>
            )}
        />
    )
}

export default IconFerry