import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconWheelchair(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(1.3733 2.0055)"
                >
                    <path
                        d="m3.93978009 4.67374144c-1.95103024 0-3.53265275 1.5816225-3.53265275 3.53265274 0 1.95103022 1.58162251 3.53265272 3.53265275 3.53265272s3.53265275-1.5816225 3.53265275-3.53265272c0-1.31728181-.72099496-2.46616623-1.78988625-3.07355462"
                    />
                    <path d="m0 0h1.64597311l.68315291 3.82254182"/>
                    <g strokeLinecap="round">
                        <path d="m8.73761235 6.27371369h1.77384275v3.43344942 1.11731109"/>
                        <path d="m8.938279 8.71653h1.573176"/>
                        <path d="m10.511455 10.824474h2.74187"/>
                        <path d="m2.096474 2.085497h4.863491"/>
                    </g>
                </g>
            )}
        />
    )
}

export default IconWheelchair