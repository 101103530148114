import React from 'react';
import GenericPopover from "@Popover/components/GenericPopover";
import IconFireTravelingDotCom from "@Icons/searchRes/IconFire";
import Translation from "@Translation/components/Translation";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import usePassengers from "@Hooks/selector/usePassengers";
import {Colors} from "@Styles/Colors.style";
import Row from "@Containers/components/Row";

interface TotalAllotmentLeftProps {
    totalAllotmentLeft: number,
}

function TotalAllotmentLeft({totalAllotmentLeft}: TotalAllotmentLeftProps) {
    const {shortName} = useCompanyStyle()
    const {passengers} = usePassengers()

    const displayOverlay = () => (
        <span className="gb--emphasize gb--emphasize-warning">
            <Translation
                translationKey="sys.only_left_on_getbybus"
                exact
                translationVariables={{
                    "<b>%count%</b>": totalAllotmentLeft,
                    "%store%": shortName,
                }}
            />
        </span>
    )

    const displayTrigger = () => (
        <Row center className="total-allotment-badge">
            <IconFireTravelingDotCom stroke={Colors.neutralDarkestColor}/>
            <Translation
                translationKey="sys.only_left"
                exact
                translationVariables={{
                    "<b>%count%</b>": totalAllotmentLeft,
                }}
            />
        </Row>
    )

    if (totalAllotmentLeft <= 5 + passengers) {
        return (
            <GenericPopover
                overlay={displayOverlay()}
                trigger={displayTrigger()}
                triggerWrapperClassName="gb--badge-red"
                id="TotalAllotmentPopover"
            />
        );
    }

    return null
}

export default TotalAllotmentLeft