import {useRebookTicketMutation} from "@MyProfile/API/myProfileApi";

export default function useRebookModal(ticketId: string, closeModal: () => void) {
    const [rebookTicket, {data, isLoading, isError}] = useRebookTicketMutation()

    const voucher = data?.voucher

    const successfulRebook = !!voucher
    const unsuccessfulRebook = voucher === null || isError || !!data?.error

    const handleTicketRebook = () => {
        rebookTicket(ticketId)
    }

    const reFetchTicketsAndCloseModal = () => {
        closeModal()
    }

    return {
        handleTicketRebook,
        voucher,
        loading: isLoading,
        resetApiState: successfulRebook
            ? reFetchTicketsAndCloseModal
            : undefined,
        successfulRebook,
        unsuccessfulRebook,
    }
}