import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Icon QR code to represent MTicket
 */
function IconMTicket(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.secondaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={fill} transform="translate(1.33 1.33)">
                    <path
                        d="m3.62727575 0h-2.41818605c-.66802176 0-1.2090897.5410706-1.2090897 1.2090897v2.41818605c0 .6680191.54106794 1.2090897 1.2090897 1.2090897h2.41818605c.6680191 0 1.2090897-.5410706 1.2090897-1.2090897v-2.41818605c0-.6680191-.5410706-1.2090897-1.2090897-1.2090897zm0 3.62727575h-2.41818605v-2.41818605h2.41818605zm-.6045515-.6045515h-1.2090897v-1.2090897h1.2090897z"
                    />
                    <path
                        d="m3.62727575 7.9803325h-2.41818605c-.66802176 0-1.2090897.541044-1.2090897 1.209103v2.4181395c0 .668059.54106794 1.209103 1.2090897 1.209103h2.41818605c.6680191 0 1.2090897-.541044 1.2090897-1.209103v-2.4181395c0-.668059-.5410706-1.209103-1.2090897-1.209103zm0 3.6272425h-2.41818605v-2.4181395h2.41818605zm-.6045515-.6045515h-1.2090897v-1.2090365h1.2090897z"
                    />
                    <path
                        d="m12.0904315 0h-2.41794c-.6679925 0-1.20897.54101075-1.20897 1.20897v2.41794c0 .6679526.5409775 1.20897 1.20897 1.20897h2.41794c.667926 0 1.20897-.5410174 1.20897-1.20897v-2.41794c0-.66795925-.541044-1.20897-1.20897-1.20897zm0 3.62691h-2.41794v-2.41794h2.41794zm-.604485-.604485h-1.20897v-1.20897h1.20897z"
                    />
                    <path d="m6.0452357 6.046047h1.2089833v1.2091695h-1.2089833z"/>
                    <path d="m8.4635215 6.046047h1.20897v1.2091695h-1.20897z"/>
                    <path d="m10.881395 6.046047h1.2089035v1.2090365h-1.2089035z"/>
                    <path d="m6.0452357 8.4628565h1.2089833v1.2091695h-1.2089833z"/>
                    <path d="m7.2544185 12.092094h1.2090365v1.2091695h-1.2090365z"/>
                    <path d="m9.672558 12.092094h1.20897v1.2091695h-1.20897z"/>
                    <path d="m12.0896335 12.092094h1.2089035v1.2090365h-1.2089035z"/>
                    <path d="m6.0452357 10.8831905h1.2089833v1.209236h-1.2089833z"/>
                    <path d="m7.2544185 7.253953h1.20897v1.20897h-1.20897z"/>
                    <path d="m9.672558 7.253953h1.20897v1.20897h-1.20897z"/>
                    <path d="m8.4635215 8.4628565h1.20897v1.20897h-1.20897z"/>
                    <path d="m7.2544185 9.6730235h1.20897v1.20897h-1.20897z"/>
                    <path d="m8.4635215 10.8831905h1.20897v1.20897h-1.20897z"/>
                    <path d="m12.090498 7.253953h1.20897v1.20897h-1.20897z"/>
                    <path d="m12.090498 9.6730235h1.20897v1.20897h-1.20897z"/>
                    <path d="m9.672558 9.6730235h1.20897v1.20897h-1.20897z"/>
                    <path d="m10.8817275 10.8831905h1.20897v1.20897h-1.20897z"/>
                    <path d="m10.8817275 8.4628565h1.20897v1.20897h-1.20897z"/>
                </g>

            )}
        />
    )
}

export default IconMTicket