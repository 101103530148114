import Portal from "@Portals/components/Portal";
import Row from "@Containers/components/Row";
import {CompanyReviewInputField} from "@Pages/companyDirectReview/components/CompanyReviewInputField";
import {CompanySelect} from "@Pages/companyDirectReview/components/CompanySelect";
import {CompanyDirectSubmitButton} from "@Pages/companyDirectReview/components/CompanyDirectSubmitButton";
import {useSubmitCompanyReview} from "@Pages/companyDirectReview/hooks/useSubmitCompanyReview";
import IconCamera from "@Icons/IconCamera";
import {ImageUpload} from "@Features/imageUpload/components/ImageUpload";
import datasetParser from "@Dataset/datasetParser";
import {CompanyReviewDatePicker} from "@Pages/companyDirectReview/components/CompanyReviewDatePicker";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import React from "react";
import Translation from "@Translation/components/Translation";

export const CompanyDirectReview = () => {
    const { submit, errors } = useSubmitCompanyReview();
    const companyUploadData = datasetParser({id: "review-company-image"})
    const ticketUploadData = datasetParser({id: "review-ticket-image"})
    const clickHereLabel = useTranslation("place_review.click_here_to_add_photo")
    const selectPhotoLabel = useTranslation("place_review.selects.photo_of_ticket")

    return (
        <>
            <Portal id="review-company-image">
                <ImageUpload
                    inputId="company"
                    boxText={(
                        <>
                            <IconCamera/>
                            {companyUploadData?.paragraph}
                        </>
                      )}
                />
            </Portal>

            <Portal id="review-date-picker">
                <CompanyReviewDatePicker error={errors?.travelDate}/>
            </Portal>

            <Portal id="review-station-portal">
                <Row>
                    <CompanyReviewInputField
                        error={errors?.departureStationId}
                        fieldKey="departureStation"
                        validateForm={submit}
                    />
                    <CompanyReviewInputField
                        error={errors?.destinationStationId}
                        fieldKey="destinationStation"
                        validateForm={submit}
                    />
                </Row>
            </Portal>

            <Portal id="review-company">
                <CompanySelect/>
            </Portal>

            <Portal id="review-ticket-image">
                <ImageUpload
                    headline={ticketUploadData?.title}
                    inputId="ticket"
                    boxText={(
                        <>
                            <IconCamera/>
                            {`${clickHereLabel} ${selectPhotoLabel}`}
                        </>
                    )}
                />
            </Portal>

            <Portal id="nonTrvlCompanyReviewButton">
                {errors?.tosAndPrivacyPolicy && (
                    <Notification
                        notificationType="orange"
                        notificationIcon={<IconExclamationCircle height="16" fill="#f5a623"/>}
                        isRow
                    >
                        <Translation translationKey="t_summary.you_must_accept"/>
                    </Notification>
                )}
                <CompanyDirectSubmitButton onSubmit={submit}/>
            </Portal>
        </>
    );
};