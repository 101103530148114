import React from 'react';
import IconDepartureTime from "@Icons/searchRes/filters/IconDepartureTime";
import {Colors} from "@Styles/Colors.style";

interface RouteTravelDurationProps {
    /** Route being rendered */
    travelDuration: string,
    showClockIcon?: boolean,
    customClassName?: string,
}

function RouteTravelDuration({travelDuration, customClassName, showClockIcon = true}: RouteTravelDurationProps) {
    return (
        <span className={(customClassName)}>
            {showClockIcon && (
                <IconDepartureTime
                    stroke={Colors.neutralDarkestColor}
                />
            )}
            {travelDuration}
        </span>
    );
}

export default RouteTravelDuration;
