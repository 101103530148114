import React from 'react';
import TravelingDotComLogo from "@Generic/logotypes/TravelingDotComLogo";
import {Colors} from "@Styles/Colors.style";
import useLogoColorConstant from "@Header/hooks/useLogoColorConstant";

function TravelingDotComLogoContainer() {
    const {displayInverseLogo} = useLogoColorConstant()

    return (
        <TravelingDotComLogo
            width={200}
            height={40}
            fill={displayInverseLogo ? Colors.neutralDarkestColor : Colors.neutralLightestColor}
            color={displayInverseLogo ? "purple" : "blue"}
        />
    )
}

export default TravelingDotComLogoContainer;