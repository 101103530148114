import React, {ReactNode} from 'react'
import Column from "@Containers/components/Column";

interface FeatureCardProps {
    icons?: ReactNode[],
    color: string,
    title: string,
    children: ReactNode
}

const FeatureCard = ({icons, color, title, children}: FeatureCardProps) => {
    return (
        <div className="feature-card">
            <div className="feature-card-icons-container">
                {icons.map((icon, index) => (
                    <Column center className="feature-card-icon" key={index}>
                        {icon}
                    </Column>
                ))}
            </div>
            <Column className="feature-card-info-container">
                <p className="gb--emphasize gb--emphasize-default feature-card-title">{title}</p>
                {children}
            </Column>
        </div>
    )
}

export default FeatureCard