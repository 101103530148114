import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconOpenLink(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconOpenLink',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m1.24740238 1.24740238h7.94881296v-1.24740238h-8.57251534c-.34445941 0-.6237.27924059-.6237.6237v12.4740024c0 .3444012.27924059.6237.6237.6237h12.4740024c.3444012 0 .6237-.2792988.6237-.6237v-6.19458842h-1.2474v5.57088842h-11.22660002zm7.76051496 7.7845482-.17312724-2.87328492-4.37622372 4.14741494-1.06235712-1.12096118 4.35775032-4.12991568-2.75125356-.32684256.18219168-1.53362484 4.41378828.52437132.64089032.07612704.0388239.64422864.2711135 4.49959752z"
                    fill="#7e00d9"
                    fillRule="evenodd"
                    transform="translate(1.1393 1.1393)"
                />
            )}
        />

    );
}

export default IconOpenLink;
