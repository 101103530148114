import {CardType} from "@/types/Card.type";
import React from "react";
import Link from "@Generic/navigation/Link";
import {NavLink} from "react-router-dom";

type BaseCardWrapperProps = {
    type: CardType,
    onClick?: () => void,
    children: React.ReactNode,
}

type StaticWrapperProps = BaseCardWrapperProps & {
    type: "static",
    cardProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

type NavLinkWrapperProps = BaseCardWrapperProps & {
    type: "nav-link",
    cardProps: React.ComponentProps<typeof NavLink>
}

type LinkWrapperProps = BaseCardWrapperProps & {
    type: "link",
    cardProps: Omit<React.ComponentProps<typeof Link>, "children">,
}

type ButtonWrapperProps = BaseCardWrapperProps & {
    type: "button",
    cardProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
}

export type CardWrapperProps = StaticWrapperProps | NavLinkWrapperProps | LinkWrapperProps | ButtonWrapperProps
export type CardWrapperWithPredefinedChildrenProps = Omit<StaticWrapperProps | NavLinkWrapperProps | LinkWrapperProps | ButtonWrapperProps, "children">

/**
 * main card wrapper
 * determines whether a card is static element, nav-link or anchor tag
 */
function CardWrapper({type, children, onClick, cardProps}: CardWrapperProps) {
    if (type === "static") {
        return (
            <div {...(cardProps ?? {})}>
                {children}
            </div>
        )
    }

    if (type === "button") {
        return (
            <button {...(cardProps ?? {})} onClick={onClick}>
                {children}
            </button>
        )
    }

    if (type === "nav-link") {
        return (
            <NavLink {...(cardProps)}>
                {children}
            </NavLink>
        )
    }

    return (
        <Link {...cardProps}>
            {children}
        </Link>
    )
}

export default CardWrapper