import React, {MutableRefObject, ReactNode} from "react";
import IconTimes from "@Icons/controls/IconTimes";
import Row from "@Containers/components/Row";

interface DialogProps {
    dialogRef: MutableRefObject<HTMLDialogElement>,
    children: ReactNode,
    className?: string,
    showCloseButton?: boolean
}

/*
 * This is a new Modal component. It can be placed and triggered anywhere from components tree and is controlled by ref
 * outside of component. The component is completely empty and its function is to wrap children (content) in modal.
 */
function Dialog({dialogRef, children, className = "", showCloseButton}: DialogProps) {
    return (
        <dialog
            ref={dialogRef}
            className={`gb--dialog ${className}`}
        >
            {showCloseButton && (
                <Row reverse>
                    <button
                        type="submit"
                        onClick={() => dialogRef.current.close()}
                        style={{margin: 'unset'}}
                    >
                        <IconTimes/>
                    </button>
                </Row>
            )}
            {children}
        </dialog>
    )
}

export default Dialog