import { useState } from "react";
import { errorsInitialState } from "@Pages/companyDirectReview/constants/errorsInitialState";
import { ErrorsType } from "@Pages/companyDirectReview/types/Errors.type";
import validateEmail from "@User/functions/validateEmail";
import {prepareFormData} from "@Pages/companyDirectReview/functions/prepareFormData";

export const useSubmitCompanyReview = () => {
    const [_, setFormData] = useState<{ [key: string]: string }>({});
    const [errors, setErrors] = useState<ErrorsType>(errorsInitialState);

    const submit = (event: ProgressEvent) => {
        const form = document.getElementById('nonGbbCompanyReview') as HTMLFormElement;
        if (form) {
            const formElements = form.elements as HTMLFormControlsCollection;
            const data: { [key: string]: string | boolean } = prepareFormData(formElements);
            const newErrors: ErrorsType = { ...errorsInitialState };

            // Validation checks
            newErrors.travelDate = !data.travelDate;
            newErrors.departureStationId = !data.departureStationId;
            newErrors.destinationStationId = !data.destinationStationId;
            newErrors.userEmail = !validateEmail(data.userEmail);
            newErrors.tosAndPrivacyPolicy = !data.gbb_tos_and_privacy_policy;

            if (Object.values(newErrors).some((value) => value === true)) {
                const firstErrorKey = Object.keys(newErrors).find((key) => newErrors[key] === true);
                if (firstErrorKey) {
                    const firstErrorElement = formElements.namedItem(firstErrorKey);
                    if (firstErrorElement) {
                        firstErrorElement?.scrollIntoView({ behavior: 'smooth' });
                        firstErrorElement.classList.add("station-error")
                    }
                }
                setErrors(newErrors);
                event.preventDefault();
            }

            setFormData(data);
        }
    };

    return {
        submit,
        errors,
    };
};