import Translation from "@Translation/components/Translation";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface RouteClosedOrUnavailableTriggerProps {
    label: string,
}

function RouteClosedOrUnavailableTrigger({label}: RouteClosedOrUnavailableTriggerProps) {
    const isMobile = useIsMobile()

    const setClassName = () => {
        let className = 'status-closed ';

        if (!isMobile) {
            className += 'gb--truncate'
        }

        return className
    }

    return (
        <span className={setClassName()}>
            <Translation translationKey={label}/>
        </span>
    )
}

export default RouteClosedOrUnavailableTrigger