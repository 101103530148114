import React from 'react';
import Translation from "@Translation/components/Translation";
import Row from "@Containers/components/Row";
import CompanyLogo from "@Route/features/company/components/CompanyLogo";

interface TicketInfoLabelProps {
    isReturn: boolean,
    isIntermodal: boolean,
    companyId: number | string,
    companyLogoUrl: string,
}

function TicketInfoLabel({isReturn, isIntermodal, companyId, companyLogoUrl}: TicketInfoLabelProps) {
    const regularLabel = isReturn ? "check_order.return_trip_info" : "check_order.outbound_trip_info"
    const label = isIntermodal ? "sys.ticket_info" : regularLabel

    return (
        <Row justify center className="company-section">
            <span className="gb--card-title gb--emphasize-default">
                <Translation translationKey={label}/>
            </span>
            <CompanyLogo
                companyId={companyId}
                logoUrl={companyLogoUrl}
            />
        </Row>
    );
}

export default TicketInfoLabel;