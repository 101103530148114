import IconChevron from "@Icons/chevrons/IconChevron";
import {Colors} from "@Styles/Colors.style";

interface SliderArrowProps {
    direction: "left" | "right",
    move: () => void,
    className: string,
}

function SliderArrow({direction, move, className}: SliderArrowProps) {
    return (
        <button
            type="button"
            className={`slider-arrow ${className}`}
            onClick={move}
        >
            <IconChevron
                chevronOrientation={direction}
                fill={Colors.primaryColor}
            />
        </button>
    )
}

export default SliderArrow