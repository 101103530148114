import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconBorderCrossing(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke}>
                    <g strokeWidth="1.2" transform="translate(3.6362 1.072)">
                        <path
                            d="m8.72761976 2.3766331c-5.81841317 0-8.72761976 0-8.72761976 0v11.0668419h8.72761976z"
                            strokeLinejoin="round"
                        />
                        <path d="m0 2.3766331 8.11013698-2.20848531.61748278-.16814779"/>
                    </g>
                    <circle cx="8.00001" cy="8.000032" r="2.098919"/>
                    <path d="m5.745521 12.06118h4.508978" strokeWidth="1.2"/>
                </g>
            )}
        />
    )
}

export default IconBorderCrossing