import React from 'react';
import MyTicketsButton from "@User/components/MyTicketsButton";
import {activateMobileMenu} from "@Reducers/mobileSlice";
import IconMenu from "@Icons/menuIcons/IconMenu";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Column from "@Containers/components/Column";
import useScrollToFormAndAnimate from "@Form/hooks/useScrollToFormAndAnimate";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import useActivateMobileFormOverlay from "@Form/hooks/useActivateMobileFormOverlay";
import {capitalize} from "@String/capitalize";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useStsPageDatePickerClickEvent from "@Events/hooks/useStsPageDatePickerClickEvent";
import {Colors} from "@Styles/Colors.style";
import IconSearchWithLogomark from "@Icons/search/IconSearchWithLogomark";

function MobileNavigation() {
    const {menu, search} = useTranslation('sys')
    const isSearchResults = useRouteMatch('searchResults')
    const isMyProfile = useRouteMatch('myProfile')
    const dispatch = useAppDispatch()
    const displayMenu = () => dispatch(activateMobileMenu(true))
    const {scrollToFormAndAnimate} = useScrollToFormAndAnimate()
    const {toggleFormAction} = useActivateMobileFormOverlay()
    const {mobileStsStickyCta} = useAppSelector((state) => (state?.page.experimentsJson));
    const ctaButtonText = useTranslation('sts_page.sticky_cta_text')
    const clickStickyCtaEvent = useStsPageDatePickerClickEvent("sticky_cta");

    const onClick = () => {
        clickStickyCtaEvent()
    }

    return (
        <>
            {(mobileStsStickyCta && (window.permalinkTypeId == 1 || window.permalinkTypeId == 7)) ? (
                <div className="gb--navigation__sticky--cta">
                    <a href={window.tomorrowSearchLink} onClick={onClick} className="btn btn--green cta--button">
                        {ctaButtonText}
                    </a>
                </div>
            ) : (
                <div className="gb--navigation__bottom">
                    {!isSearchResults && (
                        <>
                            <Column onClick={displayMenu} center>
                                <IconMenu
                                    height="20"
                                    fill={Colors.primaryColor}
                                />
                                {menu}
                            </Column>
                            <MyTicketsButton/>
                        </>
                    )}
                    {!isSearchResults && (
                        <button
                            type="button"
                            onClick={!isMyProfile ? scrollToFormAndAnimate : toggleFormAction}
                            className="gb--logomark__search"
                        >
                            <div className="gb--logomark__search-shadow"/>
                            <IconSearchWithLogomark
                                width={25}
                                height={25}
                            />
                            {capitalize(search)}
                        </button>
                    )}
                </div>
            )}
        </>
    );
}

export default MobileNavigation;