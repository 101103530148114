import React from "react";

interface UserModalTitleProps {
    title: string,
    subtitle: string,
    onClick: (any?) => any,
    switchToContent: any,
    titleSuffix?: string,
}

const UserModalTitle = ({title, subtitle, onClick, switchToContent, titleSuffix}: UserModalTitleProps) => (
    <>
        {title}
        <br/>
        {titleSuffix}
        <br/>
        <small className="gb--emphasize gb--emphasize-default">
            {subtitle}
            &nbsp;
            <span
                role="button"
                className="gb--emphasize-info sign-up__link"
                onClick={onClick}
            >
                {switchToContent}
            </span>
        </small>
    </>
)

export default UserModalTitle