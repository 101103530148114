import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconFire(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m7.87323335 1.86396319c.0767491 1.70705389-.86547808 3.29278627-2.82668153 4.75719713-2.94180518 2.19661629-1.61150371 6.33485518 1.30600942 7.29350108.81801567.2687862 1.19861762.2174542 1.94815603.2174542 1.23230801 0 2.54647273-.4250907 3.50255943-2.0422013.633641-1.0717309.8918423-2.0362935.7746039-2.89368775-1.5704181 1.23934295-2.5485241 1.63807705-2.93431804 1.19620235-.57869084-.66281203.30477488-1.08794687.94296314-2.71546282.6381882-1.62751595.4348075-2.8533664-.64728382-4.00524347-.72139424-.76791804-1.41006375-1.37050452-2.06600853-1.80775942z"
                    fill="none"
                    stroke={stroke}
                    strokeWidth="1.2"
                />
            )}
        />
    )
}

export default IconFire