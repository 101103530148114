import {useTranslation} from "@Translation/hooks/useTranslation";
import FilterButton from "@Generic/buttons/FilterButton";
import React from "react";
import usePopularFilters from "@SearchResults/features/sidebar/features/popularFilters/hooks/usePopularFilters";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";
import IconDiscount from "@Icons/searchRes/filters/IconDiscount";
import {Colors} from "@Styles/Colors.style";

function WebDiscountFilter() {
    const label = useTranslation("search_results.filter_by.popular_filters.filters.web_discount")
    const {isActive, setActive} = usePopularFilters("webDiscount")

    const handleOnBlur = useDispatchFilterEvents('webDiscountFilter', isActive.toString());

    return (
        <FilterButton
            label={label}
            active={isActive}
            icon={(
                <IconDiscount
                    width="20"
                    height="20"
                    stroke={isActive ? Colors.primaryColor : Colors.neutralDarkestColor}
                />
            )}
            onClick={setActive}
            onBlur={handleOnBlur}
        />
    );
}

export default WebDiscountFilter;