import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

interface IconCalendarAvailableDatesProps extends IconType {
    isChecked: boolean,
}

function IconCalendarAvailableDates(props: IconCalendarAvailableDatesProps) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        isChecked,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" transform="translate(.4375 .5537)">
                    <g fill={fill}>
                        <path
                            d="m14.7812557 2.47943198c.267582 0 .4845.21691804.4845.4845v11.44408382c0 .2675819-.216918.4845-.4845.4845l-6.11285314-.0003567c-.10059368-.0003011-.22624963-.0008017-.37119727-.0015708-.4026665-.0021363-.80537919-.0056555-1.18155222-.0108801-1.00371696-.0139404-1.59022009-.0341959-1.77962136-.0762974-.83715702-.183448-1.62526906-.6296538-2.24740528-1.2780207l-.00585057-.0064581-.0227131.0067216c-.9383159.2093588-2.03537697.0282252-2.93319682-.7942519l-.12686594-.1218867.68755171-.682813c.51129702.5148453 1.1083322.7181667 1.6615111.7215739l.07771305-.0023439-.07265794-.1242942c-.50054917-.9190965-.34448364-1.8437266.30034382-2.3600351l.11883257-.0867904c.73119323-.48642719 1.69122745-.28974165 2.14509355.5444525.50811957.9339106.07413931 1.8726568-.76757044 2.4417903l-.08504156.0538769.14676983.118437c.34477934.262264.72932049.4569783 1.13046998.5726301l.20185158.0511841c.08871078.0197182.72458483.0416789 1.58424276.0536185.37307781.0051816.77313389.0086776 1.17323615.0108003.14404911.0007642.26885701.0012616.36852833.0015605l5.62490141-.0002305v-10.47500002h-12.82600004v1.579l11.90978904.0004855v.969h-12.39412047c-.26758196 0-.4845-.21691804-.4845-.4845v-2.5479855c0-.26758196.21691804-.4845.4845-.4845zm-10.71404097 8.60293682c-.17282778-.317653-.48528754-.3816677-.7572068-.2007731-.31225475.2077278-.40154058.6319173-.10506241 1.1763038.0367479.0673682.07535063.1332332.11570101.1975416l.09212933.1374909.07601521-.0408692c.50341484-.2930604.78938973-.7588985.61631387-1.1894333z"
                        />
                        <path
                            d="m4.5487772 0c.26758196 0 .4845.21691804.4845.4845v2.47943198c0 .26758196-.21691804.4845-.4845.4845h-1.6571192c-.26758196 0-.4845-.21691804-.4845-.4845v-2.47943198c0-.26758196.21691804-.4845.4845-.4845zm-.4851192.9685h-.688v1.51h.688z"
                        />
                        <path
                            d="m12.8748774 0c.267582 0 .4845.21691804.4845.4845v2.47943198c0 .26758196-.216918.4845-.4845.4845h-1.6571192c-.267582 0-.4845-.21691804-.4845-.4845v-2.47943198c0-.26758196.216918-.4845.4845-.4845zm-.4851192.9685h-.688v1.51h.688z"
                        />
                    </g>
                    {isChecked ? (
                        <path
                            d="m12.7196565 8.25454883.8339602.8628501-3.3484764 3.23636307c-.239955.2319209-.62303958.2234835-.85255082-.0187773l-1.9623865-2.071399.87114144-.82529545 1.54442928 1.63068365z"
                            fill="#448f6c"
                        />
                    ) : (
                        <path
                            d="m5.31 0 .83395608.86285413-2.20997804 2.13457294 2.20997804 2.13473445-.83395608.86285413-2.23802196-2.16458858-2.23802196 2.16458858-.83395608-.86285413 2.20897804-2.13473445-2.20897804-2.13457294.83395608-.86285413 2.23802196 2.16342707z"
                            fill="#ff6144"
                            transform="translate(7.4097 7.3066)"
                        />
                    )}
                </g>
            )}
        />
    )
}

export default IconCalendarAvailableDates