/* max mobile screen width; generally not used even thought it is defined in @see media-queries.less */
export const smallMobileScreenWidth = 480

/** max mobile screen width */
export const mobileScreenWidth = 680

/** max small tablet screen width */
export const smallTabletScreenWidth = 768

/** max tablet screen width */
export const tabletScreenWidth = 992