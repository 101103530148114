import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconHandLuggage(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(2.6661 2.9178)"
                >
                    <rect height="8.311825" rx="1" width="11.867738" x="-.6" y="3.120453"/>
                    <path
                        d="m2.74934885 2.02789067c0-1.35192711 0-2.02789067 0-2.02789067h5.16904054v2.02789067"
                        strokeLinejoin="round"
                    />
                </g>
            )}
        />
    )
}

export default IconHandLuggage