import React, {cloneElement} from 'react';
import useAutoSuggest from "@Features/autosuggest/hooks/useAutoSuggest";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import {isNotEmpty} from "@Array/isNotEmpty";
import AutoSuggestType from "@Features/autosuggest/types/AutoSuggest.type";

interface AutoSuggestProps {
    autoSuggestModel: AutoSuggestType,
    placeholder?: string,
    inputClassName?: string,
    inputId?: string,
    mainContainerClassName?: string,
    suggestionsContainerClassName?: string,
    suggestionsListClassName?: string,
    suggestionItemClassName?: string,
    suggestionComponent: any,
    decorationComponent?: any,
    additionalProps?: any,
    autoFocus?: boolean,
    children?: React.ReactNode,
    additionalMainContainerClassName?: string,
    additionalInputClassName?: string,
    additionalSuggestionsContainerClassName?: string,
    inputDecoration?: React.ReactNode,
    isDisabled?: boolean,
}

function AutoSuggest(props: AutoSuggestProps) {
    const {
        autoSuggestModel,
        suggestionComponent,
        additionalProps,
        mainContainerClassName = 'react-autosuggest__container',
        suggestionsContainerClassName = "react-autosuggest__suggestions-container",
        suggestionsListClassName = 'react-autosuggest__suggestions-list',
        suggestionItemClassName = 'react-autosuggest__suggestion',
        inputClassName = 'react-autosuggest__input',
        inputId,
        placeholder,
        decorationComponent,
        autoFocus,
        children,
        additionalMainContainerClassName = "",
        additionalInputClassName = "",
        additionalSuggestionsContainerClassName = "",
        inputDecoration,
        isDisabled,
    } = props

    const {
        input,
        onChange,
        handleKeyDown,
        suggestions,
        onClick,
        onBlur,
        showSuggestions,
        onSuggestionClick,
        position,
        shouldDisplayDecoration,
        onFocus,
        fieldRef,
        setShowSuggestions,
        setSuggestions,
    } = useAutoSuggest(autoSuggestModel)

    const parseClass = (className) => (isNotEmpty(suggestions) && showSuggestions
            ? `${className} ${className}--open`
            : className
    )

    const highlightClass = (className, index) => ((position === index)
        ? `${className} ${className}--highlighted`
        : className)

    return (
        <div className={`${parseClass(mainContainerClassName)} ${additionalMainContainerClassName}`}>
            {inputDecoration && (
                // little bit of magic(hacks) required here
                // @ts-ignore
                cloneElement(inputDecoration, {
                    onClick: () => {
                        autoSuggestModel.suggestionsHandler(null).then((response) => {
                            setShowSuggestions(true)
                            setSuggestions(response)
                        })
                    },
                })
            )}
            <input
                id={inputId}
                ref={fieldRef}
                className={`${parseClass(inputClassName)} ${additionalInputClassName}`}
                onClick={onClick}
                value={input}
                placeholder={placeholder}
                onBlur={onBlur}
                onKeyDown={handleKeyDown}
                onChange={onChange}
                disabled={isDisabled}
                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                autoFocus={autoFocus}
                onFocus={onFocus}
            />
            {showSuggestions && (
                <ClickOutsideAwareComponent
                    className={`${parseClass(suggestionsContainerClassName)} ${additionalSuggestionsContainerClassName}`}
                    onClickOutside={onBlur}
                >
                    {shouldDisplayDecoration && (
                        decorationComponent
                    )}
                    <ul className={suggestionsListClassName}>
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={suggestion?.value}
                                className={highlightClass(suggestionItemClassName, index)}
                            >
                                {cloneElement(suggestionComponent, {
                                    index,
                                    suggestion,
                                    key: suggestion?.id ?? index,
                                    onClick: onSuggestionClick,
                                    ...additionalProps,
                                })}
                            </li>
                        ))}
                    </ul>
                </ClickOutsideAwareComponent>
            )}
            {children}
        </div>
    );
}

export default AutoSuggest;