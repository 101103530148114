import React from 'react';
import Row from "@Containers/components/Row";
import Translation from "@Translation/components/Translation";
import Column from "@Containers/components/Column";
import IconPenTrvl from "@Icons/edit/IconPen";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {clearPassengerSubmissionData} from "@CheckOrder/reducers/checkOrderSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PaymentSummary from "@CheckOrder/features/paymentSummary/components/PaymentSummary";
import IconEmail from "@Icons/chat/IconEmail";

function PaymentInfoSection() {
    const {
        passengers,
        email,
        purchasedRoute: {
            passengerGroups,
        },
    } = useAppSelector((state) => state?.checkOrder)
    const dispatch = useAppDispatch()

    const PassengersList = () => (
        <>
            {passengers.map((passenger) => (
                <span className="passengers-list__summary">
                    {`${passenger.passengerFirstName} ${passenger.passengerLastName || passengerGroups[passenger.singlePassengerGroupId].name}`}
                </span>
            ))}
        </>
    )

    const onClick = () => {
        dispatch(clearPassengerSubmissionData())
    }

    return (
        <>
            <div className="gb--card gb--check-order__payment-form-summary">
                <Row center justify className="gb--card-title edit-passengers__section">
                    <Translation translationKey="form.passengers.label"/>
                    <button
                        onClick={onClick}
                        type="button"
                    >
                        <IconPenTrvl/>
                        <Translation translationKey="sys.edit"/>
                    </button>
                </Row>
                <Row wrap className="passengers-list">
                    <PassengersList/>
                </Row>
                <hr/>
                <Column className="contact-summary">
                    <Row center gap className="contact-summary__email">
                        <IconEmail/>
                        <span className="gb--emphasize gb--emphasize-info">{email}</span>
                    </Row>
                    <Translation translationKey="check_order.buyer_info_section.email_note"/>
                </Column>
            </div>
            <div className="gb--card">
                <Row center justify className="gb--card-title">
                    <Translation translationKey="sys.total"/>
                </Row>
                <PaymentSummary/>
            </div>
        </>
    )
}

export default PaymentInfoSection