import Row from "@Containers/components/Row";
import IconPlus from "@Icons/controls/IconPlus";
import Column from "@Containers/components/Column";
import VerticalDivider from "@CheckOrder/components/VerticalDivider";
import Button from "@Generic/buttons/Button";
import {ReactNode} from "react";

interface AddAdditionalServiceButtonProps {
    closeModal: () => void,
    cta: string,
    service?: string,
    total: string,
    icon: ReactNode,
    includePlusIcon?: boolean
}

/**
 * used for adding addons and amenities to ticket
 */
function AddAdditionalServiceButton(props: AddAdditionalServiceButtonProps) {
    const {
        closeModal,
        cta,
        service,
        total,
        icon,
        includePlusIcon,
    } = props

    return (
        <Button onClick={closeModal} className="blue" additionalClasses="amenities--count-amenity_submit-counts-button">
            <Row gap center>
                {includePlusIcon && (
                    <IconPlus/>
                )}
                <Column>
                    <span className="amenities--count-amenity_submit-counts-button_label">
                        {cta}
                    </span>
                    <span className="amenities--count-amenity_submit-counts-button_description">
                        {service}
                    </span>
                </Column>
            </Row>
            <Row gap center>
                {icon}
                <VerticalDivider/>
                {total}
            </Row>
        </Button>
    )
}

export default AddAdditionalServiceButton