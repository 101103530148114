import React from 'react';
import LanguageSwitcher from "@Header/components/LanguageSwitcher/LanguageSwitcher";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {toggleMobileLanguages} from "@Reducers/mobileSlice";
import MobileOverlay from "./MobileOverlay";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

function MobileLanguageSwitcher() {
    const dispatch = useAppDispatch()
    const title = useTranslation("sys.choose_lang")
    const backClick = () => dispatch(toggleMobileLanguages())

    return (
        <MobileOverlay title={title} backClick={backClick} slideInDirection="down">
            <LanguageSwitcher/>
        </MobileOverlay>
    );
}

MobileLanguageSwitcher.propTypes = {};

export default MobileLanguageSwitcher;