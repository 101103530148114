import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import Column from "@Containers/components/Column";
import useIsTablet from "@Hooks/selector/useIsTablet";
import TravelInfoClickInterceptor from "@SearchResults/components/HeadingContent/Mobile/TravelInfoClickInterceptor";
import useIsCheckOrder from "@CheckOrder/hooks/useIsCheckOrder";
import MobileAppTitle from "../../../components/HeadingContent/Mobile/MobileAppTitle";
import TravelInfo from "./TravelInfo";
import SortContainer from "../../sorting/components/SortContainer";
import MobileActiveTicketSectionButtons
    from "../../../components/HeadingContent/Mobile/MobileActiveTicketSectionButtons";
import useActiveParams from "../../../hooks/useActiveParams";
import ParamsType from "@SearchResults/types/params/Params.type";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

function TravelInfoContainer({params, isLoading}: { params: ParamsType, isLoading: boolean }) {
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()

    const additionalInfoActive = useRouteMatch('additionalInfo')
    const returnAdditionalInfoActive = useRouteMatch('returnAdditionalInfo')
    const isCheckOrder = useIsCheckOrder()

    const {
        departureDate,
        departureName,
        destinationName,
    } = useActiveParams(params, isLoading)

    const isAnyAdditionalInfoActive = additionalInfoActive || returnAdditionalInfoActive

    return (
        <div className="gb--travel-info">
            {(isMobile || isTablet) ? (
                <MobileAppTitle
                    subtitleComponentVisible
                    additionalClassName="gb--travel-info__mobile-wrapper"
                    showBackButton={!isTablet}
                    subtitleComponent={isAnyAdditionalInfoActive && !isTablet ? (
                        <MobileActiveTicketSectionButtons/>
                    ) : (
                        <SortContainer/>
                    )}
                >
                    {!additionalInfoActive && !isCheckOrder ? (
                        <TravelInfoClickInterceptor>
                            <TravelInfo
                                departureStationName={departureName}
                                destinationStationName={destinationName}
                                date={departureDate}
                            />
                        </TravelInfoClickInterceptor>
                    ) : (
                        <TravelInfo
                            departureStationName={departureName}
                            destinationStationName={destinationName}
                            date={departureDate}
                        />
                    )}
                </MobileAppTitle>
            ) : (
                <Column className="gb--travel-info__container">
                    <TravelInfo
                        departureStationName={departureName}
                        destinationStationName={destinationName}
                        date={departureDate}
                    />
                    <SortContainer/>
                </Column>
            )}
        </div>
    );
}

export default TravelInfoContainer;