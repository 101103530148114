import Row from "@Containers/components/Row";
import IconConnection from "@Icons/IconConnection";
import Translation from "@Translation/components/Translation";
import IconPaxWithLuggage from "@Icons/filledOutline/IconPaxWithLuggage";

const ConnectionGuaranteedNotification = () => (
    <Row wrap center className="intermodal-labels">
        <Row center className="gb--label gb--label-green" style={{marginRight: ".5rem"}}>
            <IconConnection/>
            <Translation translationKey="search_results.connection_guaranteed"/>
        </Row>
        <Row center className="gb--label gb--label-blue" style={{marginRight: ".5rem"}}>
            <IconPaxWithLuggage
                width="20"
                height="16"
                viewBox="0 10 68 85"
            />
            <Translation translationKey="search_results.self_transfer"/>
        </Row>
    </Row>
)
export default ConnectionGuaranteedNotification