import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import Form from "@Form/components/Form";
import FormTravelingDotCom from "@Form/components/FormTravelingDotCom";

type FormVersionsContainerProps = {}

function FormVersionsContainer(props: FormVersionsContainerProps) {
    const isCompanyStore = useIsCompanyStore()

    if (isCompanyStore) {
        return (
            <Form/>
        )
    }

    return (
        <FormTravelingDotCom inverseColors/>
    )
}

export default FormVersionsContainer;