import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MyTickets from "@MyTickets/components/MyTickets";
import useGetValidTickets from "@MyTickets/hooks/useGetValidTickets";
import useGetArchivedTickets from "@MyTickets/hooks/useGetArchivedTickets";
import useCountValidTickets from "@MyTickets/hooks/useCountValidTickets";
import useCountArchivedTickets from "@MyTickets/hooks/useCountArchivedTickets";
import MyTicketsNoResultsNotification from "@MyTickets/components/MyTicketsNoResultsNotification";
import {Outlet, useParams} from "react-router";
import {generatePath, useNavigate} from "react-router-dom";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TrvlMyProfileShowArchivedData from "@MyProfile/components/TrvlMyProfileShowArchivedData";

function MyTicketsContainer() {
    const isMobile = useIsMobile()

    const {
        data: validTickets,
        elRef: validTicketsRef,
        page: validTicketsPage,
        setPage: setValidTicketsPage,
        loading: validTicketsLoading,
        batchLoading: validTicketsBatchLoading,
    } = useGetValidTickets()

    const {
        data: archivedTickets,
        elRef: archivedTicketsRef,
        page: archivedTicketsPage,
        setPage: setArchivedTicketsPage,
        loading: archivedTicketsLoading,
        batchLoading: archivedTicketsBatchLoading,
    } = useGetArchivedTickets()

    const {
        validTicketsCount,
        validTicketsCountLoading,
    } = useCountValidTickets()

    const {
        archivedTicketsCount,
        archivedTicketsCountLoading,
    } = useCountArchivedTickets()

    const {
        show_archived_tickets: showArchivedTicketsLabel,
    } = useTranslation('my_profile')

    const {
        showValid,
        showArchived,
    } = useAppSelector((state) => state.userProfile.filter)

    const params = useParams()
    const navigate = useNavigate()

    if (isMobile && params?.ticket && (validTickets || archivedTickets)) {
        const activeValidTicket = validTickets?.tickets.find((ticket) => ticket.id === parseInt(params.ticket, 10))
        const archivedValidTicket = archivedTickets?.tickets.find((ticket) => ticket.id === parseInt(params.ticket, 10))
        const ticket = activeValidTicket ?? archivedValidTicket

        if (!ticket) {
            navigate(generatePath("/:locale/my-profile/tickets", {locale: params.locale}))
        }
        return (
            <Outlet
                context={ticket}
            />
        )
    }

    return (
        <>
            <MyTicketsNoResultsNotification/>
            {showValid && (
                <MyTickets
                    tickets={validTickets?.tickets}
                    totalCount={validTicketsCount}
                    elRef={validTicketsRef}
                    page={validTicketsPage}
                    setPage={setValidTicketsPage}
                    loading={validTicketsLoading}
                    batchLoading={validTicketsBatchLoading}
                />
            )}
            {!archivedTicketsCountLoading && archivedTicketsCount !== 0 && (
                <TrvlMyProfileShowArchivedData
                    description={showArchivedTicketsLabel}
                />
            )}
            {/* !validTicketsLoading is here to stop double loading icons on page render */}
            {showArchived && !validTicketsLoading && !archivedTicketsCountLoading && archivedTicketsCount !== 0 && (
                <MyTickets
                    tickets={archivedTickets?.tickets}
                    totalCount={archivedTicketsCount}
                    className="ticket__archived"
                    elRef={archivedTicketsRef}
                    page={archivedTicketsPage}
                    setPage={setArchivedTicketsPage}
                    loading={archivedTicketsLoading}
                    batchLoading={archivedTicketsBatchLoading}
                    isArchived
                />
            )}
        </>
    );
}

export default MyTicketsContainer;