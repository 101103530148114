export const prepareFormData = (formElements: HTMLFormControlsCollection) => {
    const data: { [key: string]: string | boolean } = {};

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < formElements.length; i++) {
        const element = formElements[i] as HTMLInputElement;
        if (element.name) {
            if (element.name === "gbb_tos_and_privacy_policy" || element.name === "reviewer-name-chk" || element.name === "reviewer-name-checkbox") {
                data[element.name] = element.checked
            } else {
                data[element.name] = element.value;
            }
        }
    }

    return data;
}
