import React from "react";
import RadioField from "@Generic/form/RadioField";
import Notification from "@Features/notification/components/Notification";
import IconExclamationCircle from "@Icons/IconExclamationCircle";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import RadioButtonLabel from "@UserModals/features/manageBookings/components/RadioButtonLabel";
import {createAdditionalNotification} from "@MyTicket/functions/createAdditionalNotification";
import IconDiamond from "@Icons/filledOutline/IconDiamond";
import AdditionalNotificationType from "@MyTicket/types/AdditionalNotification.type";

interface ManageBookingFormProps {
    radioButtonLabels: any,
    isOneCompanyReturnTicket: boolean,
    isFirstLegExpired: boolean,
    option: string | null,
    hasPremiumSupport: boolean
}

function ManageBookingForm(props: ManageBookingFormProps) {
    const {
        radioButtonLabels,
        isOneCompanyReturnTicket,
        isFirstLegExpired,
        option,
        hasPremiumSupport,
    } = props

    const {
        first_leg_passed_rebookable: firstLegPassedRebookableLabel,
        first_leg_passed_not_rebookable: firstLegPassedNotRebookableLabel,
        premium_support: premiumSupportLabel,
    } = useTranslation('my_profile.my_tickets.booking_modal')
    const isMobile = useIsMobile()

    if (isFirstLegExpired && isOneCompanyReturnTicket) {
        return (
            <Notification
                notificationType="red"
                notificationIcon={<IconExclamationCircle/>}
                additionalClass="gb--row manage-booking-modal-body"
            >
                {firstLegPassedNotRebookableLabel}
            </Notification>
        );
    }

    return (
        <div className="manage-booking-modal-body">
            {isFirstLegExpired && (
                <Notification
                    notificationType="red"
                    notificationIcon={!isMobile && <IconExclamationCircle/>}
                    additionalClass="gb--row"
                >
                    {firstLegPassedRebookableLabel}
                </Notification>
            )}
            <form className="modal-form">
                {radioButtonLabels.map((buttonInfo) => {
                    const additionalNotificationsArray: AdditionalNotificationType[] = [];
                    if (buttonInfo.id === "rebook_ticket" && hasPremiumSupport) {
                        additionalNotificationsArray.push(createAdditionalNotification(premiumSupportLabel, <IconDiamond/>));
                    }
                    return (
                        <RadioField
                            key={buttonInfo.id}
                            label={(
                                <RadioButtonLabel
                                    labelHeader={buttonInfo.header}
                                    labelDescription={buttonInfo.description}
                                    notificationText={buttonInfo.notificationText}
                                    notificationType={buttonInfo.notificationType}
                                    currentOptionId={buttonInfo.id}
                                    selectedOptionId={option}
                                    additionalNotifications={{additionalNotifications: additionalNotificationsArray}}
                                />
                            )}
                            labelClassName="label-title"
                            onClick={buttonInfo.onClick}
                            value={buttonInfo.id}
                            comparisonValue={option}
                            passengerId={1}
                            required={false}
                        />
                    )
                })}
            </form>
        </div>
    );
}

export default ManageBookingForm