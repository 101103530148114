import React, {HTMLInputTypeAttribute, ReactNode} from 'react';
import Notification from "@Features/notification/components/Notification";
import Column from "@Containers/components/Column";

export type InputFieldProps = {
    id: string,
    label: string,
    value: string|number
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string,
    showError?: boolean,
    errorMessage?: string | ReactNode,
    onBlur?: () => void,
    type?: HTMLInputTypeAttribute
    className?: string,
    style?: object,
    description?: string,
}
function InputField(props: InputFieldProps) {
    const {
        id,
        label,
        value,
        type = "text",
        onChange,
        onBlur,
        showError,
        errorMessage,
        placeholder,
        className,
        style,
        description,
    } = props

    return (
        <div className={className} style={style}>
            <Column>
                <label htmlFor={id}>
                    {label}
                </label>
                {description && (
                    <small>
                        {description}
                    </small>
                )}
            </Column>
            <input
                {...props}
                className="gb--input-standalone"
                id={id}
                type={type}
                name={id}
                onChange={onChange}
                value={value}
                placeholder={placeholder ?? label}
                onBlur={onBlur}
            />
            {showError && (
                <Notification notificationType="orange">
                    {errorMessage}
                </Notification>
            )}
        </div>
    );
}

export default InputField;