import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconPet(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(1.582 1.4375)"
                >
                    <path
                        d="m8.99022783 12.6433856c.51986994.0413096 1.04734747-.0991515 1.54332517-.5928656.5713-.56865.6035-1.80265.08005-3.07050002-.5997-1.45255-1.97379998-3.0045-4.03199998-3.0045-2.0583 0-3.43223 1.55205-4.03198 3.0045-.52345 1.26795002-.4913 2.50175002.080075 3.07050002.942375.9379 1.987005.5491 2.908755.20625.4005-.14905.74635-.27775 1.04325-.27775.29685 0 .64275.12875 1.04335.27775"
                    />
                    <path
                        d="m4.6430028 3.95c.76405-.20225 1.15325-1.245025.8675-2.3246-.25315-.95615-.95205-1.6254-1.63935-1.6254-.0888 0-.17725.011135-.26455.03428-.3639.09629-.655395.386725-.82092.817875-.17002.442775-.18652.977925-.04658 1.50675.13994.528845.41905.985645.78585 1.286445.3571.29275.7542.40105 1.11805.30465z"
                    />
                    <path
                        d="m2.28775282 5.81055129c.09211-.3377.059585-.7235-.09167-1.0863-.15126-.36285-.40237-.65755-.707085-.8298-.1793-.10135-.36423-.15265-.541935-.15265-.11681 0-.230535.02215-.33759.0668-.27009.11255-.46827.35595-.558115.6852-.09211.33765-.05959.7235.09167 1.0863s.40237.6575.707085.8297c.29707.16795.609435.19855.879525.08585.27009-.1125.46827-.35575.558115-.6851z"
                    />
                    <path
                        d="m8.52093249 3.95002958c.0873.02315.17585.03425.26455.03425.6873.0001 1.38620001-.66915 1.63925001-1.625385.1399-.5288.1234-1.063975-.0466-1.50675-.16555-.43115-.45705001-.721575-.82090001-.817875-.76405-.202245-1.6182.511525-1.9039 1.591125-.28565 1.0795.1035 2.122385.8676 2.324635z"
                    />
                    <path
                        d="m12.5540878 3.80865129c-.1083-.0452-.2221-.0666-.33725-.0666-.48655 0-.99965.38315-1.24945.98225-.30885.7408-.09965 1.5355.46645 1.7715.5659.23605 1.27785-.17475 1.5867-.91555.30885-.7409.09955-1.53565-.46645-1.7716z"
                    />
                </g>
            )}
        />
    )
}

export default IconPet