import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconQuestionsAndAnswers(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(2 2)">
                    <path
                        d="m3.20312374 3.34173021c.14666-.67745.45654-1.3282.93438-1.88668 1.51628-1.77499 4.14673-1.94982 5.87352996-.39337 1.7268 1.55646 2.0911 4.42412.0213 6.42251l-.62919996 2.47434-1.86877-1.4229c-.88943.0582-1.79778-.1724-2.5784-.7066"
                        stroke={fill}
                        strokeWidth={rest.strokeWidth}
                        strokeLinejoin="round"
                    />
                    <path
                        d="m3.16262374 3.59902021c-.18687.84743.13484 1.72157.73331 1.85998 1.17329.27195 1.62983-1.59288.04258-1.95953-1.07158-.24767-2.09584.03642-2.80549.57305l-.01183.00971c-.58901.45892-1.00297 1.15338-1.09996 1.96439-.14429 1.21408.45891 2.34321 1.44059 2.90161.30989.1773.65761.2963 1.03136.3424l1.30103 1.97899999 1.03609-2.16839999"
                        stroke={fill}
                        strokeLinejoin="round"
                        strokeWidth={rest.strokeWidth}
                    />
                    <g stroke={fill} fillRule="nonzero" strokeWidth={rest.strokeWidth}>
                        <path
                            d="m6.26856374 4.73751021c.17637 0 .31934-.14676.31934-.3278s-.14297-.3278-.31934-.3278-.31934.14676-.31934.3278.14297.3278.31934.3278z"
                        />
                        <path
                            d="m7.26660874 4.73751021c.176365 0 .319345-.14676.319345-.3278s-.14298-.3278-.319345-.3278-.319345.14676-.319345.3278.14298.3278.319345.3278z"
                        />
                        <path
                            d="m8.26463374 4.73751021c.1764 0 .3194-.14676.3194-.3278s-.143-.3278-.3194-.3278c-.1763 0-.3193.14676-.3193.3278s.143.3278.3193.3278z"
                        />
                        <path
                            d="m1.82910874 6.94503021c.176365 0 .319345-.14676.319345-.3278s-.14298-.3278-.319345-.3278-.319345.14676-.319345.3278.14298.3278.319345.3278z"
                        />
                        <path
                            d="m2.82715874 6.94491021c.176365 0 .319345-.14676.319345-.3278s-.14298-.3278-.319345-.3278c-.176375 0-.319345.14676-.319345.3278s.14297.3278.319345.3278z"
                        />
                        <path
                            d="m3.82520374 6.94503021c.17637 0 .31934-.14676.31934-.3278s-.14297-.3278-.31934-.3278-.31934.14676-.31934.3278.14297.3278.31934.3278z"
                        />
                    </g>
                </g>
            )}
        />
    )
}

export default IconQuestionsAndAnswers