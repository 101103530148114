export default {
    stationToStation: 1,
    station: 2,
    busCompany: 3,
    port: 6,
    portToPort: 7,
    busCountry: 8,
    ferryCountry: 9,
    islandPage: 10,
    ferryCompany: 11,
    customPermalink: 12,
    trainToTrain: 13,
    trainStationProfile: 14,
    trainCompanyProfile: 15,
    travelPage: 16,
};