import formatDate from "@DateTime/functions/formatDate";
import React from "react";
import isSlideDisabled from "@SearchResults/features/dateSlick/functions/isSlideDisabled";
import getSlidesClassName from "@SearchResults/features/dateSlick/functions/getSlidesClassName";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import getSlideFunctions from "@SearchResults/features/dateSlick/functions/getSlideFunctions";

interface DateSlickSlideProps {
    date: Date,
    isReturn: boolean,
    searchedDate: string,
    setClientXonMouseDown: (value: number) => void,
    setClientXonMouseUp: (value: number) => void,
    selectNewSearchDate: (selectedDate: string) => void
}

function DateSlickSlide(props: DateSlickSlideProps) {
    const {
        date,
        isReturn,
        searchedDate,
        setClientXonMouseUp,
        setClientXonMouseDown,
        selectNewSearchDate,
    } = props

    const {date: singleLegDate} = useAppSelector((state) => (state.form.urlParams))
    const isDisabled = isSlideDisabled(date, singleLegDate, searchedDate, isReturn)
    const formattedDate = formatDate(date, undefined, true)

    const {
        onClick,
        onMouseDown,
        onMouseUp,
        onTouch,
    } = getSlideFunctions({isDisabled, formattedDate, setClientXonMouseDown, setClientXonMouseUp, selectNewSearchDate})

    return (

        <div
            className={getSlidesClassName(formattedDate, searchedDate, isDisabled)}
            onClick={onClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchStart={onTouch}
        >
            <div className="datestrip__content">
                <span className="datestrip__day">
                    {formatDate(formattedDate, 'eeee', true)}
                </span>
                <span>
                    {formatDate(formattedDate, 'dd MMM', true)}
                </span>
            </div>
        </div>

    )
}

export default DateSlickSlide