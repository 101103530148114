import React from 'react';
import GenericPopover from "@Popover/components/GenericPopover";
import RouteIconType from "@SearchResults/types/routeIcons/RouteIcon.type";
import RouteAmenitiesListDetails from "@Route/features/amenities/components/RouteAmenitiesListDetails";
import RouteAmenitiesIconsList from "@Route/features/amenities/components/RouteAmenitiesIconsList";

interface RouteAmenitiesPopoverProps {
    amenities: number[],
    routeIcons: Record<number, RouteIconType>,
}

const RouteAmenitiesPopover = ({amenities, routeIcons}: RouteAmenitiesPopoverProps) => (
    <GenericPopover
        className="amenities-popover"
        id="amenities-popover"
        overlay={(
            <RouteAmenitiesListDetails
                routeIcons={routeIcons}
                amenities={amenities}
            />
        )}
        trigger={(
            <RouteAmenitiesIconsList
                routeIcons={routeIcons}
                amenities={amenities}
            />
        )}
        placement="bottom"
    />
);

export default RouteAmenitiesPopover;