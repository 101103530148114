import React from "react";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import TicketNotifications from "@MyTicket/features/ticketDetails/components/TicketNotifications";
import Notification from "@Features/notification/components/Notification";
import Translation from "@Translation/components/Translation";

interface TicketDataProps {
    isCanceled: boolean,
    isExchangedForVoucher: boolean,
    isExpired: boolean,
    isMTicket: boolean,
    id: string,
    route: TicketRouteType,
    isArchived: boolean,
    hasReturn: boolean,
    isReturn?: boolean
}

function TicketData(props: TicketDataProps) {
    const {
        isCanceled,
        isExchangedForVoucher,
        isExpired,
        isMTicket,
        id,
        route,
        isArchived,
        isReturn,
        hasReturn,
    } = props

    return (
        <>
            {hasReturn && (
                <Notification
                    isBorder
                    notificationType="primary"
                    additionalClass="ticket-details__direction-notification"
                >
                    <Translation
                        translationKey={isReturn ? "sys.return" : "sys.outbound"}
                    />
                </Notification>
            )}
            <TicketNotifications
                isCanceled={isCanceled}
                isExchangedForVoucher={isExchangedForVoucher}
                isExpired={isExpired}
                isMTicket={isMTicket}
            />
        </>
    );
}

export default TicketData;