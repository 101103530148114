import React from 'react';
import IntermodalPurchaseRouteInfo from "@CheckOrder/components/Sidebar/IntermodalPurchaseRouteInfo";
import TicketTripReview from "@CheckOrder/components/MainSection/TermsAndConditions/Checkboxes/TicketTripReview";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import PurchaseRouteInfo from "@CheckOrder/components/Sidebar/PurchaseRouteInfo";
import {isEmpty} from "@Array/isEmpty";

const CheckOrderSidebar = () => {
    const {
        ticket: {
            ticketCode,
            isIntermodal,
        },
        purchasedRoute: {
            singleRoute,
            singleRoutes,
            returnRoute,
            companies,
        },
    } = useAppSelector((state) => state?.checkOrder)

    return (
        <div className="gb--card gb--sidebar-container check-order__sidebar">
            <span className="gb--card-title check-order__sidebar-main-title">
                <span className="gb--emphasize-default">
                    <Translation translationKey="contact.ticket_code"/>
                    :
                </span>
                {` ${ticketCode}`}
            </span>
            {(isIntermodal || !isEmpty(singleRoutes)) ? (
                <IntermodalPurchaseRouteInfo
                    displayMultiTicketNotice={isIntermodal}
                    singleRoute={{
                        baseRoutes: singleRoutes,
                        isIntermodalRoute: true,
                        id: "",
                        key: "0",
                        isSuggestedRoute: false,
                    }}
                    companies={companies}
                />
            ) : (
                <PurchaseRouteInfo
                    companies={companies}
                    returnRoute={returnRoute}
                    singleRoute={singleRoute}
                />
            )}
            <TicketTripReview/>
        </div>
    );
}

export default CheckOrderSidebar;