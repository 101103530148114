import React from 'react';
import Accordion from "react-bootstrap/Accordion";
import TicketType from "@MyTicket/types/Ticket.type";
import TicketAccordion from "@MyTicket/components/TicketAccordion";
import {useParams} from "react-router";

interface TicketsProps {
    tickets: TicketType[];
    className: string,
    isArchived: boolean,
}

function Tickets({tickets = [], className, isArchived}: TicketsProps) {
    const params = useParams()
    return (
        <div>
            <Accordion
                className="panel-group routes-list accordion"
            >
                {tickets.map((ticket) => (
                    <TicketAccordion
                        key={ticket.id}
                        ticket={ticket}
                        className={className}
                        isArchived={isArchived}
                        isActive={params?.ticket ? params.ticket.toString() === ticket.id.toString() : false}
                    />
                ))}
            </Accordion>
        </div>
    );
}

export default Tickets;