import React from 'react';
import Sorter from "./Sorter";
import useHasSearchResults from "../../../hooks/useHasSearchResults";

const SortContainer = () => {
    const hasResults = useHasSearchResults()

    if (hasResults) {
        return (
            <Sorter/>
        );
    }

    // TODO ADD CHECK for "not is loading" (if it is not loading then remove this section)
    return (
        <div style={{height: "28.8px"}}/>
    )
}

export default SortContainer;