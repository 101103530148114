import Link from "@Generic/navigation/Link";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface ModalLinkProps {
    link: string,
    text: string,
    onClick?: () => void,
}

function ModalLinkButton(props: ModalLinkProps) {
    const {
        link = "",
        text,
        onClick = () => {
        },
    } = props

    const isMobile = useIsMobile()

    return (
        <Link
            href={link}
            className={`btn btn--green btn--${isMobile ? "mobile" : ""}`}
            opensInNewWindow
            onClick={onClick}
        >
            {text}
        </Link>
    );
}

export default ModalLinkButton