import React from 'react';
import Notification from "@Features/notification/components/Notification";
import Column from "@Containers/components/Column";

interface TicketActionButtonProps {
    mainLabel: string,
    descriptionLabel: string,
    notificationColor: "green" | "blue",
    onClick: (any) => any,
    notificationIcon: React.ReactNode,
}

function TicketActionButton(props: TicketActionButtonProps) {
    const {
        mainLabel,
        descriptionLabel,
        notificationColor,
        onClick,
        notificationIcon,
    } = props

    return (
        <Notification
            additionalClass="gb--card"
            isRow
            notificationIcon={notificationIcon}
        >
            <button
                type="button"
                onClick={onClick}
                className="open-modal-button"
            >
                <Column>
                    <div className="gb--emphasize-default">{mainLabel}</div>
                    <div>{descriptionLabel}</div>
                </Column>
            </button>
        </Notification>
    );
}

export default TicketActionButton;