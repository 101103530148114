import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";

export default function useGetRouteClosedOrUnavailableTranslationKeys(soldOut: boolean, closed: boolean, unavailable: boolean) {
    const isCompanyStore = useIsCompanyStore()

    const status = {
        [`${soldOut}`]: {
            label: isCompanyStore ? "sys.tickets_sold_out_company_store" : "sys.tickets_sold_out",
            popover: "sys.tickets_sold_out_popup",
        },
        [`${!soldOut && closed}`]: {
            label: "sys.route_not_in_sellable_range",
            popover: "sys.route_not_in_sellable_range_popup",
        },
        [`${!soldOut && unavailable}`]: {
            label: "sys.price_not_set",
            popover: "sys.price_not_set_popup",
        },
    }

    return status.true
}