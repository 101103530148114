import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconTransfer(props: IconType) {
    const {
        width = 16,
        height = 16,
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} transform="translate(2.0754 2.5861)">
                    <path
                        d="m1.40988 8.04184h1.12868m1.12868 0h4.51476m1.1286 0h1.1287m-9.52249-2.70988c.75117.15224 2.61946.47955 5.00394.47955 2.38445 0 4.26045-.32731 5.00775-.47955-.4699-1.04664-.7319-2.1732-.7704-3.3264 0-.16366-.1348-.28925-.2966-.28925h-7.87765c-.16179 0-.29276.1294-.29661.28925-.03852 1.1532-.30047 2.27976-.77043 3.3264zm0 0c-.20802.46052-.45455.90962-.73961 1.33208l-.12712.18649c-.03467.04567-.05008.10657-.05008.16366v3.51665c0 .1637.13483.2969.30047.2969h.97073l.04238-.0685c.41218-.6508 1.13638-1.0466 1.91451-1.0466h5.39691c.7781 0 1.5023.3958 1.9106 1.0466l.0424.0685h.9707c.1657 0 .3005-.1332.3005-.2969v-3.51665c0-.0609-.0193-.11418-.0501-.16366l-.1271-.18649c-.2851-.42246-.5355-.87156-.7396-1.33208"
                    />
                    <path
                        d="m6.86071 0c.16564 0 .30046.17127.30046.37679v1.32447h-2.46537v-1.32447c0-.20933.13482-.37679.30046-.37679z"
                    />
                </g>

            )}
        />
    )
}

export default IconTransfer