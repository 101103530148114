import React, {ReactNode, useState} from 'react';
import ToggleableComponent from "@Generic/blocks/ToggleableComponent";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import IconChevronDown from "@Icons/chevrons/IconChevronDown";
import {Colors} from "@Styles/Colors.style";

interface DropdownButtonProps {
    label: string | ReactNode,
    cssButtonClass?: string,
    cssLabelClass?: string,
    baseClass?: string,
    children: ReactNode,
    onKeyDown?: (any?) => any,
    isDisabled?: boolean,
    customAttributes?: object,
    style?: object,
}

function DropdownButton(props: DropdownButtonProps) {
    const {
        label,
        baseClass = "btn btn-default dropdown-toggle",
        cssButtonClass,
        cssLabelClass,
        children,
        onKeyDown,
        isDisabled,
        customAttributes = {},
        style,
    } = props

    const [display, toggleDisplay] = useState(false)
    const onClick = () => {
        if (!isDisabled) {
            toggleDisplay(!display)
        }
    }
    const onClickOutside = () => toggleDisplay(false)

    return (
        <ClickOutsideAwareComponent className="dropdown" onClickOutside={onClickOutside}>
            <button
                className={`${baseClass} ${cssButtonClass}`}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={onClick}
                onKeyDown={onKeyDown}
                style={style}
                {...customAttributes}
            >
                <span className={cssLabelClass ?? ''}>
                    {label}
                </span>
                <IconChevronDown
                    style={{verticalAlign: 'middle', marginLeft: '.5rem'}}
                    stroke={Colors.primaryColor}
                />
            </button>
            <ToggleableComponent display={display}>
                <div
                    style={{position: 'absolute', display: 'block'}}
                    className="dropdown-menu"
                    onClick={onClick}
                >
                    {children}
                </div>
            </ToggleableComponent>
        </ClickOutsideAwareComponent>
    );
}

export default DropdownButton;