import React from 'react'
import Link from "@Generic/navigation/Link";
import SourcedImage from "@Images/components/SourcedImage";

interface AppStoreLinkProps {
    link?: string,
    filePath: string,
    fileType: "jpg" | "jpeg" | "png" | "svg",
    className?: string
}

const AppStoreLink = ({link, filePath, fileType, className}: AppStoreLinkProps) => (
    <Link
        className={className}
        href={link}
    >
        <SourcedImage
            filePath={filePath}
            fileType={fileType}
        />
    </Link>
)

export default AppStoreLink