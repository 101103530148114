import RadioField from "@Generic/form/RadioField";
import React from "react";

interface DownloadDocumentOptionProps {
    value: string,
    label: string,
    onClick: (any?) => any,
    option: string,
    showHr: boolean,
}

function DownloadDocumentOption({value, label, onClick, option, showHr}: DownloadDocumentOptionProps) {
    return (
        <>
            <RadioField
                label={label}
                value={value}
                onClick={onClick}
                comparisonValue={option}
                passengerId={1}
                required={false}
            />
            {showHr && (
                <hr/>
            )}

        </>
    );
}

export default DownloadDocumentOption;