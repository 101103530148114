import React from 'react';
import HeroBackgroundCompanyStore from "./HeroBackgroundCompanyStore";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";

function HeroBackground() {
    const isCompanyStore = useIsCompanyStore()

    if (isCompanyStore) {
        return <HeroBackgroundCompanyStore/>
    }

    return null
}

export default HeroBackground
