import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import RatingType from "@Route/types/ratings/Rating.type";

/**
 * Checks whether the ratings should be displayed per rating type
 */
export default function useRouteRatings({ratings}: RouteWithAddedPropsAndPricesType) {
    const displayRatings = (reviews: RatingType, cap: number) => (
        reviews?.score >= 4 && reviews?.reviewCount >= cap
    )

    const displayRouteRating = displayRatings(ratings?.routeRating, 10)
    const displayStationRating = displayRatings(ratings?.stationRating, 9)
    const displayCompanyRating = displayRatings(ratings?.companyRating, 4)

    return {
        ratings,
        displayRouteRating,
        displayStationRating,
        displayCompanyRating,
        displayPopover: displayRouteRating || displayStationRating || displayCompanyRating,
    }
}