import IconMobileApp from "@Icons/IconMobileApp";
import Translation from "@Translation/components/Translation";
import AppStoreLinksContainer from "@Sidebar/components/AppStoreNotice/AppStoreLinksContainer";
import SourcedImage from "@Images/components/SourcedImage";
import React from "react";
import Grid from "@Containers/components/Grid";

const MobileAppPromo = () => {
    return (
        <section className="app-landing-page-promo-section">
            <Grid min="350px">
                <SourcedImage
                    filePath="images/mobile-app-landing-page/mobile-app-promo-hero"
                    fileType="png"
                    className="app-landing-page-promo-hero"
                />
                <div className="app-landing-page-promo-content">
                    <IconMobileApp/>
                    <h4>
                        <Translation translationKey="mobile_app.mobile_promo_section.intro"/>
                    </h4>
                    <h2>
                        <Translation translationKey="mobile_app.mobile_promo_section.title"/>
                    </h2>
                    <p>
                        <Translation translationKey="mobile_app.mobile_promo_section.description"/>
                    </p>
                    <AppStoreLinksContainer/>
                </div>
            </Grid>
        </section>
    )
}

export default MobileAppPromo