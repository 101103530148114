import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Main traveling.com plane icon, not filled
 */
function IconPlane(props: IconType) {
    const {
        width = 16,
        height = 16,
        viewBox = "0 0 16 16",
        title = "IconPlane",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, title, ...rest}}
            svgIconPath={(
                <path
                    d="m5.39831763 4.3131253-3.89067 1.66323c-.17335.0723-.37366-.0229-.41218-.2056-.26195-1.19502-.8128-2.44718-1.06704-2.99143-.0732-.15224 0-.33492.16179-.39582l.25039-.09895c.10015-.03806.21186-.02284.29661.04186.22728.17888.71265.59754 1.27121 1.29022l6.49082-3.36066c.6549-.33872 1.433-.33872 2.09179997-.01522.3698.17888.99.5747 1.4137.84873.1965.12559.1695.41865-.0424.5138l-3.94071997 1.78118c-.5393 2.03619-1.40604 3.53569-1.68724 3.98859-.05393.0876-.15024.1409-.25425.1409h-.70108c-.19261 0-.33129-.1713-.29277-.354.11557-.548.31588-1.66318.31588-2.85064v.00761z"
                    fill="none"
                    stroke={stroke}
                    strokeLinejoin="round"
                    transform="translate(1.9311 4.243)"
                />
            )}
        />
    )
}

export default IconPlane