import React from 'react';
import Translation from "@Translation/components/Translation";

/**
 * Plan a trip section on homepage
 */
function CatchphraseIntroSection() {
    return (
        <section className="plan-trip-section gb--column">
            <h2 className="gb--emphasize">
                <Translation translationKey="home_page.intro.title"/>
            </h2>
            <h3 className="plan-trip-section__subtitle">
                <Translation translationKey="home_page.intro.subtitle"/>
            </h3>
        </section>
    );
}

export default CatchphraseIntroSection;