import rootExtractor from "@Dataset/rootExtractor";
import getMainCompanyTransportType from "pages/searchResults/features/tabs/functions/getCompanyMainTransportType";
import generateTab from "@Tabs/functions/generateTab";
import {generateEmptyMainTransportTypeTab} from "@Tabs/functions/generateEmptyMainTransportTypeTab";
import {RoutesOrganizedByTransportTypeType} from "@SearchResults/types/RoutesOrganizedByTransportType.type";
import IntermodalRouteType from "@Intermodal/types/IntermodalRoute.type";

export default function createTabsFromGroups(singleRoutes: RoutesOrganizedByTransportTypeType, intermodalRoutes?: IntermodalRouteType[]) {
    const companyStore = JSON.parse(rootExtractor('companyStore'));
    const companyTransportType = getMainCompanyTransportType(companyStore)

    const tabsToShow = []
    const generateNonEmptyTabs = (routes, tab) => {
        if (routes.length) {
            tabsToShow.push(generateTab({routes, type: tab, intermodalRoutes}))
        }
    }

    if (!singleRoutes[companyTransportType]?.length) {
        tabsToShow.push(generateEmptyMainTransportTypeTab(companyTransportType))
    }

    Object.entries(singleRoutes).map((routeType) => {
        generateNonEmptyTabs(routeType[1], routeType[0])
    })

    return tabsToShow
}