import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Palette} from "@Styles/Palette.style";

function IconStar(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Palette.orange,
        fill = Palette.orange,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m5.821 0 .678 1.76693c.241.67338.679 1.23659 1.235 1.63397.558.39932 1.237.63118 1.956.63912l2.005.02212-1.468 1.04082c-.612.4347-1.048 1.04364-1.272 1.72242-.224.67879-.236 1.42743-.002 2.14153l.547 1.6704-1.733-1.171c-.58-.3918-1.25-.5878-1.919-.5879-.67 0-1.34.1959-1.92.5876l-1.733 1.171.547-1.6706c.234-.7141.222-1.46276-.002-2.14155-.224-.67878-.66-1.28773-1.273-1.72242l-1.467-1.04082 2.005-.02212c.719-.00794 1.397-.2398 1.956-.63912.559-.39931.997-.96608 1.238-1.64382z"
                    fill={fill}
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(2.1525 2.6813)"
                />
            )}
        />
    )
}

export default IconStar