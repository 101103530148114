import React from 'react';
import LoaderButton from "@Generic/loaders/LoaderButton";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";
import {capitalize} from "@String/capitalize";

interface SearchButtonProps {
    formVersion?: FormVersionType,
}

function SearchButton({formVersion = "1"}: SearchButtonProps) {
    const searchButtonLabel = useTranslation("form.cta")
    const {isLoading} = useSearchResults()

    const {v} = getFormVersionClassNameAggregator(formVersion)

    if (isLoading) {
        return (
            <span className={`btn btn--green btn--inactive-green gb--search${v()}__select--action`}>
                <LoaderButton/>
            </span>
        )
    }

    return (
        <button
            type="submit"
            className={`btn btn--green gb--search${v()}__select--action`}
        >
            {capitalize(searchButtonLabel)}
        </button>
    )
}

export default SearchButton;