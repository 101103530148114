import React, {ForwardedRef, ReactNode} from 'react';
import Row from "@Containers/components/Row";
import BackButton from "@Routing/components/BackButton";
import EditSearchButton from "@Form/components/Mobile/EditSearchButton";
import useDisplayEditButton from "@SearchResults/hooks/useDisplayEditButton";

interface MobileAppTitleProps {
    children: ReactNode,
    backClick?: () => void,
    className?: string,
    subtitleComponent?: ReactNode,
    additionalClassName?: string,
    subtitleComponentVisible?: boolean,
    titleRef?: ForwardedRef<null>,
    sticky?: boolean,
    showBackButton?: boolean
}

function MobileAppTitle(props: MobileAppTitleProps) {
    const {
        children,
        backClick = undefined,
        className = "gb--app-title__single",
        additionalClassName = "",
        showBackButton = true,
        subtitleComponent = null,
        subtitleComponentVisible = true,
        titleRef = null,
        sticky,
    } = props;

    const displayEditButton = useDisplayEditButton()

    return (
        <div
            style={sticky ? {position: "sticky", top: 0, zIndex: 3} : {}}
            className={`gb--mobile__app-title ${additionalClassName}`}
            ref={titleRef}
        >
            <Row justify center className="gb--app-title">
                <Row justify className="gb--app-title__wrapper">
                    {showBackButton && (
                        <BackButton backClick={backClick}/>
                    )}
                    <span className={className}>
                        {children}
                    </span>
                </Row>
                {displayEditButton && (
                    <EditSearchButton/>
                )}
            </Row>
            {subtitleComponentVisible && subtitleComponent}
        </div>
    );
}

export default MobileAppTitle
