import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Clock icon with incomplete circle in the upper right corner
 */
function IconTrvlClock(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={fill}>
                    <path
                        d="m6.6500001 0v1.2c-3.0099521 0-5.4500001 2.440048-5.4500001 5.4500001s2.440048 5.4499999 5.4500001 5.4499999 5.4499999-2.4400478 5.4499999-5.4499999c0-1.641109-.728963-3.1619082-1.966608-4.191679l.767517-.9224515c1.508815 1.2553961 2.399091 3.112731 2.399091 5.1141305 0 3.6726939-2.977306 6.6499999-6.6499999 6.6499999-3.6726937 0-6.6500001-2.977306-6.6500001-6.6499999 0-3.6726937 2.9773064-6.6500001 6.6500001-6.6500001z"
                        transform="translate(1.35 1.35)"
                    />
                    <path
                        d="m.60000002 0c.33137083 0 .59999998.26862916.59999998.60000002v3.41200008l2.1978767-1.065907c.2683263-.1301706.5859001-.0423904.7521696.1938116l.0495444.0841358c.1446342.2981403.0201926.6570799-.2779474.801714l-3.05976014 1.4843535c-.39848071.1933117-.86188316-.0969353-.86188316-.5398307v-4.37027728c0-.33137086.26862916-.60000002.60000002-.60000002z"
                        transform="translate(7.08275 3.92522)"
                    />
                </g>
            )}
        />
    )
}

export default IconTrvlClock