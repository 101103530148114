import React from 'react';
import Row from "@Containers/components/Row";
import IconInfo from "@Icons/IconInfo";
import {useToggle} from "@Hooks/dispatch/useToggle";
import ToggleButton from "@Generic/buttons/ToggleButton";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import useSetCookie from "@Cookies/hooks/useSetCookie";
import CookieDescriptionModal from "@Cookies/components/CookieDescriptionModal";
import CookieType from "@Cookies/types/Cookie.type";

type CookieDetailsProps = {
    cookie: CookieType,
    canBeToggled: boolean,
}

function CookieDetails({cookie, canBeToggled}: CookieDetailsProps) {
    const {
        set,
        onChange: toggleExpand,
        value: expanded,
    } = useToggle()

    const closeModal = () => {
        set(false)
    }

    const toggleInfo = (e) => {
        e.stopPropagation()
        toggleExpand()
    }

    const {
        isActive,
        toggleCookie,
    } = useSetCookie(cookie.cookieKey, canBeToggled)

    return (
        <div className="gb--cookie-details">
            <Row justify center>
                <Row center>
                    <button style={{margin: "unset", paddingRight: '1rem'}} type="button" onClick={toggleInfo}>
                        <IconInfo fill="navy"/>
                    </button>
                    <span>
                        {cookie.name}
                    </span>
                </Row>
                <ToggleButton
                    disabled={!canBeToggled}
                    onClick={toggleCookie}
                    checked={canBeToggled ? isActive : true}
                />
            </Row>
            {expanded && (
                <ClickOutsideAwareComponent onClickOutside={closeModal}>
                    <CookieDescriptionModal
                        closeModal={closeModal}
                        cookie={cookie}
                    />
                </ClickOutsideAwareComponent>
            )}
        </div>
    );
}

export default CookieDetails;