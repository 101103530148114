import LocationToLocationCountryImageType
    from "@Pages/stationToStation/features/header/types/LocationToLocationCountryImage.type";
import BackgroundImageCanvas from "@Form/components/BackgroundImageCanvas";

type LocationToLocationBackgroundImageProps = {
    imagePaths: LocationToLocationCountryImageType
}

function LocationToLocationBackgroundImage({imagePaths}: LocationToLocationBackgroundImageProps) {
    const {
        pathMobile,
        pathTablet,
        pathWindow,
    } = imagePaths

    return (
        <picture className="gb--navigation-background">
            <BackgroundImageCanvas imagePaths={imagePaths}/>
            <source
                media="(min-width: 992px)"
                type="image/jpg"
                srcSet={pathWindow}
            />
            <img
                className="background-image"
                typeof="image/jpg"
                src={pathTablet}
                alt="heading"
            />
        </picture>
    )
}

export default LocationToLocationBackgroundImage