import React, {ReactNode} from "react";

interface GenericCheckboxProps {
    id?: string,
    name?: string,
    className?: string,
    checked: boolean,
    onClick: () => void,
    children?: ReactNode,
    onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void
    disabled?: boolean,
}

function GenericCheckbox({id, name, className = "", checked, onClick, children, onBlur, disabled}: GenericCheckboxProps) {
    return (
        <div className={`md-checkbox ${className}`}>
            <input
                id={id}
                name={name}
                type="checkbox"
                checked={checked}
                // Return null to silence react error.
                // we're using label as change handler instead of checkbox
                onChange={() => null}
                disabled={disabled}
            />
            <button
                type="button"
                className="gb--input-label"
                onClick={onClick}
                onBlur={onBlur}
            >
                {children}
            </button>
        </div>
    );
}

export default GenericCheckbox;