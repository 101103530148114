import React from 'react';
import VehicleTypeIcon from "@Generic/vehicle/VehicleTypeIcon";
import ConnectionDivider from "@Generic/station/ConnectionDivider";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import IntermodalRouteWithDetailsType from "@Intermodal/types/IntermodalRouteWithDetails.type";
import RouteType from "@Route/types/Route.type";
import RouteListBusChange from "./RouteListBusChange";
import RouteTravelDuration from "./RouteTravelDuration";

interface RouteConnectionProps {
    /** Route being rendered */
    /**
     *  RouteWithAddedPropsAndPricesType is added here because of Search Results.
     *
     *  RouteType is added here because of Check Order.
     */
    route: IntermodalRouteWithDetailsType | RouteWithAddedPropsAndPricesType | RouteType,
    showVehicleIcon?: boolean,
    activeHub?: string,
    vertical?: boolean,
    operatingDateShown?: boolean,
    hasStopover?: boolean
}

function RouteConnection(props: RouteConnectionProps) {
    const {
        route,
        showVehicleIcon = true,
        activeHub = null,
        vertical = false,
        operatingDateShown = false,
        hasStopover = false,
    } = props;
    // @ts-ignore TODO Luka-this-is-typescript-type-issue-Do-The-Fixing
    const travelDuration = route?.travelInfo?.combinedTravelDuration ?? route.travelInfo.travelDuration

    const VerticalConnection = () => (
        <ConnectionDivider customClass="gb--connect-divider_vertical" hasStopover={hasStopover}>
            {showVehicleIcon && (
                <span className="gb--connect-line" style={{position: 'relative'}}>
                    <VehicleTypeIcon/>
                </span>
            )}
        </ConnectionDivider>
    )

    const Connection = () => (
        <div className="gb--route-element gb--connect">
            <RouteTravelDuration
                customClassName="travel-duration"
                showClockIcon={false}
                travelDuration={travelDuration}
            />
            <ConnectionDivider
                customClass={operatingDateShown ? "gb--connect-divider-date-displayed" : ""}
            />
            <RouteListBusChange
                isIntermodal={route.status.isIntermodal}
                travelInfo={route?.travelInfo}
                activeHub={activeHub}
            />
        </div>
    )
    if (vertical) {
        return (
            <VerticalConnection/>
        )
    }
    return (<Connection/>)
}

export default RouteConnection;
