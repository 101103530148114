import React from 'react';
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import Translation from "@Translation/components/Translation";
import useTogglePassengerPicker from "../hooks/useTogglePassengerPicker";
import BusRentNotice from "./BusRentNotice";
import PassengerGroup from "./PassengerGroup";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";
import useToggleBusRentNotice from "@Form/features/passengers/hooks/useToggleBusRentNotice";
import usePreparePassengerGroupData from "@Form/features/passengers/hooks/usePreparePassengerGroupData";

interface PassengerPickerProps {
    formVersion?: FormVersionType,
}

function PassengerPicker({formVersion = "1"}: PassengerPickerProps) {
    const closePicker = useTogglePassengerPicker()
    const passengerGroupData = usePreparePassengerGroupData()

    const {showBusRent} = useToggleBusRentNotice()

    const {v} = getFormVersionClassNameAggregator(formVersion)

    return (
        <ClickOutsideAwareComponent
            className={`gb--search${v()}__select gb--search${v()}__select--pax-open`}
            onClickOutside={closePicker}
        >
            {passengerGroupData.map(({type, count, name}) => (
                <PassengerGroup
                    key={type}
                    passengerType={type}
                    count={count}
                    fieldName={name}
                    formVersion={formVersion}
                />
            ))}
            {showBusRent && (
                <BusRentNotice/>
            )}
            <button
                type="submit"
                className="btn btn-block btn--green"
                onClick={closePicker}
            >
                <Translation translationKey="sys.add"/>
                {' '}
                <Translation translationKey="form.passengers.label"/>
            </button>
        </ClickOutsideAwareComponent>
    )
}

export default PassengerPicker;