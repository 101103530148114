import React, {useState} from 'react';
import ClickOutsideAwareComponent from "../../clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import Translation from "@Translation/components/Translation";
import IconChevronDown from "@Icons/chevrons/IconChevronDown";
import MyTicketsListItem from "./MyTicketsListItem";
import useUser from "@User/hooks/useUser";

function MyTicketsDropdown() {
    const [dropdownVisible, setDropdown] = useState(false)
    const user = useUser()

    function setClassName() {
        let className = 'select__list ';
        if (dropdownVisible) {
            className += 'select__list--opened'
        }
        return className
    }
    const toggleDropdown = () => () => {
        setDropdown(!dropdownVisible)
    }
    const displayName = user?.realName ?? user?.email

    return (
        <ClickOutsideAwareComponent onClickOutside={() => setDropdown(false)}>
            <ul className="gb--menu login__dropdown gb--row gb--row-middle gb--row-justify">
                <li className="gb--menu__item">
                    <span
                        onClick={toggleDropdown()}
                        className="gb--emphasize-default"
                    >
                        <span className="login__user gb--truncate">
                            <Translation translationKey="sys.my_tickets"/>
                            &nbsp;
                            <span>
                                {`(${displayName})`}
                            </span>
                        </span>
                        <IconChevronDown
                            style={{verticalAlign: 'middle', marginLeft: '.5rem'}}
                            stroke="white"
                        />
                    </span>
                    {dropdownVisible && (
                        <MyTicketsListItem
                            showListItems={setClassName()}
                        />
                    )}
                </li>
            </ul>
        </ClickOutsideAwareComponent>
    );
}

export default MyTicketsDropdown;