import React from 'react';
import {NavLink} from 'react-router-dom';
import Translation from "@Translation/components/Translation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useTabLabelConfigurator from "@Tabs/hooks/useTabLabelConfigurator";
import TabType from "@Tabs/types/Tab.type";
import useGenerateSearchUrl from "@SearchResults/hooks/useGenerateSearchUrl";
import useResetTabSpecificFilters from "@SearchResults/features/sidebar/hooks/useResetTabSpecificFilters";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import ClickTabModel from "@Events/models/ClickTabEventModel";
import TravelingDotComTabVehicleIcon from "@Tabs/components/TravelingDotComTabVehicleIcon";

interface TabProps {
    activeTab: TabType,
    tab: TabType,
}

function Tab({tab, activeTab}: TabProps) {
    const active = tab.tabType === activeTab.tabType;
    const isMobile = useIsMobile()
    const tabTranslation = useTabLabelConfigurator(tab.tabType)
    const {generateSearchUrl} = useGenerateSearchUrl()
    const {resetTabSpecificFilters} = useResetTabSpecificFilters()
    const dispatchClickTabEvent = useSingleTrackingEvent("click_tab_search_results", ClickTabModel(tab.tabType))
    const resetFilter = () => {
        dispatchClickTabEvent();
        if (tab !== activeTab) {
            resetTabSpecificFilters()
        }
    }

    return (
        <li
            className={`nav-item gb--row ${active ? 'active' : 'inactive'}`}
        >
            <NavLink
                onClick={resetFilter}
                replace
                to={generateSearchUrl({tab: tab.tabType})}
                className="nav-link gb--row-middle"
            >
                <TravelingDotComTabVehicleIcon
                    tag={tab.tabType}
                    width={isMobile ? '2.5rem' : '4rem'}
                    height={isMobile ? '2.5rem' : '3.5rem'}
                />
                <span className="gb--tabs-type gb--column">
                    <span>
                        <Translation translationKey={tabTranslation}/>
                    </span>
                    <span className="route-count">
                        x
                        {tab.routes}
                    </span>
                </span>
            </NavLink>
        </li>
    );
}

export default Tab