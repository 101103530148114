import React from 'react';
import getFormattedTravelTime from "@Route/functions/getFormattedTravelTime";
import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";
import CountryBorder from "@SearchResults/components/Popover/StationsPopover/CountryBorder";

interface StationProps {
    highlight: boolean,
    hadBorder: boolean,
    station: StationWithDateTimesType,
}

function Station({station: {name, arrivalTime, departureTime, hasBorder}, hadBorder, highlight}: StationProps) {
    const className = highlight ? " " : " gb--emphasize-inactive"

    return (
        <>
            <span className={`gb--truncate ${className}`}>
                {name}
            </span>
            <span className={`${className} gb--stations-details-arrival_time`}>
                {getFormattedTravelTime({travelTime: arrivalTime})}
            </span>
            <span className={`${className} gb--stations-details-departure_time`}>
                {getFormattedTravelTime({travelTime: departureTime})}
            </span>
            {hasBorder && hadBorder && (
                <CountryBorder/>
            )}
        </>
    );
}

export default Station;