import FilterSection from "@SearchResults/features/sidebar/components/FilterSection";
import React from "react";
import DepartureTimeFilter
    from "@SearchResults/features/sidebar/features/departureTimeFilters/components/DepartureTimeFilter";
import formatDate from "@DateTime/functions/formatDate";
import useLocale from "@Hooks/selector/useLocale";
import useCurrency from "@Currency/hooks/useCurrency";
import IconMorning from "@Icons/searchRes/filters/IconMorning";
import IconAfternoon from "@Icons/searchRes/filters/IconAfternoon";
import IconEvening from "@Icons/searchRes/filters/IconEvening";
import IconNight from "@Icons/searchRes/filters/IconNight";

function DepartureTimeFilters() {
    const locale = useLocale()
    const currency = useCurrency()

    const hourFormat = (locale === 'en-us' || currency?.val === 'USD') ? 'H aa' : 'HH:mm'
    const morning = formatDate(new Date(0, 0, 0, 5, 0, 0), hourFormat);
    const afternoon = formatDate(new Date(0, 0, 0, 12, 0, 0), hourFormat);
    const evening = formatDate(new Date(0, 0, 0, 19, 0, 0), hourFormat);
    const night = formatDate(new Date(0, 0, 0, 0, 0, 0), hourFormat);

    const formatText = (from: string, to: string) => (`${from} - ${to}`)

    return (
        <FilterSection translationKey="search_results.filter_by.departure_time.label">
            <DepartureTimeFilter
                departureTime="morning"
                icon={(
                    <IconMorning
                        width="20"
                        height="20"
                    />
                )}
                suffix={formatText(morning, afternoon)}
            />
            <DepartureTimeFilter
                departureTime="afternoon"
                icon={(
                    <IconAfternoon
                        width="20"
                        height="20"
                    />
                )}
                suffix={formatText(afternoon, evening)}
            />
            <DepartureTimeFilter
                departureTime="evening"
                icon={(
                    <IconEvening
                        width="20"
                        height="20"
                    />
                )}
                suffix={formatText(evening, night)}
            />
            <DepartureTimeFilter
                departureTime="night"
                icon={(
                    <IconNight
                        width="20"
                        height="20"
                    />
                )}
                suffix={formatText(night, morning)}
            />
        </FilterSection>
    );
}

export default DepartureTimeFilters;