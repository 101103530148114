import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import IconTravelDeals from "@Icons/home/IconTravelDeals";
import IconHeadsetWithLogomark from "@Icons/chat/IconHeadsetWithLogomark";
import {TravelingIntroDataType} from "@Pages/homepage/features/featureCards/types/TravelingIntroData.type";
import IconUserApp from "@Icons/home/IconUserApp";

export default function useTravelingPlanATripCards(): TravelingIntroDataType[] {
    const {
        urlsObject,
    } = useAppSelector((state) => state?.page)

    const {
        feature_cards: {
            travel_deals: {
                title: travelDealsTitle,
                description: travelDealsDescription,
            },
            help_center: {
                title: helpTitle,
                description: helpDescription,
            },
            mobile_app: {
                title: appTitle,
                description: appDescription,
            },
        },
    } = useTranslation("home_page")

    return [
        {
            type: "link",
            cardProps: {href: urlsObject.specialOffer.url},
            Icon: IconTravelDeals,
            title: travelDealsTitle,
            description: travelDealsDescription,
            cardId: "travelDeals",
        },
        {
            type: "link",
            cardProps: {href: urlsObject.trvlHelpCenter?.url},
            Icon: IconHeadsetWithLogomark,
            title: helpTitle,
            description: helpDescription,
            cardId: "help",
        },
        {
            type: "nav-link",
            cardProps: {to: urlsObject.mobileAppPage?.url},
            Icon: IconUserApp,
            title: appTitle,
            description: appDescription,
            cardId: "mobile-app",
        },
    ]
}