import Row from "@Containers/components/Row";
import React from "react";
import ControlCheckbox from "@Generic/form/ControlCheckbox";

interface MyProfileFilterRowProps {
    label: string,
    checked: boolean,
    disabled: boolean,
    setFilter: (value: boolean) => void,
}

function MyProfileFilterRow({label, checked, disabled, setFilter}: MyProfileFilterRowProps) {
    return (
        <Row
            reverse
            className="filter-item"
            gap
        >
            {label}
            <ControlCheckbox
                disableChange={disabled}
                checked={checked}
                onChange={() => {
                    setFilter(!checked)
                }}
            />
        </Row>
    );
}

export default MyProfileFilterRow