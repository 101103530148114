import React from "react";
import {TransportImageType} from "@Route/types/travelInfo/TransportImage.type";

interface SeatClassImageProps {
    image: TransportImageType,
    onClick: (value: any) => void,
    className: string,
}

function SeatClassImage({image, onClick, className}: SeatClassImageProps) {
    return (
        <img
            className={className}
            key={image.url}
            src={image.url}
            alt={image.type}
            onClick={onClick}
        />
    )
}

export default SeatClassImage