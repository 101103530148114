import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconBicycle(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    transform="translate(.8136 3.1255)"
                >
                    <path
                        d="m3.02306375 3.79696124c-1.66959201 0-3.02306375 1.33241107-3.02306375 2.9760234 0 1.64361234 1.35347174 2.9760234 3.02306375 2.9760234s3.02306375-1.33241106 3.02306375-2.9760234"
                        strokeWidth="1.2"
                    />
                    <path
                        d="m8.32670588 6.77298464c0 1.64361234 1.35347175 2.9760234 3.02306372 2.9760234 1.669592 0 3.0230638-1.33241106 3.0230638-2.9760234 0-1.64361233-1.3534718-2.9760234-3.0230638-2.9760234-.1853337 0-.3667721.01641824-.5428964.04785787"
                        strokeWidth="1.2"
                    />
                    <path
                        d="m11.4140103 6.89900804-1.6395335-6.89900804h2.1471009"
                        strokeWidth="1.14"
                    />
                    <path
                        d="m5.24809539 2.63252601-2.23596524 4.00411775h3.76199283c2.26526291-2.75087614 3.39789432-4.08558205 3.39789432-4.00411775 0 .08146431-1.64130728.08146431-4.92392191 0z"
                        strokeLinejoin="round"
                    />
                    <g strokeWidth="1.14">
                        <path d="m3.85647 1.117655h1.899201" strokeLinecap="round"/>
                        <path d="m4.80607 1.377318 1.968053 5.52169"/>
                    </g>
                </g>
            )}
        />
    )
}

export default IconBicycle