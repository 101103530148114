import React, {useState} from 'react';
import Translation from "@Translation/components/Translation";
import useSharedImagesPath from "@Images/hooks/useSharedImagesPath";
import useGetAccommodationMap
    from "@SearchResults/features/banners/features/accommodationBanner/hooks/useGetAccommodationMap";
import ParamsType from "@SearchResults/types/params/Params.type";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useUpdateAccommodationBanner
    from "@SearchResults/features/banners/features/accommodationBanner/hooks/useUpdateAccommodationBanner";
import IconAccommodationBanner from "@Icons/banners/IconAccommodationBanner";
import useIsMobile from "@Hooks/selector/useIsMobile";
import IconArrowAirplane from "@Icons/arrows/IconArrowAirplane";

type AccommodationBannerProps = {
    params: ParamsType,
}

const AccommodationBanner = (props: AccommodationBannerProps) => {
    const {
        params,
    } = props

    const {companyMainTransportType} = useAppSelector((state) => state.page)
    const iconPath = useSharedImagesPath(`images/stay22/${companyMainTransportType}.png`)

    const {getAccommodationMapData} = useGetAccommodationMap(params, iconPath)
    const [{url, bannerUrl}, setBannerUrl] = useState(getAccommodationMapData)

    useUpdateAccommodationBanner(params, setBannerUrl, iconPath)

    const isMobile = useIsMobile()

    return (
        <a
            className="stay22-banner gb--card gb--row gb--row-middle gb--row-justify gb--row-wrap"
            target="_blank"
            href={url}
            rel="noopener noreferrer"
        >
            <IconAccommodationBanner
                className="stay22-banner__icon"
                width={isMobile ? "60" : "100"}
                height={isMobile ? "60" : "100"}
            />
            <div className="stay22-banner__header-wrapper">
                <h2 className="header__title">
                    <Translation
                        translationKey="succ.staying_overnight"
                        translationVariables={{location: params.destinationStation.name}}
                    />
                    ?
                </h2>
                <Translation translationKey="succ.accommodation_within"/>
                <IconArrowAirplane
                    className="header__icon"
                />
            </div>
            <img
                src={bannerUrl}
                className="stay22-banner__map"
                id="stay-banner"
                alt={`${params.destinationStation.name} " accommodation map"`}
            />
        </a>
    );
}

export default AccommodationBanner;