import React from 'react';
import useIsMobile from "@Hooks/selector/useIsMobile";
import useRedirectToPartner from "@Route/hooks/useRedirectToPartner";
import useBuyTicketButtonLabelConfigurator
    from "@Route/features/ticketPurchase/hooks/useBuyTicketButtonLabelConfigurator";
import RouteWithAddedPropsAndPricesType from "@Route/types/RouteWithAddedPropsAndPrices.type";
import Badge from "@Features/badge/components/Badge";
import ParamsType from "@SearchResults/types/params/Params.type";
import BuyTicketLabel from "@Route/features/ticketPurchase/components/BuyTicketLabel";

/**
 * Redirects user to partner website, same tab if single, additional tab if return
 * @constructor
 */

interface RedirectButtonProps {
    route: RouteWithAddedPropsAndPricesType,
    className?: string,
    hideLabels?: boolean,
    params: ParamsType,
}

function RedirectButton(props: RedirectButtonProps) {
    const {
        route,
        className = "btn routes-list__cta routes-list__cta--operator ",
        hideLabels,
        params,
    } = props

    const {
        buyFromLabel,
        showBuyFromLabel,
        compactButtonClassName,
        buttonLabel,
        operatorClassName,
        capitalize,
    } = useBuyTicketButtonLabelConfigurator(route)

    const isMobile = useIsMobile()
    const {redirectToPartner} = useRedirectToPartner(route, params)

    const redirect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        redirectToPartner()
    }

    const suggestionClass = route.status.isSuggestion ? 'btn--compact btn--suggestion ' : ''

    return (
        <>
            {!hideLabels && showBuyFromLabel && (
                <Badge info className="redirect-badge">
                    {buyFromLabel}
                </Badge>
            )}
            <button
                type="submit"
                style={!capitalize ? {textTransform: "none"} : undefined}
                className={`${operatorClassName}${className}${compactButtonClassName}${suggestionClass}${isMobile ? ' btn--mobile' : ''}`}
                onClick={(e) => redirect(e)}
            >
                {route.status.isSuggestion ? (
                    <BuyTicketLabel
                        route={route}
                    />
                ) : (
                    buttonLabel
                )}
            </button>
        </>
    );
}

export default RedirectButton