import React, {useState} from 'react';
import Translation from "@Translation/components/Translation";
import SortingOptions from "./SortingOptions";
import ClickOutsideAwareComponent from "@Features/clickOutsideAwareComponent/components/ClickOutsideAwareComponent";
import IconChevron from "@Icons/chevrons/IconChevron";
import {Colors} from "@Styles/Colors.style";
import useCompanyStoreStyleForComponent from "@Features/companyStore/hooks/useCompanyStoreStyleForComponent";

interface SortProps {
    className?: string,
    sortingOptions: any[],
    title?: string,
    selectedLabel: string,
    onSelect: (any) => void,
    value?: string,
    label?: string,
    selected: any,
    additionalLabel?: string,
}

/**
 * TODO this is more dead than late dr. Franjo Tudman, document this somewhere if the idea is to use it generically
 */
function Sort(props: SortProps) {
    const {
        title,
        sortingOptions,
        selectedLabel,
        selected,
        onSelect,
        value = "value",
        label = "label",
        className = "sort",
        additionalLabel,
    } = props

    const [sortActive, setSortActive] = useState(false)
    const toggleSort = () => setSortActive(!sortActive)
    const closeSort = () => setSortActive(false)
    const onSelection = (option) => () => {
        onSelect(option)
        toggleSort()
    }
    const style = useCompanyStoreStyleForComponent('buttonBackgroundColor1', null)

    return (
        <div className={className}>
            {title && (
                <span>
                    <Translation translationKey={title}/>
                </span>
            )}
            <button
                className="gb--row gb--row-middle"
                type="submit"
                onClick={toggleSort}
            >
                {selectedLabel}
                <IconChevron height="16" fill={style ?? Colors.neutralWhiteColor}/>
            </button>

            {sortActive && (
                <ClickOutsideAwareComponent onClickOutside={closeSort}>
                    <SortingOptions
                        onSelection={(option) => onSelection(option)}
                        sortingOptions={sortingOptions}
                        value={value}
                        label={label}
                        additionalLabel={additionalLabel}
                        selected={selected?.val}
                    />
                </ClickOutsideAwareComponent>
            )}
        </div>
    );
}

export default Sort;