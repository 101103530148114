import React, {useEffect} from 'react'
import AppOverview from "@Pages/mobileApp/features/appOverview/components/AppOverview";
import MobileAppReviews from "@Pages/mobileApp/features/appReviews/components/MobileAppReviews";
import BookingsSection from "@Pages/mobileApp/features/bookingSection/components/BookingsSection";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useScrollToTopOnMount} from "@Scroll/hooks/useScrollToTopOnMount";
import ajaxHandler from "@Ajax/v2/ajaxHandler";
import useAsyncEffect from "@Hooks/promises/useAsyncEffect";
import TravelingDotComMTicketSection from "@Pages/mobileApp/components/TravelingDotComMTicketSection";
import FormTravelingDotCom from "@Form/components/FormTravelingDotCom";
import MobileAppPromo from "@Pages/mobileApp/components/MobileAppPromo";

const MobileAppLandingPage = () => {
    const {
        title: metaTitle,
        description: metaDescription,
    } = useTranslation("mobile_app.meta")

    useEffect(() => {
        document.title = metaTitle
        document.head.getElementsByTagName("meta")[2].content = metaDescription
    }, [])

    useAsyncEffect(async () => {
        const response = await ajaxHandler({
            url: "/ajax/get-mobile-app-landing-page-translations",
            type: "get",
            handle: "ignore",
        })
        if (response && response.status === 200) {
            window.routeTranslations = response.data
        }
    }, [])

    useScrollToTopOnMount()

    return (
        <ErrorBoundary shouldReload={false}>
            <div className="mobile-app-landing-page">
                <FormTravelingDotCom inverseColors/>
                <AppOverview/>
                <TravelingDotComMTicketSection/>
                <BookingsSection/>
                <MobileAppReviews/>
                <MobileAppPromo/>
            </div>
        </ErrorBoundary>
    )
}

export default MobileAppLandingPage