import {dismissModals} from "@MyProfile/reducers/userProfileSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {dismissAllOverlays} from "@Reducers/mobileSlice";

/**
 * Dismisses all modals & mobile overlays
 * @return {function(): void}
 */
export default function useDismissAllOverlays() {
    const dispatch = useAppDispatch()
    return () => {
        dispatch(dismissAllOverlays())
        dispatch(dismissModals())
    }
}