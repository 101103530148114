import getImageWidthAndHeight
    from "@SearchResults/features/banners/features/accommodationBanner/functions/getImageWidthAndHeight";
import {
    bannerUrlDefaultQueryParams
} from "@SearchResults/features/banners/features/accommodationBanner/constants/bannerUrlDefaultQueryParams";
import entries from "@Object/entries";
import ParamsType from "@SearchResults/types/params/Params.type";
import useCurrency from "@Currency/hooks/useCurrency";
import usePassengers from "@Hooks/selector/usePassengers";
import {Colors} from "@Styles/Colors.style";

/**
 * Returns accommodation banner href & image url
 */
export default function useGetAccommodationMap(params: ParamsType, iconPath: string) {
    const {
        accommodation: {lat, lon, url},
        date,
        returnDate,
    } = params

    const currency = useCurrency()
    const passengers = usePassengers()

    const handleQueryParams = (queryObj: Record<string, any>) => (
        entries(queryObj).map(([key, value]) => (`${key}=${encodeURIComponent(value)}`)).join('&')
    )

    const returnParams = () => (
        returnDate ? {checkout: returnDate} : {}
    )

    const getAccommodationMapData = () => {
        const {width, height} = getImageWidthAndHeight()

        const {
            passengersChildren: children,
            passengers: total,
        } = passengers

        const adults = total - children

        const queryObj = {
            ...bannerUrlDefaultQueryParams,
            lat,
            lng: lon,
            width,
            height,
            maincolor: Colors.neutralWhiteColor.substring(1).toLowerCase(), // your brand color in hex (without the #)
            markerimage: iconPath,
            adults,
            children,
            currency: currency.val,
            checkin: date,
            ...returnParams(),
        }

        const queryParams = handleQueryParams(queryObj)

        const urlQueryObj = {
            adults,
            children,
            currency: currency.val,
            checkin: date,
            ...returnParams(),
        };

        const urlQueryParams = handleQueryParams(urlQueryObj)

        return {
            url: `${url}&${urlQueryParams}`,
            bannerUrl: `https://static.stay22.com/embed/gm?${queryParams}`,
        };
    }

    return {
        getAccommodationMapData,
    }
}
