import useElementEventListener from "@Hooks/eventListeners/useElementEventListener";
import {MutableRefObject} from "react";

export default function useCloseDialogOnOutsideClick(
    dialogRef: MutableRefObject<HTMLDialogElement>,
    closeModal: () => void,
    isClosedClickOutside: boolean
) {
    const closeDialogOnOutsideClick = (e: React.MouseEvent) => {
        if (!isClosedClickOutside) {
            return;
        }

        if (e.currentTarget.tagName !== 'DIALOG') {
            return
        }

        const rect = e.currentTarget.getBoundingClientRect()

        if (!(
            e.clientY > rect.top
            && e.clientY <= rect.top + rect.height
            && e.clientX > rect.left
            && e.clientX <= rect.left + rect.width
        )) {
            closeModal()
        }
    }

    useElementEventListener(dialogRef, "mousedown", closeDialogOnOutsideClick)
}