import roundToTwoDecimals from "@Number/roundToTwoDecimals";
import PriceFractionDisplay from "@CheckOrder/features/paymentSummary/components/PriceFractionDisplay";

type BookingFeeProps = {
    bookingFeeLabel: string,
    bookingFee: string,
    paxCount: number,
}

function BookingFeePerPax({bookingFeeLabel, bookingFee, paxCount}: BookingFeeProps) {
    const [price, currency] = bookingFee.split(" ")
    const bookingFeePerPax = roundToTwoDecimals(parseFloat(price) / paxCount);

    return (
        <PriceFractionDisplay
            fractionTitle={`${paxCount} x ${bookingFeeLabel}`}
            price={`${bookingFeePerPax} ${currency}`}
        />
    );
}

export default BookingFeePerPax;