import React from 'react'
import Row from "@Containers/components/Row";
import BookingInfoType from "@Pages/mobileApp/features/bookingSection/types/bookingInfo.type";
import Column from "@Containers/components/Column";
import SourcedImage from "@Images/components/SourcedImage";

interface ImagesCarouselProps {
    orderedData: BookingInfoType[],
    centralPosition: number
}

const ImagesCarousel = ({orderedData, centralPosition}: ImagesCarouselProps) => {
    return (
        <Column center className="image-carousel-outer-container">
            <Row
                center
                className="image-carousel-container"
            >
                <SourcedImage
                    filePath={orderedData[centralPosition].imagePath}
                    fileType="png"
                />
            </Row>
        </Column>
    )
}

export default ImagesCarousel