import React from "react";
import useTravelingPlanATripCards from "@Pages/homepage/features/featureCards/hooks/useTravelingPlanATripCards";
import CardIconCentered from "@Generic/cards/CardIconCentered";

function FeatureCards() {
    const travelingDotComIntroData = useTravelingPlanATripCards()

    return (
        <div className="card-icon-centered__wrapper">
            {travelingDotComIntroData.map(({Icon, ...item}) => {
                return (
                    <CardIconCentered
                        key={item.cardId}
                        type={item.type}
                        cardProps={item.cardProps}
                        Icon={Icon}
                        title={item.title}
                        description={item.description}
                    />
                )
            })}
        </div>
    )
}

export default FeatureCards