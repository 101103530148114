import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Icon representing connection (looks like paper clip)
 */
function IconConnected(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={fill} transform="translate(1.2685 4.3349)">
                    <path
                        d="m.00063338 3.67374586c-.04237443-2.95043098 2.04433073-3.961821 5.39891952-3.60525544.32951465.03502473.5682459.33054229.53322117.66005695-.03502473.32951465-.3305423.5682459-.66005695.53322117-2.73943869-.2911801-4.10130301.36889206-4.07220749 2.39474456.03001584 2.08993196 1.27624387 2.71944992 4.07003782 2.41221192.32938509-.036223.62576901.20143182.66199201.53081691.03622301.32938508-.20143182.625769-.5308169.661992-3.40238692.37416594-5.35837758-.61388057-5.40108918-3.58778807z"
                    />
                    <path
                        d="m7.65203978 3.67374586c-.04237442-2.95043098 2.04433074-3.961821 5.39891952-3.60525544.3295147.03502473.5682459.33054229.5332212.66005695-.0350248.32951465-.3305423.5682459-.660057.53322117-2.7394387-.2911801-4.10130299.36889206-4.07220746 2.39474456.03001584 2.08993196 1.27624386 2.71944992 4.07003786 2.41221192.329385-.036223.625769.20143182.661992.53081691.036223.32938508-.2014318.625769-.5308169.661992-3.40238695.37416594-5.35837762-.61388057-5.40108922-3.58778807z"
                        transform="matrix(-1 0 0 1 21.239 0)"
                    />
                    <path
                        d="m9.50046477 3.18422145c.33137085 0 .60000003.26862915.60000003.6s-.26862918.6-.60000003.6h-5.54265098c-.33137085 0-.6-.26862915-.6-.6s.26862915-.6.6-.6z"
                    />
                </g>
            )}
        />
    )
}

export default IconConnected