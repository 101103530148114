import Translation from "@Translation/components/Translation";
import React from "react";

interface RouteClosedOrUnavailableOverlayProps {
    popover: string,
}

function RouteClosedOrUnavailableOverlay({popover}: RouteClosedOrUnavailableOverlayProps) {
    return (
        <span>
            <Translation translationKey={popover}/>
        </span>
    )
}

export default RouteClosedOrUnavailableOverlay