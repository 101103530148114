import Translation from "@Translation/components/Translation";
import useLoadDifferentDepartureTimeSlots from "@Tabs/hooks/useLoadDifferentDepartureTimeSlots";
import IconChevronTriple from "@Icons/chevrons/IconChevronTriple";
import {Colors} from "@Styles/Colors.style";

type EarlierOrLaterDeparturesProps = {
    timeslots: 'earlier' | 'later';
}

function LoadDifferentDepartureTimeslots({timeslots}: EarlierOrLaterDeparturesProps) {
    const {
        onPress,
        isVisible,
    } = useLoadDifferentDepartureTimeSlots(timeslots);

    const translationKey = timeslots === 'earlier' ? 'search_results.view_earlier_departures' : 'search_results.view_later_departures';

    const viewBox = timeslots === 'earlier' ? "-6 -10 25 25" : "-4 8 25 15";
    if (!isVisible) {
        return null
    }

    return (
        <button
            type="button"
            className="gb--different-departure-timeslots"
            onClick={onPress}
        >
            <Translation translationKey={translationKey}/>
            <IconChevronTriple
                chevronOrientation={timeslots === 'earlier' ? "up" : "down"}
                fill={Colors.secondaryColor}
            />
        </button>
    );
}

export default LoadDifferentDepartureTimeslots;