import React from 'react';
import IconFilterWithCount from "@Icons/IconFilterWithCount";
import {toggleFiltersOverlay} from "@Reducers/mobileSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {Palette} from "@Styles/Palette.style";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsTablet from "@Hooks/selector/useIsTablet";

function MobileFiltersButton() {
    const dispatch = useAppDispatch()
    const onClick = () => dispatch(toggleFiltersOverlay())
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const travelingColor = isMobile ? Palette.white : Palette.jet

    return (
        <button className="gb--sorter-mobile-filter-button" onClick={onClick} type="button">
            <IconFilterWithCount
                fill={travelingColor}
                width={(isMobile || isTablet) ? "20" : undefined}
                height={(isMobile || isTablet) ? "20" : undefined}
            />
        </button>
    );
}

export default MobileFiltersButton;