import React from "react"
import Column from "@Containers/components/Column"
import {isNotEmpty} from "@Array/isNotEmpty"
import RouteNotesSection from "@Route/components/Sections/RouteNotesSection"
import RouteNoteType from "@Route/types/notes/RouteNote.type"
import TravelingDotComTicketTypeSection from "@Route/features/notes/TravelingDotComTicketTypeSection";

interface RouteNotesAndTicketTypeProps {
    notes: RouteNoteType[],
    hasMTicket: boolean,
    displayTicketPrintType?: boolean,
}

const RouteNotesAndTicketType = (props: RouteNotesAndTicketTypeProps) => {
    const {
        hasMTicket,
        notes,
        displayTicketPrintType = true,
    } = props

    return (
        <Column className="gb--route-details__notes">
            {displayTicketPrintType && (
                <TravelingDotComTicketTypeSection
                    hasMTicket={hasMTicket}
                    cardClassName="gb--route-details__notes-ticket-type"
                    contentClassName="gb--route-details__notes-ticket-type__content"
                />
            )}
            {isNotEmpty(notes) && (
                <RouteNotesSection
                    notes={notes}
                />
            )}
        </Column>
    );
}

export default RouteNotesAndTicketType;