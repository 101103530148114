import Translation from "@Translation/components/Translation";
import React from "react";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";

interface DatePickerLabelProps {
    datepickerLabel: string,
    formVersion?: FormVersionType
}

function DatePickerLabel({datepickerLabel, formVersion = "1"}: DatePickerLabelProps) {
    const {v} = getFormVersionClassNameAggregator(formVersion)

    return (
        <span
            className={`gb--search${v()}__select--label`}
        >
            <Translation translationKey={datepickerLabel}/>
        </span>
    )
}

export default DatePickerLabel