import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";
import {RotationOrientationType} from "@Features/icons/types/RotationOrientationType";
import setOrientationArrowAirplane from "@Features/icons/functions/setOrientationArrowAirplane";

interface IconArrowAirplaneProps extends IconType {
    direction?: RotationOrientationType,
}

function IconArrowAirplane(props: IconArrowAirplaneProps) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        direction = "right",
        ...rest
    } = props

    const style = setOrientationArrowAirplane(direction as RotationOrientationType);
    const addedStyle = {
        verticalAlign: "middle",
        ...style,
    }

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            style={addedStyle}
            svgIconPath={(
                <path
                    clipRule="evenodd"
                    d="m10.5518 4.78156c0 .98918.3715 1.89123.9827 2.57426l-9.95149.00001v1.28765h9.95429c-.6109.68297-.9822 1.58482-.9822 2.57372h1.2876c0-1.42221 1.1515-2.57372 2.5737-2.57372v-1.28765c-.001 0-.0021-.00001-.0031-.00001h-.0001v-.00058c-1.4222 0-2.5737-1.1515-2.5737-2.57368z"
                    fill={fill}
                    fillRule="evenodd"
                />
            )}
        />
    )
}

export default IconArrowAirplane