import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconMorning(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke}>
                    <g transform="translate(.7372 6.0092)">
                        <path
                            d="m7.07220877 2.29576888c.73849214 0 1.40704044.29972539 1.89098595.78422104.48419209.4847425.78361277 1.15442414.78361277 1.89410016 0 .79110562-.34254418 1.50211225-.8872357 1.99248414-.47363997.42640599-1.10024421.68583707-1.78736302.68583707-.65268098 0-1.25078393-.23404293-1.71501518-.622997-.58651439-.49140846-.95958355-1.22974064-.95958355-2.05532421 0-.73967602.29942069-1.40935766.78361277-1.89410016.48394551-.48449565 1.15249382-.78422104 1.89098596-.78422104z"
                            strokeWidth="1.2"
                        />
                        <g strokeLinecap="round">
                            <path d="m.285797 7.819277h14" strokeWidth="1.25"/>
                            <path
                                d="m12.195862.554674-1.318525 1.319943"
                                strokeWidth="1.2"
                                transform="matrix(.9961477 .08769125 -.08769125 .9961477 .164369 -.992394)"
                            />
                            <path
                                d="m13.970039 4.153768-1.318525 1.319942"
                                strokeWidth="1.2"
                                transform="matrix(-.70672657 -.70748679 .70748679 -.70672657 18.927187 17.793258)"
                            />
                            <path
                                d="m2.262894 4.153768-1.318525 1.319942"
                                strokeWidth="1.2"
                                transform="matrix(-.70672657 -.70748679 .70748679 -.70672657 -1.053631 9.51064)"
                            />
                            <path
                                d="m3.240251.263896-1.318525 1.319943"
                                strokeWidth="1.2"
                                transform="matrix(.9961477 -.08769125 -.08769125 -.9961477 .104369 2.375787)"
                            />
                        </g>
                    </g>
                    <g strokeLinecap="round" strokeWidth="1.2" transform="translate(5.5767 1.4913)">
                        <path
                            d="m0 0 2.23274196 2.10336447c1.51055135-1.40224298 2.26582702-2.10336447 2.26582702-2.10336447"
                            strokeLinejoin="round"
                            transform="matrix(-1 0 0 -1 4.4986 2.1034)"
                        />
                        <path
                            d="m2.232742 0-.011742 4.844988"
                            transform="matrix(-1 0 0 -1 4.4538 4.845)"
                        />
                    </g>
                </g>

            )}
        />
    )
}

export default IconMorning