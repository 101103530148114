import {smallMobileScreenWidth} from "@Reducers/constants/screenWidths";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useSharedImagesPath from "@Images/hooks/useSharedImagesPath";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useSharedImagesPathAsMethod from "@Images/hooks/useSharedImagesPathAsMethod";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

type LargeFormPictureAndIntroTextProps = {
    inverseColors?: boolean,
}

function LargeFormPictureAndIntroText({inverseColors}: LargeFormPictureAndIntroTextProps) {
    const {getPath} = useSharedImagesPathAsMethod()
    const isMobileAppPage = useRouteMatch('mobileApp')
    const getFormConfiguration = () => {
        switch (window.currentRoute) {
            case 'global_review_page':
                return {
                    title: 'global_review_page.form.title',
                    description: 'global_review_page.form.description',
                    imagePath: null,
                    displayAsH1: false,
                }
            default:
                // This is crap but since we need to evaluate
                // the client routing in this case we don't have many options
                if (isMobileAppPage) {
                    return {
                        title: 'mobile_app.form.title',
                        description: 'mobile_app.form.description',
                        imagePath: getPath('images/mobile-app-landing-page/form_app_promo_hero.png'),
                        displayAsH1: false,
                    }
                }
                return {
                    title: 'form.title',
                    description: 'form.subtitle',
                    imagePath: null,
                    displayAsH1: true,
                }
        }
    }
    const desktopPath = useSharedImagesPath('images/layout/header-traveling-dot-com-woman-hero.png')
    const mobilePath = useSharedImagesPath('images/layout/header-traveling-dot-com-woman-hero-mobile.png')
    const inversedDesktopPath = useSharedImagesPath('images/layout/header-traveling-dot-com-woman-hero-inversed.png')
    const inversedMobilePath = useSharedImagesPath('images/layout/header-traveling-dot-com-woman-hero-mobile-inversed.png')
    const formIntroH1 = useTranslation(getFormConfiguration().title)
    const formIntroH4 = useTranslation(getFormConfiguration().description)
    const inversedTextColorClass = inverseColors ? 'gb--search-description-inversed-colors' : '';
    const isMobile = useIsMobile()

    // it's shit solution, but it works - prevent layout shift on removing placeholder
    const imageHeightStyle = isMobile ? {minHeight: '158px', width: "100%"} : {};

    return (
        <div className="gb--search-pre-content">
            <div className={`gb--search-description ${inversedTextColorClass}`}>
                {getFormConfiguration().displayAsH1 ? (
                    <h1 className="form-title huge">
                        {formIntroH1}
                    </h1>
                ) : (
                    <span className="form-title huge">
                        {formIntroH1}
                    </span>
                )}
                <span className="form-subtitle">
                    {formIntroH4}
                </span>
            </div>
            <picture className="gb--search-background__wrapper" style={imageHeightStyle}>
                <source
                    media={`(min-width: ${smallMobileScreenWidth}px)`}
                    type="image/png"
                    srcSet={getFormConfiguration().imagePath ?? (inverseColors ? inversedDesktopPath : desktopPath)}
                />
                <img
                    className="gb--search-background"
                    src={getFormConfiguration().imagePath ?? (inverseColors ? inversedMobilePath : mobilePath)}
                    alt="Traveling.com"
                />
            </picture>
        </div>
    );
}

export default LargeFormPictureAndIntroText;