import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconCheckmarkPurple = (props: IconType) => {
    const {
        width = '16',
        height = '16',
        fill = '#fff',
        viewBox = '0 0 16 16',
        title = 'IconCheckmarkPurple',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...props}
            svgIconPath={(
                <>
                    <path
                        d="M7.90425 0.300781V1.69913C4.4244 1.69913 1.60343 4.5201 1.60343 7.99995C1.60343 11.4798 4.4244 14.3008 7.90425 14.3008C11.3841 14.3008 14.2051 11.4798 14.2051 7.99995H15.6034C15.6034 12.2521 12.1564 15.6991 7.90425 15.6991C3.65212 15.6991 0.205078 12.2521 0.205078 7.99995C0.205078 3.74782 3.65212 0.300781 7.90425 0.300781Z"
                        fill="#7E00D9"
                    />
                    <path
                        d="M7.09656 11.1923L15.0966 3.19226L13.9812 2.07688L7.09656 8.92303L4.63502 6.46149L3.51964 7.61534L7.09656 11.1923Z"
                        fill="#7E00D9"
                    />
                </>
            )}
        />
    );
};

export default IconCheckmarkPurple;
