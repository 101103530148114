import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";
import GenericSvgIcon from "@Icons/GenericSvgIcon";

/**
 * Main traveling.com train icon, not filled
 */
function IconTrain(props: IconType) {
    const {
        width = 16,
        height = 16,
        viewBox = "0 0 16 16",
        title = "IconTrain",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, title, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} transform="translate(2.919 1.371)">
                    <path d="m3.10822 10.46433-.33129.6547c-.19261.3767-.43529.7193-.7242 1.0199"/>
                    <path
                        d="m2.05319 12.13893c-.41988.4377-.93222.7878-1.50619 1.02-.17335.0723-.35825.0989-.547.0989"
                    />
                    <path d="m2.05273 12.13893h6.05562"/>
                    <path
                        d="m7.05322 10.46433.33133.6547c.1926.3767.4352.7193.7242 1.0199.4198.4377.9322.7879 1.5061 1.02.1734.0724.3583.099.5471.099"
                    />
                    <path
                        d="m1.69439 8.79363h1.69494m3.38218 0h1.69494m-8.1858-2.10848c.85517.16366 2.6079.43768 4.79592.43768 2.18798 0 3.94078-.27402 4.79588-.43768m-9.5918-4.57856h9.5918m-9.5918 8.06484v-9.48445c0-.14844.10786-.27403.25424-.29306l.85903-.1256c2.43841-.35776 4.92304-.35776 7.36143 0l.8591.1256c.1463.01903.2542.14462.2542.29306v9.48445c0 .1636-.1348.2968-.3005.2968h-8.99474c-.16564 0-.30047-.1332-.30047-.2968z"
                    />
                </g>
            )}
        />
    )
}

export default IconTrain