import IconBus from "@Icons/transportType/IconBus";
import IconFerry from "@Icons/transportType/IconFerry";
import IconTrain from "@Icons/transportType/IconTrain";
import IconTransfer from "@Icons/transportType/IconTransfer";
import IconPlane from "@Icons/transportType/IconPlane";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";

export default function useGenerateTransportSections() {
    const {
        bus,
        ferry,
        train,
        other,
    } = useTranslation("mobile_app.transport_types")

    return [
        {
            title: bus.title,
            icons: [<IconBus width="72" height="72" key="bus"/>],
            content: bus.description,
        },
        {
            title: ferry.title,
            icons: [<IconFerry width="72" height="72" key="ferry"/>],
            content: ferry.description,
        },
        {
            title: train.title,
            color: "orange",
            icons: [<IconTrain width="72" height="72" key="train"/>],
            content: train.description,
        },
        {
            title: other.title,
            color: "red",
            icons: [
                <IconTransfer width="72" height="72" key="car"/>,
                <IconPlane width="72" height="72" key="airplane"/>,
            ],
            content: other.description,
        },
    ]
}