import CheckOrderInputContainer from "@CheckOrder/components/MainSection/CheckOrderInputContainer";
import DropdownButton from "@Generic/buttons/DropdownButton";
import React from "react";
import {VehicleAddonResponseType} from "@CheckOrder/api/types/GetAddonData.type";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface VehicleTypeProps {
    selectOptions: { value: number, label: string, activeGroup: VehicleAddonResponseType }[],
    activeOption: { value: number, label: string, activeGroup: VehicleAddonResponseType },
    setVehicleType: (value) => void,
}

function VehicleType({selectOptions, activeOption, setVehicleType}: VehicleTypeProps) {
    const {
        vehicle_type: vehicleTypeLabel,
        // "you must select your vehicle model"
    } = useTranslation("check_order.addons.JADL.vehicle")

    const getActiveLabel = () => (
        selectOptions.find((option) => option.value === activeOption.value)?.label ?? "title"
    )

    return (
        <CheckOrderInputContainer label={vehicleTypeLabel}>
            <DropdownButton
                label={getActiveLabel()}
                baseClass="gb--select"
                cssButtonClass="gb--select-dropdown"
            >
                {selectOptions.map((option) => (
                    <button
                        type="button"
                        key={option.value}
                        value={option.value}
                        onClick={() => setVehicleType(option)}
                        className="gb--select__option full-width"
                    >
                        {option.label}
                    </button>
                ))}
            </DropdownButton>
        </CheckOrderInputContainer>
    )
}

export default VehicleType