import React from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import Link from "@Generic/navigation/Link";
import Translation from "@Translation/components/Translation";
import useSharedImagesPath from "@Images/hooks/useSharedImagesPath";

function MobileAppPromoNotice({departureStation, destinationStation}) {
    const urlToBlogPage = `/hr/blog/popgbmapp`
    const imagePath = useSharedImagesPath(`images/banner/mobile-app-m-ticket.png`)
    const mobileAppNotice = useTranslation('search_results.use_mobile_app_discount', {
        departureStation,
        destinationStation,
    })

    return (
        <Link
            href={urlToBlogPage}
            target="_blank"
            className="gb--card gb--card__mobile-promo"
        >
            <div className="mobile-app-image">
                <img
                    alt="Download our app"
                    src={imagePath}
                />
            </div>
            <h2>5% POPUSTA</h2>
            <div
                className="gb--emphasize gb--emphasize-default mobile-app-notice">
                {mobileAppNotice}
            </div>
            <span
                className="mobile-app-redirect-button"
            >
                <Translation translationKey="search_results.click_here"/>
            </span>
        </Link>
    )
}

export default MobileAppPromoNotice;