import {Dispatch, SetStateAction} from "React"

/**
 * Used to move sliders by a step left or right.
 * Returns functions for both limited left/right scroll and infinite scroll
 *
 * TODO add a config object param (if needed) with attributes like "step" and if anything more is needed
 */
export default function slide(setSlideIndex: Dispatch<SetStateAction<number>>, lastIndex: number) {
    const step = 1

    const slideLeftInfinite = () => {
        setSlideIndex((prevIndex) => (
            prevIndex === 0 ? lastIndex : prevIndex - step
        ))
    }

    const slideRightInfinite = () => {
        setSlideIndex((prevIndex) => (
            prevIndex === lastIndex ? 0 : prevIndex + step
        ))
    }

    const slideLeft = () => {
        setSlideIndex((prevIndex) => (
            prevIndex === 0 ? 0 : prevIndex - step
        ))
    }
    const slideRight = () => {
        setSlideIndex((prevIndex) => (
            prevIndex === lastIndex ? lastIndex : prevIndex + step
        ))
    }

    return {
        slideLeft,
        slideRight,
        slideLeftInfinite,
        slideRightInfinite,
    }
}