import React from 'react';
import Column from "@Containers/components/Column";
import {useTranslation} from "@Translation/hooks/useTranslation";
import OtherMeansOfTransportation from "@SearchResults/components/NoResults/OtherMeansOfTransportation";
import useTabLabelConfigurator from "@Tabs/hooks/useTabLabelConfigurator";
import {RouteTransferTypeValuesType} from "@Route/features/transportType/types/routeTransferType.type";
import TabType from "@Tabs/types/Tab.type";

interface NoResultsForVehicleTypeProps {
    vehicleType: RouteTransferTypeValuesType,
    tabsToShow: TabType[],
    departureStation: string,
    destinationStation: string,
}

function NoResultsForVehicleType(props: NoResultsForVehicleTypeProps) {
    const {
        vehicleType,
        tabsToShow,
        departureStation,
        destinationStation,
    } = props

    const transportType = useTabLabelConfigurator(vehicleType).toLowerCase()
    const noResultsTitle = useTranslation('search_results.no_connections_title', {
        transportType,
    })
    const noResultsDescription = useTranslation('search_results.no_connections_description', {
        transportType,
        departureStation,
        destinationStation,
    })

    return (
        <Column center className="gb--notification__no-connection">
            <p className="no-connection__title">
                {noResultsTitle}
            </p>
            {noResultsDescription}
            <br/>
            <br/>
            <OtherMeansOfTransportation
                tabsToShow={tabsToShow}
            />
        </Column>
    )
}

export default NoResultsForVehicleType;