import React from 'react'
import Row from "@Containers/components/Row";
import TravelingDotComBookingsCarousel
    from "@Pages/mobileApp/features/bookingSection/components/TravelingDotComBookingsCarousel";

const BookingsSection = () => {
    return (
        <Row className="bookings-section-background">
            <TravelingDotComBookingsCarousel/>
        </Row>
    )
}

export default BookingsSection