import React, {Fragment} from 'react';
import {useTranslation} from "@Translation/hooks/useTranslation";
import Column from "@Containers/components/Column";
import VehicleTypeIcon from "@Generic/vehicle/VehicleTypeIcon";
import StationWithDateTimesType from "@SearchResults/types/station/StationWithDateTimes.type";
import getFormattedTravelTime from "@Route/functions/getFormattedTravelTime";

interface RouteBusChangeDetailsProps {
    busChange: StationWithDateTimesType[],
    companyLetterCode: string,
}

function RouteBusChangeDetails({busChange, companyLetterCode}: RouteBusChangeDetailsProps) {
    const {arrival, departure} = useTranslation("sys")

    return (
        <>
            {busChange.map((stop) => {
                const {
                    id,
                    name,
                    arrivalTime,
                    departureTime,
                } = stop
                const label = `${arrival} - ${getFormattedTravelTime({travelTime: arrivalTime})} | 
                       ${departure} - ${getFormattedTravelTime({travelTime: departureTime})}`

                return (
                    <Fragment key={id}>
                        <Column>
                             <span className="gb--emphasize-default">
                                {name}
                             </span>
                            <small>
                                {label}
                            </small>
                        </Column>
                        <span className="gb--label gb--label-default">
                            <VehicleTypeIcon
                                width="10"
                                height="10"
                                fill="#070c63"
                                className="vehicle-icon"
                            />
                            {companyLetterCode}
                        </span>
                    </Fragment>
                )
            })}
        </>
    );
}

export default RouteBusChangeDetails;