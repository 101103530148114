import React, {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import useFormFields from "../../../../hooks/state/useFormFields";
import InputField from "@Generic/form/InputField";
import {useTranslation} from "@Translation/hooks/useTranslation";
import TermWithCheckbox from "@Generic/form/TermWithCheckbox";
import validateEmail from "../../functions/validateEmail";
import validateUserName from "../../functions/validateUserName";
import LoaderButton from "@Generic/loaders/LoaderButton";
import Password from "@Generic/form/Password";
import validatePassword from "../../functions/validatePassword";
import validatePasswordsMatch from "../../functions/validatePasswordsMatch";
import useRegister from "../../hooks/useRegister";
import Notification from "../../../notification/components/Notification";
import {isNotEmpty} from "@Array/isNotEmpty";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import NameErrorMessage from "@User/components/NameErrorMessage";
import PasswordErrorMessage from "@User/components/PasswordErrorMessage";
import Consent from "@User/components/Consent";

function SignUp() {
    const {
        email: emailLabel,
        password: passwordLabel,
        repeat_password: confirmPassword,
        sign_up: signUp,
    } = useTranslation('user')

    const {
        first_name: firstName,
        last_name: lastName,
    } = useTranslation('check_order')

    const {
        terms_of_service: terms,
        you_must_accept: mustAccept,
    } = useTranslation('sys')

    const {
        invalid_email: invalidEmail,
        password_too_short: passwordToShort,
    } = useTranslation('log_in')

    const [userConfirmed, setUserConfirmed] = useState(false)
    const [userConsent, setUserConsent] = useState(false)
    const [fields, handleFieldChange] = useFormFields({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
    })

    const [errorFirstName, handleErrorFirstName] = useState(null)
    const [errorLastName, handleErrorLastName] = useState(null)
    const [errorEmail, handleErrorEmail] = useState(null)
    const [errorPassword, handleErrorPassword] = useState(null)
    const [errorConfirmPassword, handleErrorConfirmPassword] = useState(null)

    const {submit, recaptchaRef, errors} = useRegister(fields)
    const {loading} = useAppSelector((state) => state?.userProfile)
    const firstNameValidation = () => handleErrorFirstName(!validateUserName(fields.firstName))
    const lastNameValidation = () => handleErrorLastName(!validateUserName(fields.lastName))
    const emailValidation = () => handleErrorEmail(!validateEmail(fields.email))
    const passwordValidation = () => handleErrorPassword(!validatePassword(fields.password))
    const confirmPasswordValidation = () => handleErrorConfirmPassword(
        !validatePasswordsMatch(fields.password, fields.confirmPassword)
    )

    const handleSubmit = (e) => {
        e.preventDefault();
        setUserConfirmed(true)

        const hasNoErrors = [
            errorFirstName,
            errorLastName,
            errorEmail,
            errorPassword,
            errorConfirmPassword,
        ].every((error) => error === false)

        if (hasNoErrors && userConsent) {
            submit()
        } else {
            firstNameValidation()
            lastNameValidation()
            emailValidation()
            passwordValidation()
            confirmPasswordValidation()
        }
    };

    return (
        <form onSubmit={handleSubmit} className="sign-up__form">
            <InputField
                label={firstName}
                id="firstName"
                type="text"
                value={fields.firstName}
                onChange={handleFieldChange}
                onBlur={firstNameValidation}
                showError={!!errorFirstName}
                errorMessage={(
                    <NameErrorMessage
                        field={fields.firstName}
                        fieldLabel={firstName}
                    />
                )}
            />
            <InputField
                label={lastName}
                id="lastName"
                value={fields.lastName}
                onChange={handleFieldChange}
                onBlur={lastNameValidation}
                showError={!!errorLastName}
                errorMessage={(
                    <NameErrorMessage
                        field={fields.lastName}
                        fieldLabel={lastName}
                    />
                )}
            />
            <InputField
                label={emailLabel}
                id="email"
                value={fields.email}
                onChange={handleFieldChange}
                onBlur={emailValidation}
                showError={!!errorEmail}
                errorMessage={invalidEmail}
            />

            <Password
                label={passwordLabel}
                id="password"
                value={fields.password}
                onChange={handleFieldChange}
                onBlur={passwordValidation}
                showError={!!errorPassword}
                errorMessage={passwordToShort}
                strengthValidator
            />

            <Password
                label={confirmPassword}
                id="confirmPassword"
                value={fields.confirmPassword}
                onChange={handleFieldChange}
                onBlur={confirmPasswordValidation}
                showError={!!errorConfirmPassword}
                errorMessage={<PasswordErrorMessage fields={fields}/>}
            />

            <TermWithCheckbox
                onClick={() => setUserConsent(!userConsent)}
                value={userConsent}
                errorLabelText={`${mustAccept} ${terms}`}
                userConfirmed={userConfirmed}
            >
                <Consent/>
            </TermWithCheckbox>

            <button name="_submit" type="submit" className="btn btn--green btn--sign">
                {loading ? (
                    <LoaderButton/>
                ) : (
                    <>
                        {signUp}
                    </>
                )}
            </button>

            {isNotEmpty(errors) && errors.map((error) => (
                <Notification notificationType="red" key={error}>
                    {error}
                </Notification>
            ))}

            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                badge="inline"
                sitekey={window.googleInvisibleCaptchaSiteKey}
            />

        </form>
    );
}

export default SignUp;