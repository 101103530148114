import React, {useLayoutEffect} from 'react';
import RepeatElement from "@Generic/utility/RepeatElement";
import toggleStaticMainContentElements from "@Features/domElements/functions/toggleStaticMainContentElements";

function SearchResultsPlaceholder() {
    useLayoutEffect(() => {
        toggleStaticMainContentElements(false)
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <div className="gb--row gb--dateslider" style={{margin: "1em"}}>
                <RepeatElement numTimes={10}>
                    <div className="placeholder-results-item" style={{width: "10%"}}>
                        <div className="animated-background">
                            <div className="background-mask content-first-end"/>
                            <div className="background-mask content-second-line"/>
                            <div className="background-mask content-second-end"/>
                            <div className="background-mask content-third-line"/>
                            <div className="background-mask content-third-end"/>
                        </div>
                    </div>
                </RepeatElement>
            </div>
            <div className="gb--tabs gb--row">
                <RepeatElement numTimes={2}>
                    <div className="placeholder-results-item" style={{width: "50%"}}>
                        <div className="animated-background" style={{height: "60px", margin: "1rem"}}/>
                    </div>
                </RepeatElement>
            </div>
            <div className="placeholder-tabs-wrapper">
                <div className="placeholder-results-wrapper">
                    <RepeatElement numTimes={12}>
                        <div className="placeholder-results-item">
                            <div className="animated-background">
                                <div className="background-mask content-first-end"/>
                                <div className="background-mask content-second-line"/>
                                <div className="background-mask content-second-end"/>
                                <div className="background-mask content-third-line"/>
                                <div className="background-mask content-third-end"/>
                            </div>
                        </div>
                    </RepeatElement>
                </div>
            </div>
        </>
    );
}

export default SearchResultsPlaceholder;