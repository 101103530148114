import {useEffect} from "react";

/**
 * Event listener hook with built in cleanup but for refs
 */
export default function useElementEventListener(ref: React.MutableRefObject<any>, type: string, handler: (e?: any) => void) {
    useEffect(() => {
        if (!ref.current) {
            return
        }

        ref?.current?.addEventListener(type, handler);

        // cleanup this component
        return () => {
            ref?.current?.removeEventListener(type, handler);
        };
    }, [ref?.current]);
}