import React from 'react';
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import TravelInfo from "@SearchResults/features/travelInfo/components/TravelInfo";
import MobileAppTitle from "@SearchResults/components/HeadingContent/Mobile/MobileAppTitle";
import Row from "@Containers/components/Row";
import BackButton from "@Routing/components/BackButton";
import Timer from "@CheckOrder/components/Sidebar/Timer";
import RouteType from "@Route/types/Route.type";
import useResolvePreviousRouteFromCheckOrderData from "@CheckOrder/hooks/useResolvePreviousRouteFromCheckOrderData";

function CheckOrderTitle({singleRoute}: { singleRoute: RouteType }) {
    const {
        travelInfo: {
            departureStation: {
                cityName: departureStationName,
                departureDate,
            },
            destinationStation: {
                cityName: destinationStationName,
            },
        },
    } = singleRoute
    const isDesktop = useIsDesktop()
    const {goBack} = useResolvePreviousRouteFromCheckOrderData()

    return (
        <div className="gb--travel-info">
            {!isDesktop ? (
                <MobileAppTitle
                    backClick={goBack}
                    subtitleComponent={<Timer/>}
                >
                    <TravelInfo
                        departureStationName={departureStationName}
                        destinationStationName={destinationStationName}
                        date={departureDate}
                    />
                </MobileAppTitle>
            ) : (
                <Row justify className="gb--travel-info__wrapper">
                    <Row>
                        <BackButton
                            backClick={goBack}
                        />
                        <TravelInfo
                            departureStationName={departureStationName}
                            destinationStationName={destinationStationName}
                            date={departureDate}
                        />
                    </Row>
                    <Timer/>
                </Row>
            )}
        </div>
    )
}

export default CheckOrderTitle;