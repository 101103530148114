import React from 'react';
import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "@/types/Icon.type";

const IconMobileApp = (props: IconType) => {
    const {
        width = '68',
        height = '68',
        fill = '#fff',
        viewBox = '0 0 68 68',
        title = 'IconMobileApp',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...props}
            svgIconPath={(
                <>
                    <g filter="url(#filter0_d_1366_5913)">
                        <rect x="9" y="3" width="50" height="50" rx="6" fill="#7E00D9" shapeRendering="crispEdges"/>
                        <g clipPath="url(#clip0_1366_5913)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M48.828 15.3355L48.8234 15.3471C48.7644 15.4968 48.7016 15.645 48.6389 15.7933C48.5004 16.1167 48.2889 16.5893 48.0073 17.1581C47.4394 18.306 46.6127 19.7931 45.5615 21.2208C45.4941 21.3123 45.4263 21.4029 45.3584 21.4926C44.4471 19.242 42.8526 17.4495 40.9463 16.3212C39.1767 15.2737 37.0362 14.7467 34.9124 15.1194C32.7045 15.507 30.7457 16.837 29.536 19.0319C27.7087 22.3477 28.3607 25.6668 30.2377 28.0568C31.9804 30.2759 34.7177 31.6779 37.4126 32.0497C37.5264 32.0655 37.6403 32.0795 37.7532 32.0915C37.1482 33.1163 36.4757 33.9476 35.8042 34.6024C35.0918 35.2972 34.2845 35.972 33.35 36.3447C31.6417 37.0264 29.7033 36.353 28.4625 35.1099C26.7244 33.3677 24.3502 29.3037 22.2803 19.8926L16.0005 21.2742C18.1526 31.0603 20.8606 36.5947 23.911 39.6521C26.1542 41.9004 29.042 43.0599 32.2198 42.9977C35.1522 42.9405 37.849 41.4743 39.9634 39.5206C42.0203 37.6199 43.5813 35.2228 44.6395 32.6385C44.8031 32.2393 44.9546 31.8355 45.0949 31.4275C45.1669 31.2183 45.4941 30.149 45.4941 30.149C47.7234 28.7139 49.481 26.7435 50.7399 25.0338C52.0815 23.2121 53.096 21.3746 53.7713 20.0097C54.0594 19.4269 54.2908 18.9213 54.4628 18.5272L48.828 15.3355ZM37.6714 21.8551C38.7017 22.4648 39.6985 23.7395 39.7501 25.6542C39.2668 25.7369 38.7803 25.7471 38.2918 25.6798C37.038 25.5069 35.885 24.8363 35.2948 24.0848C34.5931 23.1912 34.7502 21.6762 36.0235 21.4526C36.3967 21.3871 36.9948 21.455 37.6705 21.8551H37.6714Z"
                                fill="#81EEB8"
                            />
                            <path
                                d="M36.5684 38.1832C39.1141 36.3764 41.718 32.9979 42.7947 27.8278C43.7251 23.3618 41.4814 20.0419 38.7539 18.7773"
                                stroke="url(#paint0_linear_1366_5913)"
                                strokeWidth="6.43083"
                                strokeLinejoin="bevel"
                            />
                            <path
                                d="M48.8351 15.3398C48.4145 16.333 47.2894 18.8002 45.5974 21.1191C44.4235 22.728 43.2194 23.9409 42.0185 24.724C40.7643 25.5419 39.562 25.8579 38.3417 25.6897C36.9345 25.4954 35.5027 24.6324 35.0867 23.6653C34.9222 23.2824 34.7521 22.6978 35.057 22.129L29.5384 19.0409C28.1944 21.4296 28.102 24.1998 29.2968 26.5819C30.0357 28.0547 31.243 29.354 32.7878 30.3392C34.1959 31.2375 35.8126 31.8324 37.4624 32.0601C37.9364 32.1256 38.4425 32.1647 38.9774 32.1647C42.2681 32.1647 46.6221 30.6873 50.8794 24.7895C52.5938 22.4148 53.8044 19.9857 54.4615 18.5237L48.8351 15.3398Z"
                                fill="#81EEB8"
                            />
                        </g>
                    </g>
                    <defs>
                        <filter id="filter0_d_1366_5913" x="0.428572" y="0.142858" width="67.1429" height="67.1429"
                                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="5.71429"/>
                            <feGaussianBlur stdDeviation="4.28571"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix"
                                           values="0 0 0 0 0.894118 0 0 0 0 0.909804 0 0 0 0 0.945098 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1366_5913"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1366_5913"
                                     result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_1366_5913" x1="40.8921" y1="35.1709" x2="43.0086" y2="22.6234"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#81EBB8"/>
                            <stop offset="0.5" stopColor="#71C79D"/>
                            <stop offset="1" stopColor="#81EBB8"/>
                        </linearGradient>
                        <clipPath id="clip0_1366_5913">
                            <rect width="38.4623" height="28" fill="white" transform="translate(16 15)"/>
                        </clipPath>
                    </defs>
                </>
            )}
        />
    );
};

export default IconMobileApp;
