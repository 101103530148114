import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconUserApp(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={fill}>
                    <path
                        clipRule="evenodd"
                        d="m4.08594 2.39624v10.04816h3.28577v1h-3.53439c-.4144 0-.75138-.337-.75138-.7514v-10.54538c0-.4144.33698-.75138.75138-.75138h8.32568c.4144 0 .7514.33698.7514.75138v5.86607l-.0003.07973c-.0003.05064-.0007.12318-.0014.21104-.0014.17561-.0038.41296-.0081.65921-.0043.24565-.0104.50285-.0193.71727-.0079.19258-.0195.40586-.0447.52446l-.0006.0029c-.1391.6347-.4719 1.2729-.9886 1.7965.0753.2831.1021.5901.0615.91-.0719.5652-.3494 1.1337-.8732 1.6538l-.7046-.7095c.3862-.3836.5455-.7534.5857-1.0703.004-.0314.0069-.0627.0087-.0937-.4191.2246-.8385.2936-1.21984.2133-.38356-.0807-.69037-.3053-.88304-.5949-.1906-.2865-.27503-.646-.20255-.9997.07463-.3643.30828-.685.6806-.8875.43981-.2393.93063-.1608 1.31723.0314.2729.1357.5269.3413.7369.5948.2571-.3303.4256-.6989.5043-1.05826l.4884.10706-.489-.10418c-.0001.00057 0 .00004.0002-.0017.001-.00744.0049-.0371.0095-.09922.0051-.06781.0098-.15488.014-.25647.0083-.20272.0143-.45078.0185-.69344.0042-.24205.0066-.47604.008-.6497.0007-.08677.0011-.15834.0014-.20815l.0003-.07613v-5.61607zm6.52836 9.32606c-.1317-.1681-.2878-.2947-.4376-.3692-.20579-.1023-.33492-.0806-.3941-.0484-.12667.0689-.1664.1489-.17888.2098-.01464.0715.00007.1618.0555.2451.05338.0803.1395.1456.25642.1702.11466.0242.29796.0165.55026-.1209.0508-.0277.1002-.0566.1484-.0866z"
                        fillRule="evenodd"
                    />
                    <path
                        d="m9.87594 7.0763h-.7465c-.05153 0-.09331.04178-.09331.09331v.7465c0 .05154.04178.09331.09331.09331h.7465c.05154 0 .09331-.04177.09331-.09331v-.7465c0-.05153-.04177-.09331-.09331-.09331z"
                    />
                    <path
                        d="m8.3814 6.3296h.55987c.05153 0 .09331.04178.09331.09331v.55987c0 .05154-.04178.09331-.09331.09331h-.55987c-.05154 0-.09331-.04177-.09331-.09331v-.55987c0-.05153.04177-.09331.09331-.09331z"
                    />
                    <path
                        d="m10.6239 8.00916h-.5599c-.0515 0-.0933.04178-.0933.09332v.55987c0 .05153.0418.09331.0933.09331h.5599c.0515 0 .0933-.04178.0933-.09331v-.55987c0-.05154-.0418-.09332-.0933-.09332z"
                    />
                    <path
                        d="m10.2486 6.3296h.3732c.0516 0 .0933.04178.0933.09331v.37325c0 .05153-.0417.09331-.0933.09331h-.3732c-.0515 0-.0933-.04178-.0933-.09331v-.37325c0-.05153.0418-.09331.0933-.09331z"
                    />
                    <path
                        d="m8.75465 8.19581h-.37325c-.05154 0-.09331.04178-.09331.09331v.37325c0 .05153.04177.09331.09331.09331h.37325c.05153 0 .09331-.04178.09331-.09331v-.37325c0-.05153-.04178-.09331-.09331-.09331z"
                    />
                    <g clipRule="evenodd" fillRule="evenodd">
                        <path
                            d="m8.66158 3.37305h1.67962c.099 0 .1939.03932.2639.10932s.1093.16493.1093.26393v1.67961c0 .09899-.0393.19393-.1093.26393-.07.06999-.1649.10932-.2639.10932h-1.67962c-.09899 0-.19392-.03933-.26392-.10932-.07-.07-.10932-.16494-.10932-.26393v-1.67961c0-.099.03932-.19393.10932-.26393s.16493-.10932.26392-.10932zm1.27904 1.65228c.0175-.0175.02733-.04123.02733-.06598v-.7465c0-.02474-.00983-.04848-.02733-.06598s-.04123-.02733-.06598-.02733h-.7465c-.02474 0-.04848.00983-.06598.02733s-.02733.04124-.02733.06598v.7465c0 .02475.00983.04848.02733.06598s.04124.02733.06598.02733h.7465c.02475 0 .04848-.00983.06598-.02733z"
                        />
                        <path
                            d="m7.24124 3.37305h-1.67962c-.09899 0-.19393.03932-.26392.10932-.07.07-.10933.16493-.10933.26393v1.67961c0 .09899.03933.19393.10933.26393.06999.06999.16493.10932.26392.10932h1.67962c.09899 0 .19393-.03933.26392-.10932.07-.07.10933-.16494.10933-.26393v-1.67961c0-.099-.03933-.19393-.10933-.26393-.06999-.07-.16493-.10932-.26392-.10932zm-.37325 1.5863c0 .02475-.00983.04848-.02733.06598s-.04123.02733-.06598.02733h-.7465c-.02475 0-.04848-.00983-.06598-.02733s-.02733-.04123-.02733-.06598v-.7465c0-.02474.00983-.04848.02733-.06598s.04123-.02733.06598-.02733h.7465c.02475 0 .04848.00983.06598.02733s.02733.04124.02733.06598z"
                        />
                        <path
                            d="m5.56172 6.3296h1.67962c.09899 0 .19393.03932.26393.10932.06999.07.10932.16493.10932.26393v1.67961c0 .09899-.03933.19393-.10932.26393-.07.07-.16494.10932-.26393.10932h-1.67962c-.09899 0-.19392-.03932-.26392-.10932s-.10932-.16494-.10932-.26393v-1.67961c0-.099.03932-.19393.10932-.26393s.16493-.10932.26392-.10932zm1.27904 1.65228c.0175-.0175.02733-.04123.02733-.06598v-.74649c0-.02475-.00983-.04849-.02733-.06599-.0175-.01749-.04123-.02733-.06598-.02733h-.7465c-.02474 0-.04848.00984-.06598.02733-.0175.0175-.02733.04124-.02733.06599v.74649c0 .02475.00983.04848.02733.06598s.04124.02733.06598.02733h.7465c.02475 0 .04848-.00983.06598-.02733z"
                        />
                    </g>
                </g>
            )}
        />
    )
}

export default IconUserApp