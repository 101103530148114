export const displayLargeFormPictureAndIntroText = () => {
    const permalinkTypesWithLargeForm = [3, 8]
    const nonPermalinkPagesWithLargeForm = [
        'global_review_page',
        'front_homepage',
        'ferry_company_list',
        'bus_company_list',
        'bus_stations_in_country',
        'ferry_ports_in_country',
        'train_stations_in_country',
        'gb_front_mobile_app_page',
    ]
    switch (window.currentRoute) {
        case 'base_permalink':
            // Fix for custom cms pages
            if (!window?.permalinkTypeId) {
                return true
            }
            return permalinkTypesWithLargeForm.includes(window.permalinkTypeId)
        default:
            return nonPermalinkPagesWithLargeForm.includes(window.currentRoute)
    }
}