import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconAddPassengerTravelingCom({fill = "none", height = 16, width = 16, viewBox = "0 0 16 16", ...rest}: IconType) {
    return (
        <GenericSvgIcon
            {...{fill, height, width, viewBox, ...rest}}
            svgIconPath={(
                <path
                    d="m8.33366 9.83467v1.39333c-.60339-.2133-1.24915-.2788-1.88307-.1908-.63391.0879-1.23747.3267-1.75998.6963-.52251.3695-.94872.859-1.24284 1.4274s-.44757 1.1991-.44744 1.8391l-1.33334-.0007c-.0002-.814.18595-1.6174.5442-2.3484s.87909-1.3703 1.52261-1.8689 1.39264-.84324 2.18996-1.00757 1.62167-.14395 2.4099.05957zm-1.33333-.83467c-2.21 0-4-1.79-4-4s1.79-4 4-4 3.99997 1.79 3.99997 4-1.78997 4-3.99997 4zm0-1.33333c1.47333 0 2.66666-1.19334 2.66666-2.66667s-1.19333-2.66667-2.66666-2.66667c-1.47334 0-2.66667 1.19334-2.66667 2.66667s1.19333 2.66667 2.66667 2.66667zm3.99997 4.00003v-2.00003h1.3334v2.00003h2v1.3333h-2v2h-1.3334v-2h-1.99997v-1.3333z"
                    fill="#7e00d9"
                />
            )}
        />
    )
}

export default IconAddPassengerTravelingCom