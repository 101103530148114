import Translation from "@Translation/components/Translation";
import React from "react";
import IconGoogle from "@Icons/IconGoogle";

const GoogleSignInButton = ({onClick, className = "btn btn--superG"}: {onClick?: (e: any) => void, className?: string}) => (
    <button
        onClick={onClick}
        type="button"
        className={className}
    >
        <IconGoogle
            style={{
                marginRight: "1.2rem",
                verticalAlign: "text-bottom",
            }}
        />
        <small
            className="gb--emphasize"
        >
            <Translation translationKey="user.sign_in_with"/>
            {` Google`}
        </small>
    </button>
);

export default GoogleSignInButton;