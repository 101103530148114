import React from 'react'
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import BookingInfo from "@Pages/mobileApp/features/bookingSection/components/BookingInfo";
import IconChevron from "@Icons/chevrons/IconChevron";
import ImageCarousel from "@Pages/mobileApp/features/bookingSection/components/ImageCarousel";
import BookingInfoType from "@Pages/mobileApp/features/bookingSection/types/bookingInfo.type";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import MobileSwiper from "@Features/mobileSwiper/components/MobileSwiper";
import useCarousel from "@Pages/mobileApp/features/bookingSection/hooks/useCarousel";
import DotsProgressBar from "@Pages/mobileApp/features/bookingSection/components/DotsProgressBar";
import {Colors} from "@Styles/Colors.style";

const Carousel = ({data}: { data: BookingInfoType[] }) => {
    const {
        orderedData,
        prevButtonClick,
        nextButtonClick,
        centralPosition,
        mobileSwipe,
    } = useCarousel(data)

    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()

    return (
        <Row
            justify
            gap
            className="bookings-section gb-container"
        >
            {(isSmallTablet || isMobile) ? (
                <MobileSwiper
                    onSwipe={mobileSwipe}
                    className="image-carousel gb--row gb--row-middle"
                >
                    <ImageCarousel
                        orderedData={orderedData}
                        centralPosition={centralPosition}
                    />
                </MobileSwiper>
            ) : (
                <ImageCarousel
                    orderedData={orderedData}
                    centralPosition={centralPosition}
                />
            )}
            {(isMobile || isSmallTablet) && (
                <DotsProgressBar
                    items={orderedData}
                    activeIndex={centralPosition}
                />
            )}
            <Column
                justify
                wrap
                className="bookings-section-container"
            >
                <BookingInfo
                    title={orderedData[centralPosition].title}
                    label={orderedData[centralPosition].label}
                    color={orderedData[centralPosition].color}
                >
                    {orderedData[centralPosition].content}
                </BookingInfo>
                {!(isMobile || isSmallTablet) && (
                    <div>
                        <button
                            className="bookings-section-btn"
                            onClick={prevButtonClick}
                        >
                            <IconChevron
                                chevronOrientation="left"
                                fill={Colors.neutralDarkestColor}
                            />
                        </button>
                        <button
                            onClick={nextButtonClick}
                            className="bookings-section-btn"
                        >
                            <IconChevron
                                chevronOrientation="right"
                                fill={Colors.neutralDarkestColor}
                            />
                        </button>
                    </div>
                )}
            </Column>
        </Row>
    )
}

export default Carousel