import {useEffect} from "react";
import {setShowArchivedFilter} from "@MyProfile/reducers/userProfileSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useIsMobile from "@Hooks/selector/useIsMobile";

/**
 * Automatically show archived data on mobile screens (filters not available on mobile).
 *
 * By default, archived tickets/vouchers are toggled off/not shown on initial screen.
 * This toggles on archived data on mobile screens
 */
export default function useAutomaticallyShowArchivedDataOnMobile() {
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()

    useEffect(() => {
        if (isMobile) {
            dispatch(setShowArchivedFilter(true))
        }
    }, [isMobile]);
}