import React from "react";
import {RouteAmenityType} from "@Route/features/amenities/types/RouteAmenity.type";
import IconPicture from "@Icons/searchRes/amenities/IconPicture";
import IconWarning from "@Icons/searchRes/IconWarning";
import IconAirConditioning from "@Icons/searchRes/amenities/IconAirConditioning";
import IconNoInfoAvailable from "@Icons/searchRes/amenities/IconNoInfoAvailable";
import IconPet from "@Icons/searchRes/amenities/IconPet";
import IconDrinks from "@Icons/searchRes/amenities/IconDrinks";
import IconFood from "@Icons/searchRes/amenities/IconFood";
import IconHandLuggage from "@Icons/searchRes/amenities/IconHandLuggage";
import IconWiFi from "@Icons/searchRes/amenities/IconWiFi";
import IconCharger from "@Icons/searchRes/amenities/IconCharger";
import IconBicycle from "@Icons/searchRes/amenities/IconBicycle";
import IconToilet from "@Icons/searchRes/amenities/IconToilet";
import IconCoffeeBreak from "@Icons/searchRes/amenities/IconCoffeeBreak";
import IconSeatbelt from "@Icons/searchRes/amenities/IconSeatbelt";
import IconWheelchair from "@Icons/searchRes/amenities/IconWheelchair";
import IconMultimedia from "@Icons/searchRes/amenities/IconMultimedia";
import IconSpecialInfo from "@Icons/searchRes/amenities/IconSpecialInfo";
import IconBorderCrossing from "@Icons/searchRes/amenities/IconBorderCrossing";
import IconHighway from "@Icons/searchRes/amenities/IconHighway";
import IconCountryRoute from "@Icons/searchRes/amenities/IconCountryRoute";


interface TravelingDotComRouteAmenityIconProps {
    tag?: RouteAmenityType
}
function TravelingDotComRouteAmenityIcon({tag = "noInfoAvailableOption"}: TravelingDotComRouteAmenityIconProps) {
    const components = {
        noInfoAvailableOption: IconNoInfoAvailable,
        petsAllowed: IconPet,
        drinksAllowed: IconDrinks,
        foodAllowed: IconFood,
        handLuggage: IconHandLuggage,
        airCondition: IconAirConditioning,
        wifiAvailable: IconWiFi,
        powerSocket: IconCharger,
        bicycle: IconBicycle,
        toilet: IconToilet,
        coffeeBreak: IconCoffeeBreak,
        seatbelts: IconSeatbelt,
        wheelchairAccess: IconWheelchair,
        multimedia: IconMultimedia,
        specialInfo: IconSpecialInfo,
        borderCrossing: IconBorderCrossing,
        highWay: IconHighway,
        countryRoute: IconCountryRoute,
        picturesqueView: IconPicture,
        delayInformation: IconWarning,
    };

    // TODO remove this default once all amenity icons are here
    const IconRouteAmenities = components[tag];

    return (
        <IconRouteAmenities
            width="25"
            height="25"
            className="gb--route-icons"
        />
    )
}

export default TravelingDotComRouteAmenityIcon