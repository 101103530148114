import React from "react"
import IconCalendar from "@Icons/date/IconCalendar";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {getTodayServerDate} from "@DateTime/functions/getTodayServerDate";
import parseDate from "@DateTime/functions/parseDate";
import FadingError from "@Errors/components/FadingError";
import useSetDate from "../../../hooks/useSetDate";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useForm from "../../../hooks/useForm";
import useActivateCalendar from "../hooks/useActivateCalendar";
import popperModifiers from "@Form/features/dates/constants/popperModifiers";
import DatePickerLabel from "@Form/features/dates/components/DatePickerLabel";
import displayedDate from "@Form/features/dates/functions/displayedDate";
import useUpdateDateFromSearchParams from "@Form/features/dates/hooks/useUpdateDateFromSearchParams";
import usePrefillDateOnStsPages from "@Form/features/dates/hooks/usePrefillDateOnStsPages";
import {FormVersionType} from "@Form/types/FormVersion.type";
import getFormVersionClassNameAggregator from "@Form/functions/getFormVersionClassNameAggregator";
import Row from "@Containers/components/Row";
import DatePickerWrapper from "@Features/datePicker/components/DatePickerWrapper";
import {Colors} from "@Styles/Colors.style";

interface DatePickerInputFieldsProps {
    formVersion?: FormVersionType,
}

function DatePickerInputFields({formVersion = "1"}: DatePickerInputFieldsProps) {
    const {date, returnDate} = useForm('urlParams')
    const formErrors = useForm('formErrors')
    const returnCalendarActive = useForm("returnCalendarActive")
    const activateSingleCalendar = useActivateCalendar("single")
    const activateReturnCalendar = useActivateCalendar("return")
    const isMobile = useIsMobile()
    const changeSingle = useSetDate()
    const changeReturn = useSetDate('return')
    const dateSelectLabel = useTranslation("form.date.placeholder")
    const addReturnLabel = useTranslation("form.return_date.placeholder")
    const today = getTodayServerDate()
    const className = `gb--input-standalone search-datepicker__outbound ${returnDate ? 'search-datepicker--animation' : ''}`
    const returnClassName = `gb--input-standalone search-datepicker__return ${returnDate ? 'search-datepicker--animation' : ''}`

    usePrefillDateOnStsPages(date)
    useUpdateDateFromSearchParams(date, returnDate)

    const {v} = getFormVersionClassNameAggregator(formVersion)

    const handleReturnCalendarClick = (e) => {
        if (e.target.className !== 'react-datepicker__close-icon') {
            activateReturnCalendar()
        }
    }

    return (
        <Row className={`gb--search${v()}__select gb--search${v()}__select--datepicker`} center>
            <div
                className={`gb--search${v()}__select--datepicker-input`}
                onClick={activateSingleCalendar}
            >
                <IconCalendar
                    className={`gb--search${v()}__select--icon`}
                    fill={Colors.primaryColor}
                />
                <FadingError error={!formErrors?.dateValid}/>
                {/* TODO add custom input field for DatePickerWrapper */}
                <DatePickerWrapper
                    className={className}
                    selected={displayedDate(date)}
                    onChange={changeSingle}
                    placeholderText={dateSelectLabel}
                    popperPlacement="bottom"
                    popperModifiers={popperModifiers}
                    closeOnScroll
                />
                <DatePickerLabel
                    datepickerLabel="form.date.label"
                    formVersion={formVersion}
                />
            </div>

            <div
                className={`gb--search${v()}__select--datepicker-input`}
                onClick={(e) => handleReturnCalendarClick(e)}
            >

                <IconCalendar
                    className={`gb--search${v()}__select--icon`}
                    fill={Colors.primaryColor}
                />
                {/* TODO add custom input field for DatePickerWrapper */}
                <DatePickerWrapper
                    selected={displayedDate(returnDate)}
                    onChange={changeReturn}
                    placeholderText={!isMobile && returnCalendarActive ? dateSelectLabel : addReturnLabel}
                    className={returnClassName}
                    minDate={date ? parseDate(date) : today}
                    isClearable
                    popperPlacement="bottom"
                    popperModifiers={popperModifiers}
                    closeOnScroll
                />
                <DatePickerLabel
                    datepickerLabel="form.return_date.label"
                    formVersion={formVersion}
                />
            </div>
        </Row>
    );
}

export default DatePickerInputFields;