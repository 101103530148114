import React from 'react';
import IconLogin from "@Icons/IconLogin";
import Translation from "@Translation/components/Translation";
import Notification from "@Features/notification/components/Notification";
import Link from "@Generic/navigation/Link";
import PasswordLogin from "@CheckOrder/components/MainSection/BuyerInfo/PasswordLogin";
import GoogleLogin from "@CheckOrder/components/MainSection/BuyerInfo/GoogleLogin";
import AppleLogin from "@CheckOrder/components/MainSection/BuyerInfo/AppleLogin";

interface LoginFieldsProps {
    userLoginMethods: any
}

const LoginFields = ({userLoginMethods}: LoginFieldsProps) => (
    <>
        {
            userLoginMethods.password
            && (<PasswordLogin/>)
            && (
                <Notification
                    additionalClass="existing-user-notification"
                    notificationIcon={<IconLogin/>}
                    isRow
                >
                    <Link
                        className="gb--emphasize gb--emphasize-info"
                        href="/resetting/request"
                    >
                        &nbsp;
                        <Translation translationKey="user.forgot_password"/>
                    </Link>
                </Notification>
            )
        }
        {userLoginMethods.google && (
            <GoogleLogin/>
        )}
        {userLoginMethods?.apple && (
            <AppleLogin/>
        )}
    </>
);

export default LoginFields;