import CountryType from "@/types/pageSlice/homepageData/Country.type";
import useCountryPageUrl from "@Pages/homepage/features/countries/hooks/useCountryPageUrl";
import Translation from "@Translation/components/Translation";
import React, {useRef} from "react";
import CardImageCentered from "@Generic/cards/CardImageCentered";

interface TravelingDotComCountryHomeProps {
    country: CountryType
}

function TravelingDotComCountryHome({country}: TravelingDotComCountryHomeProps) {
    const cardUrl = useCountryPageUrl(country)

    return (
        <CardImageCentered
            type="link"
            cardProps={{href: cardUrl, className: "gb--card gb--card-full gb--card-media"}}
            imageSrc={country.imagePath}
            imageAlt={country.name}
            title={country.name}
            description={(
                <Translation
                    translationKey="country_page.country_index.travel_information"
                />
            )}
        />
    )
}

export default TravelingDotComCountryHome