import {MouseEvent, TouchEvent} from "react";

interface GetSlideFunctionAttributes {
    isDisabled: boolean,
    formattedDate: string,
    setClientXonMouseDown: (value: number) => void,
    setClientXonMouseUp: (value: number) => void,
    selectNewSearchDate: (selectedDate: string) => void
}

/**
 * Function which returns handle functions for single date slide
 */
export default function getSlideFunctions(attributes: GetSlideFunctionAttributes) {
    const {
        isDisabled,
        formattedDate,
        selectNewSearchDate,
        setClientXonMouseUp,
        setClientXonMouseDown
    } = attributes

    const onClick = isDisabled ? null : () => {
        selectNewSearchDate(formattedDate)
    }

    const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
        setClientXonMouseDown(e.clientX)
    }

    const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
        setClientXonMouseUp(e.clientX)
    }

    const onTouch = (e: TouchEvent<HTMLDivElement>) => {
        setClientXonMouseDown(e.touches[0].clientX)
    }

    return {
        onClick,
        onMouseDown,
        onMouseUp,
        onTouch,
    }
}