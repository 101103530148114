import React from 'react';
import IconChevron from "@Icons/chevrons/IconChevron";
import {Colors} from "@Styles/Colors.style";

interface DateSlickArrowProps {
    /** Method to execute on click */
    click: (any?) => any,
    /** Additional classes to add on button */
    customClassName: string,
    orientation: 'right' | 'left',
}

const DateSlickArrow = ({orientation, click, customClassName}: DateSlickArrowProps) => {
    return (
        <button
            type="button"
            className={`slick-arrow ${customClassName}`}
            onClick={click}
        >
            <IconChevron
                chevronOrientation={orientation}
                fill={Colors.primaryColor}
                height="30"
                width="30"
            />
        </button>
    );
}

export default DateSlickArrow;
