import React, {useState} from 'react';
import TableOfContentsHeader from "@Features/tableOfContents/components/TableOfContentsHeader";

function TableOfContents() {
    const [expandToc, setExpandToc] = useState(false);
    const onClick = () => {
        setExpandToc(!expandToc);
    }
    return (
        <div className="gb-toc">
            <TableOfContentsHeader expandToc={expandToc} callback={onClick}/>
            {expandToc && (
                <div className="gb-toc__list">
                    <nav>
                        <ul>
                            {Object.values(JSON.parse(window.tableOfContents)).map(({name, href, children}) => {
                                if (children) {
                                    return (
                                        <li>
                                            <a href={href}>{name}</a>
                                            <ul>
                                                {children.map(({childLevel, childName, childHref}) => {
                                                    return (<li><a href={childHref}>{childName}</a></li>);
                                                })}
                                            </ul>
                                        </li>
                                    )
                                }
                                return (
                                    <li>
                                        <a href={href}>{name}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
}

export default TableOfContents;