import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconSpecialInfo(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth="1.2">
                    <circle cx="7.999999" cy="7.999999" r="6.179799"/>
                    <g transform="translate(7.5 5.6442)">
                        <path d="m.5 1.95992818v4.13954106"/>
                        <path d="m.5 0v1.174046"/>
                    </g>
                </g>
            )}
        />
    )
}

export default IconSpecialInfo