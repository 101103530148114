import Row from "@Containers/components/Row";
import React from "react";
import SeatClassType from "@Route/types/travelInfo/SeatClass.type";
import Column from "@Containers/components/Column";
import routeIconsHashMapResolver from "@Route/features/amenities/functions/routeIconsHashMapResolver";
import TravelingDotComRouteAmenityIcon from "@Route/features/amenities/components/TravelingDotComRouteAmenityIcon";
import ImageSlider from "@Features/slider/features/imageSlider/components/ImageSlider";
import SeatClassImage from "@Route/components/SeatClass/SeatClassImage";
import {isNotEmpty} from "@Array/isNotEmpty";

type SeatClassProps = {
    seatClass: SeatClassType,
    displayImages?: boolean,
}

function SeatClass({seatClass, displayImages}: SeatClassProps) {
    return (
        <>
            <Column>
                <h4>{seatClass.className}</h4>
                <span>{seatClass.description}</span>
                <Column className="gb--route-amenities_icons">
                    {seatClass.amenities.map((amenity) => (
                        <Row key={amenity.id} style={{paddingTop: '1rem'}}>
                            <TravelingDotComRouteAmenityIcon tag={routeIconsHashMapResolver(amenity?.id)}/>
                            <span>{amenity.label}</span>
                        </Row>
                    ))}
                </Column>
            </Column>
            {displayImages && isNotEmpty(seatClass?.images) && (
                <Row className="gb--route-seat_classes">
                    <ImageSlider
                        ImageComponent={SeatClassImage}
                        images={seatClass.images}
                        prepareImage={(image) => ({image, className: "seat-class-image__small"})}
                        prepareOpenImage={(image) => ({image, className: "seat-class-image__opened"})}
                    />
                </Row>
            )}
        </>
    );
}

export default SeatClass;