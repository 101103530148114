import parseISO from "date-fns/parseISO";
import formatDate from "@DateTime/functions/formatDate";
import {useParams} from "react-router";
import useGenerateSearchUrl from "@SearchResults/hooks/useGenerateSearchUrl";
import useSetDate from "@Form/hooks/useSetDate";
import useNavigateWithStoredState from "@Routing/hooks/useNavigateWithStoredState";
import useClearAllFilters from "@SearchResults/features/sidebar/hooks/useClearAllFilters";

export default function useSelectNewSearchDate(isReturn: boolean) {
    const {navigateWithStoredState} = useNavigateWithStoredState()
    const params = useParams()
    const {generateSearchUrl} = useGenerateSearchUrl()
    const setDate = useSetDate(isReturn ? "return" : "single")
    const {clearFilters} = useClearAllFilters()

    const selectNewSearchDate = async (selectedDate: string) => {
        const parsedDate = formatDate(parseISO(selectedDate))
        setDate(parseISO(selectedDate))
        const {
            date: paramsDate,
            returnDate,
        } = params

        const path = generateSearchUrl({
            date: isReturn ? paramsDate : parsedDate,
            returnDate: isReturn ? parsedDate : returnDate,
        })

        navigateWithStoredState(path)
    }

    const onClick = (date: string) => {
        selectNewSearchDate(date).then(() => {
            clearFilters()
        })
    }

    return {
        onClick,
    }
}