import React from 'react';
import Translation from "@Translation/components/Translation";
import {toggleLoginModal} from "@MyProfile/reducers/userProfileSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import IconSignIn from "@Icons/login/IconSignIn";

function LoginButton() {
    const dispatch = useAppDispatch()
    const onClick = () => dispatch(toggleLoginModal())

    return (
        <button
            type="button"
            onClick={onClick}
            className="buyer-info__login"
        >
            <IconSignIn
                width="20"
                height="20"
            />
            <Translation translationKey="user.sign_in"/>
        </button>
    );
}

export default LoginButton;