import {useEffect} from "react";
import useGetAccommodationMap
    from "@SearchResults/features/banners/features/accommodationBanner/hooks/useGetAccommodationMap";
import useCurrency from "@Currency/hooks/useCurrency";
import usePassengers from "@Hooks/selector/usePassengers";
import ParamsType from "@SearchResults/types/params/Params.type";

export default function useUpdateAccommodationBanner(params: ParamsType, setBannerUrl: (any) => void, iconPath: string) {
    const currency = useCurrency()
    const passengers = usePassengers()

    const {getAccommodationMapData} = useGetAccommodationMap(params, iconPath)

    useEffect(() => {
        setBannerUrl(getAccommodationMapData())
    }, [passengers, currency?.val])
}