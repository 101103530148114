import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconDuration(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth="1.2"
                   transform="translate(2.7187 1.3037)">
                    <path
                        d="m2.16264737 13.0675025v-3.25273c0-1.722305 1.396174-3.11844 3.11844-3.11844 1.7222985 0 3.1184335 1.396135 3.1184335 3.11844v3.25273"
                    />
                    <path d="m0 13.067568h10.562461"/>
                    <path
                        d="m8.39984587.325208v3.2527495c0 1.722266-1.396135 3.11844-3.1184335 3.11844-1.7222725 0-3.1184465-1.396174-3.1184465-3.11844v-3.2527495"
                    />
                    <path d="m10.562526.325h-10.562494"/>
                </g>

            )}
        />
    )
}

export default IconDuration