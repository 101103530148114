import {Colors} from "@Styles/Colors.style";
import IconLocationMarker from "@Icons/IconLocationMarker";

interface CompanyInputFieldSuggestionProps {
    suggestion?: any,
    onClick?: (any?) => any
    showIcon?: boolean
}

export const CompanyInputFieldSuggestion = ({suggestion, onClick, showIcon}: CompanyInputFieldSuggestionProps) => {
    return (
        <div
            role="button"
            onClick={onClick}
            className="suggestion-content"
        >
            {showIcon && (
                <IconLocationMarker
                    style={{marginRight: '1rem'}}
                    fill={Colors.primaryColor}
                />
            )}
            <p dangerouslySetInnerHTML={{__html: suggestion.label}}/>
        </div>
    )
}