import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

/**
 * Icon representing flexible cancellation ancillary
 */
function IconFlexibleCancellationAncillary(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={fill}>
                    <g transform="translate(3.9045 5.9237)">
                        <path
                            d="m6.88139483 0v8.96h-6.88086403l-.0005308-2.987.13532933-.00592119c.76065191-.07268474 1.35553999-.7134373 1.35553999-1.49313342 0-.82538144-.66664391-1.49505984-1.49086932-1.49997279l.0005308-2.9739726zm-1.20039483 1.2h-4.481l-.001.86.07077783.03544463c.79926869.43065774 1.35638615 1.25235388 1.41582122 2.20843736l.00527027.1700634c0 .98784645-.53253432 1.85859301-1.35555513 2.33088455l-.13631419.07117006.001.884h4.481z"
                        />
                        <path
                            d="m10.8115183 0v8.96h-5.14645561v-2.98851504c.82706826 0 1.49753957-.6704713 1.49753957-1.49753957s-.67047131-1.49753957-1.49753957-1.49753957v-2.97640582zm-1.20045561 1.2h-2.746v.858l.06812695.03446486c.80330664.42865029 1.36425706 1.25186015 1.42410554 2.21088388l.00530708.17059665c0 1.0314069-.57885265 1.92761552-1.42941262 2.38148053l-.06812695.03257408v.872h2.746z"
                            transform="matrix(-1 0 0 1 16.4766 0)"
                        />
                    </g>
                    <g transform="translate(.6396 .6101)">
                        <path
                            d="m5.19947142 0 2.36468799 1.50511997c.52191181.332196.26048068 1.14119345-.35713308 1.10514668-1.70043405-.0992451-3.02982128.1116023-3.9901582.61501756-.97366641.51040266-1.66479823 1.67355963-2.04040567 3.54194391l-1.17646246-.23650812c.44030965-2.19023253 1.31588917-3.6638102 2.65972834-4.36825998.70607001-.37012678 1.54280357-.60609311 2.50976945-.71168429l.06373344-.00660972-.67810746-.43183399z"
                        />
                        <path
                            d="m6.85041926 1.55666986.78313265.90923224-1.96431315 1.6918865-.78313264-.90923224z"
                        />
                    </g>
                </g>
            )}
        />
    )
}

export default IconFlexibleCancellationAncillary