import {ComponentType, useState} from "react";
import Dialog from "@Features/dialog/components/Dialog";
import useDialog from "@Features/dialog/hooks/useDialog";
import Row from "@Containers/components/Row";
import slide from "@Features/slider/functions/slide";
import SliderArrow from "@Features/slider/components/SliderArrow";
import useHandleSwipe from "@Features/mobileSwiper/hooks/useHandleSwipe";
import handleImageSwipe from "@Features/slider/features/imageSlider/functions/handleImageSwipe";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface ImageScrollerProps<ImageComponentPropsType, ImageType> {
    ImageComponent: ComponentType<ImageComponentPropsType>,
    images: ImageType[],
    prepareImage: (value: ImageType) => Omit<ImageComponentPropsType, "onClick">
    prepareOpenImage: (value: ImageType) => Omit<ImageComponentPropsType, "onClick">
}

/**
 * Slider used for displaying/scrolling through images in a bigger resolution (if they are minimized.
 * Opens clicked on image inside a full-screen dialog.
 *
 * To close the modal, click outside the image.
 */
function ImageSlider<P, T>({ImageComponent, images, prepareImage, prepareOpenImage}: ImageScrollerProps<P, T>) {
    const {dialogRef, openModal} = useDialog(true, true)
    const [imageIndex, setImageIndex] = useState(0)

    const isMobile = useIsMobile()

    const lastImageIndex = images.length - 1
    const {slideLeftInfinite, slideRightInfinite} = slide(setImageIndex, lastImageIndex)

    const onImageClick = (newImageIndex: number) => () => {
        setImageIndex(newImageIndex)
        openModal()
    }

    const {handleSwipe} = handleImageSwipe(slideRightInfinite, slideLeftInfinite)
    useHandleSwipe(dialogRef, handleSwipe)

    return (
        <>
            {images.map((image, index) => (
                // @ts-ignore
                <ImageComponent
                    {...{
                        ...prepareImage(image),
                        onClick: onImageClick(index),
                    }}
                />
            ))}
            <Dialog
                dialogRef={dialogRef}
                className="trvl--image-slider"
            >
                <Row justify center>
                    {!isMobile && (
                        <SliderArrow
                            direction="left"
                            move={slideLeftInfinite}
                            className=""
                        />
                    )}
                    {/* @ts-ignore */}
                    <ImageComponent
                        {...{
                            ...prepareOpenImage(images[imageIndex]),
                            onClick: () => {
                            },
                        }}
                    />
                    {!isMobile && (
                        <SliderArrow
                            direction="right"
                            move={slideRightInfinite}
                            className=""
                        />
                    )}
                </Row>
            </Dialog>
        </>
    )
}

export default ImageSlider