import React from "react"

interface TripleDotLoaderProps {
    isInline?: boolean,
    small?: boolean,
}

function TripleDotLoader({isInline, small}: TripleDotLoaderProps) {
    const mainClassName = `dot-loader ${isInline ? "dot-loader__inline" : ""}`
    const ulClassName = `dot-loader__loading ${small ? "dot-loader__small" : ''}`

    return (
        <div className={mainClassName}>
            <ul className={ulClassName}>
                <li/>
                <li/>
                <li/>
            </ul>
        </div>
    )
}

export default TripleDotLoader