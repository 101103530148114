import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconDownload(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill={fill}>
                    <path d="m0 0h10v1.3h-10z" transform="translate(3 12.9246)"/>
                    <path
                        d="m4.9187641 0 .0581403 7.2002454 2.7269974-2.306581.8395276.9925689-3.8088618 3.2215863-.4147873.3508329-.4188809-.3459358-3.9008994-3.2215863.8278091-1.0023632 2.8490953 2.353478-.058098-7.23175438z"
                        transform="translate(3.66176 1)"
                    />
                </g>
            )}
        />
    )
}

export default IconDownload