import {Dispatch, SetStateAction, useCallback, useEffect, useRef} from "react";
import {
    timePeriodBetweenCountdownChecks
} from "@Features/slider/features/slider/constants/timePeriodBetweenCountdownChecks";
import calculateMoveDistance from "@Features/slider/features/slider/functions/calculateMoveDistance";

interface MoveSliderByTouchParams {
    setTranslateX: Dispatch<SetStateAction<number>>,
    setSlideIndex: Dispatch<SetStateAction<number>>,
    setMoveDistance: Dispatch<SetStateAction<number | null>>
    moveStep: number,
    dataLength: number
}

export default function useMoveSliderByTouch(params: MoveSliderByTouchParams) {
    const {
        setTranslateX,
        setSlideIndex,
        setMoveDistance,
        moveStep,
        dataLength,
    } = params

    let prevX: number = null
    let x: number = null
    let newX: number = null
    let moveDistance: number = null

    let countdown = null
    let timePeriodBetweenMovementChecks = timePeriodBetweenCountdownChecks

    const sliderRef = useRef<HTMLDivElement>(null)

    /**
     * Used with sliderRef, detects when user clicked inside of slider body
     * Initializes prevX
     */
    const handleMovementStart = useCallback((e: TouchEvent) => {
        prevX = e.changedTouches[0].clientX
    }, [])

    /**
     * If no prevX ==> handleStart was not ran
     * If countdown ==> handleMovement was run in near past (do not run for each iteration)
     * Updates translateX value
     */
    const handleMovement = useCallback((e: TouchEvent) => {
        x = e.changedTouches[0].clientX

        if (countdown || prevX === null) {
            return;
        }
        moveDistance = calculateMoveDistance(prevX, x)

        setMoveDistance(moveDistance)
        setTranslateX((prevTranslateX) => {
            newX = prevTranslateX - moveDistance
            if (newX <= 0) {
                newX = 0;
                return newX;
            }
            if (Math.abs(newX / moveStep) >= dataLength - 1) {
                newX = (dataLength - 1) * moveStep
                return newX
            }

            return newX
        })

        prevX = x

        countdown = setInterval(() => {
            timePeriodBetweenMovementChecks -= 1;
            if (timePeriodBetweenMovementChecks <= 0) {
                clearInterval(countdown)
                timePeriodBetweenMovementChecks = timePeriodBetweenCountdownChecks
                countdown = null
            }
        }, 1)
    }, [])

    /**
     * If no prevX ==> handleStart was not ran
     * Clear temp data and finish up calculations
     */
    const handleMovementEnd = useCallback((_e: TouchEvent) => {
        if (prevX === null) {
            return;
        }

        clearInterval(countdown)
        countdown = null

        setTranslateX((prevTranslateX) => {
            newX = Math.round(prevTranslateX / moveStep) * moveStep

            if (newX <= 0) {
                newX = 0;
                return newX;
            }
            if (Math.abs(newX / moveStep) >= dataLength - 1) {
                newX = (dataLength - 1) * moveStep
                return newX
            }

            return newX
        })

        setSlideIndex(newX === 0 ? 0 : Math.abs(Math.round(newX / moveStep)))
        moveDistance = null
        setMoveDistance(moveDistance)
        prevX = null
    }, [])

    useEffect(() => {
        sliderRef?.current?.addEventListener("touchstart", handleMovementStart)
        window.addEventListener("touchmove", handleMovement)
        window.addEventListener("touchend", handleMovementEnd)
        return () => {
            if (sliderRef && sliderRef?.current) {
                sliderRef?.current?.removeEventListener("touchstart", handleMovementStart)
            }
            window.removeEventListener("touchmove", handleMovement)
            window.removeEventListener("touchend", handleMovementEnd)
        }
    }, [sliderRef]);

    return {
        sliderRef,
        moveDistance: Math.abs(Math.round(moveDistance)),
    }
}