import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "@Translation/hooks/useTranslation";
import CookieTab from "./CookieTab";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {CookieDataType} from "@Cookies/types/CookieData.type";

function CookieTabs() {
    const cookieData: CookieDataType = useAppSelector((state) => state?.cookies.cookieData)
    const {
        tabs: {
            content: {
                cookie_policy: cookiePolicyContent,
            },
        },
    } = useTranslation("cookie.preferences")

    return (
        <>
            <p>{cookiePolicyContent}</p>
            <hr/>
            {cookieData.map((cookieCategory) => (
                <CookieTab
                    key={cookieCategory.cookieKey}
                    cookieCategory={cookieCategory}
                />
            ))}
        </>
    );
}

export default CookieTabs;