import {useTranslation} from "@Translation/hooks/useTranslation";
import {useState} from "react";
import {fetchSuggestions} from "@Form/functions/fetchSuggestions";
import datasetParser from "@Dataset/datasetParser";

interface StationType {
    slug: string;
    value: number | null;
    countryCode: string;
    countryBusRentPaxCountMin: number | null;
    label: string;
}

export const useCompanyStationInput = () => {
    const parsedData = datasetParser({id: 'review-station-portal'});
    const locationPlaceholder = useTranslation("place_review.selects.location_placeholder") as string;
    const [showDropdown, setShowDropdown] = useState(false);
    const [suggestionResponse, setSuggestionResponse] = useState<StationType[]>([])
    const [selectedSuggestion, setSelectedSuggestion] = useState<StationType>()
    const [fieldValue, setFieldValue] = useState<string>('')
    const [showError, setShowError] = useState(false)

    const stripHtml = (html: string) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const handleChangeStation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(event.target.value)
        if (event.target.value.length > 2) {
            fetchSuggestions(event.target.value)
                .then((response) => {
                    setSuggestionResponse(response)
                })
        }
    }

    const handleSelectStation = (station: StationType) => {
        setShowDropdown(false)
        setSelectedSuggestion(station)
        setFieldValue(stripHtml(station.label))
    }

    return {
        locationPlaceholder,
        showDropdown,
        setShowDropdown,
        suggestionResponse,
        setSuggestionResponse,
        selectedSuggestion,
        setSelectedSuggestion,
        fieldValue,
        setFieldValue,
        stripHtml,
        handleChangeStation,
        handleSelectStation,
        data: parsedData,
        showError,
        setShowError,
    }
}