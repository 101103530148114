import IconType from "@/types/Icon.type";
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import {Colors} from "@Styles/Colors.style";

function IconMultimedia(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth="1.2">
                    <rect height="7.796758" rx="1" strokeLinejoin="round" width="12.008499" x="1.9958" y="1.9311"/>
                    <path d="m8.000049 11.025099v2.543857"/>
                    <path d="m5.286362 13.568956h5.427374" strokeLinecap="round"/>
                </g>
            )}
        />
    )
}

export default IconMultimedia