import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

/**
 * Main traveling.com bus icon, not filled
 */
function IconBus(props: IconType) {
    const {
        width = 16,
        height = 16,
        viewBox = "0 0 16 16",
        title = "IconBus",
        stroke = "#7e00d9",
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, title, ...rest}}
            svgIconPath={(
                <path
                    d="m1.40989 8.92117h1.12867m1.12868 0h3.38604m1.12868 0h1.1287m-9.30681-7.25036h10.72441m-.0077 5.02005s-2.2381.55567-5.35837.55567c-3.12024 0-5.35834-.55567-5.35834-.55567m.29662-6.69086h10.12339c.1657 0 .3005.13321.3005.29687v11.1172c0 .1636-.1348.2968-.3005.2968h-.8898c-.0501 0-.0963-.0266-.1233-.0685-.4121-.6508-1.1363-1.0466-1.9145-1.0466h-4.26816c-.77813 0-1.50234.3958-1.91452 1.0466-.02696.0419-.07319.0685-.12327.0685h-.88984c-.16564 0-.30047-.1332-.30047-.2968v-11.1172c0-.16366.13483-.29687.30047-.29687z"
                    fill="none"
                    stroke={stroke}
                    transform="translate(2.6359 2.1446)"
                />
            )}
        />
    )
}

export default IconBus