import SidebarSectionContainerItem from "@Sidebar/components/Sections/SidebarSectionContainerItem";
import React, {ReactNode} from "react";
import Row from "@Containers/components/Row";
import IconChevron from "@Icons/chevrons/IconChevron";
import {useToggle} from "@Hooks/dispatch/useToggle";
import FilterSectionTitle from "@SearchResults/features/sidebar/components/FilterSectionTitle";
import {ArrayFilterState} from "@SearchResults/reducers/filterSlice";

type FilterSectionProps = {
    translationKey: string,
    children: ReactNode,
    isToggleable?: boolean,
    filterDataKey?: keyof ArrayFilterState
    onBlur?: (event) => void,
}

function FilterSection({children, translationKey, isToggleable, filterDataKey, onBlur}: FilterSectionProps) {
    const {
        value: visible,
        onChange,
    } = useToggle()

    const displayChildren = isToggleable ? visible : true

    return (
        <SidebarSectionContainerItem>
            {isToggleable ? (
                <Row justify>
                    <FilterSectionTitle
                        filterDataKey={filterDataKey}
                        translationKey={translationKey}
                    />
                    <button type="button" onClick={onChange} onBlur={onBlur}>
                        <IconChevron chevronOrientation={visible ? "up" : "down"}/>
                    </button>
                </Row>
            ) : (
                <FilterSectionTitle
                    filterDataKey={filterDataKey}
                    translationKey={translationKey}
                />
            )}
            {displayChildren && children}
        </SidebarSectionContainerItem>
    );
}

export default FilterSection;