import React from "react";
import GetByTransferNoResultsNotification
    from "@SearchResults/features/banners/features/getByTransferBanner/components/GetByTransferNoResultsNotification";
import GbtVehicles from "@Features/getByTransfer/components/GbtVehicles";
import {TransfersDataType} from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersData.type";
import {
    defaultTransfersData
} from "@SearchResults/features/banners/features/getByTransferBanner/constants/defaultTransfersData";
import TrvlGetByTransferBanner
    from "@SearchResults/features/banners/features/getByTransferBanner/components/TrvlGetByTransferBanner";

interface NoResultsWithGbtPromoProps {
    bannerType: false | "vehicle-snippets" | "banner",
    transfersData: TransfersDataType,
    showNoDirectRoutesNotification: boolean,
}

function NoResultsWithGbtPromo(props: NoResultsWithGbtPromoProps) {
    const {
        bannerType,
        transfersData,
        showNoDirectRoutesNotification,
    } = props

    const {
        fromName,
        toName,
        vehicles,
    } = transfersData || {}

    if (bannerType === "vehicle-snippets") {
        return (
            <>
                {showNoDirectRoutesNotification && (
                    <GetByTransferNoResultsNotification
                        fromName={fromName}
                        toName={toName}
                    />
                )}
                <GbtVehicles vehicles={vehicles}/>
            </>
        )
    }
    return (
        <>
            {showNoDirectRoutesNotification && (
                <GetByTransferNoResultsNotification
                    fromName={fromName}
                    toName={toName}
                />
            )}
            <TrvlGetByTransferBanner
                transfersData={transfersData ?? defaultTransfersData}
            />
        </>
    )
}

export default NoResultsWithGbtPromo