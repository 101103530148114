import Button from "@Generic/buttons/Button";
import React from "react";
import {setShowArchivedFilter} from "@MyProfile/reducers/userProfileSlice";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import IconChevron from "@Icons/chevrons/IconChevron";
import {Colors} from "@Styles/Colors.style";
import useIsMobile from "@Hooks/selector/useIsMobile";

interface TrvlMyProfileShowArchivedDataProps {
    description: string,
}

function TrvlMyProfileShowArchivedData({description}: TrvlMyProfileShowArchivedDataProps) {
    const dispatch = useAppDispatch()
    const {showArchived, showValid} = useAppSelector((state) => (state.userProfile.filter))

    const isMobile = useIsMobile()

    const toggleArchivedFilterValue = () => {
        if (isMobile) {
            return
        }

        if (showValid !== false) {
            dispatch(setShowArchivedFilter(!showArchived))
        }
    }

    return (
        <Button
            onClick={toggleArchivedFilterValue}
            className="inline"
            additionalClasses="my-profile__show-archived-button"
        >
            {description}
            {!isMobile && (
                <IconChevron
                    chevronOrientation={showArchived ? "up" : "down"}
                    fill={Colors.primaryColor}
                />
            )}
        </Button>
    );
}

export default TrvlMyProfileShowArchivedData