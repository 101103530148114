import Row from "@Containers/components/Row";
import {ReactNode} from "react";

interface PriceFractionDisplayProps {
    fractionTitle: string | ReactNode,
    price: string,
}

/**
 * Used in payment Summarry section to display segment/fraction expense of a total price
 */
function PriceFractionDisplay({fractionTitle, price}: PriceFractionDisplayProps) {
    return (
        <Row justify center className="price-fragment">
            <span>
                {fractionTitle}
            </span>
            <span>
                {price}
            </span>
        </Row>
    )
}

export default PriceFractionDisplay