import React from "react";
import StationInputFields from "@Form/features/stations/components/StationInputFields";
import MobileStationInputButton from "@Form/components/Mobile/MobileStationInputButton";
import DatePickerInputFields from "@Form/features/dates/components/DatePickerInputFields";
import PassengerPickerButton from "@Form/features/passengers/components/PassengerPickerButton";
import SearchButton from "@Form/components/SearchButton";
import useFormMethods from "@Form/hooks/useFormMethods";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useValidateForm from "@Form/hooks/useValidateForm";
import usePushNewUrl from "@Routing/hooks/usePushNewUrl";
import useScrollToFormAndAnimate from "@Form/hooks/useScrollToFormAndAnimate";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import googleTagManagerSearchEvent from "@Form/functions/googleTagManager";
import LargeFormPictureAndIntroText from "@Form/components/LargeFormPictureAndIntroText";
import {displayLargeFormPictureAndIntroText} from "@Form/functions/displayLargeFormPictureAndIntroText";
import useRouteMatch from "@Routing/hooks/useRouteMatch";

type FormTravelingDotComProps = {
    inverseColors?: boolean,
    title?: string,
    description?: string,
}

function FormTravelingDotCom({inverseColors, title, description}: FormTravelingDotComProps) {
    const {mobileOverlayActive} = useFormMethods()
    const isMobile = useIsMobile()
    const formVersion = '1'
    const {checkForErrors} = useValidateForm()
    const pushNewUrl = usePushNewUrl()
    const {formAnimationActive} = useScrollToFormAndAnimate()
    const {urlParams} = useAppSelector((state) => (state?.form));
    // Fix for client navigation between my-profile / search / homepage / app promo
    const isHomepage = useRouteMatch('homepage')
    const isAppPromo = useRouteMatch('mobileApp')
    const inversedColorClass = inverseColors ? 'gb--search-inversed-colors' : '';

    const validateAndPushUrl = () => {
        if (checkForErrors()) {
            pushNewUrl(urlParams);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        googleTagManagerSearchEvent(urlParams);
        validateAndPushUrl()
    };

    const isLarge = displayLargeFormPictureAndIntroText();
    const smallFormCss = !isLarge ? 'gb--search-small' : '';

    return (
        <section className={`gb--search ${inversedColorClass} ${smallFormCss}`}>
            {(isHomepage || isAppPromo || isLarge) && (
                <LargeFormPictureAndIntroText
                    inverseColors={inverseColors}
                />
            )}
            <form
                className={`gb--search-form ${formAnimationActive ? 'animate--wiggle' : ''}`}
                onSubmit={(e) => submitForm(e)}
            >
                {!isMobile ? (
                    <StationInputFields formVersion={formVersion}/>
                ) : (
                    <>
                        <MobileStationInputButton direction="departure" formVersion={formVersion}/>
                        <MobileStationInputButton direction="destination" formVersion={formVersion}/>
                    </>
                )}
                {!mobileOverlayActive && (
                    <DatePickerInputFields formVersion={formVersion}/>
                )}
                <PassengerPickerButton formVersion={formVersion}/>
                <SearchButton formVersion={formVersion}/>
            </form>
        </section>
    );
}

export default FormTravelingDotCom;