import React from 'react';
import GenericPopover from "@Popover/components/GenericPopover";
import RouteClosedOrUnavailableTrigger
    from "@Route/features/status/features/closedOrUnavailable/components/RouteClosedOrUnavailableTrigger";
import RouteClosedOrUnavailableOverlay
    from "@Route/features/status/features/closedOrUnavailable/components/RouteClosedOrUnavailableOverlay";
import useGetRouteClosedOrUnavailableTranslationKeys
    from "@Route/features/status/features/closedOrUnavailable/hooks/useGetRouteClosedOrUnavailableTranslationKeys";

interface RouteClosedOrUnavailableProps {
    soldOut: boolean,
    closed: boolean,
    unavailable: boolean,
}

function RouteClosedOrUnavailable({soldOut, closed, unavailable}: RouteClosedOrUnavailableProps) {
    const {label, popover} = useGetRouteClosedOrUnavailableTranslationKeys(soldOut, closed, unavailable)

    return (
        <GenericPopover
            id="route-closed"
            trigger={(
                <RouteClosedOrUnavailableTrigger
                    label={label}
                />
            )}
            overlay={(
                <RouteClosedOrUnavailableOverlay
                    popover={popover}
                />
            )}
        />
    );
}

export default RouteClosedOrUnavailable
