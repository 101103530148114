import {useState} from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import ModalDataType from "@UserModals/types/ModalData.type";

type ManageBookingOptions = "rebook_ticket" | "cancel_ticket"

interface UseManageBookingModalAttributes {
    modalData: ModalDataType,
}

export default function useManageBookingModal({modalData}: UseManageBookingModalAttributes) {
    const {
        ticketCode,
        singleRoute,
        rebookAmount,
        isCancelable,
        isRebookable,
    } = modalData

    const {
        departure,
        destination,
        departureDate,
        departureTime,
    } = singleRoute

    const {
        rebook_via_voucher: rebookViaVoucherLabel,
        rebook_info: rebookInfoLabel,
        cancel_ticket: cancelTicketLabel,
        cancel_info: cancelInfoLabel,
        costumer_service_redirect: costumerServiceRedirectLabel,
        premium_support: premiumSupportLabel,
    } = useTranslation('my_profile.my_tickets.booking_modal')

    const exchangeTicketInfoLabel = useTranslation(
        'my_profile.my_tickets.booking_modal.exchange_ticket_info',
        {ticketCode, departure, destination, departureDate, departureTime, price: rebookAmount}
    )

    const [option, setOption] = useState<ManageBookingOptions>("rebook_ticket")

    const rebookViaVoucherButton = {
        header: rebookViaVoucherLabel,
        description: rebookInfoLabel,
        notificationText: exchangeTicketInfoLabel,
        notificationType: "blue",
        id: "rebook_ticket",
        onClick: () => {
            setOption("rebook_ticket")
        },
        showOption: isRebookable,
    }

    const cancelButton = {
        header: cancelTicketLabel,
        description: cancelInfoLabel,
        notificationText: costumerServiceRedirectLabel,
        notificationType: "orange",
        id: "cancel_ticket",
        onClick: () => {
            setOption("cancel_ticket")
        },
        showOption: isCancelable,
    }

    return {
        option,
        radioButtonLabels: [rebookViaVoucherButton, cancelButton].filter((option) => (option.showOption) && option),
        cancelTicketSelected: option === "cancel_ticket",
    }
}