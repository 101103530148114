import datasetParser from "@Dataset/datasetParser";
import React, {useState} from "react";
import DatePickerWrapper from "@Features/datePicker/components/DatePickerWrapper";
import {subYears} from "date-fns";

export const CompanyReviewDatePicker = ({error}: {error?: boolean}) => {
    const datePickerData = datasetParser({id: "review-date-picker"})

    const [selectedDate, setSelectedDate] = useState()

    return (
        <>
            <h3>
                {datePickerData?.title}
            </h3>
            <div className="line">
                <div className="triangle" />
            </div>
            <DatePickerWrapper
                selected={selectedDate}
                placeholderText={datePickerData?.placeholder}
                onChange={(date) => setSelectedDate(date)}
                minDate={subYears(new Date(), 2)}
                id="travelDate"
                name="travelDate"
                className={`search-datepicker__outbound gb--input-standalone ${error ? "station-error" : ""}`}
            />
        </>
    )
}