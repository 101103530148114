import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconBlog(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconBlog',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(2.7502 1.5)">
                    <path d="m.456055 3.041015h10.043485" stroke="#7e00d9" strokeLinejoin="round"/>
                    <path
                        d="m0 10.237495v-10.237495h10.47169l-.0389 8.969995c-.09195 2.2714-2.8612 3.3764-4.35365 1.6864-.4916-.5597-.69675-1.25665-.4209-1.7731.76395-1.4336 2.98145-.2744 1.9452 1.66835-.70025 1.31085-1.8497 2.28225-3.02035 2.3653-.6932.04695-1.34434.083055-1.843955.083055"
                        stroke="#7e00d9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <g fill="#7e00d9" fillRule="nonzero">
                        <path
                            d="m1.881755 2.04336c.26369 0 .477455-.218265.477455-.4875 0-.26924-.213765-.487505-.477455-.487505s-.477455.218265-.477455.487505c0 .269235.213765.4875.477455.4875z"
                        />
                        <path
                            d="m3.18644 2.04336c.26368 0 .47745-.218265.47745-.4875 0-.26924-.21377-.487505-.47745-.487505s-.477455.218265-.477455.487505c0 .269235.213775.4875.477455.4875z"
                        />
                        <path
                            d="m4.49209 2.04336c.2637 0 .47745-.218265.47745-.4875 0-.26924-.21375-.487505-.47745-.487505-.263695 0-.47744.218265-.47744.487505 0 .269235.213745.4875.47744.4875z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconBlog;
