import {SeatType} from "@CheckOrder/components/MainSection/SeatPicker/types/Seat.type";
import IconTimes from "@Icons/controls/IconTimes";
import useUpdateSeatPickerSeats from "@CheckOrder/components/MainSection/SeatPicker/hooks/useUpdateSeatPickerSeats";
import getFormattedSeatNumber from "@CheckOrder/components/MainSection/SeatPicker/functions/getFormattedSeatNumber";

type SeatRowProps = {
    seat: SeatType
    isReturn: boolean
}

function Seat({seat, isReturn}: SeatRowProps) {
    const {
        onSeatClick,
        isActive,
    } = useUpdateSeatPickerSeats(seat, isReturn)

    const getDisplaySeatNumber = () => {
        if (seat.seatNumber) {
            return getFormattedSeatNumber(seat)
        }

        return null
    }

    const seatNumber = getDisplaySeatNumber()
    const activeClass = isActive() ? 'gb--seat-picker-seat-selected' : 'gb--seat-picker-seat-available'
    const className = seatNumber ? activeClass : 'gb--seat-picker-seat-unavailable'

    return (
        <button
            className={`gb--seat-picker-seat ${className}`}
            onClick={onSeatClick}
            type={'button'}
        >
            <span>
                {seatNumber ? (
                    <small>
                        {seatNumber}
                    </small>
                ) : (
                    <IconTimes
                        height={18}
                        width={18}
                        viewBox="0 0 20 15"
                    />
                )}
            </span>
        </button>
    );
}

export default Seat;