import React from 'react';
import Portal from "@Portals/components/Portal";
import TableOfContents from "@Features/tableOfContents/components/TableOfContents";

function TableOfContentsPortal() {
    if (window.tableOfContents) {
        return (
            <Portal id="table_of_contents">
                <TableOfContents/>
            </Portal>
        );
    }
}
export default TableOfContentsPortal;