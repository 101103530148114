import React from 'react';
import Translation from "@Translation/components/Translation";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {setSortBy} from "@SearchResults/reducers/filterSlice";
import sortingOptions from "@SearchResults/features/sorting/constants/sortingOptions";
import SortOption from "@SearchResults/features/sorting/components/SortOption";
import Row from "@Containers/components/Row";
import ActiveFiltersCount from "@SearchResults/components/HeadingContent/ActiveFiltersCount";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import MobileFiltersButton from "@SearchResults/components/HeadingContent/Mobile/MobileFiltersButton";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import SearchResultsSortingOptionsEventModel from "@Events/models/SearchResultsSortingOptionsEventModel";
import TravelingDotComSortIcons from "@SearchResults/features/sorting/components/TravelingDotComSortIcons";
import useIsTablet from "@Hooks/selector/useIsTablet";
import CurrencySelect from "@SearchResults/features/sorting/features/currency/components/CurrencySelect";

function Sorter({hideFilterButton}: { hideFilterButton?: boolean }) {
    const isDesktop = useIsDesktop()
    const sortByLabel = useTranslation("sys.sort_by")
    const selectedSortKey = useAppSelector((state) => state?.filter.sortBy)
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const isTablet = useIsTablet()
    const onSortingChange = (sortingMethod) => dispatch(setSortBy(sortingMethod))

    const dispatchUseSearchResultsSortingOptions = useSingleTrackingEvent("use_search_results_sorting_options", SearchResultsSortingOptionsEventModel(selectedSortKey))
    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        dispatchUseSearchResultsSortingOptions()
    }

    return (
        <Row justify center className="gb--sorter__wrapper">
            {isMobile && (
                <CurrencySelect/>
            )}
            <Row className="gb--sorter" center>
                {isDesktop && (
                    <span className="sort-by-label">
                        {`${sortByLabel}: `}
                    </span>
                )}
                <Row gap>
                    {sortingOptions.map((tag) => (
                        <SortOption
                            key={tag.label}
                            onClick={onSortingChange}
                            value={tag.value}
                            label={<Translation translationKey={tag.label}/>}
                            icon={(isMobile ? null : <TravelingDotComSortIcons tag={tag.value}/>)}
                            active={tag.value === selectedSortKey}
                            onBlur={handleOnBlur}
                        />
                    ))}
                </Row>
            </Row>
            {!hideFilterButton && (
                <>
                    {(isMobile || isTablet) ? (
                        <MobileFiltersButton/>
                    ) : (
                        <ActiveFiltersCount/>
                    )}
                </>
            )}
        </Row>
    )
}

export default Sorter;