import React from 'react'
import IconStarFull from "@Icons/IconStarFull";
import IconAppleSymbol from "@Icons/IconAppleSymbol";
import IconGooglePlaySymbol from "@Icons/IconGooglePlaySymbol";
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import {Colors} from "@Styles/Colors.style";

interface ReviewDisplayProps {
    username: string,
    review: string,
    appStore: string
}

const ReviewDisplay = ({username, review, appStore = "google"}: ReviewDisplayProps) => {
    return (
        <Column className="app-review-container">
            <div>
                <div>
                    <IconStarFull fill="#F1C644"/>
                    <IconStarFull fill="#F1C644"/>
                    <IconStarFull fill="#F1C644"/>
                    <IconStarFull fill="#F1C644"/>
                    <IconStarFull fill="#F1C644"/>
                </div>
                <p className="gb--emphasize gb--emphasize-default app-review-username">
                    {username}
                </p>
                <p className="app-review-content">
                    {review}
                </p>
            </div>
            {appStore === "apple" && (
                <Row gap>
                    <Row
                        center
                        className="app-review-icon-container app-review-apple-icon"
                    >
                        <IconAppleSymbol
                            width={20}
                            height={20}
                            fill={Colors.neutralDarkestColor}
                        />
                    </Row>
                    <a
                        href={window.appStoreUrl}
                        className="app-review-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        App Store
                    </a>
                </Row>
            )}
            {appStore === "google" && (
                <Row gap>
                    <Row
                        center
                        className="app-review-icon-container"
                    >
                        <IconGooglePlaySymbol/>
                    </Row>
                    <a
                        href={window.googleStoreUrl}
                        className="app-review-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Google Play
                    </a>
                </Row>
            )}
        </Column>
    )
}

export default ReviewDisplay