import React from 'react';
import SortingOptions from "@SearchResults/features/sorting/components/SortingOptions";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useChangeCurrency} from "@Currency/hooks/useChangeCurrency";
import useSearchResults from "@SearchResults/hooks/useSearchResults";
import useCurrency from "@Currency/hooks/useCurrency";
import {toggleCurrencySelect} from "@Reducers/mobileSlice";
import MobileOverlay from "./MobileOverlay";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";

function MobileCurrencySelect() {
    const title = useTranslation("sys.currency")
    const dispatch = useAppDispatch()
    const changeCurrency = useChangeCurrency()
    const {data: {result: {currencyRates}}} = useSearchResults()
    const selectedCurrency = useCurrency()
    const selectAndClose = (selection) => () => {
        changeCurrency(selection)
        dispatch(toggleCurrencySelect())
    }

    return (
        <MobileOverlay
            slideInDirection="down"
            backClick={() => dispatch(toggleCurrencySelect())}
            title={title}
        >
            <SortingOptions
                onSelection={(selection) => selectAndClose(selection)}
                sortingOptions={currencyRates}
                value="val"
                label="val"
                additionalLabel="sign"
                selected={selectedCurrency?.val}
            />
        </MobileOverlay>
    );
}

export default MobileCurrencySelect;