import React from 'react';
import GenericPopover from "@Popover/components/GenericPopover";
import Row from "@Containers/components/Row";
import LoaderButton from "@Generic/loaders/LoaderButton";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import FeesNotice from "@CheckOrder/features/paymentSummary/features/totalPrice/components/FeesNotice";
import PricesInOtherCurrencies
    from "@CheckOrder/features/paymentSummary/features/totalPrice/components/PricesInOtherCurrencies";
import TotalLabel from "@CheckOrder/features/paymentSummary/features/totalPrice/components/TotalLabel";
import useGetDisplayPriceWithAncillaries from "@CheckOrder/hooks/useGetDisplayPriceWithAncillaries";
import useGetPricesInOtherCurrencies from "@CheckOrder/hooks/useGetPricesInOtherCurrencies";

function TotalPrice() {
    const {
        ticket: {totalPrice, bookingFeeRange},
        ancillaries: {premiumSupport: {displayCurrencySymbol}},
        passengerDataUpdating,
        userConfirmed,
    } = useAppSelector((state) => state?.checkOrder)

    const price = useGetDisplayPriceWithAncillaries();
    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(
        totalPrice,
        true,
        false
    )
    const pricesInOtherCurrencies = useGetPricesInOtherCurrencies();
    const displayPriceTrigger = () => (
        <>
            {(passengerDataUpdating && !userConfirmed) ? (
                <LoaderButton fill="#7ed321"/>
            ) : (
                <strong className="value-with-currency">
                    {price} {displayCurrencySymbol}
                    {shouldDisplayPriceInKn && ` ${priceInKn}`}
                </strong>
            )}
        </>
    )
    const displayPricePopover = () => {
        return (
            <PricesInOtherCurrencies
                pricesInOtherCurrencies={pricesInOtherCurrencies}
            />
        )
    }

    return (
        <Row justify className="total-price">
            {bookingFeeRange ? (
                <FeesNotice/>
            ) : (
                <TotalLabel
                    showInfoIcon={false}
                />
            )}
            <GenericPopover
                trigger={displayPriceTrigger()}
                overlay={displayPricePopover()}
                id="price-overlay-trigger"
            />
        </Row>
    );
}

export default TotalPrice;