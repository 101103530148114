import React from 'react';
import Link from "@Generic/navigation/Link";
import Countries from "@Pages/homepage/features/countries/components/Countries";
import Translation from "@Translation/components/Translation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function CountriesSection() {
    const {countriesUrl} = useAppSelector((state) => state?.page.homepageData)

    return (
        <section className="countries-network-section">
            <div className="countries-network-section-content">
                <h2>
                    <Translation translationKey="home_page.transportation_network_section.title"/>
                </h2>
                <p>
                    <Translation translationKey="home_page.transportation_network_section.description" translationVariables={{countryCount: '58'}}/>
                </p>
                <Link href={countriesUrl} className="btn btn--blue">
                    <Translation translationKey="home_page.transportation_network_section.cta"/>
                </Link>
                <Countries/>
            </div>
        </section>
    );
}

export default CountriesSection;