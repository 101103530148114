import React from 'react';
import TermWithCheckbox from "@Generic/form/TermWithCheckbox";
import GbbTermsAndConditions from "./GbbTermsAndConditions";
import CompanySpecificTermsAndConditions
    from "@CheckOrder/components/MainSection/TermsAndConditions/Checkboxes/CompanySpecificTermsAndConditions";
import {useTranslation} from "@Translation/hooks/useTranslation";

type TosAndPrivacyPolicyProps = {
    userConfirmed: boolean,
    onClick: () => void,
    value: boolean,
    singleRouteCompanyTermsUrl: null | string,
    returnRouteCompanyTermsUrl: null | string,
    carrierName: string,
}

function TosAndPrivacyPolicy(props: TosAndPrivacyPolicyProps) {
    const {
        userConfirmed,
        onClick,
        value,
        singleRouteCompanyTermsUrl,
        returnRouteCompanyTermsUrl,
        carrierName,
    } = props

    return (
        <TermWithCheckbox
            onClick={onClick}
            value={value}
            userConfirmed={userConfirmed}
            errorLabelText={useTranslation("t_summary.you_must_accept")}
        >
            {(singleRouteCompanyTermsUrl || returnRouteCompanyTermsUrl) ? (
                <CompanySpecificTermsAndConditions
                    singleRouteCompanyTermsUrl={singleRouteCompanyTermsUrl}
                    returnRouteCompanyTermsUrl={returnRouteCompanyTermsUrl}
                    carrierName={carrierName}
                />
            ) : (
                <GbbTermsAndConditions/>
            )}
        </TermWithCheckbox>
    );
}

export default TosAndPrivacyPolicy;