import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconWiFi(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1.3643 3.4149)">
                    <g stroke={stroke} strokeWidth="1.2">
                        <path
                            d="m13.2714502 2.31964784c-1.801987-1.47190645-4.19430739-2.31964784-6.70250001-2.31964784-2.48818398 0-4.77344303.86882493-6.56895019 2.31964784"
                        />
                        <path
                            d="m9.46954385 6.79194628c-.77374854-.68082547-1.78895001-1.09374357-2.90059366-1.09374357-1.11973789 0-2.14162402.41895317-2.91745693 1.10865805"
                        />
                        <path
                            d="m11.3036894 4.5402904c-1.2742986-1.08801089-2.92780569-1.74490743-4.73473921-1.74490743-1.81289111 0-3.47134243.66123535-4.74733115 1.75568322"
                        />
                    </g>
                    <path
                        d="m5.22663851 8.45263419c.88925336 1.10891092 1.33388004 1.66336641 1.33388004 1.66336641s.44735557-.55445549 1.34206672-1.66336641c-.87411618-.62697772-1.76396996-.70167258-2.67594676 0z"
                        fill={stroke}
                    />
                </g>
            )}
        />
    )
}

export default IconWiFi