import {memo} from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import TravelingDotComCountryHome from "@Pages/homepage/features/countries/components/TravelingDotComCountryHome";

function Countries() {
    const {countries} = useAppSelector((state) => state?.page.homepageData);
    return (
        // @ts-ignore
        <div className="countries-network-section__cards">
            {countries.map((country) => (
                <TravelingDotComCountryHome
                    country={country}
                    key={country?.name}
                />
            ))}
        </div>
    );
}

export default memo(Countries);