import IconType from "@/types/Icon.type";
import CardWrapper, {CardWrapperWithPredefinedChildrenProps} from "@Generic/cards/CardWrapper";
import React, {ReactNode} from "react";
import Column from "@Containers/components/Column";
import useIsMobile from "@Hooks/selector/useIsMobile";

export type CardIconCenteredProps = CardWrapperWithPredefinedChildrenProps & {
    Icon: React.ComponentType<IconType>,
    title: string | ReactNode,
    description: string | ReactNode,
    onClick?: () => void
}

/**
 * Card where the icon is the focal point of interest
 *
 * Icon first, then title, then description
 */
function CardIconCentered({Icon, type, cardProps, title, description, onClick}: CardIconCenteredProps) {
    const className = `${cardProps?.className ?? ""} card-icon-centered ${type === "nav-link" || type === "link" ? "card-icon-centered__clickable" : ""}`
    const isMobile = useIsMobile()

    return (
        // @ts-ignore
        <CardWrapper
            type={type}
            onClick={onClick}
            cardProps={{...(cardProps ?? {}), className}}
        >
            <Icon
                width={isMobile ? 56 : 72}
                height={isMobile ? 56 : 72}
                className="card-icon-centered__icon"
            />
            <Column className={type === "button" ? "" : "card-icon-centered__title-wrapper"}>
                <h4 className="card-icon-centered__title">
                    {title}
                </h4>
                <p className="card-icon-centered__description">
                    {description}
                </p>
            </Column>
        </CardWrapper>
    )
}

export default CardIconCentered