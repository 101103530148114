import React, {useEffect} from 'react';
import SlideInContainer from "@Containers/components/SlideInContainer";
import Row from "@Containers/components/Row";
import IconTimes from "@Icons/controls/IconTimes";
import TravelingDotComLogoContainer from "@Generic/logotypes/TravelingDotComLogoContainer";
import Form from "../Form";
import {setFormVisibility} from "../../reducers/formSlice";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import Column from "@Containers/components/Column";
import {useBlocker} from "react-router";

function MobileFormOverlay() {
    const dispatch = useAppDispatch()
    const closeForm = () => dispatch(setFormVisibility(false))
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return currentLocation.pathname === nextLocation.pathname
    })

    useEffect(() => {
        if (blocker.state === "blocked") {
            closeForm()
        }
    }, [blocker])

    return (
        <SlideInContainer className="gb--mobile-form-overlay-container" slideInDirection="up">
            <Column>
                <div className="gb--mobile-form-overlay-toggle"/>
                <Row center justify className="gb--mobile-form-overlay-title">
                    <span>
                        <TravelingDotComLogoContainer/>
                    </span>
                    <button type="button" onClick={closeForm}>
                        <IconTimes/>
                    </button>
                </Row>
                <Form/>
            </Column>
        </SlideInContainer>
    );
}

export default MobileFormOverlay;