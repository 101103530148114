import Column from "@Containers/components/Column";
import IconChevron from "@Icons/chevrons/IconChevron";
import {Colors} from "@Styles/Colors.style";
import React, {useRef} from "react";

interface BrandFaqCardProps {
    question: string,
    answer: string,
    index: number,
    displayAnswer: boolean,
    handleItemClick: (index: number) => void
}

export const BrandFaqCard = (props: BrandFaqCardProps) => {
    const {question, answer, index, displayAnswer, handleItemClick} = props;
    const contentHeight = useRef<HTMLDivElement>(null);

    return (
        <Column className="accordion accordion__container">
            <button
                className={`accordion__container-toggle ${displayAnswer ? "active" : ""}`}
                onClick={() => handleItemClick(index)}
            >
                <IconChevron fill={Colors.primaryColor} chevronOrientation={displayAnswer ? "up" : "down"}/>
                <span className="accordion__container-toggle-content">{question}</span>
            </button>

            <div
                ref={contentHeight}
                className="accordion__container-collapse"
                style={
                    displayAnswer
                        ? {height: contentHeight?.current?.scrollHeight}
                        : {height: "0px"}
                }
            >
                <p className="accordion__container-collapse-content" dangerouslySetInnerHTML={{__html: answer}}/>
            </div>
        </Column>
    )
}