import Row from "@Containers/components/Row";
import IconArrowAirplane from "@Icons/arrows/IconArrowAirplane";
import {Colors} from "@Styles/Colors.style";
import {Palette} from "@Styles/Palette.style";
import CardWrapper, {CardWrapperWithPredefinedChildrenProps} from "@Generic/cards/CardWrapper";
import React, {ReactNode, useRef} from "react";
import useHover from "@Hooks/effects/useHover";

export type CardImageCenteredProps = CardWrapperWithPredefinedChildrenProps & {
    imageSrc: string,
    imageAlt: string,
    title: string | ReactNode,
    description: string | ReactNode
}

/**
 * Card where the image is the focal point of interest
 *
 * Image first, then title, then description
 */
function CardImageCentered({cardProps, title, description, type, imageSrc, imageAlt}: CardImageCenteredProps) {
    const cardRef = useRef<HTMLAnchorElement>(null)
    const isHovered = useHover(cardRef)

    return (
        // @ts-ignore
        <CardWrapper
            type={type}
            cardProps={{...cardProps, linkRef: cardRef}}
        >
            <div className="gb--card-media_image">
                <img
                    src={imageSrc}
                    alt={imageAlt}
                />
                <p>
                    {title}
                </p>
            </div>
            <Row justify center className="gb--card-media_description">
                {description}
                <IconArrowAirplane
                    fill={isHovered ? Colors.primaryColor : Palette.jet}
                />
            </Row>
        </CardWrapper>
    )
}

export default CardImageCentered