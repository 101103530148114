import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconPicture(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <svg xmlns="http://www.w3.org/2000/svg">
                    <g
                        fill="none"
                        fillRule="evenodd"
                        stroke={stroke}
                        transform="translate(1.7627 1.9552)"
                    >
                        <g strokeWidth="1.2">
                            <path
                                d="m12.4841645-.6v12.7219408h-12.51300002v-12.7219408z"
                                strokeLinejoin="round"
                            />
                            <path
                                d="m0 7.08113865 2.54545567-2.4713001c2.63272575 2.59474175 3.94908863 3.89211262 3.94908863 3.89211262"
                            />
                            <path
                                d="m4.10231858 12.0895806s4.91637144-5.948754 4.91637144-5.948754 1.15196758.78704153 3.45590288 2.36112457"
                            />
                        </g>
                        <circle cx="8.711032" cy="3.281868" fill={stroke} r="1"/>
                    </g>
                </svg>
            )}
        />
    )
}

export default IconPicture