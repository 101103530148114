import useLocale from "@Hooks/selector/useLocale";
import {LocaleType} from "@/types/Locale.type";

/**
 * This is a hook used to visually modify calendar by appending classNames to the main calendar wrapper
 */
export default function useGenerateDatePickerMainClassNameHandler(calendarClassName: string) {
    const locale = useLocale()

    const forceCapitalizeMonth = () => {
        return locale === "hr" || locale === "bs" ? "react-datepicker__capitalize-current-month" : ""
    }

    const highlightWeekend = () => {
        const localesWithWeekStartingWithSunday: LocaleType[] = ["en-us"]

        const localesMappedToWeekSettings = {
            // If we are to add Iraqi, Afghani or Somali (or other languages whose week starts on saturday (who are supported by react-datepicker))
            // add another attribute to the object
            [`${localesWithWeekStartingWithSunday.includes(locale)}`]: "react-datepicker__week-starting-with-sunday",
        }

        return localesMappedToWeekSettings?.true ?? "react-datepicker__week-starting-with-monday"
    }

    return `${calendarClassName} ${forceCapitalizeMonth()} ${highlightWeekend()}`
}