import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useLocale from "@Hooks/selector/useLocale";
import useSharedImagesPathAsMethod from "@Images/hooks/useSharedImagesPathAsMethod";

type CompanyStoreHomepageProps = {}

function CompanyStoreHomepage(props: CompanyStoreHomepageProps) {
    const {introContent, style, descriptionContent} = useAppSelector((state) => state?.page.companyStore)
    const locale = useLocale()
    const introKey = locale === 'en' ? 'content' : `content${locale.toUpperCase()}`
    const descriptionTitleKey = locale === 'en' ? 'title' : `title${locale.toUpperCase()}`
    const {getPath} = useSharedImagesPathAsMethod()

    return (
        <div className="gb--content-without-sidebar gb--main-content">
            <div className="gb--column company-store-homepage">
                <div className="intro gb--row gb--row-justify gb--row-wrap">
                    <div>
                        <h2 className="blue">
                            {introContent[descriptionTitleKey]}
                        </h2>
                        <div dangerouslySetInnerHTML={{__html: introContent[introKey]}}/>
                    </div>
                    <div className="intro-ticket">
                        <img
                            className="intro__logo"
                            width="100"
                            height="50"
                            alt="Company logo"
                            src={`https://d2bgjx2gb489de.cloudfront.net/admin/public_html/${style.topLogoImage}`}
                        />
                        <img
                            width="100%"
                            height="auto"
                            alt="Mobile phone"
                            src={getPath('images/mobile_ticket.png')}
                        />
                    </div>
                </div>
                <hr/>
                <div className="intro--2">
                    <h2 className="blue">
                        {descriptionContent[descriptionTitleKey]}
                    </h2>
                    <div dangerouslySetInnerHTML={{__html: descriptionContent[introKey]}}/>
                </div>
            </div>
        </div>
    );
}

export default CompanyStoreHomepage;