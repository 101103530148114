import Translation from "@Translation/components/Translation";
import IconCamera from "@Icons/IconCamera";
import {useImageUpload} from "@Features/imageUpload/hooks/useImageUpload";
import {ReactNode} from "react";

interface ImageUploadProps {
    headline?: string;
    placeholder?: string;
    inputId: string;
    boxText?: string | ReactNode;
}

export const ImageUpload = (props: ImageUploadProps) => {
    const {
        headline,
        inputId,
        placeholder,
        boxText,
    } = props;

    const {
        imageSrc,
        inputRef,
        imgPlaceholderRef,
        wrapperDivRef,
        handleImageChange,
        checkImageSizeAndType,
    } = useImageUpload();

    return (
        <>
            {headline && (
                <>
                    <h3>
                        {headline}
                    </h3>
                    <div className="line">
                        <div className="triangle"/>
                    </div>
                </>
            )}
            <div className="ticket-image">
                <div
                    onClick={() => {
                        if (!imageSrc) {
                            inputRef.current?.click();
                        }
                    }}
                    className="add-ticket-photo"
                >
                    <div className="review-ticket-image" ref={wrapperDivRef}>
                        <p>
                            {boxText}
                        </p>
                        <input
                            ref={inputRef}
                            type="file"
                            name={`${inputId}Photo`}
                            id={`${inputId}Photo`}
                            onChange={(e) => {
                                const file = e.target.files?.[0];
                                if (file && checkImageSizeAndType(file)) {
                                    handleImageChange(e);
                                }
                            }}
                        />
                    </div>
                    <img src={imageSrc} alt={placeholder} id={`${inputId}Image`} ref={imgPlaceholderRef}/>
                </div>
            </div>
        </>
    )
}