import React from 'react';
import RouteOtherDatesSuggestions
    from "@RouteSuggestions/features/suggestedDates/components/RouteOtherDatesSuggestions";
import RoutesList from "@RoutesList/components/RoutesList";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useShowNoRoutesNotification from "@SearchResults/features/notifications/hooks/useShowNoRoutesNotification";
import useShowMobileAppPromoNotice from "../../notifications/hooks/useShowMobileAppPromoNotice";
import {PreparedTabContentDataType} from "@Tabs/types/PreparedTabContentData.type";
import RouteNotifications from "@SearchResults/components/Notifications/RouteNotifications";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import useSingleSelectedRoute from "@Route/hooks/useSingleSelectedRoute";
import useAdditionalInfo from "@Route/hooks/useAdditionalInfo";
import useCompanyMainTransportType from "@Hooks/selector/useCompanyMainTransportType";
import SuggestedRoutesList from "@RouteSuggestions/components/SuggestedRoutesList";
import RoutePlaceholder from "@SearchResults/components/Placeholders/RoutePlaceholder";
import TopBanners from "@SearchResults/features/banners/components/TopBanners";
import BottomBanners from "@SearchResults/features/banners/components/BottomBanners";
import LoadLaterDepartures from "@Tabs/components/LoadLaterDepartures";
import LoadEarlierDepartures from "@Tabs/components/LoadEarlierDepartures";

type TabContentProps = {
    preparedTabContentData: PreparedTabContentDataType,
}

/**
 * Show tab content.
 */
function TabContent({preparedTabContentData}: TabContentProps) {
    const {
        activeTab,
        singleRoutes,
        suggestedRoutes,
        suggestionRoutesEnabled,
        result: {
            intermodalRoutes,
            params,
            passengerGroups,
            routeIcons,
            companies,
            getByFerryData,
            resolvedApiCount,
            transfersData,
            totalApiCount,
            resolvedSuggestionsCount,
            totalSuggestionsCount,
            featuredRoutes,
        },
        tabsToShow,
    } = preparedTabContentData

    const isCompanyStore = useIsCompanyStore()
    const singleSelectedRoute = useSingleSelectedRoute()
    const additionalInfoActive = useAdditionalInfo()
    const {filteredRoutesCount} = useAppSelector((state) => state.filter)
    const companyMainTransportType = useCompanyMainTransportType()
    const apiRoutesLoading = resolvedApiCount < totalApiCount
    const suggestionRoutesLoading = resolvedSuggestionsCount < totalSuggestionsCount
    const noRoutesNotification = useShowNoRoutesNotification({
        singleRoutes,
        activeTab,
        apiRoutesLoading,
        companyMainTransportType,
        suggestionRoutesLoading,
    })

    // const showTripDelayNotification = useShowTripDelayNotification()
    // const onlyFootPassengersNotification = useShowOnlyFootPassengersNotification()
    const showMobileAppPromo = useShowMobileAppPromoNotice(params)
    const hasActiveRoutes = !!singleRoutes.length || !!intermodalRoutes.length || !!suggestedRoutes.length

    return (
        <div className="search-results_main-content">
            {!additionalInfoActive && (
                <RouteNotifications
                    companies={companies}
                    params={params}
                    noRoutesNotification={noRoutesNotification}
                    allRoutesFilteredNotification={!apiRoutesLoading && filteredRoutesCount === 0}
                    onlyFootPassengersNotification={false}
                    activeTab={activeTab}
                    tabsToShow={tabsToShow}
                    hasActiveRoutes={hasActiveRoutes}
                    mobileAppPromoNotification={showMobileAppPromo}
                />
            )}

            <TopBanners
                numberOfResults={singleRoutes.length}
                hasActiveRoutes={hasActiveRoutes}
                transfersData={transfersData}
                params={params}
                getByFerryData={getByFerryData}
                activeTab={activeTab}
            />

            <LoadEarlierDepartures/>

            <RoutesList
                singleSelectedRoute={singleSelectedRoute}
                intermodalRoutes={intermodalRoutes}
                companies={companies}
                routeIcons={routeIcons}
                passengerGroups={passengerGroups}
                routes={singleRoutes}
                params={params}
                featuredRoutes={featuredRoutes}
            />

            {suggestionRoutesEnabled && (
                <SuggestedRoutesList
                    resolvedSuggestionsCount={resolvedSuggestionsCount}
                    totalSuggestionsCount={totalSuggestionsCount}
                    suggestedRoutes={suggestedRoutes}
                    companies={companies}
                    routeIcons={routeIcons}
                    passengerGroups={passengerGroups}
                    params={params}
                />
            )}
            {apiRoutesLoading && (
                <RoutePlaceholder/>
            )}
            {suggestionRoutesLoading && suggestionRoutesEnabled && (
                <RoutePlaceholder/>
            )}
            <LoadLaterDepartures/>
            {!apiRoutesLoading && (
                <RouteOtherDatesSuggestions
                    params={params}
                    suggestionData={preparedTabContentData.suggestionData}
                    hasRoutesOnSelectedDate={hasActiveRoutes}
                />
            )}
            <BottomBanners
                numberOfResults={singleRoutes.length}
                hasActiveRoutes={hasActiveRoutes}
                transfersData={transfersData}
                getByFerryData={getByFerryData}
                params={params}
                apiRoutesLoading={apiRoutesLoading}
                suggestionRoutesLoading={suggestionRoutesLoading}
                isCompanyStore={isCompanyStore}
            />
        </div>
    );
}

export default TabContent
