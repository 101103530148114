import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconCharger(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(5.0589 .8672)"
                >
                    <path d="m2.94109978 14.2656204c0-.942808 0-2.3951555 0-4.35704231"/>
                    <path
                        d="m0 4.59588716v3.59480337c.0255983.74576096.96528214 1.11864143 2.81905151 1.11864143 1.85376938 0 2.87481873-.37288047 3.06314805-1.11864143v-3.59480337c-2.80086603 0-4.50348983 0-5.10787139 0-.51621878 0-.77432817 0-.77432817 0z"
                    />
                    <g strokeLinecap="round" strokeLinejoin="round">
                        <path d="m1.091585 4.595887v-2.840128"/>
                        <path d="m4.718508 4.595887v-2.840128"/>
                    </g>
                </g>
            )}
        />
    )
}

export default IconCharger