import FilterButton from "@Generic/buttons/FilterButton";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import usePopularFilters from "@SearchResults/features/sidebar/features/popularFilters/hooks/usePopularFilters";
import useDispatchFilterEvents from "@SearchResults/features/sidebar/hooks/useDispatchFilterEvent";
import IconMTicket from "@Icons/ticket/IconMTicket";
import {Colors} from "@Styles/Colors.style";

function MTicketEcoFriendlyFilter() {
    const label = useTranslation("search_results.filter_by.popular_filters.filters.m_ticket")
    const {isActive, setActive} = usePopularFilters("mTicket")

    const handleOnBlur = useDispatchFilterEvents('mTicketEcoFriendlyFilter', isActive.toString());

    return (
        <FilterButton
            label={label}
            active={isActive}
            icon={(
                <IconMTicket
                    fill={isActive ? Colors.primaryColor : Colors.neutralDarkestColor}
                    width="20"
                    height="20"
                />
            )}
            onClick={setActive}
            onBlur={handleOnBlur}
        />
    );
}

export default MTicketEcoFriendlyFilter;