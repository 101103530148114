import React from 'react'
import ReviewDisplay from "@Pages/mobileApp/features/appReviews/components/ReviewDisplay";
import {reviews} from "@Pages/mobileApp/constants/reviews";
import UserSection from "@Pages/mobileApp/features/appReviews/components/UsersSection";
import Column from "@Containers/components/Column";
import ReviewsNumbersSection from "@Pages/mobileApp/features/appReviews/components/ReviewsNumbersSection";
import Translation from "@Translation/components/Translation";

const MobileAppReviews = () => {
    return (
        <Column>
            <UserSection/>
            <div className="gb-container mobile-app-landing-page-section">
                <h2 className="app-reviews-section-title">
                    <Translation translationKey="mobile_app.user_reviews_section.title"/>
                </h2>
                <div className="app-reviews-section-container">
                    {reviews.map((review) => (
                        <ReviewDisplay
                            username={review.username}
                            review={review.content}
                            appStore={review.store}
                            key={review.content}
                        />
                    ))}
                </div>
            </div>
            <ReviewsNumbersSection/>
        </Column>
    )
}

export default MobileAppReviews