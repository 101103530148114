import React from 'react';
import Translation from "@Translation/components/Translation";
import Link from "@Generic/navigation/Link";
import {useTranslation} from "@Translation/hooks/useTranslation";
import Button from "@Generic/buttons/Button";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useInput} from "@Hooks/dispatch/useInput";
import {useToggle} from "@Hooks/dispatch/useToggle";
import ajaxHandler from "@Ajax/v2/ajaxHandler";
import validateEmail from "@User/functions/validateEmail";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {activatePopup} from "@Reducers/pageSlice";
import ParamsType from "@SearchResults/types/params/Params.type";
import SearchResultsUrlsType from "@SearchResults/types/urls/SearchResultsUrls.type";
import useSingleTrackingEvent from "@Events/hooks/useSingleTrackingEvent";
import SubmitEmailModel from "@Events/models/SubmitEmailEventModel";
import GenericCheckbox from "@Generic/form/GenericCheckbox";

function CollectEmail({params, urls}: { params: ParamsType, urls: SearchResultsUrlsType }) {
    const {
        date,
        departureStation: {
            id: dp,
        },
        destinationStation: {
            id: ds,
        },
    } = params

    const {
        general_terms: generalTerms,
        privacy_policy: privacyPolicy,
        no_results_email: noResultsEMail,
    } = urls

    const {
        enter_email: enterEmailLabel,
        email_entered: emailEnteredLabel,
    } = useTranslation("no_res")

    const {
        general_terms: generalTermsLabel,
        privacy_policy: privacyPolicyLabel,
    } = useTranslation("menu.footer")

    const {
        you_must_accept: youMustAcceptLabel,
        and: andLabel,
    } = useTranslation("sys")

    const invalidEmailLabel = useTranslation("log_in.invalid_email")
    const terms = useToggle()
    const email = useInput('')
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()

    const activatePopupMessage = (message, title = 'Error') => {
        dispatch(activatePopup({
            message,
            title,
        }))
    }

    const dispatchSubmitEmailEvent = useSingleTrackingEvent("search_trip_unavailability_email", SubmitEmailModel(email.value))

    const submitEMail = async () => {
        const mustAcceptTerms = `${youMustAcceptLabel} ${generalTermsLabel} ${andLabel} ${privacyPolicyLabel}`
        if (!terms.value) {
            activatePopupMessage(mustAcceptTerms)
        } else if (!email.value) {
            activatePopupMessage(invalidEmailLabel)
        } else {
            const emailValid = validateEmail(email.value)
            const params = {
                dp,
                ds,
                date,
                mail: email.value,
            };

            if (emailValid) {
                dispatchSubmitEmailEvent()

                const response = await ajaxHandler({
                    url: noResultsEMail,
                    params,
                    type: "post",
                    stringify: true,
                })
                if (response?.data?.message) {
                    activatePopupMessage(emailEnteredLabel, 'Success')
                    email.updateValue('')
                    terms.set(false)
                }
            } else {
                activatePopupMessage(invalidEmailLabel)
            }
        }
    }

    return (
        <div className="collect-email">
            <input
                className="gb--input-standalone collect-email__email-input"
                type="email"
                placeholder={enterEmailLabel}
                value={email.value}
                onChange={email.onChange}
            />
            <Button
                className="green"
                additionalClasses={isMobile ? 'btn--mobile' : 'btn--inline'}
                onClick={submitEMail}
            >
                <Translation translationKey="no_res.send"/>
            </Button>
            <div className="toc-info-section confirmation">
                <div className="toc-info">
                    <div className="wrapper">
                        <p className="confirmation-box confirmation__terms">
                            <GenericCheckbox
                                id="gbb-tos-and-privacy-policy"
                                name="gbb_tos_and_privacy_policy"
                                checked={terms.value}
                                onClick={terms.onChange}
                            />
                            <label htmlFor="gbb-tos-and-privacy-policy">
                                <span style={{color: "#9C9C9C"}} id="tos_text">
                                    <Translation translationKey="t_summary.read_and_agreed_to_the"/>
                                    &nbsp;
                                    <Link href={generalTerms}>
                                        <Translation translationKey="t_summary.terms_&_conditions"/>
                                        &nbsp;
                                    </Link>
                                    <Translation translationKey="sys.and"/>
                                    &nbsp;
                                    <Link href={privacyPolicy}>
                                        <Translation translationKey="menu.footer.privacy_policy"/>
                                    </Link>
                                </span>
                            </label>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CollectEmail;