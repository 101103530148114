import React, {useEffect} from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useIsLatestCookiePolicyAccepted from "@Cookies/hooks/useIsLatestCookiePolicyAccepted";
import useDialog from "@Features/dialog/hooks/useDialog";
import Translation from '@/features/translation/components/Translation';
import CookieDialog from "@Cookies/components/CookieDialog";
import IconCheckmarkPurple from "@Icons/IconCheckmarkPurple";

function CookieNoticeDialog() {
    const {cookiePreferencesActive} = useAppSelector((state) => state?.cookies)
    const latestCookiePolicyAccepted = useIsLatestCookiePolicyAccepted()

    const {dialogRef, openModal} = useDialog()
    useEffect(() => {
        if (!latestCookiePolicyAccepted && !cookiePreferencesActive) {
            openModal()
        }
    }, [latestCookiePolicyAccepted, cookiePreferencesActive])

    if (!latestCookiePolicyAccepted && !cookiePreferencesActive) {
        return (
            <CookieDialog dialogRef={dialogRef}>
                <p>
                    <Translation translationKey="cookie.notice.we_use_cookies"/>
                </p>
                <ul>
                    <li>
                        <IconCheckmarkPurple/>
                        <Translation translationKey="cookie.notice.deliver_and_maintain"/>
                    </li>

                    <li>
                        <IconCheckmarkPurple/>
                        <Translation translationKey="cookie.notice.track_outages"/>
                    </li>

                    <li>
                        <IconCheckmarkPurple/>
                        <Translation translationKey="cookie.notice.measure_audience"/>
                    </li>
                </ul>
                <p>
                    <Translation translationKey="cookie.notice.if_you_accept_all"/>
                </p>
                <ul>
                    <li>
                        <IconCheckmarkPurple/>
                        <Translation translationKey="cookie.notice.develop"/>
                    </li>
                    <li>
                        <IconCheckmarkPurple/>
                        <Translation translationKey="cookie.notice.measure_ads"/>
                    </li>
                </ul>
                <p>
                    <Translation translationKey="cookie.notice.if_you_reject"/>
                </p>
            </CookieDialog>
        );
    }

    return null
}

export default CookieNoticeDialog