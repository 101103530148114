import React, {useEffect} from 'react';
import Router from "@Routing/components/Router";
import ErrorBoundary from "@Errors/components/ErrorBoundary";
import Popup from "@Popups/components/Popup";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useDialog from "@Features/dialog/hooks/useDialog";

function Routing() {
    const {activePopup} = useAppSelector((state) => state?.page)
    const {dialogRef, openModal, closeModal} = useDialog()
    useEffect(() => {
        if (activePopup) {
            openModal()
        }
    }, [activePopup])

    return (
        <ErrorBoundary shouldReload>
            <Router/>
            {activePopup && (
                <Popup
                    dialogRef={dialogRef}
                    activePopup={activePopup}
                />
            )}
        </ErrorBoundary>
    )
}

export default Routing
