import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconDepartureTime(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(1.95 1.95)"
                >
                    <path
                        d="m6.05 0c-3.34132274 0-6.05 2.70867726-6.05 6.05s2.70867726 6.05 6.05 6.05 6.05-2.70867726 6.05-6.05c0-1.8707247-.8490635-3.54313999-2.18284923-4.65290461"
                    />
                    <path
                        d="m5.73275437 2.57522061v4.37027745l3.05976-1.48435367"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            )}
        />
    )
}

export default IconDepartureTime