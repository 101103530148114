import React from "react";
import CookieAcceptEssentialButton from "@Cookies/components/CookieAcceptEssentialButton";
import CookieAcceptAllButton from "@Cookies/components/CookieAcceptAllButton";
import Column from "@Containers/components/Column";

type CookieActionsProps = {}

function CookieActionButtons(props: CookieActionsProps) {
    return (
        <div className="gb--cookie-dialog-ctas">
            <Column>
                <CookieAcceptAllButton/>
                <CookieAcceptEssentialButton/>
            </Column>
        </div>
    );
}

export default CookieActionButtons;