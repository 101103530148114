import React from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {
    setIsBuyingPremiumSupport,
    setIsBuyingSMSNotification,
    setIsBuyingTier1FlexibleCancellation
} from "@CheckOrder/reducers/checkOrderSlice";
import AncillaryCard from "@CheckOrder/features/ancillaries/components/AncillaryCard";
import {useTranslation} from "@Translation/hooks/useTranslation";
import IconPremiumSupportAncillary from "@Icons/ancillaries/IconPremiumSupportAncillary";
import IconSmsReminderAncillary from "@Icons/ancillaries/IconSmsReminderAncillary";
import IconFlexibleCancellationAncillary from "@Icons/ancillaries/IconFlexibleCancellationAncillary";

const AncillariesSection = () => {
    const {
        companyStore: {
            isCompanyStore,
        },
        enabledClientsideFeatures: {
            smsAncillaryEnabled,
            premiumSupportAncillaryEnabled,
            tier1FlexibleCancellationEnabled,
        },
    } = useAppSelector((state) => state.page)

    const {
        ticket: {
            isIntermodal,
            ancillariesData: {
                tier1FlexibleCancellation: {
                    saleBlockTimesFormatted,
                    isEligibleForPurchase,
                },
            },
        },
        selectedServices: {
            isBuyingPremiumSupport,
            isBuyingSMSNotification,
            isBuyingTier1FlexibleCancellation,
        },
    } = useAppSelector((state) => (state?.checkOrder))

    const enhanceYourJourney = useTranslation("sys.enhance_your_journey");
    if (!isIntermodal && !isCompanyStore) {
        return (
            <div className="gb--card">
                <span className="gb--card-title">{enhanceYourJourney}</span>
                {premiumSupportAncillaryEnabled && (
                    <>
                        <AncillaryCard
                            ancillaryName="premiumSupport"
                            icon={(
                                <IconPremiumSupportAncillary
                                    className="ancillary-icon"
                                    width="20"
                                    height="20"
                                />
                            )}
                            cardTitleKey="sys.premium_support"
                            cardTextKey="sys.skip_the_line"
                            cardTextVariables={{}}
                            ancillaryCallback={setIsBuyingPremiumSupport}
                            ancillaryValue={isBuyingPremiumSupport}
                            frequentlyBoughtLabel={premiumSupportAncillaryEnabled && !(tier1FlexibleCancellationEnabled && isEligibleForPurchase)}
                        />
                        {smsAncillaryEnabled && (
                            <hr/>
                        )}
                    </>
                )}
                {smsAncillaryEnabled && (
                    <>
                        <AncillaryCard
                            ancillaryName="smsNotification"
                            icon={(
                                <IconSmsReminderAncillary
                                    className="ancillary-icon"
                                    width="20"
                                    height="20"
                                />
                            )}
                            cardTitleKey="sys.sms_notification"
                            cardTextKey="sys.stay_in_the_loop"
                            cardTextVariables={{}}
                            ancillaryCallback={setIsBuyingSMSNotification}
                            ancillaryValue={isBuyingSMSNotification}
                            frequentlyBoughtLabel={false}
                        />
                        {tier1FlexibleCancellationEnabled && isEligibleForPurchase && (
                            <hr/>
                        )}
                    </>
                )}
                {tier1FlexibleCancellationEnabled && isEligibleForPurchase && (
                    <AncillaryCard
                        ancillaryName="tier1FlexibleCancellation"
                        icon={(
                            <IconFlexibleCancellationAncillary
                                className="ancillary-icon"
                                width="20"
                                height="20"
                            />
                        )}
                        cardTitleKey="sys.tier1_flexible_cancellation"
                        cardTextKey="sys.cancel_or_modify"
                        cardTextVariables={{SaleBlockTime: `${saleBlockTimesFormatted.singleSaleBlockTime}h`}}
                        ancillaryCallback={setIsBuyingTier1FlexibleCancellation}
                        ancillaryValue={isBuyingTier1FlexibleCancellation}
                        frequentlyBoughtLabel={tier1FlexibleCancellationEnabled && isEligibleForPurchase}
                    />
                )}
            </div>
        );
    }
};

export default AncillariesSection
