import {ComponentType, ReactNode} from "react";
import IconType from "@/types/Icon.type";

interface TrvlMyProfileNotificationProps {
    Icon: ComponentType<IconType>,
    iconProps?: IconType,
    title: string,
    description: string | ReactNode,
}

function TrvlMyProfileNotification({Icon, iconProps = {}, description, title}: TrvlMyProfileNotificationProps) {
    return (
        <div className="my-profile__notification">
            <Icon
                width="32"
                height="32"
                className="my-profile__notification-icon"
                {...iconProps}
            />
            <div className="my-profile__notification-title">{title}</div>
            <div>{description}</div>
        </div>
    )
}

export default TrvlMyProfileNotification