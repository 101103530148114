import GenericSvgIcon from "@Icons/GenericSvgIcon";
import {Colors} from "@Styles/Colors.style";
import IconType from "@/types/Icon.type";

/**
 * Slightly tilted ticket icon with QR code visible
 */
function IconTicketQr(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        fill = Colors.primaryColor,
        ...rest
    } = props
    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <svg xmlns="http://www.w3.org/2000/svg">
                    <g fill={fill} transform="translate(1.0844 .4)">
                        <path
                            d="m7.45320913 14.4781759c-.50495511.7334533-1.47463323.9448846-2.17490366.4627264l-4.6355659-3.1912697c-.70031782-.4821055-.84893966-1.4633257-.34399508-2.19683175l1.35098269-1.96244054.66470398.45761842-1.35098269 1.96244057c-.24484714.355668-.16953306.8157087.15923806 1.042043l4.63556064 3.1912697c.32875532.2263341.78542137.1324934 1.03023692-.2231746l1.35098793-1.9624405.6647251.4576184z"
                            stroke={fill}
                            strokeWidth=".19"
                        />
                        <path
                            d="m12.8677983 5.18957505c.2448682-.35566799.169512-.81572986-.1592434-1.04206404l-.6320233-.43510612c-.6508755.62372496-1.6414596.75008873-2.37848654.24271154-.73702703-.50739298-.97252177-1.47783894-.6221759-2.30845588l-1.00285382-.69040876c-.32875533-.22633417-.78542137-.1324829-1.03028959.22318509l-3.37743302 4.90610132-.66470397-.45761842 3.37746461-4.90608553c.50495512-.73348492 1.47458059-.9448741 2.17490364-.4627528l1.29795954.89355131c.19041795.13108214.22580535.38357271.09236531.57738912-.35292614.51266429-.24239319 1.19946581.23475754 1.5279242.4770981.32845839 1.158095.18659142 1.5110212-.32607288.13344-.1938164.3819417-.25086862.5723597-.11978648l.9270763.63825395c.7003231.48212128.8489291 1.46334681.3440267 2.1968528l-3.3774962 4.90610133-.66467241-.4576184z"
                            stroke={fill}
                            strokeWidth=".19"
                        />
                        <path
                            d="m7.60529074 7.90547987.34128834-.49569189c.02353894-.03422923.07035358-.04286552.10458246-.01932635l.49573964.34129174c.03422888.02353918.04286506.07040686.01927344.10463612l-.34123566.49569183c-.02353895.03422919-.07040618.04286552-.10463506.01932635l-.49568704-.34129176c-.03422887-.02353917-.04286508-.07040685-.01932612-.10463604z"
                        />
                        <path
                            d="m7.53630635 6.94458647.25597943-.37178206c.02353894-.03422923.07040617-.04286552.10463505-.01932635l.37177847.25598195c.03422888.02353917.04286506.07035422.01927345.10458345l-.25592673.37178206c-.02353895.03422922-.07040625.04286552-.10463513.01932635l-.37177838-.2559293c-.0342289-.02359183-.0428651-.07040688-.01932616-.1046361z"
                        />
                        <path
                            d="m7.88370321 8.82782583.25597948-.37183471c.02353895-.0341766.0703535-.04286552.10458238-.01927368l.37177847.25592924c.03422888.02359184.04286507.07040693.01932612.10463612l-.25592673.37178212c-.02359162.03422918-.07040625.04286552-.10463513.01932634l-.3717784-.25598198c-.03422888-.02353918-.04286506-.07040686-.01932619-.10458345z"
                        />
                        <path
                            d="m6.72581889 8.12191389.21332498-.30980085c.02353894-.03422926.07040619-.04286552.10458242-.01932635l.30985044.21327439c.03422889.02359185.04286511.07040694.01932617.10463612l-.21332498.30985352c-.02353894.03417652-.0704062.04286552-.10463508.01927368l-.30979779-.2132744c-.03422889-.02353917-.0428651-.07040693-.01932616-.10463611z"
                        />
                        <path
                            d="m8.81809927 7.7358082.21327234-.30980084c.02359162-.03422922.07040617-.04286552.10463505-.01932634l.30979782.21327438c.03422888.02359184.04286506.07040693.01932611.10463611l-.21327226.30980085c-.02359162.03422926-.07040625.04286552-.10463513.01932635l-.3098504-.21327439c-.03417622-.02359185-.04286514-.07040694-.01927353-.10463612z"
                        />
                        <path
                            d="m5.0374394 6.59434249.767833-1.11539883c.09420843-.13686425.28157211-.1714621.41848767-.07720007l1.11533521.76784053c.13691556.09420936.17151307.28157488.07725197.41849179l-.76783299 1.11534618c-.0942611.13691689-.28162477.17146208-.41848767.07725271l-1.11538788-.76784052c-.13686289-.09426203-.1714604-.28162755-.07719931-.41849179zm1.22402511.47731339c.03422889.02353918.08104348.01490288.10463508-.01932634l.3412357-.49569186c.02353895-.03422922.01490273-.08109693-.01932615-.10463611l-.49568699-.34129172c-.03422889-.02353917-.08104348-.01490288-.10463508.01932635l-.3412357.49574452c-.02359161.03417656-.0149554.08104427.01927349.10458345z"
                        />
                        <path
                            d="m6.31712595 4.73537954.76788565-1.1153883c.09420843-.13689058.28157212-.17146736.41848767-.07723166l1.11533522.76785106c.13691552.09424096.17146041.28158541.07725195.41850232l-.76783294 1.11534618c-.09426113.1369169-.28162484.17146208-.41854037.07725273l-1.11533521-.76784054c-.1368629-.09426203-.17146041-.28162754-.07725197-.41849179zm1.22407776.4773134c.03417623.02353917.08104347.01490288.10458241-.01932635l.34128834-.49569186c.02353895-.03422922.01490276-.08109693-.01932612-.10463611l-.49568699-.34126538c-.03422889-.02356025-.08109613-.01491341-.10463508.01931055l-.34128836.49573398c-.02353895.03422923-.01490273.08104428.01932615.10458345z"
                        />
                        <path
                            d="m8.17617591 6.01507866.76783302-1.11534617c.09426113-.13691691.28162476-.1714621.41848769-.07725273l1.11538788.7678932c.1368629.09420935.1714604.28157487.0771993.41849178l-.76783298 1.11534617c-.09420846.13691691-.2815721.17146209-.4184877.07725273l-1.11533518-.76789319c-.1369156-.09420937-.17146041-.28157488-.07725203-.41849179zm1.22402515.47736605c.03422888.02353918.0810961.01490289.10463505-.01932634l.34128841-.49569186c.02353895-.03422922.01490269-.08109693-.01932619-.10463611l-.49573964-.34129172c-.03417621-.02353917-.08104351-.01490288-.10458239.01932635l-.34128841.49569186c-.02353895.03422922-.01490269.08109694.01932619.10463611z"
                        />
                    </g>
                </svg>
            )}
        />
    )
}

export default IconTicketQr