import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconDrinks(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke={stroke} transform="translate(4.7603 .8336)">
                    <path d="m1.948233 10.74174h2.582871" strokeLinecap="square" strokeWidth=".8"/>
                    <path d="m1.948233 12.030141h2.582871" strokeLinecap="square" strokeWidth=".8"/>
                    <g strokeWidth="1.2">
                        <path
                            d="m1.71059449 1.56736914v.84033862s-.57019816.31172089-1.71059449.93516268v2.01596074l.85529725.68897219-.85529725.68620765v7.51341428h3.23966857"
                            strokeLinejoin="round"
                        />
                        <path
                            d="m4.95026306 1.56736914v.84033862s-.57019816.31172089-1.71059449.93516268v2.01596074l.85529725.68897219-.85529725.68620765v7.51341428h3.23966857"
                            strokeLinejoin="round"
                            transform="matrix(-1 0 0 1 9.719 0)"
                        />
                        <path d="m1.257577 0h3.964183v1.567369h-3.964183z"/>
                    </g>
                </g>
            )}
        />
    )
}

export default IconDrinks