import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {Colors} from "@Styles/Colors.style";

function IconPenWithABoard(props: IconType) {
    const {
        width = "16",
        height = "16",
        viewBox = "0 0 16 16",
        stroke = Colors.primaryColor,
        ...rest
    } = props

    return (
        <GenericSvgIcon
            {...{width, height, viewBox, ...rest}}
            svgIconPath={(
                <g
                    fill="none"
                    fillRule="evenodd"
                    stroke={stroke}
                    strokeWidth="1.2"
                    transform="translate(1.4 .96)"
                >
                    <path
                        d="m3.7731408 10.705376h-2.8500648c-.5095376 0-.923076-.4796-.923076-1.07052v-8.5643184c0-.5909376.4135384-1.0705376.923076-1.0705376h10.153836c.50952 0 .923032.4796.923032 1.0705376v3.2387872"
                        strokeLinecap="round"
                    />
                    <path d="m0 2.34667h11.999944"/>
                    <path d="m2.400235 5.280572h6.599965" strokeLinecap="round"/>
                    <path d="m2.400235 7.626696h4.200029" strokeLinecap="round"/>
                    <path
                        d="m7.350024 13.51372-2.400024.566192.5711992-2.378904 5.4803848-5.5252208c.355168-.3616008.940808-.3651736 1.300816-.0083248l.629992.6244656c.360008.35684.3564.937376-.00836 1.289464z"
                        strokeLinejoin="round"
                    />
                </g>
            )}
        />
    )
}

export default IconPenWithABoard